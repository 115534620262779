import React from 'react';

import { FormLabel, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';

import { allCategoryNames } from 'components/utils/GlobalConstants';
import { MatchingColumns } from 'components/utils/UploadContactsUtils';

import './MatchColumnsForm.scss';

const useStyles = makeStyles({
    select: {
        height: '2.5rem',
        margin: '0.3rem 0 1.5rem',
    },
    menuHeight: {
        height: '20vh',
        minHeight: '10rem',
    },
    inputLabel: {
        fontSize: '0.9rem',
        marginBottom: '0.1rem',
        paddingLeft: '1rem',
    },
    descriptionLabel: {
        paddingBottom: '1.5rem',
    },
});

const MatchColumnForm = ({
    columnName,
    columns,
    formId,
    onSubmit,
}: {
    columnName: string;
    columns: MatchingColumns[];
    formId: string;
    onSubmit: (formData: Record<string, unknown>) => void;
}): JSX.Element => {
    const { handleSubmit, control, register } = useForm();
    const classes = useStyles();

    const matchingColumn = columns.find((column) => column.newColumn.Header === columnName);
    const matchedColumnNames = columns
        .filter((column) => allCategoryNames.includes(column.newColumn.Header as string))
        .map((column) => column.newColumn.Header as string);

    if (!matchingColumn) {
        return <div>Cannot find column!</div>;
    }
    const originalColumnName = matchingColumn.originalColumn.Header as string;

    const defaultValue = matchingColumn.ignored ? 'ignored' : matchingColumn.newColumn.Header;
    return (
        <>
            <FormLabel className={classes.descriptionLabel} component="legend">
                Unrecognized columns cannot be imported. Please relabel or ignore columns.
            </FormLabel>
            <form onSubmit={handleSubmit(onSubmit)} id={formId}>
                <Controller
                    name={originalColumnName}
                    control={control}
                    defaultValue={defaultValue}
                    as={({ onChange }) => (
                        <>
                            <InputLabel className={classes.inputLabel}>
                                Rename &quot;{originalColumnName}&quot; As
                            </InputLabel>
                            <Select
                                id={originalColumnName}
                                name={originalColumnName}
                                fullWidth
                                variant="outlined"
                                onChange={onChange}
                                inputProps={{
                                    'aria-label': originalColumnName,
                                }}
                                className={classes.select}
                                MenuProps={{
                                    classes: {
                                        paper: classes.menuHeight,
                                    },
                                }}
                                inputRef={register}
                                defaultValue={defaultValue}
                                required
                            >
                                <MenuItem value="">Select a category:</MenuItem>
                                <MenuItem value="ignored">(Ignore Column)</MenuItem>
                                {allCategoryNames.map((key) => {
                                    return (
                                        <MenuItem
                                            value={key}
                                            key={key}
                                            disabled={matchedColumnNames.includes(key) && key !== columnName}
                                        >
                                            {key}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </>
                    )}
                />
            </form>
        </>
    );
};

export default MatchColumnForm;
