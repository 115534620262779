import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
/* eslint-disable no-nested-ternary */
import {
    Button,
    Chip,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { camelCase } from 'lodash';
import { Controller, useForm } from 'react-hook-form';

import { GET_CONTACTS_FROM_FILTERS } from 'api/queries/Contact';
import { companyCerts, stateAbbreviations, techAreas } from 'components/utils/GlobalConstants';
import SegmentFilterChips from '../segmentFilterChips/SegmentFilterChips';

import './ListFilter.scss';

const ListFilter = ({
    matchType,
    setMatchType,
    filterFieldList,
    setFilterFieldList,
}: {
    matchType: string;
    setMatchType: (value: string) => void;
    filterFieldList: Array<any>;
    setFilterFieldList: (value: Array<any>) => void;
}): JSX.Element => {
    const filterCategories = [
        'Email Address',
        'Job Title',
        'Company Name',
        'State',
        'Company Certifications',
        'Tech Focus Areas',
    ];

    const removeFilterField = (index: number): void => {
        const newFilterFieldList = [...filterFieldList];
        newFilterFieldList.splice(index, 1);
        setFilterFieldList(newFilterFieldList);
    };

    const anyOrAll = (event) => {
        setMatchType(event.target.value);
    };
    const andOr = matchType === 'Any' ? 'or' : 'and';
    const contactsQuery = useQuery(GET_CONTACTS_FROM_FILTERS, {
        variables: {
            audienceId: 1,
            filters: filterFieldList.length ? JSON.stringify({ [andOr]: filterFieldList }) : null,
        },
    });

    const filterContains = React.useMemo(() => {
        return ['contains', 'not contains'];
    }, []);
    const filterEquals = React.useMemo(() => {
        return ['equals', 'not equals'];
    }, []);
    const filterAll = React.useMemo(() => {
        return ['equals', 'not equals', 'contains', 'not contains'];
    }, []);
    const [filterConditions, setFilterConditions] = useState(filterAll);
    const [filterValue, setFilterValue] = useState<string | Array<string>>('');
    const [categoryFilter, setCategoryFilter] = useState('');

    const changeFilterFieldType = (e: any): void => {
        const { value } = e.target;
        setCategoryFilter(value);
    };

    const { handleSubmit, control, formState } = useForm({ mode: 'onChange' });

    useEffect(() => {
        if (categoryFilter === 'state') {
            setFilterConditions(filterEquals);
            setFilterValue(Object.keys(stateAbbreviations));
        } else if (categoryFilter === 'companyCertifications' || categoryFilter === 'techFocusAreas') {
            setFilterConditions(filterContains);
            if (categoryFilter === 'companyCertifications') {
                setFilterValue(companyCerts);
            } else {
                setFilterValue(techAreas);
            }
        } else {
            setFilterConditions(filterAll);
            setFilterValue('');
        }
    }, [categoryFilter, filterAll, filterEquals, filterContains]);

    const submitForm = (data: any) => {
        const defaultFilter = { [data.category]: { [data.condition]: data.value } };
        setFilterFieldList([...filterFieldList, defaultFilter]);
    };

    return (
        <div className="FilterBuilder">
            <div className="step">
                <div className="step-text">1</div>
            </div>
            <h6>Enter Dynamic Filter Parameters</h6>
            <p className="pSubHead">Add conditions to create your segment.</p>
            <Grid container className="box">
                <form onSubmit={handleSubmit(submitForm)} className="form">
                    <Grid container spacing={1} className="line">
                        <Typography>Contacts Match</Typography>
                        <Grid item xs={2} spacing={1}>
                            <Select
                                className="full"
                                id="select"
                                variant="outlined"
                                value={matchType}
                                onChange={anyOrAll}
                            >
                                <MenuItem value="Any">Any</MenuItem>
                                <MenuItem value="All">All</MenuItem>
                            </Select>
                        </Grid>
                        <Typography>of the following conditions.</Typography>
                    </Grid>
                    <br />
                    <Divider />
                    <br />
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Controller
                                render={({ onChange }) => (
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="type" className="label">
                                            Type
                                        </InputLabel>
                                        <Select
                                            className="text"
                                            labelId="type"
                                            variant="outlined"
                                            onChange={(event): void => {
                                                onChange(event);
                                                changeFilterFieldType(event);
                                            }}
                                            labelWidth={32}
                                        >
                                            {filterCategories.map((category) => {
                                                const columnAccessor = camelCase(category);
                                                return (
                                                    <MenuItem key={columnAccessor} value={columnAccessor}>
                                                        {category}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                )}
                                control={control}
                                name="category"
                                defaultValue={categoryFilter}
                                rules={{ required: true }}
                            />
                        </Grid>
                        <Grid item xs={3} spacing={1}>
                            <Controller
                                render={({ onChange }) => (
                                    <FormControl fullWidth>
                                        <InputLabel id="conditions" className="label">
                                            Condition
                                        </InputLabel>
                                        <Select
                                            labelId="conditions"
                                            variant="outlined"
                                            onChange={onChange}
                                            className="text"
                                            labelWidth={65}
                                        >
                                            {filterConditions.map((condition) => {
                                                const conditionAccessor = camelCase(condition);
                                                return (
                                                    <MenuItem key={conditionAccessor} value={conditionAccessor}>
                                                        {condition}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                )}
                                control={control}
                                name="condition"
                                onChange={([selected]) => {
                                    // React Select return object instead of value for selection
                                    return { value: selected };
                                }}
                                rules={{ required: true }}
                            />
                        </Grid>
                        <Grid item xs={4} spacing={1}>
                            <Controller
                                render={({ onChange }) => (
                                    <>
                                        {typeof filterValue === 'string' && (
                                            <TextField
                                                className="text"
                                                type="text"
                                                variant="outlined"
                                                onChange={onChange}
                                                size="small"
                                            />
                                        )}
                                        {typeof filterValue !== 'string' && (
                                            <Select className="text" variant="outlined" onChange={onChange}>
                                                {filterValue.map((key) => (
                                                    <MenuItem value={key} key={key}>
                                                        {key}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    </>
                                )}
                                control={control}
                                name="value"
                                id="height"
                                rules={{ required: true }}
                            />
                        </Grid>
                        <Grid item xs={1} spacing={1}>
                            <Button type="submit" variant="contained" color="primary" disabled={!formState.isValid}>
                                Add
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <SegmentFilterChips
                    filterQuery={{ [andOr]: filterFieldList }}
                    onDelete={removeFilterField}
                    showAndOrs
                />
                <br />
                <br />
                <Grid container>
                    <Grid item xs={12}>
                        <Typography className="numLabel">Number of Contacts</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography id="contactsNumber" className="numDisplay">
                            {contactsQuery.data?.Contacts.length !== undefined
                                ? contactsQuery.data?.Contacts.length
                                : '0'}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
export default ListFilter;
