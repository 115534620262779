import React, { ReactElement } from 'react';

import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import './ErrorCard.scss';

const ErrorCard = ({ errorMsg }: { errorMsg: string }): ReactElement => {
    return (
        <div className="ErrorCard">
            <ErrorOutlineOutlinedIcon color="error" className="ErrorIcon" fontSize="small" />
            <p className="ErrorMsg">{errorMsg}</p>
        </div>
    );
};
export default ErrorCard;
