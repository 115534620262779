import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers/index';

export interface Action {
    type: string;
    payload: Record<string, unknown>;
}

const composeEnhancers =
    process.env.NODE_ENV !== 'development' ? compose() : composeWithDevTools({ trace: true, traceLimit: 10 });

export default createStore(rootReducer, composeEnhancers(applyMiddleware()));
