import React, { ReactElement } from 'react';

import { useSelector } from 'react-redux';

import InputForm from 'pageComponents/campaign/inputForms/InputForm';
import CampaignTemplate from 'pageComponents/emailTemplates/CampaignTemplate';
import { Store } from 'store/reducers/index';

import './AddContent.scss';

const AddContent = ({
    showFieldRequired,
    endAnimation,
    validationAnimation,
}: {
    showFieldRequired: boolean;
    validationAnimation: boolean;
    endAnimation: () => void;
}): ReactElement => {
    const template = useSelector((storeState: Store) => storeState.Campaign.template);
    const newsletter = useSelector((storeState: Store) => storeState.Campaign.template.newsletter);
    const press = useSelector((storeState: Store) => storeState.Campaign.template.press);
    const event = useSelector((storeState: Store) => storeState.Campaign.template.event);

    return (
        <div className="AddContent">
            <div className="Form">
                <InputForm
                    showFieldRequired={showFieldRequired}
                    endAnimation={endAnimation}
                    validationAnimation={validationAnimation}
                />
            </div>
            {template && (
                <div className="TemplatePreview">
                    <CampaignTemplate
                        isHtml={false}
                        event={event}
                        newsletter={newsletter}
                        press={press}
                        type={template.type}
                    />
                </div>
            )}
        </div>
    );
};
export default AddContent;
