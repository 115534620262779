import React, { ReactElement } from 'react';

import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';

import { showSnackbar } from 'store/actions/Snackbar';
import { Store } from 'store/reducers';

export const AlertSnackbar = (): ReactElement => {
    const { show, type, message } = useSelector((state: Store) => state.Snackbar);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(showSnackbar(false, message, type));
    };

    return (
        <Snackbar
            data-testid="snackbar"
            open={show}
            onClose={onClose}
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={onClose}
                    data-testid="deleted-notification-close"
                >
                    <CloseIcon />
                </IconButton>
            }
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            autoHideDuration={5000}
        >
            <MuiAlert onClose={onClose} severity={type}>
                {message}
            </MuiAlert>
        </Snackbar>
    );
};

export default AlertSnackbar;
