import { ReactElement } from 'react';

import { DialogProps } from '@material-ui/core';

// why do this? Removes potential misspelling and duplicate declaration of types
export const SHOW_DIALOG = 'SHOW_DIALOG';

// why do this? Makes dispatching a store actions easy and look like a function inside a component
export const showDialog = (
    show: boolean,
    title: string,
    content: ReactElement | undefined,
    actionButtons: ReactElement | undefined,
    maxWidth?: DialogProps['maxWidth'],
    fullWidth?: boolean
): Record<string, unknown> => ({
    type: SHOW_DIALOG,
    payload: { show, title, content, actionButtons, maxWidth, fullWidth },
});
