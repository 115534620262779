import React from 'react';

import { Box, Button, DialogContentText } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { showDialog } from 'store/actions/Dialog';

import './DemoBanner.scss';

const showDemoModal = (dispatch): void => {
    dispatch(
        showDialog(
            true,
            'More Information',
            <DialogContentText className="DemoBannerModal__Text">
                For demo purposes some features in the Engage tool have been disabled. {'\n\n'}- Emails cannot be sent{' '}
                {'\n'}- Analytics are simulated
            </DialogContentText>,
            <Button
                id="cancelButton"
                color="primary"
                variant="contained"
                onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
            >
                CLOSE
            </Button>,
            'lg'
        )
    );
};

const DemoBanner = (): JSX.Element => {
    const dispatch = useDispatch();
    return (
        <Box className="DemoBanner" role="alert">
            <div className="DemoBanner__Text" id="a">
                For demo purposes some features in the Engage tool have been disabled.
            </div>
            <div
                className="DemoBanner__Text"
                id="b"
                onClick={() => showDemoModal(dispatch)}
                onKeyPress={(event): void => {
                    if (event.key === 'Enter') showDemoModal(dispatch);
                }}
                role="button"
                tabIndex={0}
            >
                More Information
            </div>
        </Box>
    );
};

export default DemoBanner;
