import React, { ReactElement } from 'react';

import { Grid } from '@material-ui/core';

import './unauthorized.scss';

const Unauthorized = (): ReactElement => {
    return (
        <div className="UnauthorizedContainer">
            <Grid container>
                <div className="UnauthorizedBox">
                    <Grid item className="GridContainer">
                        <div>
                            <p className="UnauthorizedText Heading">UNAUTHORIZED ACCESS</p>
                            <p className="UnsubscribeText">
                                Access to this page is limited to invited users only. If you are receiving this and feel
                                this is in error, please reach out the Mile 2 Support Team.
                            </p>
                        </div>
                    </Grid>
                </div>
            </Grid>
        </div>
    );
};
export default Unauthorized;
