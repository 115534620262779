import React from 'react';

import { DialogContentText, makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { TableInstance } from 'react-table';

const useStyles = makeStyles({
    dialogImportantText: {
        color: '#07090c',
    },
    dialogImportantBoldedText: {
        color: '#07090c',
        fontWeight: 600,
    },
    paddedLabels: {
        paddingTop: '1rem',
    },
    bulletedListItems: {
        listStyleType: 'disc',
    },
});

const DeleteContactForm = ({
    formId,
    contactTableInstance,
    onDelete,
}: {
    formId: string;
    contactTableInstance: TableInstance;
    onDelete: () => void;
}): JSX.Element => {
    const { handleSubmit } = useForm();
    const classes = useStyles();

    return (
        <>
            <form onSubmit={handleSubmit(onDelete)} id={formId}>
                {contactTableInstance.selectedFlatRows.map((row) => (
                    <p key={row.values.emailAddress} className={classes.bulletedListItems}>
                        {row.values.firstName} {row.values.lastName} - {row.values.companyName}
                        <br />
                        {row.values.emailAddress}
                    </p>
                ))}
            </form>
            <DialogContentText className={classes.dialogImportantBoldedText}>
                Are you sure you want to proceed?
            </DialogContentText>
        </>
    );
};

export default DeleteContactForm;
