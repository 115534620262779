import { AlertProps } from '@material-ui/lab';

// why do this? Removes potential misspelling and duplicate declaration of types
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';

// why do this? Makes dispatching a store actions easy and look like a function inside a component
export const showSnackbar = (
    show: boolean,
    message: string,
    type: AlertProps['severity'] = undefined
): Record<string, unknown> => ({
    type: SHOW_SNACKBAR,
    payload: { show, message, type },
});
