import React, { ReactElement } from 'react';

import './App.scss';
import './assets/sass/root.css';

interface Props {
    children: ReactElement;
}

export default ({ children }: Props): ReactElement => {
    return <div className="App">{children}</div>;
};
