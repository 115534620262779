import { SHOW_DRAWER } from '../actions/Member';
import { Action } from '../index';

export interface State {
    show: boolean;
    emailAddress: string;
}

const initialState: State = {
    show: false,
    emailAddress: '',
};

export default (state = initialState, action: Action): State => {
    const { type, payload } = action;
    switch (type) {
        case SHOW_DRAWER: {
            return { ...state, ...payload };
        }
        default:
            return { ...state };
    }
};
