import { MutationFunction } from '@apollo/client';
import { isEmpty } from 'lodash';
import { Dispatch } from 'redux';

import { TEMPLATE_TYPES } from 'components/utils/GlobalConstants';
import { showSnackbar } from 'store/actions/Snackbar';
import { Campaign } from 'typings/Campaign';
import showSnackbarError from './ShowSnackbarError';

const SaveCampaignDraft = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>,
    campaign: Campaign,
    saveCampaign: MutationFunction,
    showWasSaved: boolean
): Promise<{ title: string; id: number } | undefined> => {
    let templateDataArray: Array<Record<string, unknown>> = [];
    let campaignTemplateData = {};
    const { NEWSLETTER, PRESS, EVENT } = TEMPLATE_TYPES;

    if (campaign.template.type === NEWSLETTER) {
        campaignTemplateData = { ...campaign.template.newsletter };
    } else if (campaign.template.type === EVENT) {
        campaignTemplateData = { ...campaign.template.event };
    } else if (campaign.template.type === PRESS) {
        campaignTemplateData = { ...campaign.template.press };
    }

    if (Object.entries(campaignTemplateData).length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [description, value] of Object.entries<Record<string, unknown>[]>(campaignTemplateData)) {
            if (description === 'articles') {
                const articlesArray = Object.values(value);
                const articles = articlesArray.slice(0, campaign.template.newsletter.articleCount);
                // eslint-disable-next-line no-loop-func
                articles.forEach((article, index) => {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const [description2, value2] of Object.entries(article)) {
                        templateDataArray = [
                            ...templateDataArray,
                            {
                                groupNumber: index + 1,
                                inputDescription: description2,
                                inputValue: value2,
                            },
                        ];
                    }
                    return templateDataArray;
                });
            } else {
                templateDataArray = [
                    ...templateDataArray,
                    {
                        inputDescription: description,
                        inputValue: description === 'articleCount' ? value.toString() : value,
                    },
                ];
            }
        }
    }

    const request = {
        id: campaign.id,
        title: isEmpty(campaign.campaignDetails.campaignName) ? null : campaign.campaignDetails.campaignName,
        subject: isEmpty(campaign.campaignDetails.subject) ? null : campaign.campaignDetails.subject,
        senderEmail: `${campaign.campaignDetails.senderName} <${campaign.campaignDetails.senderEmail}>`,
        templateTypeId: campaign.template.type,
        campaignTemplateInputs: templateDataArray,
        segmentIds: campaign.selectAudience?.segmentIds,
        audienceId: 1,
    };

    try {
        const responses = await saveCampaign({
            variables: {
                data: request,
            },
        });
        const response = responses.data.Campaign;

        if (showWasSaved) dispatch(showSnackbar(true, `Changes saved successfully!`, 'success'));
        return response;
    } catch (err) {
        showSnackbarError(dispatch, err);
    }
    return undefined;
};
export default SaveCampaignDraft;
