import React, { useState } from 'react';

import { IconButton, MenuItem, Select, TextField } from '@material-ui/core';
import { ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons';
import { TableInstance } from 'react-table';

import { generateSortingIndicator, pageSizeOptions } from '../utils/ReactTableUtils';

import '../../assets/sass/ReactTableStyle.scss';
import './DataTable.scss';

interface DataTableProps {
    contactTableInstance: TableInstance;
    showPagination?: boolean;
    hideHeaderGroups?: boolean;
}

const DataTable = ({ showPagination, contactTableInstance, hideHeaderGroups }: DataTableProps): JSX.Element => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageOptions,
        state: { pageIndex, pageSize },
        gotoPage,
        previousPage,
        nextPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
    } = contactTableInstance;
    const [pageInputNumber, setPageInputNumber] = useState(pageIndex + 1);

    // Render the UI for your table
    return (
        <div className="DataTable">
            <div {...getTableProps()} className="Table Sticky">
                {(hideHeaderGroups ? headerGroups.slice(-1) : headerGroups).map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()} className="Tr Header">
                        {headerGroup.headers.map((column, index) => (
                            <div
                                {...column.getHeaderProps(
                                    column.getSortByToggleProps({
                                        title: undefined,
                                    })
                                )}
                                className={`Th Header${column.isSorted ? '__IsSorted' : '__IsNotSorted'}`}
                                key={`sortIcon${index}`}
                            >
                                {column.render('Header')}
                                <span className="SortIconSpan">{generateSortingIndicator(column)}</span>
                            </div>
                        ))}
                    </div>
                ))}
                <div {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps()} className="Tr">
                                {row.cells.map((cell) => {
                                    if (cell.column.id === 'status') {
                                        return (
                                            <div {...cell.getCellProps()} className="ShorterTd">
                                                {cell.render('Cell')}
                                            </div>
                                        );
                                    }
                                    return (
                                        <div {...cell.getCellProps()} className="Td">
                                            {cell.render('Cell')}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
            {showPagination && (
                <div className="TableNavigation__Overflow">
                    <div className="TableNavigation__Left">
                        <span>
                            Showing {pageIndex * pageSize + 1}-{Math.min(pageIndex * pageSize + pageSize, rows.length)}{' '}
                            of {rows.length}
                        </span>
                    </div>
                    <div className="TableNavigation__Right">
                        <span>Rows per page</span>
                        <Select
                            id="pageSizeOptionSelect"
                            name="pageSizeOptionSelect"
                            value={pageSize}
                            variant="outlined"
                            onChange={(e): void => {
                                setPageSize(Number(e.target.value));
                            }}
                            margin="dense"
                        >
                            {pageSizeOptions.map((pageSizeOption) => (
                                <MenuItem key={pageSizeOption} value={pageSizeOption}>
                                    {pageSizeOption}
                                </MenuItem>
                            ))}
                            <MenuItem value={rows.length}>All</MenuItem>
                        </Select>
                        <IconButton
                            id="previousPageButton"
                            onClick={(): void => {
                                setPageInputNumber(pageIndex);
                                previousPage();
                            }}
                            disabled={!canPreviousPage}
                        >
                            <ArrowBackIosIcon fontSize="small" />
                        </IconButton>
                        <span>Page</span>
                        <TextField
                            size="small"
                            variant="outlined"
                            className="RoundedInput"
                            id="goToPageInput"
                            type="number"
                            value={pageInputNumber <= 0 ? '' : pageInputNumber}
                            onChange={(event) => setPageInputNumber(Number(event.target.value))}
                            onKeyPress={(event): void => {
                                if (event.key === 'Enter') {
                                    const targetValue = (event.target as HTMLInputElement).value;
                                    let newPage: number;
                                    if (targetValue && Number(targetValue) <= pageOptions.length) {
                                        newPage = Number(targetValue) - 1;
                                    } else {
                                        newPage = 0;
                                    }
                                    gotoPage(newPage);
                                    setPageInputNumber(newPage + 1);
                                }
                            }}
                        />
                        <span>of {pageOptions.length}</span>
                        <IconButton
                            id="nextPageButton"
                            onClick={(): void => {
                                setPageInputNumber(pageIndex + 2);
                                nextPage();
                            }}
                            disabled={!canNextPage}
                        >
                            <ArrowForwardIosIcon fontSize="small" />
                        </IconButton>
                    </div>
                </div>
            )}
        </div>
    );
};

DataTable.defaultProps = {
    showPagination: true,
    hideHeaderGroups: true,
};

export default DataTable;
