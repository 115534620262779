import { gql } from '@apollo/client';

const CREATE_CONTACT = gql`
    mutation createContact($contact: CreateContactInput!) {
        Contact: createContact(data: $contact) {
            id
        }
    }
`;

const CREATE_CONTACT_PUBLIC = gql`
    mutation createContactPublic($contact: CreateContactInput!) {
        Contact: createContactPublic(data: $contact) {
            id
        }
    }
`;

const CREATE_AND_UPDATE_CONTACTS = gql`
    mutation createAndUpdateContacts($contacts: CreateContactListInput!) {
        CreateContactResponse: createAndUpdateContacts(data: $contacts) {
            contactList {
                id
                emailAddress
                status
            }
            errors
        }
    }
`;

const UPDATE_CONTACT = gql`
    mutation updateContact($id: String!, $data: UpdateContactInput!) {
        Contact: updateContact(id: $id, data: $data) {
            id
        }
    }
`;

const UPDATE_CONTACT_PUBLIC = gql`
    mutation updateContactPublic($id: String!, $data: UpdateContactInput!) {
        Contact: updateContactPublic(id: $id, data: $data) {
            id
        }
    }
`;

const DELETE_CONTACT = gql`
    mutation deleteContact($id: String!) {
        Contact: deleteContact(id: $id) {
            id
        }
    }
`;

const DELETE_CONTACTS = gql`
    mutation deleteContacts($data: DeleteContactListInput!) {
        DeleteContactResponse: deleteContacts(data: $data) {
            contactList {
                id
            }
            errors
        }
    }
`;

const UNSUBSCRIBE_CONTACT = gql`
    mutation unsubscribeContact($id: String!, $campaignId: Float!) {
        Contact: unsubscribeContact(id: $id, campaignId: $campaignId) {
            id
            emailAddress
        }
    }
`;

const UNSUBSCRIBE_CONTACT_PUBLIC = gql`
    mutation unsubscribeContactPublic($id: String!, $campaignId: Float!) {
        Contact: unsubscribeContactPublic(id: $id, campaignId: $campaignId) {
            id
            emailAddress
        }
    }
`;

const SUBSCRIBE_CONTACT = gql`
    mutation subscribeContact($id: String!) {
        Contact: subscribeContact(id: $id) {
            id
            emailAddress
        }
    }
`;

const SUBSCRIBE_CONTACT_PUBLIC = gql`
    mutation subscribeContactPublic($id: String!) {
        Contact: subscribeContactPublic(id: $id) {
            id
            emailAddress
        }
    }
`;

const RESUBSCRIBE_CONTACT = gql`
    mutation resubscribeContact($id: String!) {
        Contact: resubscribeContact(id: $id) {
            id
            emailAddress
        }
    }
`;

const RESUBSCRIBE_CONTACT_PUBLIC = gql`
    mutation resubscribeContactPublic($id: String!) {
        Contact: resubscribeContactPublic(id: $id) {
            id
            emailAddress
        }
    }
`;

export {
    CREATE_CONTACT,
    CREATE_CONTACT_PUBLIC,
    CREATE_AND_UPDATE_CONTACTS,
    UPDATE_CONTACT,
    UPDATE_CONTACT_PUBLIC,
    DELETE_CONTACT,
    DELETE_CONTACTS,
    UNSUBSCRIBE_CONTACT,
    SUBSCRIBE_CONTACT,
    RESUBSCRIBE_CONTACT,
    UNSUBSCRIBE_CONTACT_PUBLIC,
    SUBSCRIBE_CONTACT_PUBLIC,
    RESUBSCRIBE_CONTACT_PUBLIC,
};
