import React, { ReactElement, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
    Button,
    ClickAwayListener,
    Divider,
    Grid,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DELETE_SEGMENT } from 'api/mutations';
import { GET_SEGMENT_CONTACTS } from 'api/queries';
import { getLocalDateString } from 'components/utils/DateUtil';
import showSnackbarError from 'components/utils/ShowSnackbarError';
import ViewContactsTable from 'pageComponents/contact/viewContacts/ViewContactsTable';
import { resetCampaign, selectAudience, selectCampaignTab } from 'store/actions/Campaign';
import { showDialog } from 'store/actions/Dialog';
import { showSnackbar } from 'store/actions/Snackbar';
import { FindSegmentsWithCountSegmentsWithCountResponseSegments } from 'typings/_graphql';
import SegmentFilterChips from '../segmentFilterChips/SegmentFilterChips';

import './SegmentTile.scss';

const SegmentTile = ({
    name,
    filterQuery,
    description,
    id,
    createdDate,
    createdBy,
    lastUsedDate,
    contacts,
}: FindSegmentsWithCountSegmentsWithCountResponseSegments): ReactElement => {
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const dispatch = useDispatch();
    const segmentsContacts = useQuery(GET_SEGMENT_CONTACTS, { variables: { id } });
    const contactData = segmentsContacts.data?.Contacts;
    const [deleteSegment] = useMutation(DELETE_SEGMENT);
    const { handleSubmit } = useForm();
    const deleteFormId = 'deleteSegmentForm';
    const onDelete = async () => {
        try {
            await deleteSegment({
                variables: { id },
                refetchQueries: () => ['findSegmentsWithCount'],
            });
            dispatch(showSnackbar(true, `Segment ${name} was deleted!`, 'success'));
            dispatch(showDialog(false, '', undefined, undefined));
        } catch (err) {
            showSnackbarError(dispatch, err);
        }
    };

    const history = useHistory();

    const handleCampaign = () => {
        dispatch(resetCampaign({ name: '' }));
        // pass name and id # to campaign
        dispatch(selectAudience({ segmentIds: [id], segmentNames: [name] }));
        dispatch(selectCampaignTab({ step: 0 }));
        history.push('/campaign/create');
    };

    const handleEditSegment = () => {
        history.push(`/audience/segments/edit/${id}`);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <div className="SegmentTile">
            <Grid container>
                <Grid item container justify="space-between" alignItems="baseline">
                    <Grid item>
                        <p id="SegmentTitle">{name}</p>
                    </Grid>
                    <Grid item>
                        <Button
                            id="openContactsBtn"
                            className="ViewContactsBtn"
                            onClick={() =>
                                dispatch(
                                    showDialog(
                                        true,
                                        'Audience Preview',
                                        <ViewContactsTable contactData={contactData} />,
                                        <Button
                                            id="cancelButton"
                                            color="primary"
                                            onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                                        >
                                            CLOSE
                                        </Button>,
                                        'lg'
                                    )
                                )
                            }
                            disabled={isEmpty(contactData)}
                            color="primary"
                        >
                            {`VIEW CONTACTS${contactData ? ` (${contactData?.length})` : ''}`}
                        </Button>
                        <IconButton
                            aria-label="more"
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                            ref={anchorRef}
                            data-testid="three-dots"
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                autoFocusItem={open}
                                                id="menu-list-grow"
                                                data-testid="test-menu-grow"
                                                className="test"
                                            >
                                                <MenuItem id="Create" onClick={handleCampaign}>
                                                    Create Campaign
                                                </MenuItem>
                                                {id !== 1 && (
                                                    <MenuItem id="Edit" onClick={handleEditSegment}>
                                                        Edit Segment
                                                    </MenuItem>
                                                )}
                                                {id !== 1 && (
                                                    <MenuItem
                                                        id="Delete"
                                                        onClick={() => {
                                                            dispatch(
                                                                showDialog(
                                                                    true,
                                                                    `Delete Segment?`,
                                                                    <>
                                                                        <form
                                                                            onSubmit={handleSubmit(onDelete)}
                                                                            id={deleteFormId}
                                                                        >
                                                                            <p>
                                                                                Deleting this segment will remove it from
                                                                                the Segment Library.
                                                                                <br />
                                                                                <br />
                                                                                The contacts within this segment will be
                                                                                preserved in the Contact Manager.
                                                                            </p>
                                                                        </form>
                                                                    </>,
                                                                    <>
                                                                        <Button
                                                                            id="cancelButton"
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                dispatch(
                                                                                    showDialog(
                                                                                        false,
                                                                                        '',
                                                                                        undefined,
                                                                                        undefined
                                                                                    )
                                                                                )
                                                                            }
                                                                        >
                                                                            CANCEL
                                                                        </Button>
                                                                        <Button
                                                                            type="submit"
                                                                            variant="contained"
                                                                            color="primary"
                                                                            form={deleteFormId}
                                                                        >
                                                                            DELETE
                                                                        </Button>
                                                                    </>
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        Delete Segment
                                                    </MenuItem>
                                                )}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container alignItems="flex-start">
                <Grid item container xs={12}>
                    <Grid item container justify="flex-start" alignItems="baseline">
                        <Grid item container xs={2}>
                            <Grid item xs={12}>
                                <p className="SegmentDesc" id="SegmentDescL">
                                    Last Used
                                </p>
                            </Grid>
                            <Grid item xs={12}>
                                <p className="SegmentContent" id="SegmentContentL">
                                    {lastUsedDate ? getLocalDateString(lastUsedDate) : ''}
                                </p>
                            </Grid>
                        </Grid>
                        <Grid item container xs={2}>
                            <Grid item xs={12}>
                                <p className="SegmentDesc">Date Created</p>
                            </Grid>
                            <Grid item xs={12}>
                                <p className="SegmentContent">{getLocalDateString(createdDate)}</p>
                            </Grid>
                        </Grid>
                        <Grid item container xs={3}>
                            <Grid item xs={12}>
                                <p className="SegmentDesc">Created By</p>
                            </Grid>
                            <Grid item xs={8}>
                                <p className="SegmentContent">
                                    {createdBy?.firstName} {createdBy?.lastName}
                                </p>
                            </Grid>
                        </Grid>
                        <Grid item container xs={5}>
                            <Grid item xs={12}>
                                <p className="SegmentDesc">Description</p>
                            </Grid>
                            <Grid item xs={12}>
                                <p className="SegmentContent">{description}</p>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider />
            <Grid id="Chips" item container alignItems="flex-start">
                <div className="Filter-Chips">
                    <SegmentFilterChips
                        filterQuery={filterQuery}
                        onlyShowFirstX={3}
                        additionalContacts={contacts ? contacts.map((contact) => contact.emailAddress) : null}
                    />
                </div>
            </Grid>
        </div>
    );
};

export default SegmentTile;
