import { camelCase } from 'lodash';
import { Column } from 'react-table';
import { Dispatch } from 'redux';
import XLSX from 'xlsx';

import { showSnackbar } from 'store/actions/Snackbar';
import { allCategoryNames } from './GlobalConstants';

// https://ui.dev/validate-email-address-javascript/
function emailIsValid(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

interface MatchingColumns {
    originalColumn: Column;
    newColumn: Column;
    ignored: boolean;
}

const fileHandler = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>,
    file: File | null,
    setContacts: (contacts: Record<string, unknown>[]) => void,
    setStepNumber: React.Dispatch<React.SetStateAction<number>>
): Promise<void> => {
    const fileObj = file;
    if (fileObj) {
        const reader = new FileReader();
        reader.onload = async function uploadContacts(): Promise<void> {
            if (typeof reader.result === 'string' || reader.result === null) {
                dispatch(showSnackbar(true, 'Spreadsheet was either a single value or no data was found', 'error'));
                return;
            }

            const rawData = new Uint8Array(reader.result);
            const workbook = XLSX.read(rawData, { type: 'array' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const parsedData: string[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false });
            const allColumnsCaseInsensitiveRegex = new RegExp(allCategoryNames.join('|'), 'i');
            const allColumnsCamelCaseInsensitiveRegex = new RegExp(
                allCategoryNames.map((column) => camelCase(column)).join('|'),
                'i'
            );

            if (parsedData && parsedData.length > 1 && parsedData[0].length > 0) {
                // get email address column
                let emailAddressColumnIndex = -1;
                for (let rowIndex = 0; rowIndex < parsedData.length; rowIndex++) {
                    const row = parsedData[rowIndex];
                    for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
                        const cell = row[columnIndex];
                        if (emailIsValid(cell)) {
                            emailAddressColumnIndex = columnIndex;
                            break;
                        }
                    }
                    if (emailAddressColumnIndex >= 0) break;
                }

                if (emailAddressColumnIndex === -1) {
                    dispatch(showSnackbar(true, 'Excel upload contained no emails!', 'error'));
                    return;
                }

                const containsHeaderRow =
                    parsedData[0].filter((column) => {
                        return (
                            allColumnsCaseInsensitiveRegex.test(column) ||
                            allColumnsCamelCaseInsensitiveRegex.test(camelCase(column))
                        );
                    }).length > 0;

                let startingRowIndex = 0;
                if (containsHeaderRow) {
                    startingRowIndex = 1;
                }

                let anyUnmatchedColumns = false;
                let anyMismatchedColumns = false;

                const allCamelCaseColumns = allCategoryNames.map((x) => camelCase(x));
                const columnAccessors = parsedData[0];
                const contactsObject = parsedData
                    .slice(startingRowIndex, parsedData.length)
                    .map((parsedRow: string[]) => {
                        const contactObject = {
                            ...parsedRow.reduce(function getContactFields(contact, field, index) {
                                const tmpContact = { ...contact };
                                const cleanedField = String(field).split('\n').join(', ');
                                if (index === emailAddressColumnIndex) {
                                    // eslint-disable-next-line dot-notation
                                    tmpContact['emailAddress'] = cleanedField;
                                } else if (containsHeaderRow && columnAccessors[index]) {
                                    tmpContact[camelCase(columnAccessors[index])] = cleanedField;
                                    if (
                                        !anyMismatchedColumns &&
                                        !allCamelCaseColumns.includes(camelCase(columnAccessors[index]))
                                    ) {
                                        anyMismatchedColumns = true;
                                    }
                                } else {
                                    tmpContact[`unmatchedColumn${index}`] = cleanedField;
                                    anyUnmatchedColumns = true;
                                }
                                return tmpContact;
                            }, {}),
                            isArchived: false,
                        };
                        return contactObject;
                    })
                    // remove all contacts that don't have any value for emailAddress
                    // eslint-disable-next-line dot-notation
                    .filter((contact) => contact['emailAddress']);

                if (anyUnmatchedColumns || anyMismatchedColumns) {
                    setStepNumber(1);
                } else {
                    setStepNumber(2);
                }
                setContacts(contactsObject);
            } else {
                dispatch(showSnackbar(true, 'Excel upload was either empty or only contained headers!', 'error'));
            }
        };
        reader.readAsArrayBuffer(fileObj);
    }
};

const matchNewColumns = (
    columns: React.MutableRefObject<MatchingColumns[] | undefined>,
    formData: Record<string, unknown>
): void => {
    const newColumns: MatchingColumns[] = [];
    if (columns.current) {
        for (let index = 0; index < columns.current.length; index++) {
            const column = columns.current[index];
            const columnValue = formData[column.originalColumn.Header as string] as string | undefined;
            if (columnValue === 'ignored') {
                newColumns.push({
                    ...column,
                    newColumn: { ...column.originalColumn },
                    ignored: true,
                });
            } else if (columnValue) {
                newColumns.push({
                    ...column,
                    newColumn: {
                        ...column.newColumn,
                        Header: columnValue,
                        accessor: camelCase(columnValue),
                    },
                    ignored: false,
                });
            } else {
                newColumns.push({ ...column });
            }
        }
        // eslint-disable-next-line no-param-reassign
        columns.current = newColumns;
    }
};

// needed to export like this for jest test mocking
// eslint-disable-next-line import/prefer-default-export
export { fileHandler, matchNewColumns };
export type { MatchingColumns };
