import { SET_USER } from '../actions/User';
import { Action } from '../index';

export interface UserState {
    name: string | undefined;
    email: string | undefined;
    id: string | undefined;
}

const initialState: UserState = {
    name: undefined,
    email: undefined,
    id: undefined,
};

export default (state = initialState, action: Action): UserState => {
    const { type, payload } = action;
    switch (type) {
        case SET_USER: {
            const updatedUser = { ...state, ...((payload as unknown) as UserState) };
            return updatedUser;
        }
        default:
            return state;
    }
};
