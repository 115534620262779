import React, { ReactElement } from 'react';

import { useMutation } from '@apollo/client';
import { Button } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch, useSelector } from 'react-redux';

import { CREATE_INVITES } from 'api/mutations';
import showSnackbarError from 'components/utils/ShowSnackbarError';
import SendTestEmailForm from 'pageComponents/campaign/sendTestEmailForm/SendTestEmailForm';
import { showDialog } from 'store/actions/Dialog';
import { showSnackbar } from 'store/actions/Snackbar';
import { Store } from 'store/reducers';
import { CreateInvitesVariables } from 'typings/_graphql';
import NavIcon from './NavIcon';

interface ProfileNavIconProps {
    overlayOn: boolean;
    opened: () => void;
}

export const ProfileNavIcon = ({ opened, overlayOn }: ProfileNavIconProps): ReactElement => {
    const sendInviteFormId = 'sendInviteForm';
    const { keycloak } = useKeycloak();
    const user = useSelector((storeState: Store) => storeState.User);
    const dispatch = useDispatch();
    const [createInvites] = useMutation(CREATE_INVITES);

    const onSubmit = async (formData) => {
        try {
            await createInvites({
                variables: {
                    data: {
                        emailAddressList: formData.testEmail,
                        message: "You've been invited to the Engage application!",
                    },
                } as CreateInvitesVariables,
            });
            dispatch(showSnackbar(true, `"${formData.testEmail}" has been invited!`, 'success'));
            dispatch(showDialog(false, '', undefined, undefined));
        } catch (err) {
            showSnackbarError(dispatch, err);
        }
    };

    return (
        <NavIcon iconTitle="Profile" icon="profile" testId="profile-button" onOpen={opened} overlayOn={overlayOn}>
            {{
                title: (
                    <>
                        {user && (
                            <>
                                {overlayOn}
                                <div className="heading">{user.name}</div>
                                <div className="sub-heading">{user.emailAddress}</div>
                            </>
                        )}
                    </>
                ),
                content: (
                    <>
                        <div className="mt-2 mb-2">
                            <Button
                                color="primary"
                                onClick={() =>
                                    dispatch(
                                        showDialog(
                                            true,
                                            'Invite User',
                                            <SendTestEmailForm
                                                formId={sendInviteFormId}
                                                onSubmit={onSubmit}
                                                useDefaultEmail={false}
                                            />,
                                            <>
                                                <Button
                                                    id="cancelButton"
                                                    color="primary"
                                                    onClick={() => {
                                                        dispatch(showDialog(false, '', undefined, undefined));
                                                    }}
                                                >
                                                    CANCEL
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    form={sendInviteFormId}
                                                >
                                                    Invite User
                                                </Button>
                                            </>,
                                            'xs',
                                            true
                                        )
                                    )
                                }
                            >
                                Invite User
                            </Button>
                        </div>
                        <div className="mt-2 mb-2">
                            <Button color="primary" href="mailto:engagesupport@miletwo.us">
                                Email Support
                            </Button>
                        </div>
                        <div className="mt-2 mb-2">
                            <Button color="primary" href="/User-Guide-for-Engage-Tool.pdf" target=" ">
                                User Guide
                            </Button>
                        </div>
                        <div className="mt-2 mb-2">
                            <Button
                                color="primary"
                                onClick={() => keycloak.logout({ redirectUri: window.location.origin })}
                            >
                                Logout
                            </Button>
                        </div>
                    </>
                ),
            }}
        </NavIcon>
    );
};

export default ProfileNavIcon;
