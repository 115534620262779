import React from 'react';

import { Button } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import Logo from 'assets/images/m2-logo-white.svg';

import './infoFooter.scss';

const InfoFooter = (): JSX.Element => {
    return (
        <footer className="info-page-footer">
            <p className="email" id="a">
                For more information contact: <a href="mailto:ecory@miletwo.us">ecory@miletwo.us</a>
            </p>
            <div className="buttons" id="b">
                <Button
                    className="flyer-button"
                    href="https://drive.google.com/file/d/1hN509fBFAY5ErjmlD2sZ3cun-bwfkJNI/view"
                    target="_blank"
                    aria-label="Opens new tab with engage flyer"
                >
                    <div className="level">
                        <PictureAsPdfIcon className="icon" />
                        <p className="flyer-text">VIEW ENGAGE FLYER</p>
                    </div>
                </Button>
                <a href="https://www.miletwo.us/" target="_blank" rel="noreferrer">
                    <img src={Logo} alt="MileTwo Logo" />
                </a>
            </div>
        </footer>
    );
};

export default InfoFooter;
