import React, { ReactElement } from 'react';

import { Chip, Grid, Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { getTemplateTypeNameFromId } from 'components/utils/CampaignUtils';
import { getLocalDateString } from 'components/utils/DateUtil';
import { Store } from 'store/reducers';

import './CampaignReportHeader.scss';

const CampaignReportHeader = (): ReactElement => {
    const campaign = useSelector((storeState: Store) => storeState.Campaign);
    const { segmentNames } = campaign.selectAudience;
    const { createdDate, sendDate } = campaign;
    const { senderName, senderEmail, subject, campaignName } = campaign.campaignDetails;
    const { type: templateTypeId } = campaign.template;

    const SegmentNames = (): ReactElement => {
        const maxDisplaySegments = 3;
        const moreSegments = segmentNames.length - maxDisplaySegments;
        return (
            <>
                {segmentNames.slice(0, maxDisplaySegments).join(', ')}
                {segmentNames.length > maxDisplaySegments && (
                    <Tooltip title={segmentNames.slice(maxDisplaySegments).join(', ')} className="MoreSegmentsTooltip">
                        <Chip
                            className="Chip SegmentNameChip"
                            id="moreSegments"
                            key="moreSegments"
                            label={`+${moreSegments}`}
                        />
                    </Tooltip>
                )}
            </>
        );
    };

    return (
        <div className="CampaignReportHeader">
            <h1 className="CampaignReportHeader__Title">{campaignName}</h1>
            <Grid container className="CampaignReportHeader__TitleSection">
                <Grid item xs={3}>
                    Date Created
                </Grid>
                <Grid item xs={3}>
                    Date Sent
                </Grid>
                <Grid item xs={3}>
                    Template
                </Grid>
                <Grid item xs={3}>
                    Audience Segment(s)
                </Grid>
            </Grid>
            <Grid container className="CampaignReportHeader__ValueSection">
                <Grid item xs={3} className="bold-text">
                    {createdDate ? getLocalDateString(createdDate) : ''}
                </Grid>
                <Grid item xs={3} className="bold-text">
                    {sendDate ? getLocalDateString(sendDate) : ''}
                </Grid>
                <Grid item xs={3} className="bold-text">
                    {templateTypeId ? getTemplateTypeNameFromId(templateTypeId) : ''}
                </Grid>
                <Grid item xs={3} className="bold-text">
                    <SegmentNames />
                </Grid>
            </Grid>
            <Grid container className="CampaignReportHeader__TitleSection">
                <Grid item xs={4}>
                    Sender Name
                </Grid>
                <Grid item>Sender Email</Grid>
            </Grid>
            <Grid container className="CampaignReportHeader__ValueSection">
                <Grid item xs={4} className="bold-text">
                    {senderName}
                </Grid>
                <Grid item className="bold-text">
                    {senderEmail}
                </Grid>
            </Grid>
            <Grid container className="CampaignReportHeader__TitleSection">
                <Grid item>Subject Line</Grid>
            </Grid>
            <Grid container className="CampaignReportHeader__ValueSection">
                <Grid item className="bold-text">
                    {subject}
                </Grid>
            </Grid>
        </div>
    );
};

export default CampaignReportHeader;
