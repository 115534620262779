import React, { ReactElement } from 'react';

import { TextField } from '@material-ui/core';

import { InputProps } from 'typings/InputProps';

const TextArea = ({ name, onChange, maxChar = 1024, label, className, id, value }: InputProps): ReactElement => {
    return (
        <div className="InputGroup">
            <TextField
                color="primary"
                variant="outlined"
                title={name}
                name={name}
                onChange={onChange}
                className={`form-control ${className}`}
                id={`${id}`}
                aria-describedby="basic-addon3"
                inputProps={{
                    maxLength: maxChar,
                }}
                multiline
                label={label}
                size="small"
                onFocus={(e): void => e.target.select()}
                value={value}
            />
            <span className="CharRemain">
                Characters Remaining: <b className="boldCount">{maxChar - value.length}</b>/{maxChar}
            </span>
        </div>
    );
};
export default TextArea;
