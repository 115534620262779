import React, { ReactNode } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { Store } from 'store/reducers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PrivateRoute: React.ComponentType<any> = ({ component: Component, ...rest }): JSX.Element => {
    const { keycloak, initialized } = useKeycloak();
    const user = useSelector((storeState: Store) => storeState.User);
    const isLoggedIn = keycloak?.authenticated || false;

    const displayRoute = (props): React.ReactNode => {
        if (initialized && !isLoggedIn) {
            if (keycloak) {
                keycloak.login({ redirectUri: window.location.href });
            }
            return <div className="not-logged-in">Logging in...</div>;
        }
        if (user.name) {
            return <Component {...props} />;
        }

        return <div />;
    };

    return <Route {...rest} render={(props): ReactNode => displayRoute(props)} />;
};

export default PrivateRoute;
