/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { Button, Chip, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { debounce } from 'lodash';

import { GET_CONTACT_FROM_EMAIL } from 'api/queries/';

import './AdditionalContacts.scss';

const useStyles = makeStyles({
    nameEntry: {
        fontSize: '14px',
        width: '100%',
        height: '36px',
    },
    button: {
        marginLeft: '5px',
        marginTop: '25px',
    },
    error: {
        fontSize: '12px',
        color: 'red',
    },
});

const AdditionalContacts = ({
    additionalContacts,
    updateList,
}: {
    additionalContacts: any[];
    updateList: (value: any[]) => void;
}): JSX.Element => {
    const [emailAddress, setEmailAddress] = useState('');
    const [queryData, setQueryData] = useState<any>(null);
    const [error, setErrors] = useState<any>(null);
    const client = useApolloClient();
    const [duplicate, setDup] = useState(false);

    const classes = useStyles();

    const peopleQuery = async (givenEmailAddress, givenAdditionalContacts) => {
        const { data, errors } = await client.query({
            query: GET_CONTACT_FROM_EMAIL,
            variables: { audienceId: 1, emailAddress: givenEmailAddress },
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        });
        setQueryData(data);
        setErrors(errors);

        if (data) {
            const email = data.Contact.emailAddress;
            const exist = givenAdditionalContacts.filter((contact) => {
                return contact.emailAddress === email;
            });
            if (exist.length > 0) {
                setDup(true);
            }
        } else {
            setDup(false);
        }
    };

    const addContact = () => {
        const newList = [...additionalContacts];
        newList.push(queryData.Contact);
        updateList(newList);
        setEmailAddress('');
        setQueryData(null);
    };

    const removeContact = (id: string) => {
        const newList = [...additionalContacts.filter((contact) => contact.id !== id)];
        updateList(newList);
    };
    const lookUp = async (givenEmailAddress, givenAdditionalContacts) => {
        await peopleQuery(givenEmailAddress, givenAdditionalContacts);
    };
    const debouncedFunction = useCallback(debounce(lookUp, 500), []);

    useEffect(() => {
        if (emailAddress) {
            debouncedFunction(emailAddress, additionalContacts);
        }
    }, [emailAddress, additionalContacts, debouncedFunction]);

    return (
        <div className="additionalContacts">
            <div className="step">
                <div className="step-text">2</div>
            </div>
            <h6>Enter Additional Emails</h6>
            <p>Add any additional contacts to this segment.</p>
            <Grid container className="box">
                <Grid item xs={6} spacing={4}>
                    <Typography>Email Address</Typography>
                    <TextField
                        value={emailAddress}
                        size="medium"
                        margin="dense"
                        inputProps={{ style: { fontSize: '14px', padding: '8px' } }}
                        name="emailAddress"
                        variant="outlined"
                        onChange={(e) => {
                            setEmailAddress(e.target.value);
                        }}
                        className={classes.nameEntry}
                    />
                </Grid>
                <Grid item xs={4} spacing={4}>
                    <Button
                        color="primary"
                        variant="contained"
                        className={classes.button}
                        onClick={addContact}
                        disabled={emailAddress === '' || duplicate || error || !queryData}
                    >
                        <ControlPointIcon id="AddContactPlusIcon" fontSize="small" /> Add Contact
                    </Button>
                </Grid>
                {error && (
                    <Grid item xs={8} spacing={4} className={classes.error}>
                        That contact could not be found. Please make sure that the email is accurate, complete, and
                        exists in the system.
                    </Grid>
                )}
                {duplicate && (
                    <Grid item xs={8} spacing={4} className={classes.error}>
                        This contact has already been added to the segment.
                    </Grid>
                )}
                <Grid item xs={8} spacing={4}>
                    {additionalContacts.length !== 0 &&
                        additionalContacts.map((contact) => {
                            return (
                                <Chip
                                    data-testId="ContactChip"
                                    className="ContactChip"
                                    id={contact.id}
                                    key={contact.id}
                                    label={contact.emailAddress}
                                    onDelete={(): void => {
                                        removeContact(contact.id);
                                    }}
                                />
                            );
                        })}
                </Grid>
            </Grid>
        </div>
    );
};

export default AdditionalContacts;
