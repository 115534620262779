import React, { ReactElement } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Button, DialogContentText, Grid, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { UPDATE_CONTACT_PUBLIC } from 'api/mutations';
import { GET_CONTACT } from 'api/queries';
import checkBoxObjToString from 'components/utils/CheckBoxObjToString';
import { defaultFormValues as defaultValues } from 'components/utils/GlobalConstants';
import showSnackbarError from 'components/utils/ShowSnackbarError';
import SubscriptionForm from 'pageComponents/contact/subscriptionForm/SubscriptionForm';
import { showDialog } from 'store/actions/Dialog';
import { showSnackbar } from 'store/actions/Snackbar';
import { ContactStatus } from 'typings/_graphql';
import { FormValues } from 'typings/Subscription';

import './UpdatePreferences.scss';

const formId = 'updatePreferences';
export interface ParamType {
    id: string;
}

const useStyles = makeStyles({
    form: {
        border: '1px solid rgb(7, 9, 12, .16)',
        padding: '1rem 2rem 0 2rem',
    },
    popoverOnTop: {
        zIndex: 10,
    },
    formControl: {
        minWidth: '10rem',
    },
    formControlLabel: {
        marginLeft: '0.9rem',
    },
    heading: {
        fontSize: '1.4rem',
        fontWeight: 400,
        paddingBottom: '1rem',
    },
    subHeading: {
        fontSize: '.9rem',
        paddingBottom: '1rem',
    },
    firstSectionName: {
        fontSize: '1.2rem',
        paddingBottom: '.3rem',
    },
    companySectionName: {
        fontSize: '1.2rem',
        paddingTop: '1.8rem',
    },
    sectionName: {
        fontSize: '1.2rem',
        marginTop: '1.25rem',
        marginBottom: '0.75rem',
    },
    inputLabels: {
        marginTop: '0.8rem',
        marginBottom: '0.5rem',
        color: '#000',
    },

    data: {
        fontSize: '1rem',
        fontFamily: 'Roboto',
    },
    vdata: {
        fontSize: '1rem',
        fontFamily: 'Roboto',
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    EmailValidation: {
        color: '#f00',
        margin: '0.1rem 0rem',
        fontSize: '0.875rem',
        fontFamily: 'Roboto',
    },
    select: {
        height: '2.5rem',
        marginTop: '0.5rem',
    },
    menuHeight: {
        height: '20vh',
        minHeight: '10rem',
    },
    UpdateButton: {
        marginLeft: '1rem',
    },
    agreeText: {
        marginTop: '1rem',
        paddingBottom: '1rem',
    },
    buttonDiv: {
        border: '1px solid rgb(7, 9, 12, .16)',
        padding: '1rem',
    },
});
const Preferences = (): ReactElement => {
    const classes = useStyles();
    const { id } = useParams<ParamType>();
    const getContact = useQuery(GET_CONTACT, {
        variables: { id },
        fetchPolicy: 'network-only',
    });
    const { techFocusAreas, companyCertifications } = defaultValues;

    const defaultFormValues = {
        id: getContact.data?.Contact.id || '',
        emailAddress: getContact.data?.Contact.emailAddress || '',
        firstName: getContact.data?.Contact.firstName || '',
        lastName: getContact.data?.Contact.lastName || '',
        jobTitle: getContact.data?.Contact.jobTitle || '',
        phoneNumber: getContact.data?.Contact.phoneNumber || '',
        companyName: getContact.data?.Contact.companyName || '',
        dunsNumber: getContact.data?.Contact.dunsNumber || '',
        city: getContact.data?.Contact.city || '',
        state: getContact.data?.Contact.state || '',
        techFocusAreas,
        companyCertifications,
    };
    if (getContact.data?.Contact.techFocusAreas !== '') {
        const selectedTechAreas = getContact.data?.Contact.techFocusAreas?.split(',') || '';

        if (selectedTechAreas)
            selectedTechAreas.forEach((area) => {
                defaultFormValues.techFocusAreas = {
                    ...defaultFormValues.techFocusAreas,
                    [area]: true,
                };
            });
    }
    if (getContact.data?.Contact.companyCertifications !== '') {
        const selectedCerts = getContact.data?.Contact.companyCertifications?.split(',') || '';
        if (selectedCerts)
            selectedCerts.forEach((cert) => {
                defaultFormValues.companyCertifications = {
                    ...defaultFormValues.companyCertifications,
                    [cert]: true,
                };
            });
    }

    const dispatch = useDispatch();

    const [updateContact] = useMutation(UPDATE_CONTACT_PUBLIC);

    const onSave = async (formData: FormValues) => {
        const data = {
            emailAddress: formData.emailAddress,
            companyName: formData.companyName,
            city: formData.city,
            state: formData.state,
            firstName: formData.firstName,
            lastName: formData.lastName,
            jobTitle: formData.jobTitle,
            dunsNumber: formData.dunsNumber,
            phoneNumber: formData.phoneNumber,
            status: ContactStatus.SUBSCRIBED,
            audienceId: 1,
            techFocusAreas: checkBoxObjToString(formData.techFocusAreas),
            companyCertifications: checkBoxObjToString(formData.companyCertifications),
        };

        Object.keys(data).forEach((key) => {
            if (data[key] === undefined) data[key] = '';
        });

        try {
            await updateContact({
                variables: {
                    id,
                    data,
                },
            });

            dispatch(showSnackbar(true, 'Contact preferences updated!', 'success'));
            dispatch(showDialog(false, '', undefined, undefined));
        } catch (err) {
            showSnackbarError(dispatch, err);
        }
    };

    return (
        <div className="UpdatePreferences">
            <div className="UpdatePreferencesForm">
                <SubscriptionForm
                    formId={formId}
                    onSave={onSave}
                    contactData={getContact?.data}
                    defaultFormValues={defaultFormValues}
                    classes={classes}
                    isPublic
                    isSubscribe={false}
                />
                <div className={classes.buttonDiv}>
                    <DialogContentText className={classes.agreeText}>
                        You agree to receive emails about SBIR/STTR news and opportunities as information becomes
                        available.
                    </DialogContentText>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <a className="OkBtn" href="https://www.afsbirsttr.af.mil/">
                                <Button color="primary" variant="outlined">
                                    Cancel
                                </Button>
                            </a>
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                form={formId}
                                className={classes.UpdateButton}
                                variant="contained"
                                color="primary"
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
};
export default Preferences;
