import { gql } from 'apollo-boost';

const CREATE_INVITES = gql`
    mutation CreateInvites($data: CreateInviteInput!) {
        createInvites(data: $data) {
            id
            emailAddress
            inviteStatus
        }
    }
`;

export default CREATE_INVITES;
