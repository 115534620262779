
/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveCampaign
// ====================================================

export interface SaveCampaignCampaign {
  readonly title: string;
  readonly id: number;
}

export interface SaveCampaign {
  readonly Campaign: SaveCampaignCampaign;
}

export interface SaveCampaignVariables {
  readonly data: CampaignInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendCampaign
// ====================================================

export interface SendCampaignCampaign {
  readonly title: string;
  readonly id: number;
}

export interface SendCampaign {
  readonly Campaign: SendCampaignCampaign;
}

export interface SendCampaignVariables {
  readonly campaign: CampaignInput;
  readonly html: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendTestCampaign
// ====================================================

export interface SendTestCampaignCampaign {
  readonly id: number;
}

export interface SendTestCampaign {
  readonly Campaign: SendTestCampaignCampaign;
}

export interface SendTestCampaignVariables {
  readonly campaign: CampaignInput;
  readonly html: string;
  readonly testEmails: ReadonlyArray<string>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateContact
// ====================================================

export interface CreateContactContact {
  readonly id: string;
}

export interface CreateContact {
  readonly Contact: CreateContactContact;
}

export interface CreateContactVariables {
  readonly contact: CreateContactInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateContactPublic
// ====================================================

export interface CreateContactPublicContact {
  readonly id: string;
}

export interface CreateContactPublic {
  readonly Contact: CreateContactPublicContact;
}

export interface CreateContactPublicVariables {
  readonly contact: CreateContactInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAndUpdateContacts
// ====================================================

export interface CreateAndUpdateContactsCreateContactResponseContactList {
  readonly id: string;
  readonly emailAddress: string;
  readonly status: ContactStatus | null;
}

export interface CreateAndUpdateContactsCreateContactResponse {
  readonly contactList: ReadonlyArray<CreateAndUpdateContactsCreateContactResponseContactList>;
  readonly errors: ReadonlyArray<string>;
}

export interface CreateAndUpdateContacts {
  readonly CreateContactResponse: CreateAndUpdateContactsCreateContactResponse;
}

export interface CreateAndUpdateContactsVariables {
  readonly contacts: CreateContactListInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateContact
// ====================================================

export interface UpdateContactContact {
  readonly id: string;
}

export interface UpdateContact {
  readonly Contact: UpdateContactContact;
}

export interface UpdateContactVariables {
  readonly id: string;
  readonly data: UpdateContactInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateContactPublic
// ====================================================

export interface UpdateContactPublicContact {
  readonly id: string;
}

export interface UpdateContactPublic {
  readonly Contact: UpdateContactPublicContact;
}

export interface UpdateContactPublicVariables {
  readonly id: string;
  readonly data: UpdateContactInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteContact
// ====================================================

export interface DeleteContactContact {
  readonly id: string;
}

export interface DeleteContact {
  readonly Contact: DeleteContactContact;
}

export interface DeleteContactVariables {
  readonly id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteContacts
// ====================================================

export interface DeleteContactsDeleteContactResponseContactList {
  readonly id: string;
}

export interface DeleteContactsDeleteContactResponse {
  readonly contactList: ReadonlyArray<DeleteContactsDeleteContactResponseContactList>;
  readonly errors: ReadonlyArray<string>;
}

export interface DeleteContacts {
  readonly DeleteContactResponse: DeleteContactsDeleteContactResponse;
}

export interface DeleteContactsVariables {
  readonly data: DeleteContactListInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnsubscribeContact
// ====================================================

export interface UnsubscribeContactContact {
  readonly id: string;
  readonly emailAddress: string;
}

export interface UnsubscribeContact {
  readonly Contact: UnsubscribeContactContact;
}

export interface UnsubscribeContactVariables {
  readonly id: string;
  readonly campaignId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnsubscribeContactPublic
// ====================================================

export interface UnsubscribeContactPublicContact {
  readonly id: string;
  readonly emailAddress: string;
}

export interface UnsubscribeContactPublic {
  readonly Contact: UnsubscribeContactPublicContact;
}

export interface UnsubscribeContactPublicVariables {
  readonly id: string;
  readonly campaignId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubscribeContact
// ====================================================

export interface SubscribeContactContact {
  readonly id: string;
  readonly emailAddress: string;
}

export interface SubscribeContact {
  readonly Contact: SubscribeContactContact;
}

export interface SubscribeContactVariables {
  readonly id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubscribeContactPublic
// ====================================================

export interface SubscribeContactPublicContact {
  readonly id: string;
  readonly emailAddress: string;
}

export interface SubscribeContactPublic {
  readonly Contact: SubscribeContactPublicContact;
}

export interface SubscribeContactPublicVariables {
  readonly id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResubscribeContact
// ====================================================

export interface ResubscribeContactContact {
  readonly id: string;
  readonly emailAddress: string;
}

export interface ResubscribeContact {
  readonly Contact: ResubscribeContactContact;
}

export interface ResubscribeContactVariables {
  readonly id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResubscribeContactPublic
// ====================================================

export interface ResubscribeContactPublicContact {
  readonly id: string;
  readonly emailAddress: string;
}

export interface ResubscribeContactPublic {
  readonly Contact: ResubscribeContactPublicContact;
}

export interface ResubscribeContactPublicVariables {
  readonly id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSegment
// ====================================================

export interface CreateSegmentSegment {
  readonly id: number;
}

export interface CreateSegment {
  readonly Segment: CreateSegmentSegment;
}

export interface CreateSegmentVariables {
  readonly data: CreateSegmentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSegment
// ====================================================

export interface UpdateSegmentSegment {
  readonly id: number;
}

export interface UpdateSegment {
  readonly Segment: UpdateSegmentSegment;
}

export interface UpdateSegmentVariables {
  readonly id: number;
  readonly data: UpdateSegmentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSegment
// ====================================================

export interface DeleteSegmentSegment {
  readonly id: number;
}

export interface DeleteSegment {
  readonly Segment: DeleteSegmentSegment;
}

export interface DeleteSegmentVariables {
  readonly id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateInvites
// ====================================================

export interface CreateInvitesCreateInvites {
  readonly id: string;
  readonly emailAddress: string;
  readonly inviteStatus: string | null;
}

export interface CreateInvites {
  readonly createInvites: ReadonlyArray<CreateInvitesCreateInvites>;
}

export interface CreateInvitesVariables {
  readonly data: CreateInviteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindCampaign
// ====================================================

export interface FindCampaignCampaignCampaignAnalytics {
  readonly sentCount: number | null;
  readonly deliveredCount: number | null;
  readonly openedCount: number | null;
  readonly withClicksCount: number | null;
  readonly hardBounceCount: number | null;
  readonly complaintCount: number | null;
  readonly hasUnsubscribesCount: number | null;
  readonly unknownStatusCount: number | null;
  readonly modifiedDate: any | null;
}

export interface FindCampaignCampaignSegments {
  readonly id: number;
  readonly name: string;
}

export interface FindCampaignCampaignCampaignTemplateDatas {
  readonly inputDescription: string;
  readonly inputValue: string;
  readonly groupNumber: number | null;
}

export interface FindCampaignCampaignLinkClicks {
  readonly clickAddress: string;
  readonly clickCount: number | null;
  readonly uniqueClickCount: number | null;
}

export interface FindCampaignCampaign {
  readonly id: number;
  readonly subject: string | null;
  readonly senderEmail: string | null;
  readonly title: string;
  readonly isSent: boolean;
  readonly templateTypeId: number | null;
  readonly sendDate: any | null;
  readonly createdDate: any;
  readonly modifiedDate: any | null;
  readonly campaignAnalytics: FindCampaignCampaignCampaignAnalytics | null;
  readonly segments: ReadonlyArray<FindCampaignCampaignSegments>;
  readonly campaignTemplateDatas: ReadonlyArray<FindCampaignCampaignCampaignTemplateDatas>;
  readonly linkClicks: ReadonlyArray<FindCampaignCampaignLinkClicks>;
}

export interface FindCampaign {
  readonly Campaign: FindCampaignCampaign;
}

export interface FindCampaignVariables {
  readonly id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTimeGraph
// ====================================================

export interface GetTimeGraph {
  readonly TimeGraph: any;
}

export interface GetTimeGraphVariables {
  readonly id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindCampaignByTitle
// ====================================================

export interface FindCampaignByTitle {
  readonly campaignExists: boolean;
}

export interface FindCampaignByTitleVariables {
  readonly title: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindCampaigns
// ====================================================

export interface FindCampaignsCampaignsCampaignAnalytics {
  readonly sentCount: number | null;
  readonly deliveredCount: number | null;
  readonly openedCount: number | null;
  readonly withClicksCount: number | null;
  readonly hardBounceCount: number | null;
  readonly complaintCount: number | null;
  readonly hasUnsubscribesCount: number | null;
  readonly unknownStatusCount: number | null;
  readonly modifiedDate: any | null;
}

export interface FindCampaignsCampaignsSegments {
  readonly name: string;
  readonly id: number;
}

export interface FindCampaignsCampaigns {
  readonly id: number;
  readonly title: string;
  readonly isSent: boolean;
  readonly sendDate: any | null;
  readonly templateTypeId: number | null;
  readonly createdDate: any;
  readonly modifiedDate: any | null;
  readonly campaignAnalytics: FindCampaignsCampaignsCampaignAnalytics | null;
  readonly segments: ReadonlyArray<FindCampaignsCampaignsSegments>;
}

export interface FindCampaigns {
  readonly Campaigns: ReadonlyArray<FindCampaignsCampaigns>;
}

export interface FindCampaignsVariables {
  readonly audienceId: number;
  readonly filters?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindCampaignsWithCount
// ====================================================

export interface FindCampaignsWithCountCampaignsWithCountResponseCampaignsCampaignAnalytics {
  readonly sentCount: number | null;
  readonly deliveredCount: number | null;
  readonly openedCount: number | null;
  readonly withClicksCount: number | null;
  readonly hardBounceCount: number | null;
  readonly complaintCount: number | null;
  readonly hasUnsubscribesCount: number | null;
  readonly unknownStatusCount: number | null;
  readonly modifiedDate: any | null;
}

export interface FindCampaignsWithCountCampaignsWithCountResponseCampaignsSegments {
  readonly name: string;
  readonly id: number;
}

export interface FindCampaignsWithCountCampaignsWithCountResponseCampaigns {
  readonly id: number;
  readonly title: string;
  readonly isSent: boolean;
  readonly sendDate: any | null;
  readonly templateTypeId: number | null;
  readonly createdDate: any;
  readonly modifiedDate: any | null;
  readonly campaignAnalytics: FindCampaignsWithCountCampaignsWithCountResponseCampaignsCampaignAnalytics | null;
  readonly segments: ReadonlyArray<FindCampaignsWithCountCampaignsWithCountResponseCampaignsSegments>;
}

export interface FindCampaignsWithCountCampaignsWithCountResponse {
  readonly campaigns: ReadonlyArray<FindCampaignsWithCountCampaignsWithCountResponseCampaigns>;
  readonly nonPaginatedCount: number;
}

export interface FindCampaignsWithCount {
  readonly CampaignsWithCountResponse: FindCampaignsWithCountCampaignsWithCountResponse;
}

export interface FindCampaignsWithCountVariables {
  readonly audienceId: number;
  readonly filters?: string | null;
  readonly sort?: SortInput | null;
  readonly pagination?: PaginationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindContact
// ====================================================

export interface FindContactContact {
  readonly id: string;
  readonly emailAddress: string;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly phoneNumber: string | null;
  readonly jobTitle: string | null;
  readonly companyName: string | null;
  readonly city: string | null;
  readonly state: string | null;
  readonly naicsCode: string | null;
  readonly dunsNumber: string | null;
  readonly companyCertifications: string | null;
  readonly techFocusAreas: string | null;
}

export interface FindContact {
  readonly Contact: FindContactContact;
}

export interface FindContactVariables {
  readonly id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindContacts
// ====================================================

export interface FindContactsContacts {
  readonly emailAddress: string;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly phoneNumber: string | null;
  readonly jobTitle: string | null;
  readonly companyName: string | null;
  readonly city: string | null;
  readonly state: string | null;
  readonly naicsCode: string | null;
  readonly dunsNumber: string | null;
  readonly companyCertifications: string | null;
  readonly techFocusAreas: string | null;
}

export interface FindContacts {
  readonly Contacts: ReadonlyArray<FindContactsContacts>;
}

export interface FindContactsVariables {
  readonly audienceId: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindContactsFromEmail
// ====================================================

export interface FindContactsFromEmailContacts {
  readonly emailAddress: string;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly phoneNumber: string | null;
  readonly jobTitle: string | null;
  readonly companyName: string | null;
  readonly city: string | null;
  readonly state: string | null;
  readonly naicsCode: string | null;
  readonly dunsNumber: string | null;
  readonly companyCertifications: string | null;
  readonly techFocusAreas: string | null;
}

export interface FindContactsFromEmail {
  readonly Contacts: ReadonlyArray<FindContactsFromEmailContacts>;
}

export interface FindContactsFromEmailVariables {
  readonly audienceId: number;
  readonly emailAddresses: ReadonlyArray<string>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindContactFromEmail
// ====================================================

export interface FindContactFromEmailContact {
  readonly id: string;
  readonly status: ContactStatus | null;
  readonly emailAddress: string;
  readonly companyName: string | null;
  readonly city: string | null;
  readonly state: string | null;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly jobTitle: string | null;
  readonly dunsNumber: string | null;
  readonly naicsCode: string | null;
  readonly phoneNumber: string | null;
  readonly companyCertifications: string | null;
  readonly techFocusAreas: string | null;
}

export interface FindContactFromEmail {
  readonly Contact: FindContactFromEmailContact;
}

export interface FindContactFromEmailVariables {
  readonly audienceId: number;
  readonly emailAddress: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindContactsFromFilters
// ====================================================

export interface FindContactsFromFiltersContacts {
  readonly id: string;
  readonly status: ContactStatus | null;
  readonly emailAddress: string;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly phoneNumber: string | null;
  readonly jobTitle: string | null;
  readonly companyName: string | null;
  readonly city: string | null;
  readonly state: string | null;
  readonly naicsCode: string | null;
  readonly dunsNumber: string | null;
}

export interface FindContactsFromFilters {
  readonly Contacts: ReadonlyArray<FindContactsFromFiltersContacts>;
}

export interface FindContactsFromFiltersVariables {
  readonly audienceId: number;
  readonly filters?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindContactsFromFiltersAndList
// ====================================================

export interface FindContactsFromFiltersAndListContacts {
  readonly id: string;
  readonly status: ContactStatus | null;
  readonly emailAddress: string;
  readonly companyName: string | null;
  readonly city: string | null;
  readonly state: string | null;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly jobTitle: string | null;
  readonly dunsNumber: string | null;
  readonly naicsCode: string | null;
  readonly phoneNumber: string | null;
  readonly companyCertifications: string | null;
  readonly techFocusAreas: string | null;
}

export interface FindContactsFromFiltersAndList {
  readonly Contacts: ReadonlyArray<FindContactsFromFiltersAndListContacts>;
}

export interface FindContactsFromFiltersAndListVariables {
  readonly audienceId: number;
  readonly filters?: string | null;
  readonly emailAddresses?: ReadonlyArray<string> | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindContactsFromFiltersCount
// ====================================================

export interface FindContactsFromFiltersCount {
  readonly Count: number;
}

export interface FindContactsFromFiltersCountVariables {
  readonly audienceId: number;
  readonly filters?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindEnvironmentVariables
// ====================================================

export interface FindEnvironmentVariables {
  readonly variables: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindSegment
// ====================================================

export interface FindSegmentSegmentContacts {
  readonly id: string;
  readonly emailAddress: string;
}

export interface FindSegmentSegment {
  readonly id: number;
  readonly name: string;
  readonly description: string | null;
  readonly filterQuery: any;
  readonly contacts: ReadonlyArray<FindSegmentSegmentContacts> | null;
}

export interface FindSegment {
  readonly Segment: FindSegmentSegment;
}

export interface FindSegmentVariables {
  readonly id: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindSegments
// ====================================================

export interface FindSegmentsSegmentsCreatedBy {
  readonly firstName: string;
  readonly lastName: string;
}

export interface FindSegmentsSegments {
  readonly id: number;
  readonly name: string;
  readonly description: string | null;
  readonly filterQuery: any;
  readonly createdDate: any;
  readonly createdBy: FindSegmentsSegmentsCreatedBy | null;
  readonly lastUsedDate: any | null;
}

export interface FindSegments {
  readonly Segments: ReadonlyArray<FindSegmentsSegments>;
}

export interface FindSegmentsVariables {
  readonly audienceId: number;
  readonly filters?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindSegmentsWithCount
// ====================================================

export interface FindSegmentsWithCountSegmentsWithCountResponseSegmentsCreatedBy {
  readonly firstName: string;
  readonly lastName: string;
}

export interface FindSegmentsWithCountSegmentsWithCountResponseSegmentsContacts {
  readonly emailAddress: string;
}

export interface FindSegmentsWithCountSegmentsWithCountResponseSegments {
  readonly id: number;
  readonly name: string;
  readonly description: string | null;
  readonly filterQuery: any;
  readonly createdDate: any;
  readonly createdBy: FindSegmentsWithCountSegmentsWithCountResponseSegmentsCreatedBy | null;
  readonly lastUsedDate: any | null;
  readonly contacts: ReadonlyArray<FindSegmentsWithCountSegmentsWithCountResponseSegmentsContacts> | null;
}

export interface FindSegmentsWithCountSegmentsWithCountResponse {
  readonly segments: ReadonlyArray<FindSegmentsWithCountSegmentsWithCountResponseSegments>;
  readonly nonPaginatedCount: number;
}

export interface FindSegmentsWithCount {
  readonly SegmentsWithCountResponse: FindSegmentsWithCountSegmentsWithCountResponse;
}

export interface FindSegmentsWithCountVariables {
  readonly audienceId: number;
  readonly filters?: string | null;
  readonly sort?: SortInput | null;
  readonly pagination?: PaginationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindSegmentNames
// ====================================================

export interface FindSegmentNamesSegmentsContacts {
  readonly emailAddress: string;
}

export interface FindSegmentNamesSegments {
  readonly id: number;
  readonly name: string;
  readonly contacts: ReadonlyArray<FindSegmentNamesSegmentsContacts> | null;
}

export interface FindSegmentNames {
  readonly Segments: ReadonlyArray<FindSegmentNamesSegments>;
}

export interface FindSegmentNamesVariables {
  readonly audienceId: number;
  readonly filters?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindSegmentContacts
// ====================================================

export interface FindSegmentContactsContacts {
  readonly emailAddress: string;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly phoneNumber: string | null;
  readonly jobTitle: string | null;
  readonly companyName: string | null;
  readonly city: string | null;
  readonly state: string | null;
  readonly naicsCode: string | null;
  readonly dunsNumber: string | null;
}

export interface FindSegmentContacts {
  readonly Contacts: ReadonlyArray<FindSegmentContactsContacts>;
}

export interface FindSegmentContactsVariables {
  readonly id?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindSegmentsContacts
// ====================================================

export interface FindSegmentsContactsContacts {
  readonly emailAddress: string;
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly phoneNumber: string | null;
  readonly jobTitle: string | null;
  readonly companyName: string | null;
  readonly city: string | null;
  readonly state: string | null;
  readonly naicsCode: string | null;
  readonly dunsNumber: string | null;
}

export interface FindSegmentsContacts {
  readonly Contacts: ReadonlyArray<FindSegmentsContactsContacts>;
}

export interface FindSegmentsContactsVariables {
  readonly ids: ReadonlyArray<number>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindSegmentsContactsCount
// ====================================================

export interface FindSegmentsContactsCount {
  readonly SegmentContactCount: ReadonlyArray<number>;
}

export interface FindSegmentsContactsCountVariables {
  readonly ids: ReadonlyArray<number>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindUser
// ====================================================

export interface FindUserUser {
  readonly id: string;
  readonly emailAddress: string;
  readonly name: string;
}

export interface FindUser {
  readonly user: FindUserUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindAuthorizedUser
// ====================================================

export interface FindAuthorizedUserUser {
  readonly id: string;
  readonly emailAddress: string;
  readonly name: string;
}

export interface FindAuthorizedUser {
  readonly user: FindAuthorizedUserUser;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ContactStatus {
  CLEANED = "CLEANED",
  PENDING = "PENDING",
  SUBSCRIBED = "SUBSCRIBED",
  UNSUBSCRIBED = "UNSUBSCRIBED",
}

/**
 * The dir type
 */
export enum DirType {
  ASC = "ASC",
  DESC = "DESC",
}

export interface CampaignInput {
  readonly id?: number | null;
  readonly title: string;
  readonly subject: string;
  readonly senderEmail: string;
  readonly templateTypeId?: number | null;
  readonly isArchived?: boolean | null;
  readonly isSent?: boolean | null;
  readonly sendDate?: any | null;
  readonly campaignTemplateInputs?: ReadonlyArray<CampaignTemplateInput> | null;
  readonly emailTracking?: EmailTrackingInput | null;
  readonly segmentIds: ReadonlyArray<number>;
  readonly audienceId: number;
}

export interface CampaignTemplateInput {
  readonly inputDescription: string;
  readonly inputValue: string;
  readonly groupNumber?: number | null;
}

export interface ContactInput {
  readonly id: string;
}

export interface CreateContactInput {
  readonly emailAddress: string;
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly jobTitle?: string | null;
  readonly phoneNumber?: string | null;
  readonly ext?: string | null;
  readonly state?: string | null;
  readonly city?: string | null;
  readonly contactSource?: string | null;
  readonly dunsNumber?: string | null;
  readonly naicsCode?: string | null;
  readonly companyCertifications?: string | null;
  readonly techFocusAreas?: string | null;
  readonly companyName?: string | null;
  readonly contractName?: string | null;
  readonly contractTitle?: string | null;
  readonly notes?: string | null;
  readonly status?: ContactStatus | null;
  readonly isArchived?: boolean | null;
  readonly audienceId: number;
}

export interface CreateContactListInput {
  readonly contactList: ReadonlyArray<CreateContactInput>;
  readonly duplicateContacts: ReadonlyArray<UpdateContactInput>;
  readonly updateDuplicates: boolean;
}

export interface CreateInviteInput {
  readonly emailAddressList: string;
  readonly message?: string | null;
}

export interface CreateSegmentInput {
  readonly name: string;
  readonly description?: string | null;
  readonly filterQuery?: any | null;
  readonly isArchived: boolean;
  readonly audienceId: number;
  readonly contacts?: ReadonlyArray<ContactInput> | null;
}

export interface DeleteContactListInput {
  readonly contactIds: ReadonlyArray<string>;
}

export interface EmailTrackingInput {
  readonly wasSent: boolean;
}

export interface PaginationInput {
  readonly offset?: number | null;
  readonly limit?: number | null;
}

export interface SortInput {
  readonly direction?: DirType | null;
  readonly field: string;
}

export interface UpdateContactInput {
  readonly emailAddress?: string | null;
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly jobTitle?: string | null;
  readonly phoneNumber?: string | null;
  readonly ext?: string | null;
  readonly state?: string | null;
  readonly city?: string | null;
  readonly contactSource?: string | null;
  readonly dunsNumber?: string | null;
  readonly naicsCode?: string | null;
  readonly companyCertifications?: string | null;
  readonly techFocusAreas?: string | null;
  readonly companyName?: string | null;
  readonly contractName?: string | null;
  readonly contractTitle?: string | null;
  readonly notes?: string | null;
  readonly status?: ContactStatus | null;
  readonly isArchived?: boolean | null;
  readonly audienceId: number;
}

export interface UpdateSegmentInput {
  readonly name: string;
  readonly description?: string | null;
  readonly filterQuery?: any | null;
  readonly isArchived?: boolean | null;
  readonly audienceId?: number | null;
  readonly contacts?: ReadonlyArray<ContactInput> | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
