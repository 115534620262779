import BaseUrl from 'components/utils/BaseUrl';
import { dateConstructor } from 'components/utils/DateUtil';
import { TEMPLATE_TYPES, validateURL } from 'components/utils/GlobalConstants';
import { Campaign } from 'typings/Campaign';

const baseUrl = BaseUrl();
const { date: dateString, day: initialDay } = dateConstructor();
const article = {
    title: 'Article Title',
    preview: 'Article Preview',
    articleURL: 'https://www.afsbirsttr.af.mil/',
    imageURL:
        'https://images.unsplash.com/photo-1517429128955-67ff5c1e29da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
};
const articlesArray = Array(8).fill(article);
const EventValidation = (campaignState: Campaign): boolean => {
    const { name, date, day, location, description, url, imageURL, time } = campaignState.template.event;
    const event = {
        date: dateString,
        day: initialDay,
        time: '12:00',
        location: 'Location',
        description: 'Event Description',
        name: 'EVENT NAME',
        url: 'https://www.afsbirsttr.af.mil/',
        imageURL: `${baseUrl}/images/templates/event2.jpg`,
    };
    if (
        name !== event.name &&
        name !== '' &&
        date !== '' &&
        day !== '' &&
        location !== event.location &&
        location !== '' &&
        description !== event.description &&
        description !== '' &&
        url !== event.url &&
        url !== '' &&
        imageURL !== '' &&
        time !== '' &&
        validateURL(url) &&
        validateURL(imageURL)
    )
        return true;
    return false;
};

const NewsletterValidation = (campaignState: Campaign): boolean => {
    const { welcomeMessage, date, articleCount, articles } = campaignState.template.newsletter;
    const newsletter = {
        tagline: 'Tagline',
        articleCount: 2,
        date: dateString,
        welcomeMessage: 'Welcome Message',
        articles: articlesArray,
    };

    for (let index = 0; index < articleCount; index++) {
        const updatedArticle = articles[index];
        const initialArticle = newsletter.articles[index];
        if (updatedArticle.title === initialArticle.title || updatedArticle.title === '') return false;
        if (updatedArticle.preview === initialArticle.preview || updatedArticle.preview === '') return false;
        if (!validateURL(updatedArticle.imageURL)) return false;
    }
    if (welcomeMessage === newsletter.welcomeMessage || date === '' || welcomeMessage === '') return false;
    return true;
};

const PressValidation = (campaignState: Campaign): boolean => {
    const press = {
        date: dateString,
        headline: 'Headline',
        subheadline: 'Sub-Headline',
        description: 'Description',
        contactName: 'Contact Name',
        contactNum: 'Contact Phone',
        contactEmail: 'Contact Email',
        imageURL:
            'https://images.unsplash.com/photo-1515700845405-9ffd33692a68?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1955&q=80',
    };
    const {
        headline,
        date,
        subheadline,
        description,
        contactName,
        contactNum,
        contactEmail,
        imageURL,
    } = campaignState.template.press;
    if (
        headline !== press.headline &&
        subheadline !== press.subheadline &&
        description !== press.description &&
        contactName !== press.contactName &&
        contactNum !== press.contactNum &&
        contactEmail !== press.contactEmail &&
        headline !== '' &&
        date !== '' &&
        subheadline !== '' &&
        description !== '' &&
        contactName !== '' &&
        contactNum !== '' &&
        contactEmail !== '' &&
        validateURL(imageURL)
    )
        return true;
    return false;
};
const ContentValidation = (campaignState: Campaign): boolean => {
    const { NEWSLETTER, PRESS, EVENT } = TEMPLATE_TYPES;
    if (campaignState.template.type === EVENT) {
        return EventValidation(campaignState);
    }
    if (campaignState.template.type === NEWSLETTER) {
        return NewsletterValidation(campaignState);
    }
    if (campaignState.template.type === PRESS) {
        return PressValidation(campaignState);
    }
    return false;
};
export default ContentValidation;
