import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';

import logo from 'assets/images/Engage_White.svg';
import { ComplianceContainer, IconContainer } from 'components/layouts/navigation/navIcons';
import TopNav from '../navigation/topNav/TopNav';

import './Header.scss';

interface HeaderProps {
    showNavigation?: boolean;
}

const Header = ({ showNavigation }): JSX.Element => {
    const history = useHistory();
    const [versionNumber] = useState(process.env.REACT_APP_VERSION || 'unknown');
    const [environment] = useState(process.env.ENVIRONMENT || 'unknown');
    const [apiVersion] = useState(() => {
        try {
            // Get from local storage by key
            const item = localStorage.getItem('x-version-backend');
            // Parse stored json or if none return initialValue
            return item;
        } catch (error) {
            // If error also return initialValue
            return '';
        }
    });

    const [apiEnvironment] = useState(() => {
        try {
            // Get from local storage by key
            const item = localStorage.getItem('x-environment-backend');
            // Parse stored json or if none return initialValue
            return item;
        } catch (error) {
            // If error also return initialValue
            return '';
        }
    });

    const [noAccess, setAccess] = useState(window.location.pathname.includes('unauthorized'));

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return history.listen((location: any) => {
            setAccess(location.pathname.includes('unauthorized'));
        });
    }, [history]);

    return (
        <header className="main-head">
            {!noAccess && (
                <>
                    <Link to="/" className="logo-link">
                        <img
                            className="logo"
                            src={logo}
                            alt="Engage logo"
                            title={`${environment} - Version ${versionNumber}: API Version ${apiVersion} (${apiEnvironment})`}
                        />
                    </Link>

                    {showNavigation ? (
                        <>
                            <TopNav />
                            <div className="filler" />
                            <div className="desktop">
                                <Grid container spacing={6}>
                                    <Grid item xs={6}>
                                        <ComplianceContainer />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <IconContainer />
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="tablet">
                                <Grid container spacing={6}>
                                    <Grid item xs={6}>
                                        <ComplianceContainer />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <IconContainer />
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    ) : (
                        <div
                            className="login-link"
                            onClick={() => history.push('/campaign')}
                            onKeyPress={(event): void => {
                                if (event.key === 'Enter') history.push('/campaign');
                            }}
                            role="button"
                            tabIndex={0}
                        >
                            Login
                        </div>
                    )}
                </>
            )}
        </header>
    );
};

Header.defaultProps = {
    showNavigation: true,
};

export default Header;
