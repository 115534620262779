import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Button, Grid, makeStyles, Step, StepButton, Stepper } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { debounce, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SAVE_CAMPAIGN, SEND_CAMPAIGN, SEND_TEST_CAMPAIGN } from 'api/mutations';
import { GET_SEGMENTS_CONTACTS_COUNT } from 'api/queries';
import { getEmailHTMLFromCampaign, getEmailRequest } from 'components/utils/CreateEmail';
import { getLocalDateString } from 'components/utils/DateUtil';
import { CAMPAIGN_PAGE } from 'components/utils/GlobalConstants';
import saveDraft from 'components/utils/SaveCampaignDraft';
import showSnackbarError from 'components/utils/ShowSnackbarError';
import ContentValidation from 'pageComponents/campaign/campaignStepper/ContentValidation';
import SendEmailConfirmationForm from 'pageComponents/campaign/sendEmailConfirmationForm/SendEmailConfirmationForm';
import SendTestEmailForm from 'pageComponents/campaign/sendTestEmailForm/SendTestEmailForm';
import AddContent from 'pages/createCampaign/campaignTabs/addContent/AddContent';
import CampaignDetails from 'pages/createCampaign/campaignTabs/campaignDetails/CampaignDetails';
import ReviewCampaign from 'pages/createCampaign/campaignTabs/reviewCampaign/ReviewCampaign';
import SelectAudience from 'pages/createCampaign/campaignTabs/selectAudience/SelectAudience';
import SelectTemplate from 'pages/createCampaign/campaignTabs/selectTemplate/SelectTemplate';
import { resetCampaign, selectCampaignTab, updateCampaignId } from 'store/actions/Campaign';
import { showDialog } from 'store/actions/Dialog';
import { showSnackbar } from 'store/actions/Snackbar';
import { Store } from 'store/reducers';
import { Campaign } from 'typings/Campaign';

import './CampaignStepper.scss';

const useStyles = makeStyles({
    stepButton: {
        backgroundColor: '#f7fbfc',
        cursor: 'pointer',
        fontSize: '12.9px',
        outline: 'none !important',
        color: '#0c1256 !important',
        '&.Mui-disabled .MuiStepLabel-label': {
            color: 'rgba(200, 200, 200, 0.87)',
        },
        '&:not(.Mui-disabled) .MuiStepLabel-label': {
            color: 'rgba(0, 0, 0, 0.87)',
        },
    },
    disabledStepButton: {
        color: '#c8cfd6',
        cursor: 'auto',
    },
    activeStep: {
        '& .MuiStepLabel-label': {
            fontWeight: 'bolder',
        },
    },
});

const CampaignStepper = (): ReactElement => {
    const sendFormId = 'confirmSendCampaignForm';
    const sendTestFormId = 'sendTestEmailForm';

    const campaign = useSelector((storeState: Store) => storeState.Campaign);
    const [lastSavedCampaign, setLastSavedCampaign] = useState(campaign);
    const campaignStore = useSelector((storeState: Store) => storeState.Campaign.template);
    const [saveCampaign] = useMutation(SAVE_CAMPAIGN);
    const [sendTestEmailMutation] = useMutation(SEND_TEST_CAMPAIGN);
    const [sendCampaign] = useMutation(SEND_CAMPAIGN);
    const { segmentIds } = campaign.selectAudience;
    const findSegmentsContactsCount = useQuery(GET_SEGMENTS_CONTACTS_COUNT, { variables: { ids: segmentIds } });
    const { campaignTab } = campaign;
    const { segmentNames } = campaign.selectAudience;
    const [showFieldRequired, setShowFieldRequired] = useState<boolean>(false);
    const [validationAnimation, setValidationAnimation] = useState<boolean>(false);
    const [farStep, setFarStep] = useState<number>(0);
    const [isUniqueCampaignName, setUniqueCampaignName] = useState<boolean>(true);
    const [completedSteps, setCompletedSteps] = useState<{ [key: number]: boolean }>({});
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const autoSave = useCallback(
        debounce(async (campaignToSave, showWasSaved) => {
            const campaignResponse = await saveDraft(dispatch, campaignToSave, saveCampaign, showWasSaved);

            if (campaignResponse) {
                const { id = 0 } = campaignResponse;
                setLastSavedCampaign({ ...campaignToSave, id });
                dispatch(updateCampaignId({ id }));
            }
        }, 1000),
        []
    );

    useEffect(() => {
        if (campaign.campaignDetails.campaignName && !isEqual(campaign.campaignTab, lastSavedCampaign.campaignTab)) {
            autoSave(campaign, true);
        } else if (
            campaign.campaignDetails.campaignName &&
            campaign.campaignDetails.subject &&
            !isEqual(campaign, lastSavedCampaign)
        ) {
            autoSave(campaign, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign]);

    const steps = [
        {
            label: 'Add Details',
            Component: CampaignDetails,
            validation: ({ campaignDetails }: Campaign) => {
                return (
                    campaignDetails.campaignName !== '' &&
                    campaignDetails.subject !== '' &&
                    campaignDetails.senderEmail !== '' &&
                    campaignDetails.senderName !== '' &&
                    isUniqueCampaignName
                );
            },
        },
        {
            label: 'Select Audience',
            Component: SelectAudience,
            validation: ({ selectAudience }: Campaign) => selectAudience.segmentIds.length > 0,
        },
        {
            label: 'Select Template',
            Component: SelectTemplate,
            validation: ({ template }: Campaign) => template.type !== 0,
        },
        {
            label: 'Add Content',
            Component: AddContent,
            validation: () => ContentValidation(campaign),
        },
        {
            label: 'Review Campaign',
            Component: ReviewCampaign,
            validation: () => false,
        },
    ];
    const { Component, validation } = steps[campaignTab];
    const farValidation = steps[farStep].validation;

    const setStepStatus = (step: number, isValid: boolean) => {
        const newCompletedSteps = { ...completedSteps };

        if (isValid) newCompletedSteps[campaignTab] = true;
        newCompletedSteps[step] = false;
        setCompletedSteps(newCompletedSteps);
    };

    const handleBack = (e): void => {
        e.currentTarget.blur();
        const isValid = validation(campaign);
        const previousStep = campaignTab - 1;
        setStepStatus(previousStep, isValid);
        dispatch(selectCampaignTab({ step: previousStep }));
    };

    const handleNext = (e): void => {
        e.currentTarget.blur();
        const isValid = validation(campaign);
        if (!isValid && campaignTab !== steps.length - 1) {
            setShowFieldRequired(true);
            setValidationAnimation(true);
        } else {
            const nextStep = campaignTab + 1;
            setStepStatus(nextStep, true);
            setShowFieldRequired(false);
            dispatch(selectCampaignTab({ step: nextStep }));
            setFarStep((prevStep) => {
                if (campaignTab > prevStep) return isValid ? nextStep : campaignTab;
                return prevStep;
            });
        }
    };

    const handleStep = (step: number): void => {
        const isValid = validation(campaign);
        if (step === CAMPAIGN_PAGE.SELECT_TEMPLATE && farStep === CAMPAIGN_PAGE.REVIEW) {
            setStepStatus(CAMPAIGN_PAGE.ADD_CONTENT, false);
            setFarStep(CAMPAIGN_PAGE.ADD_CONTENT);
            dispatch(selectCampaignTab({ step }));
        } else if (step === campaignTab + 1 && isValid) {
            setStepStatus(step, true);
            setShowFieldRequired(false);
            dispatch(selectCampaignTab({ step }));
            setFarStep((prevStep) => {
                if (step > prevStep) return step;
                return prevStep;
            });
        } else if (step <= farStep + 1 && isValid) {
            setStepStatus(step, true);
            setShowFieldRequired(false);
            dispatch(selectCampaignTab({ step }));
        } else if (step <= campaignTab) {
            setStepStatus(step, isValid);
            setShowFieldRequired(false);
            dispatch(selectCampaignTab({ step }));
        } else {
            setShowFieldRequired(true);
        }
    };

    const disableStepBtns = (stepIndex: number): boolean => {
        if (campaign.isSent) return true;
        if (stepIndex <= farStep + 1 && farValidation(campaign) && validation(campaign)) return false;
        if (stepIndex <= farStep && !farValidation(campaign) && validation(campaign)) return false;
        if (stepIndex === campaignTab + 1 && validation(campaign)) return false;
        if (stepIndex <= campaignTab) return false;
        return true;
    };

    const handleDiscard = () => {
        // push first then reset so that this stepper doesn't error when it's reset
        history.push('/campaign');
        dispatch(resetCampaign({ name: '' }));
    };

    const endAnimation = () => setValidationAnimation(false);

    const handleSend = async (): Promise<void> => {
        const html = getEmailHTMLFromCampaign(dispatch, campaignStore);
        const request = getEmailRequest(dispatch, campaign, true);
        if (html && request) {
            try {
                const responses = await sendCampaign({
                    variables: {
                        campaign: request,
                        html,
                    },
                });

                const response = responses.data.Campaign;
                dispatch(updateCampaignId({ id: response.id }));
                dispatch(showSnackbar(true, `Campaign ${response.title} was sent!`, 'success'));
                dispatch(showDialog(false, '', undefined, undefined));
                history.push('/campaign');
            } catch (err) {
                showSnackbarError(dispatch, err);
            }
        }
    };

    const sendTestEmail = async (formData) => {
        const html = getEmailHTMLFromCampaign(dispatch, campaignStore);
        const request = getEmailRequest(dispatch, campaign, false);

        try {
            const response = await sendTestEmailMutation({
                variables: {
                    campaign: request,
                    html,
                    testEmails: [formData.testEmail],
                },
            });
            dispatch(updateCampaignId({ id: response.data.Campaign.id }));
            dispatch(showSnackbar(true, `Test campaign email was sent!`, 'success'));
            dispatch(showDialog(false, '', undefined, undefined));
        } catch (err) {
            showSnackbarError(dispatch, err);
        }
    };

    const sendConfirmationDialog = () => {
        const segmentsWithoutContacts: string[] = [];
        const segmentsWithContacts: string[] = [];
        segmentNames.forEach((name, index) => {
            if (findSegmentsContactsCount.data.SegmentContactCount[index] === 0) {
                segmentsWithoutContacts.push(name);
            } else {
                segmentsWithContacts.push(name);
            }
        });

        dispatch(
            showDialog(
                true,
                '',
                <SendEmailConfirmationForm
                    segmentsWithoutContacts={segmentsWithoutContacts}
                    segmentsWithContacts={segmentsWithContacts}
                    formId={sendFormId}
                    onSubmit={handleSend}
                />,
                <>
                    {segmentsWithContacts.length > 0 ? (
                        <>
                            <Button
                                id="cancelButton"
                                color="primary"
                                onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                            >
                                CANCEL
                            </Button>
                            <Button type="submit" variant="contained" color="primary" form={sendFormId}>
                                SEND
                            </Button>
                        </>
                    ) : (
                        <Button
                            id="cancelButton"
                            variant="contained"
                            color="primary"
                            onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                        >
                            OK
                        </Button>
                    )}
                </>
            )
        );
    };

    return (
        <div className="CampaignStepper">
            <Grid container spacing={0}>
                <Grid item xs={2}>
                    <Button color="primary" onClick={handleDiscard} className="LibraryBtn">
                        <ArrowBackIosIcon fontSize="small" />
                        Campaign Library
                    </Button>
                    <div className="root">
                        <Stepper activeStep={campaignTab} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={`${step.label} Step`}>
                                    <StepButton
                                        onClick={() => handleStep(index)}
                                        id={`Step${index}`}
                                        data-testid={`Step${index}`}
                                        disabled={disableStepBtns(index)}
                                        classes={{
                                            root:
                                                campaignTab === index
                                                    ? classes.stepButton && classes.activeStep
                                                    : classes.stepButton,
                                        }}
                                        completed={completedSteps[index]}
                                    >
                                        {step.label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                </Grid>
                <Grid item xs={10}>
                    <div
                        className={
                            campaignTab === CAMPAIGN_PAGE.ADD_CONTENT || campaignTab === CAMPAIGN_PAGE.REVIEW
                                ? 'CampaignWizard__Review'
                                : 'CampaignWizard'
                        }
                    >
                        <Component
                            showFieldRequired={showFieldRequired}
                            setUniqueCampaignName={setUniqueCampaignName}
                            validationAnimation={validationAnimation}
                            endAnimation={endAnimation}
                            handleStep={handleStep}
                        />

                        <div
                            className={`Clearfix BtnSection${
                                campaignTab === CAMPAIGN_PAGE.ADD_CONTENT || campaignTab === CAMPAIGN_PAGE.REVIEW
                                    ? '__Review'
                                    : ''
                            }`}
                            data-testid="actionButtonSection"
                        >
                            <div className="BtnSection__Left">
                                {!campaign.isSent && (
                                    <Button
                                        id="BackBtn"
                                        color="primary"
                                        hidden={campaignTab === CAMPAIGN_PAGE.ADD_DETAILS}
                                        onClick={handleBack}
                                    >
                                        <ArrowBackIosIcon fontSize="small" />
                                        Back
                                    </Button>
                                )}
                            </div>
                            <div className="BtnSection__Right">
                                {!campaign.isSent && (
                                    <>
                                        {campaignTab === CAMPAIGN_PAGE.REVIEW ? (
                                            <>
                                                <Button
                                                    id="SendTestEmailBtn"
                                                    color="primary"
                                                    variant="outlined"
                                                    onClick={() =>
                                                        dispatch(
                                                            showDialog(
                                                                true,
                                                                'Send Test Email',
                                                                <SendTestEmailForm
                                                                    formId={sendTestFormId}
                                                                    onSubmit={sendTestEmail}
                                                                />,
                                                                <>
                                                                    <Button
                                                                        id="cancelButton"
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            dispatch(
                                                                                showDialog(
                                                                                    false,
                                                                                    '',
                                                                                    undefined,
                                                                                    undefined
                                                                                )
                                                                            )
                                                                        }
                                                                    >
                                                                        CANCEL
                                                                    </Button>
                                                                    <Button
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        form={sendTestFormId}
                                                                    >
                                                                        SEND TEST
                                                                    </Button>
                                                                </>
                                                            )
                                                        )
                                                    }
                                                >
                                                    SEND TEST
                                                </Button>

                                                <Button
                                                    id="SendCampaignBtn"
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={sendConfirmationDialog}
                                                >
                                                    SEND
                                                </Button>
                                            </>
                                        ) : (
                                            <Button
                                                id="ContinueBtn"
                                                data-testid="ContinueBtn"
                                                className={
                                                    campaign.reviewMode && campaignTab === CAMPAIGN_PAGE.ADD_CONTENT
                                                        ? 'ReviewChangesBtn'
                                                        : undefined
                                                }
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >
                                                CONTINUE
                                            </Button>
                                        )}
                                    </>
                                )}
                                {campaign.isSent && (
                                    <div className="SentDate">
                                        Sent:{' '}
                                        {campaign?.dateModified &&
                                            getLocalDateString(campaign?.dateModified?.toString())}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
export default CampaignStepper;
