import React, { ReactElement } from 'react';

import { useLocation } from 'react-router-dom';

import Logo from 'assets/images/m2-logo-white.svg';

import './ExternalFooter.scss';

const Footer = (): ReactElement => {
    const location = useLocation();

    const shortpages = ['unsubscribe', 'resubscribe', 'unauthorized', 'subscribe'];
    const absolute = shortpages.some((shortpage) => location.pathname.includes(shortpage));
    return (
        <footer>
            <div className={absolute ? 'FooterAbsolute' : ''}>
                <div className="FooterStyle">
                    <p>Powered by</p>
                    <a href="https://www.miletwo.us/" target="_blank" rel="noreferrer">
                        <img src={Logo} alt="MileTwo Logo" />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
