import React, { ReactElement } from 'react';

import CampaignStepper from 'pageComponents/campaign/campaignStepper/CampaignStepper';

import './CreateCampaign.scss';

export default function CreateCampaign(): ReactElement {
    return (
        <div className="CampaignCreator">
            <CampaignStepper />
        </div>
    );
}
