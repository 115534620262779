import React, { FunctionComponent } from 'react';

import BaseUrl from 'components/utils/BaseUrl';

const containingDiv = {
    background: 'rgb(44, 57, 91)',
    backgroundColor: 'rgb(44, 57, 91)',
    margin: '0px auto',
    maxWidth: '800px',
} as React.CSSProperties;

const outerTable = {
    border: '0',
    align: 'center',
    background: 'rgb(44, 57, 91)',
    backgroundColor: 'rgb(44, 57, 91)',
    width: '100%',
} as React.CSSProperties;

const center = {
    lineHeight: '100%',
    align: 'center',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '430px',
} as React.CSSProperties;

const dataContainer = {
    direction: 'ltr',
    fontSize: '0px',
    padding: '0',
    textAlign: 'center',
} as React.CSSProperties;

const innerContainingDiv = {
    fontSize: '0px',
    textAlign: 'left',
    direction: 'ltr',
    display: 'inline-block',
    verticalAlign: 'top',
    width: '100%',
} as React.CSSProperties;

const centeredTable = {
    float: 'none',
    display: 'inline-table',
    align: 'center',
    border: '0',
} as React.CSSProperties;

const dataTextDiv = {
    fontSize: '0px',
    padding: '30px 0 10px 0',
    wordBreak: 'break-word',
    align: 'center',
} as React.CSSProperties;

const textDiv = {
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    fontSize: '18px',
    lineHeight: '1',
    textAlign: 'center',
    color: '#ffffff',
} as React.CSSProperties;

const outerDataImages = {
    fontSize: '0px',
    padding: '10px 25px',
    wordBreak: 'break-word',
    align: 'center',
} as React.CSSProperties;

const table2DataHoldingTable = {
    float: 'none',
    display: 'inline-table',
    border: '0',
    align: 'center',
} as React.CSSProperties;

const tableDataHoldingTable = {
    float: 'none',
    display: 'inline-table',
    align: 'center',
    border: '0',
} as React.CSSProperties;

const dataHoldingTable = {
    padding: '0 12px 14px 12px',
    verticalAlign: 'middle',
} as React.CSSProperties;

const tableImageContainer = {
    background: 'rgb(44, 57, 91)',
    borderRadius: '3px',
    width: '30px',
    border: '0',
} as React.CSSProperties;

const imageContainer = {
    fontSize: '0',
    height: '30px',
    verticalAlign: 'middle',
    width: '30px',
} as React.CSSProperties;

const image = {
    borderRadius: '3px',
    display: 'block',
} as React.CSSProperties;

const SocialFooter: FunctionComponent = () => {
    const baseUrl = BaseUrl();
    return (
        <div className="SocialFooter">
            <div style={containingDiv}>
                <table cellPadding="0" cellSpacing="0" role="presentation" style={outerTable}>
                    <tbody style={center}>
                        <tr>
                            <td style={dataContainer}>
                                <div className="mj-column-per-100 mj-outlook-group-fix" style={innerContainingDiv}>
                                    <table
                                        cellPadding="0"
                                        cellSpacing="0"
                                        role="presentation"
                                        style={centeredTable}
                                        width="100%"
                                    >
                                        <tbody>
                                            <tr>
                                                <td style={dataTextDiv}>
                                                    <div style={textDiv}>STAY CONNECTED</div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={outerDataImages}>
                                                    <table
                                                        cellPadding="0"
                                                        cellSpacing="0"
                                                        role="presentation"
                                                        style={tableDataHoldingTable}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td style={dataHoldingTable}>
                                                                    <table
                                                                        cellPadding="0"
                                                                        cellSpacing="0"
                                                                        role="presentation"
                                                                        style={tableImageContainer}
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={imageContainer}>
                                                                                    {' '}
                                                                                    <a
                                                                                        href="https://www.linkedin.com/company/10864292"
                                                                                        target="_"
                                                                                    >
                                                                                        <img
                                                                                            height="24"
                                                                                            alt="LinkedIn for SBIR/STTR"
                                                                                            src={`${baseUrl}/images/templates/linkedin-gray.png`}
                                                                                            style={image}
                                                                                            width="24"
                                                                                        />
                                                                                    </a>{' '}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table
                                                        cellPadding="0"
                                                        cellSpacing="0"
                                                        role="presentation"
                                                        style={table2DataHoldingTable}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td style={dataHoldingTable}>
                                                                    <table
                                                                        cellPadding="0"
                                                                        cellSpacing="0"
                                                                        role="presentation"
                                                                        style={tableImageContainer}
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={imageContainer}>
                                                                                    {' '}
                                                                                    <a
                                                                                        href="https://twitter.com/AF_SBIR_STTR"
                                                                                        target="_"
                                                                                    >
                                                                                        <img
                                                                                            alt="Twitter for SBIR/STTR"
                                                                                            height="24"
                                                                                            src={`${baseUrl}/images/templates/twitter-gray.png`}
                                                                                            style={image}
                                                                                            width="24"
                                                                                        />
                                                                                    </a>{' '}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table
                                                        cellPadding="0"
                                                        cellSpacing="0"
                                                        role="presentation"
                                                        style={tableDataHoldingTable}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td style={dataHoldingTable}>
                                                                    <table
                                                                        cellPadding="0"
                                                                        cellSpacing="0"
                                                                        role="presentation"
                                                                        style={tableImageContainer}
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={imageContainer}>
                                                                                    {' '}
                                                                                    <a
                                                                                        href="https://www.facebook.com/afsbirsttr/"
                                                                                        target="_"
                                                                                    >
                                                                                        <img
                                                                                            height="24"
                                                                                            alt="Facebook for SBIR/STTR"
                                                                                            src={`${baseUrl}/images/templates/facebook-gray.png`}
                                                                                            style={image}
                                                                                            width="24"
                                                                                        />
                                                                                    </a>{' '}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table
                                                        cellPadding="0"
                                                        cellSpacing="0"
                                                        role="presentation"
                                                        style={tableDataHoldingTable}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td style={dataHoldingTable}>
                                                                    <table
                                                                        cellPadding="0"
                                                                        cellSpacing="0"
                                                                        role="presentation"
                                                                        style={tableImageContainer}
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={imageContainer}>
                                                                                    {' '}
                                                                                    <a
                                                                                        href="https://www.instagram.com/afsbirsttr/"
                                                                                        target="_"
                                                                                    >
                                                                                        <img
                                                                                            alt="Instagram for SBIR/STTR"
                                                                                            height="24"
                                                                                            src={`${baseUrl}/images/templates/instagram-gray.png`}
                                                                                            style={image}
                                                                                            width="24"
                                                                                        />
                                                                                    </a>{' '}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table
                                                        cellPadding="0"
                                                        cellSpacing="0"
                                                        role="presentation"
                                                        style={table2DataHoldingTable}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td style={dataHoldingTable}>
                                                                    <table
                                                                        cellPadding="0"
                                                                        cellSpacing="0"
                                                                        role="presentation"
                                                                        style={tableImageContainer}
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={imageContainer}>
                                                                                    {' '}
                                                                                    <a
                                                                                        href="https://www.youtube.com/channel/UCb3EQFQNK0pw5EFv0rAkTBg"
                                                                                        target="_"
                                                                                    >
                                                                                        <img
                                                                                            alt="YouTube Channel for SBIR/STTR"
                                                                                            height="24"
                                                                                            src={`${baseUrl}/images/templates/youtube-gray.png`}
                                                                                            style={image}
                                                                                            width="24"
                                                                                        />
                                                                                    </a>{' '}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table
                                                        cellPadding="0"
                                                        cellSpacing="0"
                                                        role="presentation"
                                                        style={table2DataHoldingTable}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td style={dataHoldingTable}>
                                                                    <table
                                                                        cellPadding="0"
                                                                        cellSpacing="0"
                                                                        role="presentation"
                                                                        style={tableImageContainer}
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={imageContainer}>
                                                                                    {' '}
                                                                                    <a
                                                                                        href="https://www.dvidshub.net/unit/AFSBIRSTTR"
                                                                                        target="_"
                                                                                    >
                                                                                        <img
                                                                                            alt="DVIDS for SBIR/STTR"
                                                                                            height="24"
                                                                                            src={`${baseUrl}/images/templates/dvids_icon.png`}
                                                                                            style={image}
                                                                                            width="24"
                                                                                        />
                                                                                    </a>{' '}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table
                                                        cellPadding="0"
                                                        cellSpacing="0"
                                                        role="presentation"
                                                        style={table2DataHoldingTable}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td style={dataHoldingTable}>
                                                                    <table
                                                                        cellPadding="0"
                                                                        cellSpacing="0"
                                                                        role="presentation"
                                                                        style={tableImageContainer}
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={imageContainer}>
                                                                                    {' '}
                                                                                    <a
                                                                                        href="https://www.afsbirsttr.af.mil/"
                                                                                        target="_"
                                                                                    >
                                                                                        <img
                                                                                            height="24"
                                                                                            alt="Link to SBIR/STTR"
                                                                                            src={`${baseUrl}/images/templates/web-link-gray.png`}
                                                                                            style={image}
                                                                                            width="24"
                                                                                        />
                                                                                    </a>{' '}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SocialFooter;
