import React from 'react';

import { Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core';

import './SegmentNameFinalStep.scss';

const useStyles = makeStyles({
    nameEntry: {
        fontSize: '14px',
        width: '100%',
        height: '36px',
    },
    button: {
        marginLeft: '5px',
        marginTop: '25px',
    },
});

const SegmentNameFinalStep = ({
    segmentName,
    updateName,
    segmentDescription,
    updateDescription,
}: {
    segmentName: string;
    updateName: (value: string) => void;
    segmentDescription: string;
    updateDescription: (value: string) => void;
}): JSX.Element => {
    const classes = useStyles();

    return (
        <div className="SegmentNameFinalStep">
            <div className="step">
                <div className="step-text">3</div>
            </div>
            <h6>Name Segment</h6>
            <p>Add a unique name and description to this segment.</p>
            <Grid container className="box">
                <Grid item xs={6} spacing={4} style={{ height: '100px' }}>
                    <Typography>* Name</Typography>
                    <TextField
                        value={segmentName}
                        size="medium"
                        margin="dense"
                        inputProps={{ style: { fontSize: '14px', padding: '8px' } }}
                        name="emailAddress"
                        variant="outlined"
                        error={segmentName.length > 50}
                        onChange={(e): void => {
                            if (e.target.value.length < 51) updateName(e.target.value);
                        }}
                        className={classes.nameEntry}
                        helperText={`${segmentName.length}/50 Characters Max`}
                    />
                </Grid>
                <Grid item xs={9} spacing={4} style={{ height: '150px' }}>
                    <Typography>Description</Typography>
                    <TextField
                        value={segmentDescription}
                        multiline
                        rows={4}
                        size="medium"
                        margin="dense"
                        inputProps={{ style: { fontSize: '14px' } }}
                        name="emailAddress"
                        variant="outlined"
                        error={segmentDescription.length > 160}
                        onChange={(e): void => {
                            if (e.target.value.length < 161) updateDescription(e.target.value);
                        }}
                        className={classes.nameEntry}
                        helperText={`${segmentDescription.length}/160 Characters Max`}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default SegmentNameFinalStep;
