import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { GET_CAMPAIGN_BY_TITLE, GET_ENVIRONMENT_VARIABLES } from 'api/queries';
import showSnackbarError from 'components/utils/ShowSnackbarError';
import Input from 'pageComponents/campaign/inputForms/formComponents/Input';
import { updateCampaignDetails } from 'store/actions/Campaign';
import { Store } from 'store/reducers/index';

import './CampaignDetails.scss';

const CampaignDetails = ({
    showFieldRequired,
    setUniqueCampaignName,
    endAnimation,
    validationAnimation,
}: {
    showFieldRequired: boolean;
    setUniqueCampaignName: (data: boolean) => void;
    validationAnimation: boolean;
    endAnimation: () => void;
}): ReactElement => {
    const apiEnvironmentVariables = useQuery(GET_ENVIRONMENT_VARIABLES);
    const dispatch = useDispatch();
    const { campaignDetails } = useSelector((storeState: Store) => storeState.Campaign);
    const [titleExists, setTitleExists] = useState<boolean>(false);
    const [senderEmail, setSenderEmail] = useState<string>('');

    const titleCheckQuery = useQuery(GET_CAMPAIGN_BY_TITLE, {
        variables: { title: '' },
    });

    const titleValidation = debounce(async (value) => {
        try {
            const titleQuery = await titleCheckQuery.refetch({ title: value });
            setUniqueCampaignName(!titleQuery.data.campaignExists); // if exists, not unique
            setTitleExists(titleQuery.data.campaignExists);
        } catch (err) {
            showSnackbarError(dispatch, err);
        }
    }, 500);

    const handleCampaignDetails = (e: { target: { name: string; value: string } }) => {
        const { name, value } = e.target;
        if (name === 'campaignName') titleValidation(value);
        dispatch(updateCampaignDetails({ name, value }));
    };

    const handleSender = useCallback(
        (e) => {
            const { value } = e;
            dispatch(updateCampaignDetails({ name: 'senderEmail', value }));
            dispatch(
                updateCampaignDetails({
                    name: 'senderName',
                    value: apiEnvironmentVariables.data?.variables.DEFAULT_EMAIL_NAME,
                })
            );
        },
        [apiEnvironmentVariables.data?.variables.DEFAULT_EMAIL_NAME, dispatch]
    );

    const options = [
        {
            value: senderEmail,
            label: senderEmail,
        },
    ];

    useEffect(() => {
        if (apiEnvironmentVariables.data?.variables.DEFAULT_EMAIL) {
            const defaultEmail = apiEnvironmentVariables.data?.variables.DEFAULT_EMAIL;
            setSenderEmail(defaultEmail);
            handleSender({ value: defaultEmail });
        }
    }, [apiEnvironmentVariables.data?.variables.DEFAULT_EMAIL, handleSender]);

    return (
        <div className="CampaignDetails">
            <h3 className="title">Add details to identify the campaign</h3>

            <Input
                className={showFieldRequired && campaignDetails.campaignName === '' ? 'Inputs Validation' : 'Inputs'}
                value={campaignDetails.campaignName}
                label="Campaign Name (required)"
                name="campaignName"
                onChange={handleCampaignDetails}
                maxChar={100}
            />

            {showFieldRequired && campaignDetails.campaignName === '' && (
                <p
                    className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                    onAnimationEnd={endAnimation}
                >
                    *Fill in Missing Field
                </p>
            )}
            {titleExists && (
                <p className="MissingField">*Campaign name already used. Please use a unique campaign name</p>
            )}

            <Input
                className={
                    showFieldRequired && campaignDetails.subject === '' ? 'Inputs Subject Validation' : 'Inputs Subject'
                }
                label="Subject Line (required)"
                name="subject"
                value={campaignDetails.subject}
                onChange={handleCampaignDetails}
                maxChar={100}
            />
            {showFieldRequired && campaignDetails.subject === '' && (
                <p
                    className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                    onAnimationEnd={endAnimation}
                >
                    *Fill in Missing Field
                </p>
            )}
            <p className="InputLabels">Sender Email Address (required)</p>

            <Select
                value={options[0]}
                title="senderEmail"
                className={
                    showFieldRequired && campaignDetails.senderEmail === '' ? 'SenderSelect Validation' : 'SenderSelect'
                }
                options={options}
                name="senderEmail"
                onChange={handleSender}
            />
            {showFieldRequired && campaignDetails.senderEmail === '' && (
                <p
                    className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                    onAnimationEnd={endAnimation}
                >
                    *Fill in Missing Field
                </p>
            )}

            <Input
                className={
                    showFieldRequired && campaignDetails.senderName === ''
                        ? 'Inputs SenderName Validation'
                        : 'Inputs SenderName'
                }
                label="Sender Name (required)"
                name="senderName"
                value={campaignDetails.senderName}
                onChange={handleCampaignDetails}
                maxChar={100}
            />
            {showFieldRequired && campaignDetails.senderName === '' && (
                <p
                    className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                    onAnimationEnd={endAnimation}
                >
                    *Fill in Missing Field
                </p>
            )}

            <p className="InputLabels Message">The campaign will be sent from:</p>
            <p className="SenderInfo">{`${campaignDetails.senderName} <${campaignDetails.senderEmail}>`}</p>
        </div>
    );
};
export default CampaignDetails;
