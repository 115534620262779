import React, { ReactElement, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, DialogContentText, Grid, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { CREATE_CONTACT_PUBLIC } from 'api/mutations';
import checkBoxObjToString from 'components/utils/CheckBoxObjToString';
import { defaultFormValues } from 'components/utils/GlobalConstants';
import showSnackbarError from 'components/utils/ShowSnackbarError';
import SubscriptionForm from 'pageComponents/contact/subscriptionForm/SubscriptionForm';
import { ContactStatus } from 'typings/_graphql';
import { FormValues } from 'typings/Subscription';

import './SignUp.scss';

const formId = 'signUp';
interface ParamType {
    audienceId: string;
}

const useStyles = makeStyles({
    form: {
        border: '1px solid rgb(7, 9, 12, .16)',
        padding: '1rem 2rem',
    },
    popoverOnTop: {
        zIndex: 10,
    },
    formControl: {
        minWidth: '10rem',
    },
    formControlLabel: {
        marginLeft: '0.9rem',
    },
    heading: {
        fontSize: '1.4rem',
        fontWeight: 400,
        paddingBottom: '1rem',
    },
    subHeading: {
        fontSize: '.9rem',
        paddingBottom: '1rem',
    },
    firstSectionName: {
        fontSize: '1.2rem',
        paddingBottom: '.3rem',
    },
    companySectionName: {
        fontSize: '1.2rem',
        paddingTop: '1.8rem',
    },
    sectionName: {
        fontSize: '1.2rem',
        marginTop: '1.25rem',
        marginBottom: '0.75rem',
    },
    inputLabels: {
        marginTop: '0.8rem',
        marginBottom: '0.5rem',
        color: '#000',
    },

    data: {
        fontSize: '1rem',
        fontFamily: 'Roboto',
    },
    vdata: {
        fontSize: '1rem',
        fontFamily: 'Roboto',
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    EmailValidation: {
        color: '#f00',
        margin: '0.1rem 0rem',
        fontSize: '0.875rem',
        fontFamily: 'Roboto',
    },
    select: {
        height: '2.5rem',
        marginTop: '0.5rem',
    },
    menuHeight: {
        height: '20vh',
        minHeight: '10rem',
    },
    UpdateButton: {
        marginLeft: '1rem',
    },
    agreeText: {
        marginTop: '1rem',
        paddingBottom: '1rem',
    },
    buttonDiv: {
        border: '1px solid rgb(7, 9, 12, .16)',
        padding: '1rem',
    },
});
const SignUp = (): ReactElement => {
    const { audienceId } = useParams<ParamType>();
    const history = useHistory();
    const location = useLocation();

    const dispatch = useDispatch();

    const [successfulSubscription, setSuccessfulSubscription] = useState<boolean>(false);
    const [createContactNotify] = useMutation(CREATE_CONTACT_PUBLIC);

    const classes = useStyles();

    const onSave = async (formData: FormValues) => {
        const data = {
            emailAddress: formData.emailAddress,
            companyName: formData.companyName,
            city: formData.city,
            state: formData.state,
            firstName: formData.firstName,
            lastName: formData.lastName,
            jobTitle: formData.jobTitle,
            dunsNumber: formData.dunsNumber,
            phoneNumber: formData.phoneNumber,
            status: ContactStatus.SUBSCRIBED,
            audienceId: parseInt(audienceId, 10),
            techFocusAreas: checkBoxObjToString(formData.techFocusAreas),
            companyCertifications: checkBoxObjToString(formData.companyCertifications),
        };

        Object.keys(data).forEach((key) => {
            if (data[key] === undefined) data[key] = '';
        });

        try {
            const response = await createContactNotify({
                variables: {
                    contact: data,
                },
            });
            const { id } = response.data?.Contact;
            if (id) {
                setSuccessfulSubscription(true);

                history.push(`/subscribe/${id}`, {
                    emailAddress: data.emailAddress,
                    from: location.pathname,
                });
            }
        } catch (err) {
            showSnackbarError(dispatch, err);
        }
    };

    return (
        <>
            {!successfulSubscription && (
                <div className="SignUp">
                    <SubscriptionForm
                        formId={formId}
                        onSave={onSave}
                        defaultFormValues={defaultFormValues}
                        classes={classes}
                        isPublic
                        isSubscribe
                    />
                    <div className={classes.buttonDiv}>
                        <DialogContentText className={classes.agreeText}>
                            By subscribing, you agree to receive emails about SBIR/STTR news and opportunities as
                            information becomes available.
                        </DialogContentText>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <a className="OkBtn" href="https://www.afsbirsttr.af.mil/">
                                    <Button color="primary" variant="outlined">
                                        Cancel
                                    </Button>
                                </a>
                            </Grid>
                            <Grid item>
                                <Button
                                    id="SubscribeBtn"
                                    type="submit"
                                    className={classes.UpdateButton}
                                    variant="contained"
                                    color="primary"
                                    form={formId}
                                >
                                    Subscribe
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )}
        </>
    );
};
export default SignUp;
