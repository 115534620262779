import { combineReducers } from 'redux';

import { Campaign as CampaignState } from 'typings/Campaign';
import Campaign from './Campaign';
import Dialog, { State as DialogState } from './Dialog';
import Member, { State as MemberState } from './Member';
import Navigation from './Navigation';
import ActiveSegment from './Segment';
import Snackbar, { State as SnackbarState } from './Snackbar';
import Stepper from './Stepper';
import User from './User';

export interface Store {
    Dialog: DialogState;
    Snackbar: SnackbarState;
    Campaign: CampaignState;
    ActiveSegment;
    Member: MemberState;
    User;
    Navigation;
    Stepper;
}

export default combineReducers({ Dialog, Snackbar, Member, ActiveSegment, User, Navigation, Campaign, Stepper });
