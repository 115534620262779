import React, { ReactElement } from 'react';

import { TimeProps } from 'typings/InputProps';

const TimeInput = ({ onChange, className, id, label, value }: TimeProps): ReactElement => {
    return (
        <div className="InputGroup inputDiv">
            <div className="InputGroup__prepend">
                <span className="InputLabel" id="InputGroup__sizing-default">
                    {label}
                </span>
            </div>
            <input
                name="time"
                onChange={onChange}
                type="time"
                className={`form-control ${className}`}
                id={`${id}`}
                aria-describedby="basic-addon3"
                value={value}
            />
        </div>
    );
};
export default TimeInput;
