import React from 'react';

import { isEmpty, unescape } from 'lodash';
import { Dispatch } from 'redux';

import StyleTag from 'components/styleTags/NewsletterStyle';
import { TEMPLATE_TYPES } from 'components/utils/GlobalConstants';
import showSnackbarError from 'components/utils/ShowSnackbarError';
import CampaignTemplate from 'pageComponents/emailTemplates/CampaignTemplate';
import { renderToString } from 'react-dom/server';
import { Campaign } from 'typings/Campaign';

export function getEmailHTMLFromCampaign(dispatch: Dispatch, campaignStore: Campaign['template']): string | null {
    const { NEWSLETTER, PRESS, EVENT } = TEMPLATE_TYPES;
    const { type, newsletter, press, event } = campaignStore;
    let campaignTemplateData = {};

    if (type === NEWSLETTER) {
        campaignTemplateData = newsletter;
    } else if (type === EVENT) {
        campaignTemplateData = event;
    } else if (type === PRESS) {
        campaignTemplateData = press;
    } else {
        showSnackbarError(dispatch, 'An invalid email template was selected, please select another and try again.');
        return null;
    }

    if (Object.entries(campaignTemplateData).length > 0) {
        const html = renderToString(
            <html lang="en">
                <head>
                    <meta charSet="utf-8" />
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                    <style>{StyleTag}</style>
                </head>
                <body>
                    <CampaignTemplate isHtml event={event} newsletter={newsletter} press={press} type={type} />
                </body>
            </html>
        );
        return unescape(html);
    }

    showSnackbarError(dispatch, 'No data was found for the email template selected. Please add data and try again.');
    return null;
}

export function getEmailRequest(
    dispatch: Dispatch,
    campaign: Campaign,
    isSendRealCampaign: boolean
): Record<string, unknown> | null {
    const { NEWSLETTER, PRESS, EVENT } = TEMPLATE_TYPES;
    let templateDataArray: Array<Record<string, unknown>> = [];
    let campaignTemplateData = {};

    if (campaign.template.type === NEWSLETTER) {
        campaignTemplateData = { ...campaign.template.newsletter };
    } else if (campaign.template.type === EVENT) {
        campaignTemplateData = { ...campaign.template.event };
    } else if (campaign.template.type === PRESS) {
        campaignTemplateData = { ...campaign.template.press };
    } else {
        showSnackbarError(dispatch, 'An invalid email template was selected, please select another and try again.');
        return null;
    }

    if (Object.entries(campaignTemplateData).length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [description, value] of Object.entries<Record<string, unknown>[]>(campaignTemplateData)) {
            if (description === 'articles') {
                const articlesArray = Object.values(value);
                const articles = articlesArray.slice(0, campaign.template.newsletter.articleCount);
                // eslint-disable-next-line no-loop-func
                articles.forEach((article, index) => {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const [description2, value2] of Object.entries(article)) {
                        templateDataArray = [
                            ...templateDataArray,
                            {
                                groupNumber: index + 1,
                                inputDescription: description2,
                                inputValue: value2,
                            },
                        ];
                    }
                    return templateDataArray;
                });
            } else {
                templateDataArray = [
                    ...templateDataArray,
                    {
                        inputDescription: description,
                        inputValue: description === 'articleCount' ? value.toString() : value,
                    },
                ];
            }
        }
    } else {
        showSnackbarError(
            dispatch,
            'No data was found for the email template selected. Please add data and try again.'
        );
        return null;
    }

    const request = {
        id: campaign.id,
        title: isEmpty(campaign.campaignDetails.campaignName) ? null : campaign.campaignDetails.campaignName,
        subject: isEmpty(campaign.campaignDetails.subject) ? null : campaign.campaignDetails.subject,
        senderEmail: `${campaign.campaignDetails.senderName} <${campaign.campaignDetails.senderEmail}>`,
        templateTypeId: campaign.template.type,
        campaignTemplateInputs: templateDataArray,
        segmentIds: campaign.selectAudience?.segmentIds,
        sendDate: isSendRealCampaign ? new Date() : null,
        isSent: isSendRealCampaign,
        audienceId: 1,
    };
    return request;
}
