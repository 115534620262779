/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FunctionComponent } from 'react';

const emailFooterStyle = {
    backgroundColor: 'rgb(24, 34, 41)',
    marginTop: '0',
    width: '100%',
    height: '4em',
} as React.CSSProperties;

const emailFooterStyleHtml = {
    backgroundColor: 'rgb(24, 34, 41)',
    marginTop: '0',
    width: '100%',
    height: '8em',
} as React.CSSProperties;

const unsubscribeStyle = {
    fontSize: '16px',
    color: 'rgb(115, 167, 213)',
    margin: '0 1px',
    borderBottom: '1px solid rgb(115, 167, 213)',
    textDecoration: 'none',
} as React.CSSProperties;

const contactStyle = {
    fontSize: '8px',
    color: 'rgb(115, 167, 213)',
    margin: '0 8px',
    textDecoration: 'none',
} as React.CSSProperties;

const spacer30 = {
    height: '30',
    fontSize: '30px',
    lineHeight: '30px',
} as React.CSSProperties;

const spacer20 = {
    height: '20',
    fontSize: '20px',
    lineHeight: '20px',
} as React.CSSProperties;

const span = {
    color: 'rgb(24, 34, 41)',
} as React.CSSProperties;

type EmailFooterProps = {
    isHtml: boolean;
};

const EmailFooter: FunctionComponent<EmailFooterProps> = ({ isHtml }) => {
    return (
        <table className={isHtml ? 'row' : undefined} style={isHtml ? emailFooterStyleHtml : emailFooterStyle}>
            <tbody>
                <tr>
                    <td align="center" style={{ textAlign: 'center' }}>
                        {isHtml && (
                            <table className={isHtml ? 'spacer' : undefined}>
                                <tbody>
                                    <tr>
                                        <td style={spacer30} />
                                    </tr>
                                </tbody>
                            </table>
                        )}
                        <a
                            className={isHtml ? 'text-center' : undefined}
                            style={unsubscribeStyle}
                            href="{{UNSUBSCRIBE_LINK}}"
                        >
                            Unsubscribe
                        </a>
                        <span style={span}>{` | `}</span>
                        <a
                            className={isHtml ? 'text-center' : undefined}
                            style={unsubscribeStyle}
                            href="{{PREFERENCES_LINK}}"
                        >
                            Update Mail Preferences
                        </a>
                        {isHtml && (
                            <table className={isHtml ? 'spacer' : undefined}>
                                <tbody>
                                    <tr>
                                        <td style={spacer20} />
                                    </tr>
                                </tbody>
                            </table>
                        )}
                        <p className={isHtml ? 'text-center' : undefined} style={contactStyle}>
                            This message was produced and distributed by the AFWERX SBIR/STTR office of the U.S. Air
                            Force. 711 E Monument Ave. Dayton, OH 45402
                        </p>
                        {isHtml && (
                            <table className={isHtml ? 'spacer' : undefined}>
                                <tbody>
                                    <tr>
                                        <td style={spacer30} />
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
export default EmailFooter;
