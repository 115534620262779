import React, { ReactElement, useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Button, capitalize, Chip, Grid, Tooltip } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/CancelRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircleRounded';
import HelpIcon from '@material-ui/icons/HelpRounded';
import WatchLaterIcon from '@material-ui/icons/WatchLaterRounded';
import { isEmpty, startCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
    Column,
    Row,
    useFilters,
    useFlexLayout,
    useGlobalFilter,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable,
} from 'react-table';
import { useSticky } from 'react-table-sticky';

import { CREATE_SEGMENT, DELETE_CONTACTS } from 'api/mutations/index';
import { GET_CONTACTS_FROM_FILTERS, GET_SEGMENT } from 'api/queries';
import DataTable from 'components/dataTable/DataTable';
import RowCheckbox from 'components/dataTable/IndeterminateCheckbox';
import {
    convertFilterKeyToFilterObject,
    convertJSONFilterQueryToOptions,
    FilterObject,
} from 'components/utils/FilterObject';
import { FormDataObj, getUniqueJSONFiltersFromForm } from 'components/utils/FormToFiltersUtils';
import {
    downloadRowData,
    getCaseInsensitiveSortOrderIndex,
    getColumnWidth,
    pageSizeOptions,
} from 'components/utils/ReactTableUtils';
import showSnackbarError from 'components/utils/ShowSnackbarError';
import AddContactMenuList from 'pageComponents/contact/addContactMenuList/AddContactMenuList';
import DeleteContactForm from 'pageComponents/contact/deleteForm/DeleteContactForm';
import Drawer from 'pageComponents/contact/editMember/MemberInfo';
import FilterSidebar from 'pageComponents/contact/filterSidebar/FilterSidebar';
import SearchContactsBar from 'pageComponents/contact/searchContacts/SearchContactsBar';
import { showDialog } from 'store/actions/Dialog';
import { showDrawer } from 'store/actions/Member';
import { showSnackbar } from 'store/actions/Snackbar';
import { Store } from 'store/reducers';
import { ContactStatus } from 'typings/_graphql';
import { ActiveSegment } from 'typings/Segment';

import './ContactManager.scss';

const getStatusIcon = (status: string): JSX.Element => {
    const lowerCaseStatus = status.toLowerCase();
    if (lowerCaseStatus === ContactStatus.SUBSCRIBED.toLowerCase()) {
        return <CheckCircleIcon fontSize="large" style={{ color: '#2e7d32' }} />;
    }
    if (lowerCaseStatus === ContactStatus.UNSUBSCRIBED.toLowerCase()) {
        return <CancelIcon fontSize="large" style={{ color: '#c62828' }} />;
    }
    if (lowerCaseStatus === ContactStatus.CLEANED.toLowerCase()) {
        return <HelpIcon fontSize="large" style={{ color: '#676e7a' }} />;
    }
    if (lowerCaseStatus === ContactStatus.PENDING.toLowerCase()) {
        return <WatchLaterIcon fontSize="large" style={{ color: '#006edd' }} />;
    }
    return <div />;
};

const createColumn = (columnName: string, contactData: Row[]): Column => {
    const columnAccessor = columnName;
    const columnNameArray = startCase(columnName).split(' ');
    const columnHeader =
        columnName.startsWith('naics') || columnName.startsWith('duns')
            ? `${columnNameArray[0].toUpperCase()} ${columnNameArray.slice(1).join(' ')}`
            : startCase(columnName);
    return {
        Header: columnHeader,
        accessor: columnAccessor,
        width: getColumnWidth(contactData, columnAccessor, columnHeader, true),
        sortType: 'caseInsensitive',
    };
};

const getContactManagerColumns = (dispatch, contactData: Row[]): Column[] => {
    const numStickyColumns = 2;
    if (contactData.length > 0) {
        const unfilteredColumns = Object.keys(contactData[0]).map((columnName: string, index: number) => {
            if (index < numStickyColumns) {
                return undefined;
            }

            if (index === numStickyColumns) {
                const idIndex = Object.keys(contactData[0]).indexOf('id');
                const stickyColumnNames = Object.keys(contactData[0]).slice(0, numStickyColumns + 1);
                if (idIndex !== -1) {
                    stickyColumnNames.splice(idIndex, 1);
                }
                return {
                    Header: ' ',
                    sticky: 'left',
                    columns: [
                        {
                            id: 'selection',
                            Header: ({ getToggleAllPageRowsSelectedProps }): JSX.Element => (
                                <RowCheckbox {...getToggleAllPageRowsSelectedProps()} />
                            ),
                            Cell: ({ row }): JSX.Element => <RowCheckbox {...row.getToggleRowSelectedProps()} />,
                            width: 48,
                        },
                        ...stickyColumnNames.map((stickyColumnName) => {
                            if (stickyColumnName === 'emailAddress') {
                                return {
                                    ...createColumn(stickyColumnName, contactData),
                                    Cell: ({ cell }) => (
                                        <Button
                                            color="primary"
                                            className="DrawerButtons"
                                            onClick={() => {
                                                dispatch(showDrawer(true, String(cell.value)));
                                            }}
                                        >
                                            {String(cell.value)}
                                        </Button>
                                    ),
                                };
                            }
                            return {
                                ...createColumn(stickyColumnName, contactData),
                                Cell: ({ cell }) => (
                                    <div style={{ textAlign: 'center' }}>
                                        <Tooltip title={capitalize(String(cell.value))}>
                                            {getStatusIcon(String(cell.value))}
                                        </Tooltip>
                                    </div>
                                ),
                            };
                        }),
                    ],
                };
            }
            return createColumn(columnName, contactData);
        });
        return unfilteredColumns.filter((column) => {
            return column !== undefined;
        }) as Column[];
    }
    return [];
};

const ContactManager = (): ReactElement => {
    useEffect(() => {
        document.body.style.overflowY = 'hidden';
        return () => {
            document.body.style.overflowY = '';
        };
    });
    const createSegmentFormId = 'createSegmentForm';
    const deleteContactsFormId = 'deleteContactsForm';

    const activeSegment = useSelector((storeState: Store): ActiveSegment => storeState.ActiveSegment);

    const [createSegmentMutation] = useMutation(CREATE_SEGMENT);
    const [deleteContacts] = useMutation(DELETE_CONTACTS);
    const segmentQuery = useQuery(GET_SEGMENT, { variables: { id: activeSegment.id } });
    const [currentFilters, setCurrentFilters] = useState(segmentQuery.data?.Segment.filterQuery ?? {});
    const contactQuery = useQuery(GET_CONTACTS_FROM_FILTERS, {
        variables: { audienceId: 1, filters: JSON.stringify({}) },
        fetchPolicy: 'no-cache',
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (segmentQuery.data) setCurrentFilters(segmentQuery.data.Segment.filterQuery);
    }, [segmentQuery.data]);

    const filterObjects: FilterObject[] = !isEmpty(currentFilters)
        ? Object.keys(currentFilters).map((category) => {
              const condition = Object.keys(currentFilters[category])[0];
              const value = currentFilters[category][condition];
              return new FilterObject(category, condition, value);
          })
        : [];

    const columns = React.useMemo((): Column[] => {
        return getContactManagerColumns(dispatch, contactQuery.data?.Contacts || []);
    }, [contactQuery.data, dispatch]);

    const data = React.useMemo(() => {
        return contactQuery.data?.Contacts ?? [];
    }, [contactQuery.data]);

    const contactTableInstance = useTable(
        {
            columns,
            data,
            sortTypes: React.useMemo(
                () => ({
                    caseInsensitive: (a: Row, b: Row, id: string): number => {
                        const valueA = a.values[id];
                        const valueB = b.values[id];
                        const sortValue = getCaseInsensitiveSortOrderIndex(valueA, valueB);
                        return sortValue;
                    },
                }),
                []
            ),
            initialState: {
                hiddenColumns: ['id'],
                pageSize: pageSizeOptions[0],
                sortBy: [
                    {
                        id: 'emailAddress',
                        desc: false,
                    },
                ],
            },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useFlexLayout,
        useSticky,
        useRowSelect
    );

    if (contactQuery.error || segmentQuery.error) {
        const errorMessage = (contactQuery.error as Error)?.message ?? (segmentQuery.error as Error).message;
        dispatch(showSnackbar(true, errorMessage, 'error'));
    }

    const contactIds = contactTableInstance.selectedFlatRows.map((row) => String(row.values.id));
    const multipleContacts = contactIds.length > 1;
    const onDelete = async () => {
        try {
            const contactResponse = await deleteContacts({
                variables: { data: { contactIds } },
                refetchQueries: () => ['findContactsFromFilters'],
            });
            if (contactResponse.data?.DeleteContactResponse?.errors?.length === 0) {
                dispatch(showSnackbar(true, `Contact${multipleContacts ? 's were' : ' was'} deleted.`, 'success'));
                dispatch(showDialog(false, '', undefined, undefined));
            }
        } catch (err) {
            showSnackbarError(dispatch, err);
        }
    };

    const applyFilters = async (formData: Record<string, unknown>): Promise<void> => {
        const jsonFilters = getUniqueJSONFiltersFromForm(dispatch, formData as FormDataObj);
        contactQuery.refetch({ filters: JSON.stringify(jsonFilters) });
        setCurrentFilters(jsonFilters);
    };

    const removeFilter = (filterObject: { category: string; condition: string; value: string }): void => {
        const { category, condition } = filterObject;
        if (Object.values(currentFilters[category]).length > 1) delete currentFilters[category][condition];
        else delete currentFilters[category];
        contactQuery.refetch({ filters: JSON.stringify({ ...currentFilters }) });
        setCurrentFilters({ ...currentFilters });
    };

    const numContactsSelected = Object.keys(contactTableInstance.state.selectedRowIds).length;
    const anyContactsSelected = numContactsSelected > 0;

    const exportContactsElement: JSX.Element = (
        <Grid item xs="auto">
            <Button
                color="primary"
                id="downloadTableBtn"
                onClick={(): void =>
                    downloadRowData(
                        contactTableInstance.selectedFlatRows.length > 0
                            ? contactTableInstance.selectedFlatRows
                            : contactTableInstance.rows
                    )
                }
            >
                Export List
            </Button>
        </Grid>
    );

    const onSubmit = async (formData: Record<string, unknown>): Promise<void> => {
        try {
            await createSegmentMutation({
                variables: {
                    data: {
                        name: formData.segmentName,
                        description: formData.segmentDescription,
                        filterQuery: currentFilters,
                        isArchived: false,
                        audienceId: 1,
                    },
                },
            });
            dispatch(showSnackbar(true, `Segment ${formData.segmentName} was created.`, 'success'));
            dispatch(showDialog(false, '', undefined, undefined));
        } catch (err) {
            showSnackbarError(dispatch, err);
        }
    };

    return (
        <div className="Contacts">
            <aside className="Contacts__FilterSidebar">
                <p className="Contacts__FilterSidebar--header">Filter by</p>
                {!segmentQuery.loading && <FilterSidebar onConfirm={applyFilters} />}
            </aside>
            <section className="Window Contacts__ContactTable">
                <div className="Contacts__ContactTable--topRow">
                    <Grid container justify="space-between" direction="row" spacing={2} wrap="nowrap">
                        <Grid item xs={4}>
                            <SearchContactsBar
                                globalFilter={contactTableInstance.state.globalFilter}
                                setGlobalFilter={contactTableInstance.setGlobalFilter}
                            />
                        </Grid>
                        <Grid container justify="flex-end" item xs={8} spacing={2}>
                            {anyContactsSelected ? (
                                <>
                                    {exportContactsElement}
                                    <Grid item xs="auto">
                                        <Button
                                            disabled
                                            color="primary"
                                            variant="outlined"
                                            data-testid="removeContactsBtn"
                                            onClick={() =>
                                                dispatch(
                                                    showDialog(
                                                        true,
                                                        `You are about to delete the following contact${
                                                            contactTableInstance.selectedFlatRows.length > 1 ? 's' : ''
                                                        }:`,
                                                        <DeleteContactForm
                                                            formId={deleteContactsFormId}
                                                            contactTableInstance={contactTableInstance}
                                                            onDelete={onDelete}
                                                        />,
                                                        <>
                                                            <Button
                                                                id="cancelButton"
                                                                color="primary"
                                                                onClick={() =>
                                                                    dispatch(
                                                                        showDialog(false, '', undefined, undefined)
                                                                    )
                                                                }
                                                            >
                                                                CANCEL
                                                            </Button>
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                form={deleteContactsFormId}
                                                            >
                                                                {`Delete ${
                                                                    contactTableInstance.selectedFlatRows.length > 1
                                                                        ? 'these contacts'
                                                                        : 'this contact'
                                                                }`}{' '}
                                                            </Button>
                                                        </>
                                                    )
                                                )
                                            }
                                        >
                                            DELETE
                                        </Button>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    {exportContactsElement}
                                    {/* Work temp. commented out per P3M-824, until new design
                                    <Grid item xs="auto">
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            id="createSegmentButton"
                                            onClick={() =>
                                                dispatch(
                                                    showDialog(
                                                        true,
                                                        'Create New Segment',
                                                        <CreateSegmentForm
                                                            formId={createSegmentFormId}
                                                            currentFilters={currentFilters}
                                                            onSubmit={onSubmit}
                                                        />,
                                                        <>
                                                            <Button
                                                                id="cancelButton"
                                                                color="primary"
                                                                onClick={() =>
                                                                    dispatch(
                                                                        showDialog(false, '', undefined, undefined)
                                                                    )
                                                                }
                                                            >
                                                                CANCEL
                                                            </Button>
                                                            <Button
                                                                type="submit"
                                                                variant="contained"
                                                                color="primary"
                                                                form={createSegmentFormId}
                                                            >
                                                                CREATE SEGMENT
                                                            </Button>
                                                        </>,
                                                        'sm',
                                                        true
                                                    )
                                                )
                                            }
                                            disabled={isEmpty(currentFilters)}
                                        >
                                            CREATE SEGMENT
                                        </Button>
                                    </Grid> */}
                                    <Grid item xs="auto">
                                        <AddContactMenuList />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </div>

                <div className="Contacts__ContactTable--filterChipRow">
                    {Object.getOwnPropertyNames(currentFilters).length !== 0 &&
                        convertJSONFilterQueryToOptions(currentFilters).map((filter) => {
                            return (
                                <>
                                    <Chip
                                        data-testId="Chip"
                                        className="Chip"
                                        id={filter.label}
                                        key={filter.key}
                                        label={filter.label}
                                        onDelete={(): void => {
                                            removeFilter(convertFilterKeyToFilterObject(filter.key));
                                        }}
                                    />
                                </>
                            );
                        })}
                </div>
                {!contactQuery.loading && !isEmpty(columns) && !isEmpty(data) ? (
                    <DataTable contactTableInstance={contactTableInstance} showPagination hideHeaderGroups />
                ) : (
                    !contactQuery.loading &&
                    isEmpty(columns) &&
                    isEmpty(data) && <p id="noApplicableContacts">No subscribed contacts meet all filters applied!</p>
                )}
            </section>
            <Drawer />
        </div>
    );
};
export default ContactManager;
