import React, { ReactElement } from 'react';

import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export interface LinkData {
    link: string;
    clicks: number;
    overall: number;
}

const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#ECEFF1',
            color: theme.palette.common.black,
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const LinkTable = ({ type, data }: { type: string; data: LinkData[] }): ReactElement => {
    return (
        <Table
            className="linkTable"
            aria-label="customized table"
            style={{ marginBottom: '2rem', border: '1px solid #ECEFF1' }}
        >
            <TableHead>
                <TableRow>
                    <StyledTableCell>{type}</StyledTableCell>
                    <StyledTableCell align="right" width="20%">
                        Unique Clicks
                    </StyledTableCell>
                    <StyledTableCell align="right" width="20%">
                        Total Clicks
                    </StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row) => (
                    <TableRow key={row.link}>
                        <StyledTableCell component="th" scope="row">
                            {row.link}
                        </StyledTableCell>
                        <StyledTableCell align="right">{row.clicks}</StyledTableCell>
                        <StyledTableCell align="right">{row.overall}</StyledTableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default LinkTable;
