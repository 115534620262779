import BaseUrl from 'components/utils/BaseUrl';
import { getCampaignState, parseCampaignSenderEmail } from 'components/utils/CampaignUtils';
import { dateConstructor } from 'components/utils/DateUtil';
import { TEMPLATE_TYPES } from 'components/utils/GlobalConstants';
import { FindCampaignCampaign } from 'typings/_graphql';
import { Campaign } from 'typings/Campaign';
import {
    CLEAR_ALL_FIELDS,
    CLEAR_FIELDS,
    ENABLE_REVIEW_MODE,
    RESET_CAMPAIGN,
    SELECT_AUDIENCE,
    SELECT_CAMPAIGN_TAB,
    SELECT_TEMPLATE,
    SET_CAMPAIGN_STATE,
    UPDATE_ARTICLE_COUNT,
    UPDATE_CAMPAIGN_DETAILS,
    UPDATE_CAMPAIGN_ID,
    UPDATE_EVENT,
    UPDATE_NEWSLETTER,
    UPDATE_PRESS,
} from '../actions/Campaign';
import initialState from '../initialState';

export interface Request {
    type: string;
    payload: {
        campaign: FindCampaignCampaign;
        name: string;
        value: string;
        index: string;
        step: number;
        senderEmail: string;
        segmentIds: number[];
        segmentNames: string[];
        id: number;
        boolean: boolean;
        fields: Array<{ key: string; index: number | null }>;
    };
}

export default (state = initialState, { type, payload }: Request): Campaign => {
    const {
        campaign,
        name,
        value,
        index,
        segmentIds,
        segmentNames,
        step,
        id,
        boolean,
        resetToInitial = [{ index: -1, key: '' }],
        resetToSave = [{ index: -1, key: '', value: '' }],
    } = {
        ...payload,
    };

    const baseUrl = BaseUrl();
    const article = {
        title: 'Article Title',
        preview: 'Article Preview',
        articleURL: 'https://www.afsbirsttr.af.mil/',
        imageURL:
            'https://images.unsplash.com/photo-1517429128955-67ff5c1e29da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
    };
    const articlesArray = Array(8).fill(article);

    const { date: dateString, day: initialDay } = dateConstructor();
    switch (type) {
        case SET_CAMPAIGN_STATE: {
            const template = getCampaignState(campaign);
            const { senderName, senderEmail } = parseCampaignSenderEmail(campaign.senderEmail);
            return {
                ...state,
                id: campaign.id,
                campaignAnalytics: campaign.campaignAnalytics,
                campaignTab: campaign.isSent ? 4 : 0,
                isSent: campaign.isSent,
                dateModified: campaign.modifiedDate,
                createdDate: campaign.createdDate,
                sendDate: campaign.sendDate,
                selectAudience: {
                    segmentIds: campaign.segments?.map((segment) => segment.id) || [],
                    segmentNames: campaign.segments?.map((segment) => segment.name) || [],
                },
                campaignDetails: {
                    ...initialState.campaignDetails,
                    campaignName: campaign.title,
                    subject: campaign.subject ?? '',
                    senderEmail,
                    senderName,
                },
                template,
                linkData: campaign.linkClicks,
            };
        }
        case UPDATE_CAMPAIGN_ID: {
            return { ...state, id };
        }
        case ENABLE_REVIEW_MODE: {
            return { ...state, reviewMode: boolean };
        }
        case CLEAR_ALL_FIELDS: {
            return {
                ...state,
                template: {
                    ...state.template,
                    newsletter: { ...initialState.template.newsletter },
                    event: { ...initialState.template.event },
                    press: { ...initialState.template.press },
                },
            };
        }
        case CLEAR_FIELDS: {
            const resetFields = {};
            const resetArticles = { ...state.template[name].articles };
            resetToInitial?.forEach((field) => {
                if (field.index !== null && field.index >= 0) {
                    resetArticles[field.index][field.key] =
                        initialState.template[name].articles[field.index][field.key];
                } else resetFields[field.key] = initialState.template[name][field.key];
            });
            resetToSave?.forEach((field) => {
                if (field.index !== null && field.index >= 0) {
                    resetArticles[field.index][field.key] = field.value;
                } else resetFields[field.key] = field.value;
            });
            if (name === 'newsletter')
                return {
                    ...state,
                    template: {
                        ...state.template,
                        [name]: {
                            ...state.template[name],
                            ...resetFields,
                            articles: resetArticles,
                        },
                    },
                };
            return {
                ...state,
                template: {
                    ...state.template,
                    [name]: { ...state.template[name], ...resetFields },
                },
            };
        }
        case SELECT_AUDIENCE: {
            return { ...state, selectAudience: { ...state.selectAudience, segmentIds, segmentNames } };
        }
        case SELECT_CAMPAIGN_TAB: {
            return { ...state, campaignTab: step };
        }
        case RESET_CAMPAIGN: {
            return {
                id: undefined,
                isSent: false,
                campaignAnalytics: null,
                dateModified: undefined,
                sendDate: undefined,
                createdDate: undefined,
                campaignTab: 0,
                reviewMode: false,
                selectAudience: {
                    segmentIds: [],
                    segmentNames: [],
                },
                campaignDetails: {
                    campaignName: '',
                    subject: '',
                    senderEmail: '',
                    senderName: '',
                },
                linkData: [],
                template: {
                    type: 0,
                    newsletter: {
                        tagline: 'Tagline',
                        articleCount: 2,
                        date: dateString,
                        welcomeMessage: 'Welcome Message',
                        articles: articlesArray,
                    },
                    event: {
                        date: dateString,
                        day: initialDay,
                        time: '12:00',
                        location: 'Location',
                        description: 'Event Description',
                        name: 'EVENT NAME',
                        url: 'https://www.afsbirsttr.af.mil/',
                        imageURL: `${baseUrl}/images/templates/event2.jpg`,
                    },
                    press: {
                        date: dateString,
                        headline: 'Headline',
                        subheadline: 'Sub-Headline',
                        description: 'Description',
                        contactName: 'Contact Name',
                        contactNum: 'Contact Phone',
                        contactEmail: 'Contact Email',
                        imageURL:
                            'https://images.unsplash.com/photo-1515700845405-9ffd33692a68?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1955&q=80',
                    },
                },
            };
        }

        case UPDATE_CAMPAIGN_DETAILS: {
            return { ...state, campaignDetails: { ...state.campaignDetails, [name]: value } };
        }

        case UPDATE_NEWSLETTER: {
            if (name === 'date') {
                const { date, month } = dateConstructor(new Date(value));
                // eslint-disable-next-line no-restricted-globals
                if (isNaN(month))
                    return {
                        ...state,
                        template: { ...state.template, newsletter: { ...state.template.newsletter, date: 'Date' } },
                    };
                return {
                    ...state,
                    template: {
                        ...state.template,
                        newsletter: {
                            ...state.template.newsletter,
                            date,
                        },
                    },
                };
            }
            if (name === 'welcomeMessage')
                return {
                    ...state,
                    template: {
                        ...state.template,
                        newsletter: { ...state.template.newsletter, welcomeMessage: value },
                    },
                };
            if (name === 'tagline')
                return {
                    ...state,
                    template: {
                        ...state.template,
                        newsletter: { ...state.template.newsletter, tagline: value },
                    },
                };
            return {
                ...state,
                template: {
                    ...state.template,
                    newsletter: {
                        ...state.template.newsletter,
                        articles: {
                            ...state.template.newsletter.articles,
                            [index]: { ...state.template.newsletter.articles[index], [name]: value },
                        },
                    },
                },
            };
        }
        case UPDATE_ARTICLE_COUNT: {
            if (name === 'add' && state.template.newsletter.articleCount < 8) {
                return {
                    ...state,
                    template: {
                        ...state.template,
                        newsletter: {
                            ...state.template.newsletter,
                            articleCount: state.template.newsletter.articleCount + 2,
                        },
                    },
                };
            }
            if (name === 'remove' && state.template.newsletter.articleCount > 2) {
                return {
                    ...state,
                    template: {
                        ...state.template,
                        newsletter: {
                            ...state.template.newsletter,
                            articleCount: state.template.newsletter.articleCount - 2,
                        },
                    },
                };
            }
            return state;
        }
        case UPDATE_PRESS: {
            if (name === 'date') {
                const { date, month } = dateConstructor(new Date(value));
                // eslint-disable-next-line no-restricted-globals
                if (isNaN(month))
                    return {
                        ...state,
                        template: { ...state.template, press: { ...state.template.press, date: 'Date' } },
                    };
                return {
                    ...state,
                    template: {
                        ...state.template,
                        press: {
                            ...state.template.press,
                            date,
                        },
                    },
                };
            }
            return { ...state, template: { ...state.template, press: { ...state.template.press, [name]: value } } };
        }
        case UPDATE_EVENT: {
            if (name === 'date') {
                const { date, day, month } = dateConstructor(new Date(value));

                // eslint-disable-next-line no-restricted-globals
                if (isNaN(month))
                    return {
                        ...state,
                        template: { ...state.template, event: { ...state.template.event, date: 'Date' } },
                    };
                return {
                    ...state,
                    template: {
                        ...state.template,
                        event: {
                            ...state.template.event,
                            date,
                            day,
                        },
                    },
                };
            }
            return { ...state, template: { ...state.template, event: { ...state.template.event, [name]: value } } };
        }
        case SELECT_TEMPLATE: {
            if (name === 'newsletter')
                return { ...state, template: { ...state.template, type: TEMPLATE_TYPES.NEWSLETTER } };
            if (name === 'event') return { ...state, template: { ...state.template, type: TEMPLATE_TYPES.EVENT } };
            if (name === 'press') return { ...state, template: { ...state.template, type: TEMPLATE_TYPES.PRESS } };
            return state;
        }
        default:
            return state;
    }
};
