import React, { useEffect, useState } from 'react';

import { DialogContentText } from '@material-ui/core';
import { Column, useFlexLayout, useTable } from 'react-table';
import { FixedSizeList } from 'react-window';

import { getBasicColumns } from 'components/utils/ReactTableUtils';

import 'assets/sass/ReactTableStyle.scss';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

const ViewContactsTable = ({
    contactData,
    showValidColumnsOnly,
}: {
    contactData: any[];
    showValidColumnsOnly?: boolean;
}): JSX.Element => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const scrollbarWidth = 20;
    const windowToTableHeightRatio = 0.4;

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const columns = React.useMemo((): Column[] => {
        return getBasicColumns(contactData, showValidColumnsOnly);
    }, [contactData, showValidColumnsOnly]);
    const data = React.useMemo(() => {
        return contactData ?? [];
    }, [contactData]);

    const contactTableInstance = useTable({ columns, data }, useFlexLayout);

    const RenderRow = React.useCallback(
        ({ index, style }) => {
            const row = contactTableInstance.rows[index];
            contactTableInstance.prepareRow(row);
            return (
                <div {...row.getRowProps({ style })} className="Tr">
                    {row.cells.map((cell) => {
                        return (
                            <div
                                {...cell.getCellProps({
                                    style: {
                                        fontWeight:
                                            cell.column.render('Header') === 'Email Address' ? 'bold' : undefined,
                                    },
                                })}
                                className="Td"
                            >
                                {cell.render('Cell')}
                            </div>
                        );
                    })}
                </div>
            );
        },
        [contactTableInstance]
    );

    const itemSize = 50; // height of table rows

    return data.length > 0 ? (
        <div {...contactTableInstance.getTableProps()} id="viewContactsTable" className="Table Sticky">
            {contactTableInstance.headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps({ style: { display: 'inline-flex' } })} className="Tr Header">
                    {headerGroup.headers.map((column) => (
                        <div
                            {...column.getHeaderProps({
                                style: {
                                    fontWeight: column.render('Header') === 'Email Address' ? 'bold' : undefined,
                                },
                            })}
                            className="Th Header__IsNotSorted"
                        >
                            {column.render('Header')}
                        </div>
                    ))}
                </div>
            ))}
            <div {...contactTableInstance.getTableBodyProps()}>
                <FixedSizeList
                    height={windowDimensions.height * windowToTableHeightRatio}
                    itemCount={contactTableInstance.rows.length}
                    itemSize={itemSize}
                    width={contactTableInstance.totalColumnsWidth + scrollbarWidth}
                    style={{ overflow: 'auto' }}
                >
                    {RenderRow}
                </FixedSizeList>
            </div>
        </div>
    ) : (
        <DialogContentText id="noDataError">No contact data to display!</DialogContentText>
    );
};

ViewContactsTable.defaultProps = {
    showValidColumnsOnly: true,
};
export default ViewContactsTable;
