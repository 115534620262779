import React, { useEffect } from 'react';

import { MutationFunction, useMutation } from '@apollo/client';
import { Button, ClickAwayListener, Grow, makeStyles, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';

import { CREATE_CONTACT } from 'api/mutations';
import checkBoxObjToString from 'components/utils/CheckBoxObjToString';
import { defaultFormValues } from 'components/utils/GlobalConstants';
import showSnackbarError from 'components/utils/ShowSnackbarError';
import SubscriptionForm from 'pageComponents/contact/subscriptionForm/SubscriptionForm';
import { showDialog } from 'store/actions/Dialog';
import { showSnackbar } from 'store/actions/Snackbar';
import { ContactStatus } from 'typings/_graphql';
import { FormValues } from 'typings/Subscription';

const useStyles = makeStyles({
    popoverOnTop: {
        zIndex: 10,
    },
    formControl: {
        minWidth: '10rem',
    },
    formControlLabel: {
        marginLeft: '0.9rem',
    },
    firstSectionName: {
        fontSize: '1.2rem',
        paddingBottom: '1rem',
    },
    sectionName: {
        fontSize: '1.2rem',
        marginTop: '1.25rem',
        marginBottom: '0.75rem',
    },
    companySectionName: {
        fontSize: '1.2rem',
        paddingTop: '1.8rem',
    },
    CheckboxHeading: {
        paddingTop: '1.5rem',
        fontSize: '1.2rem',
    },
    inputLabels: {
        marginTop: '1rem',
        marginBottom: '0.5rem',
    },
    data: {
        fontSize: '1rem',
        fontFamily: 'Roboto',
    },
    vdata: {
        fontSize: '1rem',
        fontFamily: 'Roboto',
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    EmailValidation: {
        color: '#f00',
        margin: '0.1rem 0rem',
        fontSize: '0.875rem',
        fontFamily: 'Roboto',
    },
    select: {
        height: '2.5rem',
        marginTop: '0.5rem',
    },
    menuHeight: {
        height: '20vh',
        minHeight: '10rem',
    },
});

export const onManualContactSubmit = async (
    formData: FormValues,
    createContact: MutationFunction,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: Dispatch<any>
): Promise<void> => {
    const data = {
        emailAddress: formData.emailAddress,
        companyName: formData.companyName,
        city: formData.city,
        state: formData.state,
        firstName: formData.firstName,
        lastName: formData.lastName,
        jobTitle: formData.jobTitle,
        dunsNumber: formData.dunsNumber,
        phoneNumber: formData.phoneNumber,
        status: formData.subscriptionStatus,
        audienceId: 1,
        techFocusAreas: checkBoxObjToString(formData.techFocusAreas),
        companyCertifications: checkBoxObjToString(formData.companyCertifications),
    };

    Object.keys(data).forEach((key) => {
        if (data[key] === undefined) data[key] = '';
    });

    try {
        await createContact({
            variables: {
                contact: data,
            },
            refetchQueries: () => ['findContactsFromFilters', 'findSegmentContacts'],
        });
        if (formData.subscriptionStatus === ContactStatus.SUBSCRIBED) {
            dispatch(showSnackbar(true, 'Contact was saved and subscribed!', 'success'));
        } else if (formData.subscriptionStatus === ContactStatus.PENDING) {
            dispatch(showSnackbar(true, 'Contact was saved and invited!', 'success'));
        } else {
            dispatch(showSnackbar(true, 'Contact is missing subscription status!', 'error'));
        }
        dispatch(showDialog(false, '', undefined, undefined));
    } catch (err) {
        showSnackbarError(dispatch, err);
    }
};

export default (): JSX.Element => {
    const formId = 'addNewContactForm';

    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);
    const [createContact] = useMutation(CREATE_CONTACT);
    const dispatch = useDispatch();
    const classes = useStyles();
    const handleToggle = () => {
        setOpen((previousOpen) => !previousOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab' || event.key === 'Escape') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current?.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
                variant="contained"
                data-testid="addContactsButton"
            >
                ADD CONTACTS <ArrowDropDownIcon />
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                className={classes.popoverOnTop}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={(event) => handleListKeyDown(event)}
                                >
                                    <MenuItem
                                        id="addNewContactBtn"
                                        onClick={() =>
                                            dispatch(
                                                showDialog(
                                                    true,
                                                    'Add New Contact',
                                                    <SubscriptionForm
                                                        formId={formId}
                                                        onSave={(formData: FormValues) =>
                                                            onManualContactSubmit(formData, createContact, dispatch)
                                                        }
                                                        defaultFormValues={defaultFormValues}
                                                        classes={classes}
                                                        isPublic={false}
                                                        isSubscribe
                                                    />,
                                                    <>
                                                        <Button
                                                            id="cancelButton"
                                                            color="primary"
                                                            onClick={() =>
                                                                dispatch(showDialog(false, '', undefined, undefined))
                                                            }
                                                        >
                                                            CANCEL
                                                        </Button>
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            form={formId}
                                                        >
                                                            ADD CONTACT
                                                        </Button>
                                                    </>
                                                )
                                            )
                                        }
                                    >
                                        Manual Entry
                                    </MenuItem>
                                    <MenuItem
                                        id="addNewContactBtn"
                                        onClick={() => history.push('/audience/contacts/upload')}
                                    >
                                        Upload File
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
