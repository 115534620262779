import React, { ReactElement, Suspense } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import PrivateRoute from 'PrivateRoute';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import ExternalFooter from 'components/layouts/externalFooter/Footer';
import ExternalHeader from 'components/layouts/externalHeader/ExternalHeader';
import Header from 'components/layouts/header/Header';
import usePageTracking from 'components/utils/usePageTracking';
import DemoBanner from 'pageComponents/general/DemoBanner';
import CampaignLibrary from 'pages/campaignLibrary/CampaignLibrary';
import CampaignReport from 'pages/campaignReport/CampaignReport';
import Contacts from 'pages/contacts/ContactsPage';
import CreateCampaign from 'pages/createCampaign/CreateCampaign';
import CreateSegment from 'pages/createSegment/CreateSegment';
import InfoPage from 'pages/infoPage/InfoPage';
import Reporting from 'pages/reporting/Reporting';
import Resubscribe from 'pages/subscriberPreferences/resubscribe/Resubscribe';
import SignUp from 'pages/subscriberPreferences/signUp/SignUp';
import Subscribe from 'pages/subscriberPreferences/subscribe/Subscribe';
import Unsubscribe from 'pages/subscriberPreferences/unsubscribe/Unsubscribe';
import Preferences from 'pages/subscriberPreferences/updatePreferences/UpdatePreferences';
import Unauthorized from 'pages/unauthorized/Unauthorized';
import UploadContacts from 'pages/uploadContacts/UploadContacts';
import { Store } from 'store/reducers';
import PrivateRouter from './PrivateRouter';

export const ReactRouter = (): ReactElement => {
    const user = useSelector((storeState: Store) => storeState.User);

    const publicRoutes = ['unsubscribe', 'preferences', 'resubscribe', 'unauthorized', 'subscribe', 'signup'];

    const isPublicRoute =
        publicRoutes.some((publicRoute) => window.location.pathname.includes(publicRoute)) ||
        window.location.pathname === '/';

    const isLoggedIn = Boolean(user.name);

    usePageTracking();
    return (
        <Suspense
            fallback={
                <div className="root_content">
                    <h3>...Loading page</h3>
                </div>
            }
        >
            <main>
                <Route exact path="/">
                    <Redirect to="/info" />
                </Route>

                {isPublicRoute && (
                    <>
                        {window.location.pathname === '/' ? <Header showNavigation={isLoggedIn} /> : <ExternalHeader />}
                        <Switch>
                            <Route exact path="/signup/:audienceId" component={SignUp} />
                            <Route path="/preferences/:id" component={Preferences} />
                            <Route exact path="/resubscribe/:id" component={Resubscribe} />
                            <Route exact path="/subscribe/:id" component={Subscribe} />
                            <Route exact path="/unsubscribe/:id" component={Unsubscribe} />
                            <Route exact path="/unsubscribe/:id/:campaignId" component={Unsubscribe} />
                            <Route exact path="/unauthorized" component={Unauthorized} />
                        </Switch>
                        {window.location.pathname !== '/' ? <ExternalFooter /> : null}
                    </>
                )}
                {!isPublicRoute && (
                    <PrivateRouter>
                        <Header showNavigation={isLoggedIn} />
                        {/* <Header /> */}
                        <DemoBanner />
                        <Switch>
                            <PrivateRoute exact path="/info" component={InfoPage} />
                            <PrivateRoute exact path="/campaign" component={CampaignLibrary} />
                            <PrivateRoute exact path="/campaign/create" component={CreateCampaign} />
                            <PrivateRoute exact path="/campaign/:id" component={CampaignReport} />
                            <PrivateRoute exact path="/audience" component={Contacts} />
                            <PrivateRoute exact path="/audience/contacts" component={Contacts} />
                            <PrivateRoute exact path="/audience/contacts/upload" component={UploadContacts} />
                            <PrivateRoute exact path="/audience/segments" component={Contacts} />
                            <PrivateRoute exact path="/audience/segments/create" component={CreateSegment} />
                            <PrivateRoute exact path="/audience/segments/edit/:id" component={CreateSegment} />
                            <PrivateRoute exact path="/reporting" component={Reporting} />
                        </Switch>
                    </PrivateRouter>
                )}
            </main>
        </Suspense>
    );
};

export default ReactRouter;
