import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';

/* eslint-disable no-param-reassign */
import { useMutation } from '@apollo/client';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';

import { CREATE_AND_UPDATE_CONTACTS } from 'api/mutations';
import CustomStepper from 'components/stepper/Stepper';
import { allCategoryNames } from 'components/utils/GlobalConstants';
import { getBasicColumns } from 'components/utils/ReactTableUtils';
import showSnackbarError from 'components/utils/ShowSnackbarError';
import { MatchingColumns, matchNewColumns } from 'components/utils/UploadContactsUtils';
import DuplicateContacts from 'pageComponents/contact/uploadWizard/duplicateContacts/DuplicateContacts';
import ImportManagement from 'pageComponents/contact/uploadWizard/importManagement/ImportManagement';
import MatchColumnsForm from 'pageComponents/contact/uploadWizard/importManagement/MatchColumnsForm';
import ImportResults from 'pageComponents/contact/uploadWizard/importResults/ImportResults';
import ReviewUpload from 'pageComponents/contact/uploadWizard/reviewUpload/ReviewUpload';
import Upload from 'pageComponents/contact/uploadWizard/upload/Upload';
import { showDialog } from 'store/actions/Dialog';
import { showSnackbar } from 'store/actions/Snackbar';
import { changeStepperStep } from 'store/actions/Stepper';
import { ContactStatus } from 'typings/_graphql';

import './UploadContacts.scss';

export const exitModalAction = (
    unblock: () => void,
    blockedEvent: any,
    closeWindowEvent: (event: { preventDefault: () => void; returnValue: string }) => void,
    history: any,
    dispatch: Dispatch<any>
): void => {
    dispatch(showDialog(false, '', undefined, undefined));
    window.removeEventListener('beforeunload', closeWindowEvent);
    unblock();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    history.push(blockedEvent.pathname);
    dispatch(changeStepperStep({ step: 0 }));
};

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value + 1); // update the state to force render
}

const UploadContacts = (): ReactElement => {
    useEffect(() => {
        document.body.style.overflowY = 'hidden';
        return () => {
            document.body.style.overflowY = '';
        };
    });

    const matchColumnsFormId = 'matchColumnsForm';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [contacts, setContacts] = useState<any[]>([]);
    const [overrideDuplicates, setOverrideDuplicates] = useState<boolean | undefined>();
    const [duplicateContacts, setDuplicateContacts] = useState<any[]>([]);
    const successfulContacts = useRef<any[]>();
    const validColumns = useMemo(() => getBasicColumns(contacts, false), [contacts]);
    const columns = useRef<MatchingColumns[]>();
    const [file, setFileName] = useState('');
    const history = useHistory();
    const [addNewContacts] = useMutation(CREATE_AND_UPDATE_CONTACTS);
    const dispatch = useDispatch();
    const forceUpdate = useForceUpdate();

    const handleMatchingColumns = useCallback(
        (formData) => {
            matchNewColumns(columns, formData);
            forceUpdate();
            dispatch(showDialog(false, '', undefined, undefined));
        },
        [dispatch, forceUpdate]
    );

    const openMatchingColumnsForm = useCallback(() => {
        dispatch(
            showDialog(
                true,
                'Edit Column Labels',
                <MatchColumnsForm
                    columns={columns.current ?? []}
                    formId={matchColumnsFormId}
                    onSubmit={handleMatchingColumns}
                />,
                <>
                    <Button
                        id="cancelButton"
                        color="primary"
                        onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                    >
                        CANCEL
                    </Button>
                    <Button type="submit" variant="contained" color="primary" form={matchColumnsFormId}>
                        SAVE CHANGES
                    </Button>
                </>,
                'xs',
                true
            )
        );
    }, [columns, dispatch, handleMatchingColumns]);

    const exitAction = () => {
        history.push('/audience/contacts');
    };

    const closeWindowEvent = useCallback((event: { preventDefault: () => void; returnValue: string }) => {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = '';
    }, []);

    useEffect(() => {
        if (
            history.location.pathname === '/audience/contacts/upload' &&
            contacts.length > 0 &&
            (successfulContacts.current === undefined || successfulContacts.current?.length === 0)
        ) {
            window.addEventListener('beforeunload', closeWindowEvent);
            const unblock = history.block((blockedEvent) => {
                dispatch(
                    showDialog(
                        true,
                        'Are you sure you want to cancel this import?',
                        <p className="UploadExitModalText">
                            If you cancel now, these contacts will not be imported and progress will not be saved.
                        </p>,
                        <>
                            <Button
                                id="cancelButton"
                                color="primary"
                                onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                            >
                                NO, STAY ON PAGE
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    exitModalAction(unblock, blockedEvent, closeWindowEvent, history, dispatch);
                                }}
                            >
                                YES, EXIT
                            </Button>
                        </>
                    )
                );
                return false;
            });
        } else {
            const unblock = history.block((blockedEvent) => {
                exitModalAction(unblock, blockedEvent, closeWindowEvent, history, dispatch);
            });
        }
    }, [closeWindowEvent, contacts.length, dispatch, history, history.location.pathname]);

    const handleUpload = async () => {
        try {
            const matchingContactList = contacts.map((contact) => {
                const tmpContact = { ...contact, audienceId: 1 };
                const matchingColumns = columns.current;
                if (matchingColumns) {
                    for (let index = 0; index < matchingColumns.length; index++) {
                        const column = matchingColumns[index];
                        const newKey = column.newColumn.accessor as string;
                        const originalKey = column.originalColumn.accessor as string;
                        if (newKey !== originalKey && tmpContact[originalKey]) {
                            tmpContact[newKey] = tmpContact[originalKey];
                            delete tmpContact[originalKey];
                        } else if (column.ignored) {
                            delete tmpContact[originalKey];
                        }
                    }
                }
                return tmpContact;
            });

            const contactResponse = await addNewContacts({
                variables: {
                    contacts: {
                        contactList: matchingContactList,
                        duplicateContacts,
                        updateDuplicates: overrideDuplicates,
                    },
                },
            });

            if (contactResponse.data?.CreateContactResponse?.errors?.length > 0) {
                const { errors } = contactResponse.data.CreateContactResponse;
                const duplicateErrors = errors.filter((el: string) => el.includes('already exists in database!'));
                if (duplicateErrors.length === contacts.length) {
                    dispatch(showSnackbar(true, 'Uploaded contacts already exist in database', 'error'));
                } else {
                    dispatch(
                        showSnackbar(
                            true,
                            `Some contacts failed to be added and ${
                                contacts[0].status === ContactStatus.SUBSCRIBED ? 'subscribed' : 'invited'
                            }.`,
                            'success'
                        )
                    );
                }
            } else {
                successfulContacts.current = contactResponse.data?.CreateContactResponse?.contactList;
                const unblock = history.block((blockedEvent) => {
                    exitModalAction(unblock, blockedEvent, closeWindowEvent, history, dispatch);
                });
                window.removeEventListener('beforeunload', closeWindowEvent);
            }
        } catch (err) {
            showSnackbarError(dispatch, err);
        }
    };
    const steps = [
        {
            label: 'File Upload',
            Component: Upload,
            validation: (state: any[]) => {
                if (state.length > 0) return true;
                return false;
            },
            componentState: contacts,
            props: {
                contacts,
                setContacts,
                setFileName,
                filename: file,
                setDuplicateContacts,
                columns,
                successfulContacts,
            },
        },
        {
            label: 'Import Management',
            Component: ImportManagement,
            validation: () => {
                const numValidColumns = validColumns.length;
                const numMatchedOrIgnoredColumns =
                    columns.current?.filter(
                        (column) =>
                            (column.newColumn.Header && allCategoryNames.includes(column.newColumn.Header as string)) ||
                            column.ignored
                    ).length || 0;
                return numValidColumns === numMatchedOrIgnoredColumns;
            },
            componentState: contacts,
            props: {
                contacts,
                columns,
                openMatchingColumnsForm,
                forceUpdate,
            },
            nextStepAction: (isValid: boolean) => {
                if (!isValid && columns) openMatchingColumnsForm();
            },
        },
        {
            label: 'Duplicate Contacts',
            Component: DuplicateContacts,
            validation: () => overrideDuplicates !== undefined,
            componentState: contacts,
            props: {
                overrideDuplicates,
                setOverrideDuplicates,
            },
        },
        {
            label: 'Review Import',
            Component: ReviewUpload,
            validation: () => contacts[0].status !== '',
            componentState: contacts,
            props: {
                contacts,
                filename: file,
                setContacts,
                columns,
            },
            uniqueAction: {
                name: 'IMPORT CONTACTS',
                action: handleUpload,
                index: 3,
            },
        },
        {
            label: 'Import Results',
            Component: ImportResults,
            validation: () => successfulContacts.current !== undefined,
            componentState: contacts,
            props: {
                contacts,
                filename: file,
                setContacts,
                duplicateContacts,
                successfulContacts,
                columns,
            },
        },
    ];

    return (
        <div className="UploadStepper">
            <CustomStepper
                steps={steps}
                exitAction={exitAction}
                exitButtonTitle="EXIT FILE UPLOAD"
                finalActionName="GO TO AUDIENCE LIST"
                finalAction={exitAction}
            />
        </div>
    );
};
export default UploadContacts;
