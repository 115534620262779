import React, { ReactElement, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { SUBSCRIBE_CONTACT_PUBLIC } from 'api/mutations';
import showSnackbarError from 'components/utils/ShowSnackbarError';

import './Subscribe.scss';

export interface ParamType {
    id: string;
}
const Subscribe = (): ReactElement => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams<ParamType>();
    const [contactEmail, setContactEmail] = useState('');
    const [subscribeContact] = useMutation(SUBSCRIBE_CONTACT_PUBLIC);

    useEffect(() => {
        if (!history.location.state?.from.includes('signup')) {
            const subscribe = async () => {
                try {
                    const contact = await subscribeContact({
                        variables: { id },
                    });
                    setContactEmail(contact.data?.Contact.emailAddress);
                } catch (err) {
                    showSnackbarError(dispatch, err); // show snack bar
                }
            };
            if (contactEmail === '') {
                subscribe();
            }
        } else {
            setContactEmail(history.location.state?.emailAddress);
        }
    }, [
        contactEmail,
        dispatch,
        history.location.state?.emailAddress,
        history.location.state?.from,
        id,
        subscribeContact,
    ]);

    return (
        <div className="SubscribeContainer">
            <Grid container>
                {(!id || !contactEmail) && <h1 className="ErrorMsg">Error: Contact not found</h1>}
                {contactEmail && (
                    <div className="SubscribeBox">
                        <Grid item className="GridContainerResub">
                            <div>
                                <p className="SubscribeText Heading">Subscription Activated</p>
                                <p className="SubscribeText">
                                    ({contactEmail}) will start to receive emails from the Air Force Small Business
                                    Innovation Research office.
                                </p>
                            </div>
                        </Grid>
                        <Grid className="GridContainerResub" container item justify="flex-end">
                            <Grid item>
                                <a className="OkBtn" href="https://www.afsbirsttr.af.mil/">
                                    <Button color="primary" variant="contained">
                                        OK
                                    </Button>
                                </a>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </Grid>
        </div>
    );
};
export default Subscribe;
