import { camelCase, isEmpty, lowerCase, startCase } from 'lodash';
import { Dispatch } from 'redux';

import { showSnackbar } from 'store/actions/Snackbar';

interface FormFilterObj {
    condition: string;
    value: string;
}
export interface FormDataObj {
    [key: string]: FormFilterObj[];
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getUniqueJSONFiltersFromForm(dispatch: Dispatch<any>, formData: FormDataObj): Record<string, unknown> {
    const jsonFilters = {};
    Object.entries(formData).forEach(([key, value]) => {
        const categoryObjects = value;
        categoryObjects.forEach((categoryObject) => {
            if (categoryObject.condition && !isEmpty(categoryObject.value)) {
                const filterCategory = key;
                const filterCondition = camelCase(categoryObject.condition);
                let filterValue;

                if (filterCondition.toLowerCase().includes('equals')) {
                    filterValue = categoryObject.value.split(/[,]+/).map((s) => s.trim());
                } else if (filterCondition.toLowerCase().includes('contains')) {
                    filterValue = `(${escapeRegExp(categoryObject.value)
                        .split(/[,]+/)
                        .map((s) => s.trim())
                        .join('|')})`;
                } else {
                    filterValue = categoryObject.value;
                }

                if (jsonFilters[filterCategory] && jsonFilters[filterCategory][filterCondition]) {
                    dispatch(
                        showSnackbar(
                            true,
                            `Duplicate filter condition in same category:\n(${startCase(filterCategory)} ${lowerCase(
                                filterCondition
                            )} ${categoryObject.value})\n\nIgnoring filter value!`,
                            'error'
                        )
                    );
                } else {
                    jsonFilters[filterCategory] = { ...jsonFilters[filterCategory], [filterCondition]: filterValue };
                }
            }
        });
    });
    return jsonFilters;
}
