import React, { JSXElementConstructor, ReactElement, useState } from 'react';

import { Button, Grid, makeStyles, Step, StepButton, Stepper } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';

import { changeStepperStep } from 'store/actions/Stepper';
import { Store } from 'store/reducers';

import './Stepper.scss';

const useStyles = makeStyles({
    dialogPadding: {
        padding: '1rem',
    },
    dialogImportantText: {
        color: '#07090c',
        fontWeight: 600,
    },
    bulletedListItems: {
        listStyleType: 'disc',
    },
    stepButton: {
        backgroundColor: '#f7fbfc',

        cursor: 'pointer',
        fontSize: '12.9px',
        outline: 'none !important',
        color: '#0c1256 !important',
        '&.Mui-disabled .MuiStepLabel-label': {
            color: 'rgba(200, 200, 200, 0.87)',
        },
        '&:not(.Mui-disabled) .MuiStepLabel-label': {
            color: 'rgba(0, 0, 0, 0.87)',
        },
    },
    disabledStepButton: {
        color: '#c8cfd6',
        cursor: 'auto',
    },
    activeStep: {
        '& .MuiStepLabel-label': {
            fontWeight: 'bolder',
        },
    },
});

const CustomStepper = ({
    steps,
    exitAction,
    exitButtonTitle,
    finalActionName,
    finalAction,
}: {
    steps: Array<{
        label: string;
        Component: ({
            props,
            showFieldRequired,
            endAnimation,
            validationAnimation,
            handleStep,
        }: {
            handleStep: (step: number) => void;
            props: any;
            showFieldRequired: boolean;
            endAnimation: () => void;
            validationAnimation: boolean;
        }) => ReactElement<any, string | JSXElementConstructor<any>>;
        componentState: Record<string, string>[];
        validation: (variable: any) => boolean;
        props: Record<string, unknown>;
        uniqueAction?: {
            name: string;
            action: () => Promise<void>;
            index: number;
        };
        nextStepAction?: ((isValid: boolean) => void) | undefined;
    }>;
    exitButtonTitle: string;
    exitAction: () => void;
    finalActionName: string;
    finalAction: () => void;
}): ReactElement => {
    const { step: stateStep } = useSelector((storeState: Store) => storeState.Stepper);
    const { Component, validation, componentState, uniqueAction, nextStepAction } = steps[stateStep];
    const [showFieldRequired, setShowFieldRequired] = useState<boolean>(false);
    const [validationAnimation, setValidationAnimation] = useState<boolean>(false);
    const [farStep, setFarStep] = useState<number>(0);

    const [completedSteps, setCompletedSteps] = useState<{ [key: number]: boolean }>({});

    const dispatch = useDispatch();
    const classes = useStyles();

    const farValidation = steps[farStep].validation;

    const setStepStatus = (step: number, isValid: boolean) => {
        const newCompletedSteps = {};
        newCompletedSteps[step] = false;
        for (let i = stateStep; i < step; i++) {
            newCompletedSteps[i] = isValid;
        }
        setCompletedSteps((prevState) => {
            return { ...prevState, ...newCompletedSteps };
        });
    };

    const handleBack = (): void => {
        const isValid = true;
        const previousStep = stateStep - 1;
        setStepStatus(previousStep, isValid);
        dispatch(changeStepperStep({ step: previousStep }));
    };

    const handleNext = (): void => {
        const isValid = validation(componentState);
        if (nextStepAction !== undefined) nextStepAction(isValid);
        if (!isValid && stateStep !== steps.length - 1) {
            setShowFieldRequired(true);
            setValidationAnimation(true);
        } else {
            const nextStep = stateStep + 1;
            setStepStatus(nextStep, true);
            setShowFieldRequired(false);
            dispatch(changeStepperStep({ step: nextStep }));
            setFarStep((prevStep) => {
                if (stateStep > prevStep) return isValid ? nextStep : stateStep;
                return prevStep;
            });
        }
    };

    const handleStep = (step: number, reset = false): void => {
        const isValid = validation(componentState);
        if (!reset) {
            if (step > stateStep + 1 && isValid) {
                for (let i = stateStep + 1; i <= step; i++) {
                    if (i === step) {
                        setStepStatus(i, true);
                        setShowFieldRequired(false);
                        dispatch(changeStepperStep({ step: i }));
                        setFarStep((prevStep) => {
                            if (i > prevStep) return i;
                            return prevStep;
                        });
                    } else if (!steps[i].validation(steps[i].componentState)) {
                        setStepStatus(i, false);
                        setShowFieldRequired(true);
                        dispatch(changeStepperStep({ step: i }));
                        setFarStep((prevStep) => {
                            if (i > prevStep) return i;
                            return prevStep;
                        });
                        break;
                    } else {
                        setStepStatus(i, true);
                    }
                }
            } else if (step === stateStep + 1 && isValid) {
                setStepStatus(step, true);
                setShowFieldRequired(false);
                dispatch(changeStepperStep({ step }));
                setFarStep((prevStep) => {
                    if (step > prevStep) return step;
                    return prevStep;
                });
            } else if (step <= farStep + 1 && isValid) {
                setStepStatus(step, true);
                setShowFieldRequired(false);
                dispatch(changeStepperStep({ step }));
            } else if (step <= stateStep) {
                setStepStatus(step, isValid);
                setShowFieldRequired(false);
                dispatch(changeStepperStep({ step }));
            } else {
                setShowFieldRequired(true);
            }
        } else {
            setCompletedSteps({});
            setFarStep(0);
            dispatch(changeStepperStep({ step: 0 }));
        }
    };

    const disableStepBtns = (stepIndex: number): boolean => {
        if (stateStep === steps.length - 1) return true;
        if (stepIndex <= farStep + 1 && farValidation(componentState) && validation(componentState)) return false;
        if (stepIndex <= farStep && !farValidation(componentState) && validation(componentState)) return false;
        if (stepIndex === stateStep + 1 && validation(componentState)) return false;
        if (stepIndex <= stateStep) return false;
        return true;
    };

    const determineStepAction = async (
        final: () => void,
        secondary: (() => Promise<void>) | undefined,
        next: () => void
    ) => {
        if (steps.length - 1 === stateStep) {
            return final;
        }
        if (secondary && uniqueAction?.index && uniqueAction?.index === stateStep) {
            if (validation(componentState)) {
                return async () => {
                    await secondary();
                    next();
                };
            }

            setShowFieldRequired(true);
        }
        return next;
    };

    const determineStepName = (final: string, secondary: string | undefined, next: string) => {
        if (steps.length - 1 === stateStep) {
            return final;
        }
        if (uniqueAction?.index && uniqueAction?.index === stateStep) {
            return secondary;
        }
        return next;
    };
    const endAnimation = () => setValidationAnimation(false);
    return (
        <div className="Stepper">
            <Grid container>
                <Grid item xs={2} container>
                    <Grid item>
                        <Button color="primary" onClick={exitAction} className="ExitBtn">
                            <ArrowBackIosIcon fontSize="small" />
                            {`${exitButtonTitle}`}
                        </Button>
                        <div className="root StepBtnsWrapper">
                            <Stepper activeStep={stateStep} orientation="vertical">
                                {steps.map((step, index) => (
                                    <Step key={`${step.label} Step`}>
                                        <StepButton
                                            onClick={() => handleStep(index)}
                                            id={`Step${index}`}
                                            data-testid={`Step${index}`}
                                            disabled={disableStepBtns(index)}
                                            classes={{
                                                root:
                                                    stateStep === index
                                                        ? classes.stepButton && classes.activeStep
                                                        : classes.stepButton,
                                            }}
                                            completed={completedSteps[index]}
                                        >
                                            {step.label}
                                        </StepButton>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                    </Grid>
                </Grid>
                <Grid container xs={10} justify="center" item>
                    <Grid item>
                        <div className="Wizard">
                            <div className="Heading">
                                <h2 className="WizardTitleText">{steps[stateStep].label}</h2>
                            </div>
                            <Component
                                props={steps[stateStep].props}
                                showFieldRequired={showFieldRequired}
                                validationAnimation={validationAnimation}
                                endAnimation={endAnimation}
                                handleStep={handleStep}
                            />

                            <div className="Clearfix BtnSection" data-testid="actionButtonSection">
                                <div className="BtnSection__Left">
                                    {stateStep !== 0 && stateStep !== steps.length - 1 && (
                                        <Button
                                            id="BackBtn"
                                            color="primary"
                                            disabled={stateStep === 0}
                                            onClick={handleBack}
                                        >
                                            <ArrowBackIosIcon fontSize="small" />
                                            Back
                                        </Button>
                                    )}
                                </div>
                                <div className="BtnSection__Right">
                                    <Button
                                        id="ContinueBtn"
                                        data-testid="ContinueBtn"
                                        variant="contained"
                                        color="primary"
                                        onClick={async () => {
                                            (
                                                await determineStepAction(finalAction, uniqueAction?.action, handleNext)
                                            )();
                                        }}
                                    >
                                        {determineStepName(finalActionName, uniqueAction?.name, 'CONTINUE')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
export default CustomStepper;
