import React, { ReactElement } from 'react';

import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { useKeycloak } from '@react-keycloak/web';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLError } from 'graphql';

interface Props {
    children: ReactElement;
}

const TECHSUITE_ID = '173cb91b-794c-4cf6-ac54-f53890e8fb5c';

export function apolloErrorHandler(graphQLErrors: GraphQLError[]): void {
    graphQLErrors.forEach(({ message, locations, path }) => {
        const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
        // if (message === 'Invalid user' || message.includes('Access denied')) {
        //     // this error typically is when the token has expired...
        //     keycloak?.login();
        // }

        // eslint-disable-next-line no-console
        console.error(errorMessage);
    });
}

export function createApolloClient(token: string | undefined): ApolloClient<NormalizedCacheObject> {
    const getVersionNumber = new ApolloLink((operation, forward) => {
        return forward(operation).map((result) => {
            const { headers } = operation.getContext().response;
            const backendVersion = headers.get('x-version-backend');
            const backendEnvironment = headers.get('x-environment-backend');
            // eslint-disable-next-line no-console
            localStorage.setItem('x-version-backend', backendVersion);
            localStorage.setItem('x-environment-backend', backendEnvironment);
            return result;
        });
    });

    const link = ApolloLink.from([
        getVersionNumber,
        onError(({ graphQLErrors }) => {
            if (graphQLErrors) apolloErrorHandler([...graphQLErrors]);
        }),
        // note: this is a termination link, needs to be last:
        createUploadLink({
            uri: '/api',
            headers: {
                authorization: token ? `Bearer ${token}` : '',
                appId: TECHSUITE_ID,
            },
        }),
    ]);
    return new ApolloClient({
        link,
        cache: new InMemoryCache({
            addTypename: false,
        }),
    });
}

export default function Providers({ children }: Props): ReactElement {
    const { keycloak } = useKeycloak();
    const token = keycloak?.token;
    const apolloClient = createApolloClient(token);

    // if (initialized != null) {
    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
    // }
    // return <div>Initializing</div>;
}
