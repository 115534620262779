import React, { ReactElement } from 'react';

import { Grid, GridSize } from '@material-ui/core';

import { getPercentage } from 'components/utils/StringUtils';

import './AnalyticsStats.scss';

const AnalyticsStats = ({
    label,
    needsGridPadding,
    size,
    omitRightBorder,
    rightBorderWithExtraPadding,
    numerator,
    denominator,
    includeContactCount,
}: {
    label: string;
    needsGridPadding?: boolean;
    size: boolean | GridSize | undefined;
    omitRightBorder?: boolean;
    rightBorderWithExtraPadding?: boolean;
    includeContactCount?: boolean;
    numerator: number | null | undefined;
    denominator: number | null | undefined;
}): ReactElement => {
    return (
        <Grid md={size} item className={needsGridPadding ? 'AnalyticPadding' : undefined}>
            <div
                className={
                    // eslint-disable-next-line no-nested-ternary
                    rightBorderWithExtraPadding
                        ? 'AnalyticBorder NoLine'
                        : omitRightBorder
                        ? undefined
                        : 'AnalyticBorder'
                }
            >
                <p className="AnalyticLabel">{label}</p>
                <p className="AnalyticPercents">{getPercentage(numerator || 0, denominator || 0)}</p>
                {includeContactCount && <p className="AnalyticLabel">{`${numerator || 0} Contacts`}</p>}
            </div>
        </Grid>
    );
};

AnalyticsStats.defaultProps = {
    rightBorderWithExtraPadding: false,
    includeContactCount: true,
    omitRightBorder: false,
    needsGridPadding: true,
};
export default AnalyticsStats;
