import React, { ReactElement } from 'react';

import BaseUrl from 'components/utils/BaseUrl';
import { Campaign } from 'typings/Campaign';

const logo = {
    marginTop: '1em',
    height: '72pt',
    width: '220pt',
} as React.CSSProperties;

const logoHtml = {
    marginTop: '1em',
    marginBottom: '.5em',
    height: '70px',
    width: '250px',
} as React.CSSProperties;

const bannerImgCell = {
    width: '100%',
    height: '100%',
    textAlign: 'center',
} as React.CSSProperties;

const bannerImgTable = {
    width: '100%',
    height: '100%',
} as React.CSSProperties;

const bannerImgTableHtml = {
    width: '100%',
} as React.CSSProperties;
const tagline = {
    color: '#C4C8CC',
    fontSize: '16px',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    wordBreak: 'keep-all',
    overflowWrap: 'anywhere',
    margin: '0 10%',
    textAlign: 'center',
} as React.CSSProperties;

const NewsletterHeader = {
    color: 'white',
    fontSize: '20px',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    margin: '.5em 0 .25em 0',
    textAlign: 'center',
} as React.CSSProperties;

const tdLine = {
    fontSize: '0px',
    padding: '8pt 7.5%',
    wordBreak: 'break-word',
} as React.CSSProperties;

const line = {
    borderTop: 'solid 2pt #B48D35',
    fontSize: '1pt',
    margin: '0px auto',
    width: '100%',
} as React.CSSProperties;

const spacer5 = {
    height: '5',
    fontSize: '5px',
    lineHeight: '5px',
} as React.CSSProperties;

const NewsletterBannerImg = ({
    isHtml,
    newsletter,
}: {
    isHtml: boolean;
    newsletter: Campaign['template']['newsletter'];
}): ReactElement => {
    const baseUrl = BaseUrl();
    return (
        <>
            {isHtml &&
                `<!--[if mso | IE]>
                                                
                                                    <table className="spacer">
                                                        <tbody>
                                                            <tr>
                                                                <td style={${spacer5}} />
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                
                                                <![endif]-->`}
            <table className={isHtml ? 'row' : undefined} style={isHtml ? bannerImgTableHtml : bannerImgTable}>
                <tbody>
                    <tr>
                        <td style={bannerImgCell} align="center">
                            <a href="https://www.afsbirsttr.af.mil/" target=" ">
                                <img
                                    width={250}
                                    height={70}
                                    src={`${baseUrl}/images/templates/SBIR-STTR-New.png`}
                                    className={isHtml ? 'float-center' : undefined}
                                    style={isHtml ? logoHtml : logo}
                                    alt="SBIR/STTR Logo"
                                />
                            </a>
                            <p style={NewsletterHeader}>NEWSLETTER</p>
                            <p style={tagline}>{newsletter.tagline}</p>
                        </td>
                    </tr>
                    <tr>
                        <td style={tdLine}>
                            <p style={line}> </p>
                            {isHtml &&
                                `<!--[if mso | IE]>
                                                        <table
                                                        align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 2pt #B48D35;font-size:1pt;margin:0px auto;width:590pt;" role="presentation" width="590pt"
                                                        >
                                                        <tr>
                                                            <td style="height:0;line-height:0;">
                                                            &nbsp;
                                                            </td>
                                                        </tr>
                                                        </table>
                                                    <![endif]-->`}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};
export default NewsletterBannerImg;
