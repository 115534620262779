// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const checkBoxObjToString = (checkBoxCategory): string => {
    let checkBoxString = '';
    if (!checkBoxCategory) return '';
    Object.entries(checkBoxCategory).forEach((checkbox) => {
        if (checkbox[1] === true) checkBoxString = checkBoxString.concat(`${checkbox[0]},`);
    });
    checkBoxString = checkBoxString.trim().slice(0, -1);
    return checkBoxString;
};
export default checkBoxObjToString;
