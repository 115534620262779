import { gql } from '@apollo/client';

const GET_CONTACT = gql`
    query findContact($id: String!) {
        Contact: findContact(id: $id) {
            id
            emailAddress
            firstName
            lastName
            phoneNumber
            jobTitle
            companyName
            city
            state
            naicsCode
            dunsNumber
            companyCertifications
            techFocusAreas
        }
    }
`;

const GET_CONTACTS = gql`
    query findContacts($audienceId: Float!) {
        Contacts: findContacts(audienceId: $audienceId, active: true) {
            emailAddress
            firstName
            lastName
            phoneNumber
            jobTitle
            companyName
            city
            state
            naicsCode
            dunsNumber
            companyCertifications
            techFocusAreas
        }
    }
`;

const GET_CONTACTS_FROM_EMAIL = gql`
    query findContactsFromEmail($audienceId: Float!, $emailAddresses: [String!]!) {
        Contacts: findContactsFromEmail(audienceId: $audienceId, emailAddresses: $emailAddresses) {
            emailAddress
            firstName
            lastName
            phoneNumber
            jobTitle
            companyName
            city
            state
            naicsCode
            dunsNumber
            companyCertifications
            techFocusAreas
        }
    }
`;

const GET_CONTACT_FROM_EMAIL = gql`
    query findContactFromEmail($audienceId: Float!, $emailAddress: String!) {
        Contact: findContactFromEmail(audienceId: $audienceId, emailAddress: $emailAddress) {
            id
            status
            emailAddress
            companyName
            city
            state
            firstName
            lastName
            jobTitle
            dunsNumber
            naicsCode
            phoneNumber
            companyCertifications
            techFocusAreas
        }
    }
`;

const GET_CONTACTS_FROM_FILTERS = gql`
    query findContactsFromFilters($audienceId: Float!, $filters: String) {
        Contacts: findContactsFromFilters(audienceId: $audienceId, filters: $filters) {
            id
            status
            emailAddress
            firstName
            lastName
            phoneNumber
            jobTitle
            companyName
            city
            state
            naicsCode
            dunsNumber
        }
    }
`;

const GET_CONTACTS_FROM_FILTERS_AND_LIST = gql`
    query findContactsFromFiltersAndList($audienceId: Float!, $filters: String, $emailAddresses: [String!]) {
        Contacts: findContactsFromFiltersAndList(
            audienceId: $audienceId
            filters: $filters
            emailAddresses: $emailAddresses
        ) {
            id
            status
            emailAddress
            companyName
            city
            state
            firstName
            lastName
            jobTitle
            dunsNumber
            naicsCode
            phoneNumber
            companyCertifications
            techFocusAreas
        }
    }
`;

const GET_CONTACTS_FROM_FILTERS_COUNT = gql`
    query findContactsFromFiltersCount($audienceId: Float!, $filters: String) {
        Count: findContactsFromFiltersCount(audienceId: $audienceId, filters: $filters)
    }
`;

export {
    GET_CONTACT,
    GET_CONTACTS,
    GET_CONTACTS_FROM_EMAIL,
    GET_CONTACT_FROM_EMAIL,
    GET_CONTACTS_FROM_FILTERS,
    GET_CONTACTS_FROM_FILTERS_COUNT,
    GET_CONTACTS_FROM_FILTERS_AND_LIST,
};
