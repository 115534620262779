import { gql } from '@apollo/client';

const GET_CAMPAIGN = gql`
    query findCampaign($id: Float!) {
        Campaign: findCampaign(id: $id) {
            id
            subject
            senderEmail
            title
            isSent
            templateTypeId
            sendDate
            createdDate
            modifiedDate
            # campaignAnalytics {
            #     sentCount
            #     deliveredCount
            #     openedCount
            #     withClicksCount
            #     hardBounceCount
            #     complaintCount
            #     hasUnsubscribesCount
            #     unknownStatusCount
            #     modifiedDate
            # }
            # segments {
            #     id
            #     name
            # }
            campaignTemplateDatas {
                inputDescription
                inputValue
                groupNumber
            }
            # linkClicks {
            #     clickAddress
            #     clickCount
            #     uniqueClickCount
            # }
        }
    }
`;

const GET_CAMPAIGN_TIME_GRAPH = gql`
    query getTimeGraph($id: Float!) {
        TimeGraph: getTimeGraph(id: $id)
    }
`;

const GET_CAMPAIGN_BY_TITLE = gql`
    query findCampaignByTitle($title: String!) {
        campaignExists: findCampaignByTitle(title: $title)
    }
`;

const GET_CAMPAIGNS = gql`
    query findCampaigns($audienceId: Float!) {
        Campaigns: findCampaigns(audienceId: $audienceId) {
            id
            title
            isSent
            sendDate
            templateTypeId
            createdDate
            modifiedDate
            # campaignAnalytics {
            #     sentCount
            #     deliveredCount
            #     openedCount
            #     withClicksCount
            #     hardBounceCount
            #     complaintCount
            #     hasUnsubscribesCount
            #     unknownStatusCount
            #     modifiedDate
            # }
            campaignToSegmentAssocs {
                segment {
                    name
                    id
                }
            }
        }
    }
`;

const GET_CAMPAIGNS_WITH_COUNT = gql`
    query findCampaignsWithCount(
        $audienceId: Float!
        $filters: String
        $sort: SortInput
        $pagination: PaginationInput
    ) {
        CampaignsWithCountResponse: findCampaignsWithCount(
            audienceId: $audienceId
            filters: $filters
            sort: $sort
            pagination: $pagination
        ) {
            campaigns {
                id
                title
                isSent
                sendDate
                templateTypeId
                createdDate
                modifiedDate
                campaignAnalytics {
                    sentCount
                    deliveredCount
                    openedCount
                    withClicksCount
                    hardBounceCount
                    complaintCount
                    hasUnsubscribesCount
                    unknownStatusCount
                    modifiedDate
                }
                segments {
                    name
                    id
                }
            }
            nonPaginatedCount
        }
    }
`;
export { GET_CAMPAIGN, GET_CAMPAIGN_TIME_GRAPH, GET_CAMPAIGN_BY_TITLE, GET_CAMPAIGNS, GET_CAMPAIGNS_WITH_COUNT };
