import React, { ReactElement, useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, DialogContent, DialogContentText, Grid } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { CREATE_SEGMENT, UPDATE_SEGMENT } from 'api/mutations';
import { GET_CONTACTS_FROM_FILTERS_AND_LIST, GET_SEGMENT } from 'api/queries';
import TableOfContents from 'components/tableOfContents/TableOfContents';
import showSnackbarError from 'components/utils/ShowSnackbarError';
import ViewContactsTable from 'pageComponents/contact/viewContacts/ViewContactsTable';
import AdditionalContacts from 'pageComponents/segment/additionalContacts/AdditionalContacts';
import ListFilter from 'pageComponents/segment/filterContacts/ListFilter';
import SegmentFilterChips from 'pageComponents/segment/segmentFilterChips/SegmentFilterChips';
import SegmentNameFinalStep from 'pageComponents/segment/segmentNameFinalStep/SegmentNameFinalStep';
import { showDialog } from 'store/actions/Dialog';
import { showSnackbar } from 'store/actions/Snackbar';
import { FindSegmentSegment } from 'typings/_graphql';

import './CreateSegment.scss';

interface ParamType {
    id: string;
}

export const SegmentSummary = ({
    name,
    description,
    filters,
    numAdditionalContacts,
    numTotalContacts,
}: {
    name: string;
    description: string;
    filters: any;
    numAdditionalContacts: number;
    numTotalContacts: number;
}): JSX.Element => {
    const numFilters = filters[Object.keys(filters)[0]].length;
    return (
        <Grid className="ViewSummary">
            <DialogContentText className="ViewSummary__Subheaders">Segment Name</DialogContentText>
            <p className="ViewSummary__Text">{name}</p>
            <DialogContentText className="ViewSummary__Subheaders">Segment Description</DialogContentText>
            <p className="ViewSummary__Text">{description}</p>
            <TableOfContents label="Total Conditions" value={numFilters} labelWidth={3} subLabelWidth={10} />
            <div className="ViewSummary__FilterChips">
                <SegmentFilterChips filterQuery={filters} />
            </div>
            <TableOfContents label="Additional Contacts" value={numAdditionalContacts} labelWidth={3} />
            <TableOfContents label="Total Contacts" value={numTotalContacts} labelWidth={3} subLabelWidth={9} />
        </Grid>
    );
};

export default function CreateSegment(): JSX.Element {
    const { id } = useParams<ParamType>();
    const history = useHistory();
    const dispatch = useDispatch();

    const [segmentName, updateName] = useState('');
    const [segmentDescription, updateDescription] = useState('');
    const [invalid, setInvalid] = useState(true);
    const [additionalContacts, setAdditionalContacts] = useState<any[]>([]);
    const [matchType, setMatchType] = useState('Any');
    const [filterFieldList, setFilterFieldList] = useState<Array<any>>([]);

    const andOr = matchType === 'Any' ? 'or' : 'and';
    const segmentQuery = useQuery(GET_SEGMENT, { variables: { id: Number(id) } });

    useEffect(() => {
        if (segmentQuery.data) {
            const { name, description, filterQuery, contacts } = segmentQuery.data.Segment as FindSegmentSegment;
            const andOrCondition = Object.keys(filterQuery)[0];
            setMatchType(andOrCondition === 'and' ? 'All' : 'Any');
            setFilterFieldList(filterQuery[andOrCondition]);
            if (contacts) setAdditionalContacts([...contacts]);
            updateName(name);
            if (description) updateDescription(description);
        }
    }, [segmentQuery.data]);

    const contactsQuery = useQuery(GET_CONTACTS_FROM_FILTERS_AND_LIST, {
        variables: {
            audienceId: 1,
            filters: filterFieldList.length ? JSON.stringify({ [andOr]: filterFieldList }) : null,
            emailAddresses: additionalContacts.map((contact) => contact.emailAddress),
        },
    });
    const [createSegmentMutation] = useMutation(CREATE_SEGMENT);
    const [updateSegmentMutation] = useMutation(UPDATE_SEGMENT);

    const allUniqueContacts = contactsQuery.data?.Contacts || [];

    useEffect(() => {
        if (segmentName.length > 2 && (additionalContacts.length > 0 || filterFieldList.length > 0)) {
            setInvalid(false);
        } else {
            setInvalid(true);
        }
    }, [additionalContacts, segmentName, filterFieldList]);

    const createSegment = async () => {
        try {
            if (id) {
                await updateSegmentMutation({
                    variables: {
                        id: Number(id),
                        data: {
                            name: segmentName,
                            description: segmentDescription,
                            filterQuery: { [andOr]: filterFieldList },
                            // contacts: additionalContacts.map((contact) => ({ id: contact.id })),
                            isArchived: false,
                            audienceId: 1,
                        },
                    },
                    refetchQueries: () => ['findSegmentsWithCount'],
                });
                dispatch(showSnackbar(true, `Segment ${segmentName} was updated.`, 'success'));
            } else {
                await createSegmentMutation({
                    variables: {
                        data: {
                            name: segmentName,
                            description: segmentDescription,
                            filterQuery: { [andOr]: filterFieldList },
                            // contacts: additionalContacts.map((contact) => ({ id: contact.id })),
                            isArchived: false,
                            audienceId: 1,
                        },
                    },
                    refetchQueries: () => ['findSegmentsWithCount'],
                });
                dispatch(showSnackbar(true, `Segment ${segmentName} was created.`, 'success'));
            }
            dispatch(showDialog(false, '', undefined, undefined));
            history.push('/audience/segments');
        } catch (err) {
            showSnackbarError(dispatch, err);
        }
    };

    const viewSummary = () => {
        dispatch(
            showDialog(
                true,
                'Segment Summary',
                <SegmentSummary
                    name={segmentName}
                    description={segmentDescription}
                    filters={{ [andOr]: filterFieldList }}
                    numAdditionalContacts={additionalContacts.length}
                    numTotalContacts={allUniqueContacts.length}
                />,
                <Button
                    id="cancelButton"
                    color="primary"
                    variant="contained"
                    onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                >
                    CLOSE
                </Button>,
                'sm',
                true
            )
        );
    };

    const closeModal = () => {
        dispatch(
            showDialog(
                true,
                'Are you sure you want to exit this page?',
                <p className="UploadExitModalText">
                    If you exit now, this segment will not be created and progress will not be saved.
                </p>,
                <>
                    <Button
                        id="cancelButton"
                        color="primary"
                        onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                    >
                        NO, STAY ON PAGE
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            dispatch(showDialog(false, '', undefined, undefined));
                            history.push('/audience/segments');
                        }}
                    >
                        YES, EXIT
                    </Button>
                </>
            )
        );
    };

    return (
        <div className="SegmentCreator">
            <div>
                <Button color="primary" onClick={closeModal} className="ExitReportBtn">
                    <ArrowBackIosIcon fontSize="small" />
                    Exit Create Segment
                </Button>
            </div>
            <Grid container xs={8} className="box" justify="space-between">
                <Grid container item className="header" justify="space-between">
                    <Grid item>
                        <h4 className="Title">{id ? 'Update' : 'Create'} Segment</h4>
                    </Grid>
                    <Grid item className="ViewContactsBtnGrid">
                        <Button
                            id="openContactsBtn"
                            className="ViewContactsBtn"
                            onClick={() =>
                                dispatch(
                                    showDialog(
                                        true,
                                        'Audience Preview',
                                        <ViewContactsTable contactData={allUniqueContacts} />,
                                        <Button
                                            id="cancelButton"
                                            color="primary"
                                            onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                                        >
                                            CLOSE
                                        </Button>,
                                        'lg'
                                    )
                                )
                            }
                            disabled={isEmpty(allUniqueContacts)}
                            color="primary"
                        >
                            {`VIEW CONTACTS (${allUniqueContacts.length})`}
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div className="steps">
                        <ListFilter
                            matchType={matchType}
                            setMatchType={setMatchType}
                            filterFieldList={filterFieldList}
                            setFilterFieldList={setFilterFieldList}
                        />
                        <AdditionalContacts
                            additionalContacts={additionalContacts}
                            updateList={setAdditionalContacts}
                        />
                        <SegmentNameFinalStep
                            segmentName={segmentName}
                            updateName={(value) => updateName(value)}
                            segmentDescription={segmentDescription}
                            updateDescription={(value) => updateDescription(value)}
                        />
                    </div>
                    <Grid className="buttons" justify="flex-end">
                        <Button color="primary" variant="outlined" onClick={viewSummary}>
                            View Summary
                        </Button>
                        <Button color="primary" variant="contained" disabled={invalid} onClick={createSegment}>
                            {id ? 'Update' : 'Create'} Segment
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}
