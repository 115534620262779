import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { Badge, Box, IconButton } from '@material-ui/core';
import BusinessOutlinedIcon from '@material-ui/icons/Business';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';

import './NavIcons.scss';

interface NavIconProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'children'> {
    icon: string;
    iconTitle: string;
    children?: {
        title?: ReactNode;
        content?: ReactNode;
    };
    overlayOn: boolean;
    onOpen: () => void;
    badgeCount?: number;
    testId?: string;
    disableOverlay?: boolean;
}

export const NavIcon = ({
    icon,
    iconTitle,
    children,
    overlayOn,
    onOpen,
    badgeCount,
    testId,
    disableOverlay,
}: NavIconProps): ReactElement => {
    const [showOverlay, setShowOverlay] = useState(overlayOn);

    useEffect(() => {
        setShowOverlay(overlayOn);
    }, [overlayOn, setShowOverlay]);

    const toggleOverlay = (): void => {
        if (disableOverlay) {
            onOpen();
        } else {
            setShowOverlay(!showOverlay);
            if (onOpen && !showOverlay) {
                onOpen();
            }
        }
    };

    const getIcon = (typeOfIcon: string, isOverLayIcon: boolean) => {
        switch (typeOfIcon) {
            case 'signup':
                return (
                    <IconButton data-testid={testId} aria-label="signup" onClick={toggleOverlay}>
                        <>
                            <PersonAddOutlinedIcon style={{ color: 'white' }} />
                            <Box component="span" style={{ color: 'white' }} fontSize="14px">
                                Sign Up
                            </Box>
                        </>
                    </IconButton>
                );
            case 'signout':
                return (
                    <IconButton data-testid={testId} aria-label="signup" onClick={toggleOverlay}>
                        <>
                            <ExitToAppOutlinedIcon style={{ color: 'white' }} />
                            <Box component="span" style={{ color: 'white' }} fontSize="14px">
                                Sign Out
                            </Box>
                        </>
                    </IconButton>
                );
            case 'login':
                return (
                    <IconButton data-testid={testId} aria-label="login" onClick={toggleOverlay}>
                        <>
                            <ExitToAppOutlinedIcon style={{ color: 'white' }} />
                            <Box component="span" style={{ color: 'white' }} fontSize="14px">
                                Login
                            </Box>
                        </>
                    </IconButton>
                );
            case 'about':
                return (
                    <IconButton data-testid={testId} aria-label="about" onClick={toggleOverlay}>
                        <>
                            <InfoOutlinedIcon style={{ color: 'white' }} />
                            <Box component="span" style={{ color: 'white' }} fontSize="14px">
                                About
                            </Box>
                        </>
                    </IconButton>
                );
            case 'organization':
                return (
                    <IconButton data-testid={testId} aria-label="organization" onClick={toggleOverlay}>
                        {isOverLayIcon ? (
                            <BusinessOutlinedIcon color="primary" />
                        ) : (
                            <BusinessOutlinedIcon style={{ color: 'white' }} />
                        )}
                        {!isOverLayIcon && (
                            <Box component="span" style={{ color: 'white' }} fontSize="14px">
                                Organization
                            </Box>
                        )}
                    </IconButton>
                );
            case 'company':
                return (
                    <IconButton data-testid={testId} aria-label="company" onClick={toggleOverlay}>
                        {isOverLayIcon ? (
                            <BusinessCenterIcon color="primary" />
                        ) : (
                            <BusinessCenterOutlinedIcon style={{ color: 'white' }} />
                        )}
                        {!isOverLayIcon && (
                            <Box component="span" style={{ color: 'white' }} fontSize="14px">
                                Company
                            </Box>
                        )}
                    </IconButton>
                );
            case 'notification':
                return (
                    <IconButton data-testid={testId} aria-label="notifications" onClick={toggleOverlay}>
                        {badgeCount && badgeCount > 0 ? (
                            <>
                                <Badge badgeContent={badgeCount} color="primary" max={99}>
                                    <NotificationsOutlinedIcon style={{ color: 'white' }} />
                                </Badge>
                                <Box component="span" style={{ color: 'white' }} fontSize="14px">
                                    Notifications
                                </Box>
                            </>
                        ) : (
                            <>
                                <NotificationsOutlinedIcon style={{ color: 'white' }} />
                                <Box component="span" style={{ color: 'white' }} fontSize="14px">
                                    Notifications
                                </Box>
                            </>
                        )}
                    </IconButton>
                );
            case 'message':
                return (
                    <IconButton data-testid={testId} aria-label="notifications" onClick={toggleOverlay}>
                        {badgeCount && badgeCount > 0 ? (
                            <>
                                <Badge badgeContent={badgeCount} color="primary" max={99}>
                                    <ChatBubbleOutlineIcon style={{ color: 'white' }} />
                                </Badge>
                                <Box component="span" style={{ color: 'white' }} fontSize="14px">
                                    Messages
                                </Box>
                            </>
                        ) : (
                            <>
                                <ChatBubbleOutlineIcon style={{ color: 'white' }} />
                                <Box component="span" style={{ color: 'white' }} fontSize="14px">
                                    Messages
                                </Box>
                            </>
                        )}
                    </IconButton>
                );
            default:
                return (
                    <IconButton data-testid={testId} aria-label="profile" onClick={toggleOverlay}>
                        {isOverLayIcon ? (
                            <PersonIcon color="primary" />
                        ) : (
                            <PersonOutlineOutlinedIcon style={{ color: 'white' }} />
                        )}
                        {!isOverLayIcon && (
                            <Box component="span" style={{ color: 'white' }} fontSize="14px">
                                Profile
                            </Box>
                        )}
                    </IconButton>
                );
        }
    };

    return (
        <div className="icon">
            {getIcon(icon, false)}
            {showOverlay && (
                <div className="overlay" data-testid="icon-overlay">
                    <div className="overlay_top">
                        <div className="title">{children?.title || iconTitle}</div>
                        <div>{getIcon(icon, true)}</div>
                    </div>
                    <div className="overlay_content">{children?.content}</div>
                </div>
            )}
        </div>
    );
};

NavIcon.defaultProps = {
    badgeCount: null,
    testId: null,
    disableOverlay: false,
    children: null,
};

export default NavIcon;
