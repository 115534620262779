import React, { ReactElement, useRef, useState } from 'react';

import { Button, DialogContentText, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Event from 'assets/images/event-icon.png';
import Newsletter from 'assets/images/newsletter-icon.png';
import Press from 'assets/images/press-icon.png';
import { CAMPAIGN_PAGE, TEMPLATE_TYPES } from 'components/utils/GlobalConstants';
import { templateTypeEnumToString } from 'components/utils/StringUtils';
import { selectTemplate } from 'store/actions/Campaign';
import { showDialog } from 'store/actions/Dialog';
import initialState from 'store/initialState';
import { Store } from 'store/reducers';

import './SelectTemplate.scss';

const useStyles = makeStyles({
    dialogImportantText: {
        color: '#07090c',
        fontWeight: 600,
    },
    dialogNormalText: {
        color: '#000',
    },
});

const SelectTemplate = ({
    showFieldRequired,
    validationAnimation,
    endAnimation,
    handleStep,
}: {
    showFieldRequired: boolean;
    validationAnimation: boolean;
    endAnimation: () => void;
    handleStep: (step: number) => void;
}): ReactElement => {
    const formId = 'changeCampaignTemplateForm';
    const { NEWSLETTER, PRESS, EVENT } = TEMPLATE_TYPES;
    const { SELECT_TEMPLATE } = CAMPAIGN_PAGE;
    const campaign = useSelector((storeState: Store) => storeState.Campaign);
    const { template } = campaign;
    const [currentTemplate, setCurrentTemplate] = useState<string>(templateTypeEnumToString(template.type));
    const prevSelectedTemplate = useRef<string>('');
    const targetTemplate = useRef<string>('');
    const { handleSubmit } = useForm();
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleTemplateChange = () => {
        prevSelectedTemplate.current = currentTemplate;
        setCurrentTemplate(targetTemplate.current);
        handleStep(SELECT_TEMPLATE);
        dispatch(selectTemplate({ name: targetTemplate.current }));
        dispatch(showDialog(false, '', undefined, undefined));
    };

    const ModalContent = (): ReactElement => {
        return (
            <form data-testid={formId} id={formId} onSubmit={handleSubmit(handleTemplateChange)}>
                <DialogContentText className={classes.dialogNormalText}>
                    If you change the campaign template now, current template content may be lost.
                </DialogContentText>
                <DialogContentText className={classes.dialogImportantText}>
                    Are you sure you want to change the campaign template?
                </DialogContentText>
            </form>
        );
    };

    const templateSelect = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        targetTemplate.current = (e.currentTarget as HTMLButtonElement).name;

        if (
            (JSON.stringify(template.event) !== JSON.stringify(initialState.template.event) ||
                JSON.stringify(template.newsletter) !== JSON.stringify(initialState.template.newsletter) ||
                JSON.stringify(template.press) !== JSON.stringify(initialState.template.press)) &&
            currentTemplate !== targetTemplate.current &&
            currentTemplate !== ''
        ) {
            dispatch(
                showDialog(
                    true,
                    'NOTICE:',
                    ModalContent(),
                    <>
                        <Button
                            id="cancelButton"
                            color="primary"
                            onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                        >
                            CANCEL
                        </Button>
                        <Button type="submit" variant="contained" color="primary" form={formId}>
                            CHANGE THE TEMPLATE
                        </Button>
                    </>
                )
            );
            setCurrentTemplate(targetTemplate.current);
        } else {
            dispatch(selectTemplate({ name: targetTemplate.current }));
        }
    };

    return (
        <div className="CampaignDetails">
            <h3 className="title">Select a template for the campaign</h3>
            {showFieldRequired && (
                <p
                    className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                    onAnimationEnd={endAnimation}
                >
                    * Please select a template
                </p>
            )}
            <div className="TemplateBtnContainer">
                <Grid container spacing={4} justify="space-evenly">
                    <Grid item xs className="TemplateBtnGrid">
                        <button
                            title="event"
                            className={template.type === EVENT ? 'TemplateButton TemplateSelected' : 'TemplateButton'}
                            value={template.type}
                            name="event"
                            type="button"
                            onClick={templateSelect}
                        >
                            <img src={Event} alt="event template" />
                            <p className={template.type === EVENT ? 'TemplateName Selected' : 'TemplateName'}>Event</p>
                        </button>
                    </Grid>
                    <Grid item xs className="TemplateBtnGrid">
                        <button
                            title="newsletter"
                            className={
                                template.type === NEWSLETTER ? 'TemplateButton TemplateSelected' : 'TemplateButton'
                            }
                            value={template.type}
                            name="newsletter"
                            type="button"
                            onClick={templateSelect}
                        >
                            <img src={Newsletter} alt="newsletter template" />
                            <p className={template.type === NEWSLETTER ? 'TemplateName Selected' : 'TemplateName'}>
                                Newsletter
                            </p>
                        </button>
                    </Grid>
                    <Grid item xs className="TemplateBtnGrid">
                        <button
                            title="press"
                            className={template.type === PRESS ? 'TemplateButton TemplateSelected' : 'TemplateButton'}
                            value={template.type}
                            name="press"
                            type="button"
                            onClick={templateSelect}
                        >
                            <img src={Press} alt="press template" />
                            <p className={template.type === PRESS ? 'TemplateName Selected' : 'TemplateName'}>Press</p>
                        </button>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
export default SelectTemplate;
