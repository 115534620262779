import React, { forwardRef, useEffect } from 'react';

import { Checkbox } from '@material-ui/core';

interface RowCheckboxProps {
    indeterminate?: boolean;
}

const useCombinedRefs = (...refs): React.MutableRefObject<HTMLInputElement | null> => {
    const targetRef = React.useRef(null);

    React.useEffect(() => {
        refs.forEach((ref) => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                // eslint-disable-next-line no-param-reassign
                ref.current = targetRef.current;
            }
        });
    }, [refs]);

    return targetRef;
};

const IndeterminateCheckbox = forwardRef<HTMLInputElement, RowCheckboxProps>(
    ({ indeterminate, ...rest }, ref: React.Ref<HTMLInputElement>) => {
        const defaultRef = React.useRef(null);
        const combinedRef = useCombinedRefs(ref, defaultRef);

        useEffect(() => {
            if (combinedRef?.current) {
                combinedRef.current.indeterminate = indeterminate ?? false;
            }
        }, [combinedRef, indeterminate]);

        return (
            <Checkbox
                color="primary"
                className="Checkbox"
                inputRef={combinedRef}
                {...rest}
                indeterminate={indeterminate}
            />
        );
    }
);

IndeterminateCheckbox.defaultProps = {
    indeterminate: false,
};

export default IndeterminateCheckbox;
