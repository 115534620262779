// const normalizeConcatString = str => str.split(/[-_]/).join(" ");

const formatStringToCamelCase = (str): string => {
    const splitted = str.split('-');
    if (splitted.length === 1) return splitted[0];
    return (
        splitted[0] +
        splitted
            .slice(1)
            .map((word) => word[0].toUpperCase() + word.slice(1))
            .join('')
    );
};

export const templateTypeEnumToString = (templateType: number): string => {
    let templateTypeString = '';
    if (templateType === 1) templateTypeString = 'newsletter';
    else if (templateType === 2) templateTypeString = 'event';
    else if (templateType === 3) templateTypeString = 'press';
    return templateTypeString;
};

export const getPercentage = (numerator = 0, denominator = 0): string => {
    return `${denominator ? Math.round((numerator / denominator) * 100) : 0}%`;
};

const getStyleObjectFromString = (str: string): Record<string, unknown> => {
    const style = {};
    str.split(';').forEach((el) => {
        const [property, value] = el.split(':');
        if (!property) return;

        const formattedProperty = formatStringToCamelCase(property.trim());
        style[formattedProperty] = value.trim();
    });

    return style;
};
export default getStyleObjectFromString;
