import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Button, Grid, Switch, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { GET_CAMPAIGN } from 'api/queries';
import { getTimePassedFromDate } from 'components/utils/DateUtil';
import CampaignReportStats, { ParamType } from 'pageComponents/campaign/reports/campaignStats/CampaignReportStats';
import CampaignReportHeader from 'pageComponents/campaign/reports/header/CampaignReportHeader';
import CampaignReportLinkDetails from 'pageComponents/campaign/reports/linkDetails/CampaignReportLinkDetails';
import TimeHistoryGraphSim from 'pageComponents/campaign/reports/timeHistoryGraph/TimeHistoryGraphSim';
import CampaignTemplate from 'pageComponents/emailTemplates/CampaignTemplate';
import { setCampaignState } from 'store/actions/Campaign';
import { Store } from 'store/reducers';
import { FindCampaign } from 'typings/_graphql';

import './CampaignReport.scss';

interface TabPanelProps {
    children: React.ReactNode;
    index: string | number;
    value: string | number;
}

function TabPanel(props: TabPanelProps): JSX.Element {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
}

function a11yProps(index: string | number): Record<string, unknown> {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useTabStyles = makeStyles({
    root: {
        fontSize: '0.9rem',
        fontWeight: 600,
        textTransform: 'none',
    },
});

const CampaignReport = (): JSX.Element => {
    const tabClasses = useTabStyles();
    const { id } = useParams<ParamType>();
    const [value, setValue] = useState(0);
    const [showGraph, setShowGraph] = useState<boolean>(false);
    const campaign = useSelector((storeState: Store) => storeState.Campaign);
    const dispatch = useDispatch();
    const history = useHistory();

    const [campaignQuery, { data }] = useLazyQuery(GET_CAMPAIGN, {
        variables: { id: Number(id) },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (!campaign.id) {
            campaignQuery();
        }
    }, [id, campaign, dispatch, campaignQuery]);

    useEffect(() => {
        if (data) {
            const { Campaign }: FindCampaign = data;
            dispatch(setCampaignState({ campaign: Campaign }));
        }
    }, [campaign.campaignAnalytics, data, dispatch]);

    const handleChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className="CampaignReport">
            <Button color="primary" onClick={() => history.push('/campaign')} className="ExitReportBtn">
                <ArrowBackIosIcon fontSize="small" />
                Exit Campaign Report
            </Button>
            <div className="CampaignReport__Body">
                <CampaignReportHeader />
                <div className="AnalyticsContainer">
                    <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
                        <Tab className={tabClasses.root} label="ANALYTICS" {...a11yProps(0)} />
                        <Tab className={tabClasses.root} label="EMAIL CONTENT" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Grid container>
                            <Grid
                                component="label"
                                container
                                item
                                alignItems="center"
                                spacing={1}
                                className="SwitchContainer"
                                xs={6}
                            >
                                <Grid item>Campaign Breakdown</Grid>
                                <Grid item>
                                    <Switch
                                        checked={showGraph}
                                        onChange={() => {
                                            setShowGraph(!showGraph);
                                        }}
                                        value="checked" // some value you need
                                        color="primary"
                                        className="AnalyticsContainer__ToggleSwitch"
                                    />
                                </Grid>
                                <Grid item>Time History</Grid>
                            </Grid>
                            <Grid xs={6} item className="RecentActivityContainer">
                                <p className="RecentActivityText">{`Most Recent Activity ${getTimePassedFromDate(
                                    campaign?.campaignAnalytics?.modifiedDate
                                )}`}</p>
                            </Grid>
                        </Grid>
                        {showGraph ? <TimeHistoryGraphSim /> : <CampaignReportStats />}
                        <CampaignReportLinkDetails />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <CampaignTemplate
                            isHtml={false}
                            event={campaign.template.event}
                            newsletter={campaign.template.newsletter}
                            press={campaign.template.press}
                            type={campaign.template.type}
                        />
                    </TabPanel>
                </div>
            </div>
        </div>
    );
};

export default CampaignReport;
