import React, { ReactElement } from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';

import { TEMPLATE_TYPES, validateURL } from 'components/utils/GlobalConstants';
import { updateArticleCount, updateEvent, updateNewsletter, updatePress } from 'store/actions/Campaign';
import initialState from 'store/initialState';
import { Store } from 'store/reducers/index';
import { DateInput, Input, PhoneInput, TextAreaInput, TimeInput, URLInput } from './formComponents';

import './InputForm.scss';

const InputForm = ({
    showFieldRequired,
    validationAnimation,
    endAnimation,
}: {
    showFieldRequired: boolean;
    validationAnimation: boolean;
    endAnimation: () => void;
}): ReactElement => {
    const dispatch = useDispatch();
    const campaign = useSelector((storeState: Store) => storeState.Campaign);

    const { NEWSLETTER, EVENT, PRESS } = TEMPLATE_TYPES;
    const { newsletter, event, press } = campaign.template;
    const { campaignName } = campaign.campaignDetails;
    const articleCountArray = Array.from(Array(newsletter.articleCount), (x, index) => index + 1);

    const handleNewsletter = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { name, value, id: index } = e.target;

        dispatch(updateNewsletter({ name, value, index }));
    };
    const handleEvent = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { name, value } = e.target;

        dispatch(updateEvent({ name, value }));
    };
    const handleNewsletterCount = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        const { name } = e.currentTarget as HTMLButtonElement;

        dispatch(updateArticleCount({ name }));
    };
    const handlePress = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        const { name, value } = e.target;

        dispatch(updatePress({ name, value }));
    };
    const handlePressPhone = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target;

        const re = /^[0-9\b]{1,10}$/;
        // if value is not blank, then test the regex
        if (re.test(value)) {
            dispatch(updatePress({ name, value }));
        }
    };
    const resetPhone = (): void => {
        const contactNum = 'contactNum';
        dispatch(updatePress({ name: contactNum, value: '' }));
    };

    const handleDate = (date: Date | null) => {
        const value = `${date}`;
        const name = 'date';

        dispatch(updateNewsletter({ name, value, index: '0' }));
        dispatch(updatePress({ name, value }));
        dispatch(updateEvent({ name, value }));
    };

    return (
        <div className="InputForm">
            <Grid container justify="flex-end" spacing={2}>
                <Grid item xs>
                    <p className="CampaignName">{campaignName}</p>
                </Grid>
            </Grid>
            {campaign.template.type === NEWSLETTER && (
                <div className="NewsletterForm">
                    <div className="ArticleBtns" data-toggle="buttons">
                        <Button
                            className="AddBtn"
                            variant="contained"
                            color="primary"
                            name="add"
                            id="option1"
                            onClick={handleNewsletterCount}
                            value={newsletter.articleCount}
                        >
                            Add 2 Articles
                        </Button>
                        <Button
                            className="RemoveBtn"
                            variant="outlined"
                            color="primary"
                            name="remove"
                            id="option3"
                            onClick={handleNewsletterCount}
                            value={newsletter.articleCount}
                        >
                            Remove 2 Articles
                        </Button>
                    </div>
                    <p className="ArticleLabel">Header</p>
                    <Input
                        name="tagline"
                        onChange={handleNewsletter}
                        maxChar={70}
                        label="Tagline"
                        className={
                            showFieldRequired &&
                            (newsletter.tagline === initialState.template.newsletter.tagline ||
                                newsletter.tagline === '')
                                ? 'Newsletter Validation'
                                : 'Newsletter'
                        }
                        id="tagline"
                        value={newsletter.tagline}
                    />
                    {showFieldRequired &&
                        (newsletter.tagline === initialState.template.newsletter.tagline ||
                            newsletter.tagline === '') && (
                            <p
                                className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                                onAnimationEnd={endAnimation}
                            >
                                *Fill in Missing Field
                            </p>
                        )}
                    <DateInput
                        onChange={handleDate}
                        label="Newsletter Date"
                        id="date"
                        className={
                            showFieldRequired &&
                            (newsletter.date === initialState.template.newsletter.date || newsletter.date === '')
                                ? 'Validation'
                                : ''
                        }
                        value={newsletter.date}
                    />
                    {showFieldRequired &&
                        (newsletter.date === initialState.template.newsletter.date || newsletter.date === '') && (
                            <p
                                className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                                onAnimationEnd={endAnimation}
                            >
                                *Fill in Missing Field
                            </p>
                        )}
                    <TextAreaInput
                        name="welcomeMessage"
                        onChange={handleNewsletter}
                        maxChar={300}
                        label="Welcome Message"
                        className={
                            showFieldRequired &&
                            (newsletter.welcomeMessage === initialState.template.newsletter.welcomeMessage ||
                                newsletter.welcomeMessage === '')
                                ? 'Newsletter Validation'
                                : 'Newsletter'
                        }
                        id="welcomeMessage"
                        value={newsletter.welcomeMessage}
                    />
                    {showFieldRequired &&
                        (newsletter.welcomeMessage === initialState.template.newsletter.welcomeMessage ||
                            newsletter.welcomeMessage === '') && (
                            <p
                                className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                                onAnimationEnd={endAnimation}
                            >
                                *Fill in Missing Field
                            </p>
                        )}
                    {articleCountArray.map((articleNum, index) => (
                        <div className="ArticleInput">
                            <p className="ArticleLabel">Article {articleNum}</p>
                            <Input
                                name="title"
                                onChange={handleNewsletter}
                                maxChar={70}
                                label="Article Title"
                                className={
                                    showFieldRequired &&
                                    (newsletter.articles[index].title ===
                                        initialState.template.newsletter.articles[index].title ||
                                        newsletter.articles[index].title === '')
                                        ? 'Newsletter Validation'
                                        : 'Newsletter'
                                }
                                id={index}
                                key={`title${index}`}
                                value={newsletter.articles[index].title}
                                showCharRemaining
                            />
                            {showFieldRequired &&
                                (newsletter.articles[index].title ===
                                    initialState.template.newsletter.articles[index].title ||
                                    newsletter.articles[index].title === '') && (
                                    <p
                                        className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                                        onAnimationEnd={endAnimation}
                                    >
                                        *Fill in Missing Field
                                    </p>
                                )}
                            <TextAreaInput
                                name="preview"
                                onChange={handleNewsletter}
                                maxChar={200}
                                label="Article Preview"
                                className={
                                    showFieldRequired &&
                                    (newsletter.articles[index].preview ===
                                        initialState.template.newsletter.articles[index].preview ||
                                        newsletter.articles[index].preview === '')
                                        ? 'Newsletter Validation'
                                        : 'Newsletter'
                                }
                                id={index}
                                key={`preview${index}`}
                                value={newsletter.articles[index].preview}
                            />
                            {showFieldRequired &&
                                (newsletter.articles[index].preview ===
                                    initialState.template.newsletter.articles[index].preview ||
                                    newsletter.articles[index].preview === '') && (
                                    <p
                                        className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                                        onAnimationEnd={endAnimation}
                                    >
                                        *Fill in Missing Field
                                    </p>
                                )}
                            <URLInput
                                name="articleURL"
                                onChange={handleNewsletter}
                                label="Article Link"
                                className="Newsletter"
                                id={index}
                                key={`articleURL${index}`}
                                value={newsletter.articles[index].articleURL}
                                showUrlMessage
                            />
                            {!validateURL(newsletter.articles[index].articleURL) && (
                                <p className="MissingField">*Invalid Format</p>
                            )}
                            <URLInput
                                name="imageURL"
                                onChange={handleNewsletter}
                                label="Article Image"
                                className="Newsletter"
                                id={index}
                                key={`imageURL${index}`}
                                value={newsletter.articles[index].imageURL}
                                showUrlMessage
                                imageRatioWidth={1.5}
                            />
                            {!validateURL(newsletter.articles[index].imageURL) && (
                                <p className="MissingField">*Invalid Format</p>
                            )}
                        </div>
                    ))}
                </div>
            )}
            {campaign.template.type === EVENT && (
                <div className="EventForm">
                    <p className="ArticleLabel">Header</p>
                    <Input
                        name="name"
                        onChange={handleEvent}
                        maxChar={26}
                        label="Event Name"
                        className={
                            showFieldRequired && (event.name === initialState.template.event.name || event.name === '')
                                ? 'Event Validation'
                                : 'Event'
                        }
                        id="eventTitle"
                        value={event.name}
                        showCharRemaining
                    />
                    {showFieldRequired && (event.name === initialState.template.event.name || event.name === '') && (
                        <p
                            className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                            onAnimationEnd={endAnimation}
                        >
                            *Fill in Missing Field
                        </p>
                    )}
                    <Input
                        name="location"
                        onChange={handleEvent}
                        maxChar={40}
                        label="Event Location"
                        className={
                            showFieldRequired &&
                            (event.location === initialState.template.event.location || event.location === '')
                                ? 'Event Validation'
                                : 'Event'
                        }
                        id="eventLocation"
                        value={event.location}
                        showCharRemaining
                    />
                    {showFieldRequired &&
                        (event.location === initialState.template.event.location || event.location === '') && (
                            <p
                                className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                                onAnimationEnd={endAnimation}
                            >
                                *Fill in Missing Field
                            </p>
                        )}
                    <p className="ArticleLabel">Image</p>
                    <URLInput
                        name="imageURL"
                        onChange={handleEvent}
                        label="Event Image"
                        className={showFieldRequired && event.imageURL === '' ? 'Event Validation' : 'Event'}
                        id="image_url"
                        value={event.imageURL}
                        showUrlMessage
                    />
                    {showFieldRequired && event.imageURL === '' && (
                        <p
                            className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                            onAnimationEnd={endAnimation}
                        >
                            *Fill in Missing Field
                        </p>
                    )}
                    {!validateURL(event.imageURL) && <p className="MissingField">*Invalid Format</p>}

                    <p className="ArticleLabel">Body</p>
                    <TextAreaInput
                        name="description"
                        onChange={handleEvent}
                        label="Event Description"
                        className={
                            showFieldRequired &&
                            (event.description === initialState.template.event.description || event.description === '')
                                ? 'Event Validation'
                                : 'Event'
                        }
                        maxChar={800}
                        id="eventDescription"
                        value={event.description}
                    />
                    {showFieldRequired &&
                        (event.description === initialState.template.event.description || event.description === '') && (
                            <p
                                className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                                onAnimationEnd={endAnimation}
                            >
                                *Fill in Missing Field
                            </p>
                        )}
                    <TimeInput
                        value={event.time}
                        onChange={handleEvent}
                        label="Time of Event"
                        id="time"
                        className={showFieldRequired && event.time === '' ? 'Event Validation' : 'Event'}
                    />
                    {showFieldRequired && event.time === '' && (
                        <p
                            className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                            onAnimationEnd={endAnimation}
                        >
                            *Fill in Missing Field
                        </p>
                    )}
                    <DateInput
                        value={event.date}
                        onChange={handleDate}
                        label="Date of Event"
                        id="date"
                        className={showFieldRequired && event.date === '' ? 'Event Validation' : 'Event'}
                    />
                    {showFieldRequired && event.date === '' && (
                        <p
                            className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                            onAnimationEnd={endAnimation}
                        >
                            *Fill in Missing Field
                        </p>
                    )}

                    <URLInput
                        name="url"
                        onChange={handleEvent}
                        label="Event Link"
                        className={
                            showFieldRequired && (event.url === initialState.template.event.url || event.url === '')
                                ? 'Event Validation'
                                : 'Event'
                        }
                        id="event_url"
                        value={event.url}
                        showUrlMessage
                    />
                    {showFieldRequired && (event.url === initialState.template.event.url || event.url === '') && (
                        <p
                            className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                            onAnimationEnd={endAnimation}
                        >
                            *Fill in Missing Field
                        </p>
                    )}
                    {!validateURL(event.url) && <p className="MissingField">*Invalid Format</p>}
                </div>
            )}
            {campaign.template.type === PRESS && (
                <div className="PressForm">
                    <p className="ArticleLabel">Header</p>
                    <DateInput
                        value={press.date}
                        onChange={handleDate}
                        label="Press Release Date"
                        id="date"
                        className={
                            showFieldRequired && (press.date === initialState.template.press.date || press.date === '')
                                ? 'Press Validation Date'
                                : 'Press Date'
                        }
                    />
                    {showFieldRequired && (press.date === initialState.template.press.date || press.date === '') && (
                        <p
                            className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                            onAnimationEnd={endAnimation}
                        >
                            *Fill in Missing Field
                        </p>
                    )}
                    <Input
                        name="headline"
                        onChange={handlePress}
                        label="Headline"
                        className={
                            showFieldRequired &&
                            (press.headline === initialState.template.press.headline || press.headline === '')
                                ? 'Press Validation'
                                : 'Press'
                        }
                        id="Headline"
                        value={press.headline}
                    />
                    {showFieldRequired &&
                        (press.headline === initialState.template.press.headline || press.headline === '') && (
                            <p
                                className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                                onAnimationEnd={endAnimation}
                            >
                                *Fill in Missing Field
                            </p>
                        )}
                    <Input
                        name="subheadline"
                        onChange={handlePress}
                        label="Sub-headline"
                        className={
                            showFieldRequired &&
                            (press.subheadline === initialState.template.press.subheadline || press.subheadline === '')
                                ? 'Press Validation'
                                : 'Press'
                        }
                        id="Sub-Headline"
                        value={press.subheadline}
                    />
                    {showFieldRequired &&
                        (press.subheadline === initialState.template.press.subheadline || press.subheadline === '') && (
                            <p
                                className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                                onAnimationEnd={endAnimation}
                            >
                                *Fill in Missing Field
                            </p>
                        )}
                    <p className="ArticleLabel">Image</p>
                    <URLInput
                        name="imageURL"
                        onChange={handlePress}
                        label="Press Image"
                        className={!validateURL(press.imageURL) ? 'Press Validation' : 'Press'}
                        id="pressImageURL"
                        value={press.imageURL}
                        showUrlMessage
                        imageRatioWidth={2}
                    />
                    {!validateURL(press.imageURL) && <p className="MissingField">*Invalid Format</p>}
                    <p className="ArticleLabel">Body</p>
                    <TextAreaInput
                        name="description"
                        onChange={handlePress}
                        maxChar={7500}
                        label="Description"
                        className={
                            showFieldRequired &&
                            (press.description === initialState.template.press.description || press.description === '')
                                ? 'Press Validation'
                                : 'Press'
                        }
                        id="pressDescription"
                        value={press.description}
                    />
                    {showFieldRequired &&
                        (press.description === initialState.template.press.description || press.description === '') && (
                            <p
                                className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                                onAnimationEnd={endAnimation}
                            >
                                *Fill in Missing Field
                            </p>
                        )}

                    <p className="ArticleLabel">Contact Info</p>
                    <Input
                        name="contactName"
                        onChange={handlePress}
                        label="Contact Name"
                        className={
                            showFieldRequired &&
                            (press.contactName === initialState.template.press.contactName || press.contactName === '')
                                ? 'Press Contact Validation'
                                : 'Press Contact'
                        }
                        id="ContactName"
                        value={press.contactName}
                    />
                    {showFieldRequired &&
                        (press.contactName === initialState.template.press.contactName || press.contactName === '') && (
                            <p
                                className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                                onAnimationEnd={endAnimation}
                            >
                                *Fill in Missing Field
                            </p>
                        )}
                    <Input
                        name="contactEmail"
                        onChange={handlePress}
                        label="Contact Email"
                        className={
                            showFieldRequired &&
                            (press.contactEmail === initialState.template.press.contactEmail ||
                                press.contactEmail === '')
                                ? 'Press Contact Validation'
                                : 'Press Contact'
                        }
                        id="ContactEmail"
                        value={press.contactEmail}
                    />
                    {showFieldRequired &&
                        (press.contactEmail === initialState.template.press.contactEmail ||
                            press.contactEmail === '') && (
                            <p
                                className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                                onAnimationEnd={endAnimation}
                            >
                                *Fill in Missing Field
                            </p>
                        )}
                    <PhoneInput
                        name="contactNum"
                        onChange={handlePressPhone}
                        label="Contact Phone"
                        id="ContactNum"
                        className={
                            showFieldRequired &&
                            (press.contactNum === initialState.template.press.contactNum || press.contactNum === '')
                                ? 'Press Contact Validation'
                                : 'Press Contact'
                        }
                        maxChar={10}
                        type="string"
                        value={String(press.contactNum)}
                    />
                    {showFieldRequired &&
                        (press.contactNum === initialState.template.press.contactNum || press.contactNum === '') && (
                            <p
                                className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                                onAnimationEnd={endAnimation}
                            >
                                *Fill in Missing Field
                            </p>
                        )}
                    <Button
                        onClick={resetPhone}
                        name="contactNum"
                        title="resetBtn"
                        value=""
                        color="primary"
                        variant="outlined"
                        id="resetPhoneBtn"
                    >
                        Clear Contact Number
                    </Button>
                </div>
            )}
        </div>
    );
};
export default InputForm;
