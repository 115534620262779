/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ReactElement } from 'react';

const articleContainer = { borderCollapse: 'collapse', width: '100%' } as React.CSSProperties;

const tileStyleLeft = {
    textAlign: 'left',
    padding: '0 .5em 0 8%',
    display: 'inline',
} as React.CSSProperties;

const tileStyleLeftHtml = {
    textAlign: 'left',
    margin: '0 .5em 0 1.5em',
    padding: '0 .5em 0 3em',
    width: '50%',
    display: 'inline',
} as React.CSSProperties;

const tileStyleRight = {
    textAlign: 'left',
    padding: '0 6% 0 1.5em',
    display: 'inline',
} as React.CSSProperties;

const tileStyleRightHtml = {
    margin: '0 2em 0 1.5em',
    textAlign: 'left',
    padding: '0 2em 0 1.5em',
    width: '50%',
    display: 'inline',
} as React.CSSProperties;

const articleTitleStyle = {
    maxWidth: '300px',
    color: '#ffffff',
    fontSize: '20px',
    marginBottom: '.5em',
    marginTop: '.5em',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    overflowWrap: 'anywhere',
    width: '97%',
    lineHeight: '1.3',
    wordBreak: 'keep-all',
    fontWeight: 'bold',
} as React.CSSProperties;

const articleTitleStyleHtml = {
    maxWidth: '300px',
    color: '#ffffff',
    fontSize: '20px',
    marginBottom: '1em',
    marginTop: '1em',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    overflowWrap: 'anywhere',
    width: '97%',
    lineHeight: '1.3',
    wordBreak: 'keep-all',
    fontWeight: 'bold',
} as React.CSSProperties;

const articleBodyStyle = {
    maxWidth: '345px',
    fontSize: '14px',
    marginTop: '1em',
    width: '97%',
    overflowWrap: 'anywhere',
    marginBottom: '.75em',
    color: '#DCDEE0',
    lineHeight: '1.5',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    wordBreak: 'keep-all',
    fontWeight: 350,
} as React.CSSProperties;

const articleBodyStyleHtml = {
    maxWidth: '300px',
    fontSize: '14px',
    marginTop: '2em',
    width: '97%',
    overflowWrap: 'anywhere',
    marginBottom: '1em',
    color: '#DCDEE0',
    msoLineHeightRule: 'exactly',
    lineHeight: '150%',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    wordBreak: 'keep-all',
    fontWeight: 350,
} as React.CSSProperties;

const articleRow = { display: 'flex' } as React.CSSProperties;

const imgLinks = { marginTop: '.5em' } as React.CSSProperties;

const tileImgStyle = {
    width: '95%',
    height: '140px',
    position: 'relative',
} as React.CSSProperties;

const tileImgStyleHtml = {
    width: '95%',
    height: '190px',
    position: 'relative',
} as React.CSSProperties;

const spacer5 = {
    height: '5',
    fontSize: '5px',
    lineHeight: '5px',
} as React.CSSProperties;

const linkStyle = {
    color: 'rgb(248, 149, 24)',
    fontSize: '18px',
    marginBottom: '0',
    marginTop: '.5em',
} as React.CSSProperties;

const Article = ({
    isHtml,
    articles,
    index,
}: {
    isHtml: boolean;
    articles: Array<Record<string, string>>;
    index: number;
}): ReactElement => {
    return (
        <>
            <tr>
                <td>
                    <table style={articleContainer}>
                        <tbody>
                            <tr style={articleRow}>
                                <td
                                    style={isHtml ? tileStyleLeftHtml : tileStyleLeft}
                                    className={isHtml ? 'articleCell' : undefined}
                                >
                                    <a href={articles[index].articleURL} target=" " style={imgLinks}>
                                        <img
                                            width={isHtml ? 360 : undefined}
                                            height={isHtml ? 190 : undefined}
                                            style={isHtml ? tileImgStyleHtml : tileImgStyle}
                                            src={
                                                articles[index].imageURL ||
                                                'https://images.unsplash.com/photo-1574170608990-8b45e8d75449?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60'
                                            }
                                            alt="Newsletter"
                                        />
                                    </a>
                                    {isHtml &&
                                        `<!--[if mso | IE]>
                                                    <h3 style="
                                                    color: #ffffff;
                                                    font-size: 20px;
                                                    margin-bottom: 1em;
                                                    margin-right: 1em;
                                                    margin-top: 1em;
                                                    font-family: Helvetica Neue; Arial; sans-serif;
                                                    overflow-wrap: anywhere;
                                                    
                                                    line-height: 1.3;
                                                    word-break: keep-all;
                                                    font-weight: bold;">${articles[index].title || 'Article Title'}</h3>
                                                <![endif]-->`}
                                    {isHtml && `<!--[if !mso]> <!---->`}
                                    <h3 style={isHtml ? articleTitleStyleHtml : articleTitleStyle}>
                                        {articles[index].title || 'Article Title'}
                                    </h3>
                                    {isHtml && `<!-- <![endif]-->`}
                                    {isHtml &&
                                        `<!--[if mso | IE]>
                                                <p style="
                                                font-size: 14px;
                                                margin-top: 2em;
                                                margin-right: 2em;
                                                overflow-wrap: anywhere;
                                                margin-bottom: 1em;
                                                color: #DCDEE0;
                                                mso-line-height-rule:exactly;
                                                line-height: 130%;
                                                font-family: Helvetica Neue; Arial; sans-serif;
                                                word-break: keep-all;
                                                font-weight: 350;">
                                                ${articles[index].preview || 'Article Preview'}
                                                    <table className="spacer">
                                                        <tbody>
                                                            <tr>
                                                                <td style={${spacer5}} />
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                <a style="color:rgb(248, 149, 24);font-size:18px;margin-bottom:0;margin-top:.5em;" href=${
                                                    articles[index].articleURL
                                                } target=" ">
                                                    Read More →
                                                </a>
                                            </p>
                                                <![endif]-->`}
                                    {isHtml && `<!--[if !mso]> <!---->`}
                                    <p style={isHtml ? articleBodyStyleHtml : articleBodyStyle}>
                                        {articles[index].preview || 'Article Preview'}
                                        <br />
                                        {isHtml && (
                                            <table className={isHtml ? 'spacer' : undefined}>
                                                <tbody>
                                                    <tr>
                                                        <td style={spacer5} />
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )}
                                        <a style={linkStyle} href={articles[index].articleURL} target=" ">
                                            Read More →
                                        </a>
                                    </p>
                                    {isHtml && `<!-- <![endif]-->`}
                                </td>
                                <td
                                    style={isHtml ? tileStyleRightHtml : tileStyleRight}
                                    className={isHtml ? 'articleCell' : undefined}
                                >
                                    <a href={articles[index + 1].articleURL} target=" " style={imgLinks}>
                                        <img
                                            width={isHtml ? 360 : undefined}
                                            height={isHtml ? 190 : undefined}
                                            style={isHtml ? tileImgStyleHtml : tileImgStyle}
                                            src={
                                                articles[index + 1].imageURL ||
                                                'https://images.unsplash.com/photo-1517976487492-5750f3195933?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60'
                                            }
                                            alt="Newsletter"
                                        />
                                    </a>
                                    {isHtml &&
                                        `<!--[if mso | IE]>
                                                    <h3 style="
                                                    color: #ffffff;
                                                    font-size: 20px;
                                                    margin-bottom: 1em;
                                                    margin-right: 1em;
                                                    margin-top: 1em;
                                                    font-family: Helvetica Neue; Arial; sans-serif;
                                                    overflow-wrap: anywhere;
                                                    line-height: 1.3;
                                                    word-break: keep-all;
                                                    font-weight: bold;">${
                                                        articles[index + 1].title || 'Article Title'
                                                    }</h3>
                                                <![endif]-->`}
                                    {isHtml && `<!--[if !mso]> <!---->`}
                                    <h3 style={isHtml ? articleTitleStyleHtml : articleTitleStyle}>
                                        {articles[index + 1].title || 'Article Title'}
                                    </h3>
                                    {isHtml && `<!-- <![endif]-->`}
                                    {isHtml &&
                                        `<!--[if mso | IE]>
                                                <p style="
                                                font-size: 14px;
                                                margin-top: 2em;
                                                margin-right: 2em;
                                                overflow-wrap: anywhere;
                                                margin-bottom: 1em;
                                                color: #DCDEE0;
                                                mso-line-height-rule:exactly;
                                                line-height: 130%;
                                                font-family: Helvetica Neue; Arial; sans-serif;
                                                word-break: keep-all;
                                                font-weight: 350;">
                                                ${articles[index + 1].preview || 'Article Preview'}
                                                    <table className="spacer">
                                                        <tbody>
                                                            <tr>
                                                                <td style={spacer5} />
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                <a style="color:rgb(248, 149, 24);font-size:18px;margin-bottom:0;margin-top:.5em;" href=${
                                                    articles[index + 1].articleURL
                                                } target=" ">
                                                    Read More →
                                                </a>
                                            </p>
                                                <![endif]-->`}
                                    {isHtml && `<!--[if !mso]> <!---->`}
                                    <p style={isHtml ? articleBodyStyleHtml : articleBodyStyle}>
                                        {articles[index + 1].preview || 'Article Preview'}
                                        <br />
                                        {isHtml && (
                                            <table className={isHtml ? 'spacer' : undefined}>
                                                <tbody>
                                                    <tr>
                                                        <td style={spacer5} />
                                                    </tr>
                                                </tbody>
                                            </table>
                                        )}
                                        <a style={linkStyle} href={articles[index + 1].articleURL} target=" ">
                                            Read More →
                                        </a>
                                    </p>
                                    {isHtml && `<!-- <![endif]-->`}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </>
    );
};
export default Article;
