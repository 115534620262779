/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ReactElement } from 'react';

import { Campaign } from 'typings/Campaign';

const welcomeCell = {
    textAlign: 'left',
    width: '100%',
    display: 'inline-block',
} as React.CSSProperties;

const dateHtml = {
    color: 'white',
    fontSize: '16px',
    margin: '.5em 2.5em 0 3.5em',
} as React.CSSProperties;

const date = {
    color: 'white',
    fontSize: '16px',
    margin: '1em 2.5em .5em 7.5%',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
} as React.CSSProperties;

const welcomeText = {
    color: 'white',
    width: '88%',
    fontSize: '14px',
    margin: '1em 2.5em 0 7.5%',
    wordBreak: 'keep-all',
    overflowWrap: 'anywhere',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    lineHeight: '150%',
} as React.CSSProperties;

const welcomeTextHtml = {
    lineHeight: '150%',
    color: 'white',
    fontSize: '14px',
    width: '88%',
    margin: '1em 3.7em 0 4em',
    wordBreak: 'keep-all',
    overflowWrap: 'anywhere',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
} as React.CSSProperties;

const spacer40 = {
    height: '40',
    fontSize: '40px',
    lineHeight: '40px',
} as React.CSSProperties;

const welcomeTable = { textAlign: 'left', width: '100%' } as React.CSSProperties;

const WelcomeMessage = ({
    isHtml,
    newsletter,
}: {
    isHtml: boolean;
    newsletter: Campaign['template']['newsletter'];
}): ReactElement => {
    return (
        <>
            <table style={welcomeTable}>
                <tbody>
                    <tr>
                        <td>
                            <table style={welcomeTable}>
                                <tbody>
                                    <tr>
                                        <td style={welcomeCell} align="left">
                                            {isHtml &&
                                                `<!--[if mso | IE]>
                                                    <p style="color:white;font-size:16px;margin:2em 2.5em 0 4.5em">${newsletter.date}</p>
                                                    <p style="color:white;mso-line-height-rule:exactly;line-height: 130%;font-size:14px;width:88%;word-break:keep-all;overflow-wrap:anywhere;font-family:Helvetica Neue,Arial,sans-serif;margin:1em 3.7em 0 4.5em;>${newsletter.welcomeMessage}</p>
                                                <![endif]-->`}
                                            {isHtml &&
                                                `<!--[if mso | IE]>
                                                    <p style="color:white;mso-line-height-rule:exactly;line-height: 130%;font-size:14px;width:88%;word-break:keep-all;overflow-wrap:anywhere;font-family:Helvetica Neue,Arial,sans-serif;margin:1em 3.7em 0 4.5em;>${newsletter.welcomeMessage}</p>
                                                <![endif]-->`}
                                            {isHtml && `<!--[if !mso]> <!---->`}
                                            <p style={isHtml ? dateHtml : date}>{newsletter.date}</p>
                                            <p style={isHtml ? welcomeTextHtml : welcomeText}>
                                                {newsletter.welcomeMessage || 'Welcome Message'}
                                            </p>
                                            {isHtml && `<!-- <![endif]-->`}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            {isHtml && (
                <table className={isHtml ? 'spacer' : undefined}>
                    <tbody>
                        <tr>
                            <td style={spacer40} />
                        </tr>
                    </tbody>
                </table>
            )}
        </>
    );
};
export default WelcomeMessage;
