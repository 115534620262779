import { AlertProps } from '@material-ui/lab';

import { Action } from 'store/';
import { SHOW_SNACKBAR } from 'store/actions/Snackbar';

export interface State {
    show: boolean;
    message: string;
    type: AlertProps['severity'];
}

const initialState: State = {
    show: false,
    message: '',
    type: 'info',
};

export default (state = initialState, action: Action): State => {
    const { type, payload } = action;
    switch (type) {
        case SHOW_SNACKBAR: {
            return { ...state, ...payload };
        }
        default:
            return { ...state };
    }
};
