import React, { ReactElement } from 'react';

import { Grid, GridSize } from '@material-ui/core';

import './TableOfContents.scss';

const TableOfContents = ({
    label,
    labelWidth,
    value,
    subLabelWidth,
}: {
    label: string;
    labelWidth: GridSize;
    value: string | number;
    subLabelWidth?: GridSize;
}): ReactElement => {
    let subWidth = (12 - (subLabelWidth as number)) as GridSize;
    if (!subWidth) subWidth = 'auto';
    return (
        <Grid id="GridContainer" container justify="space-between">
            <Grid container item xs={labelWidth}>
                <Grid item xs={subLabelWidth}>
                    <p className="Info">{label}</p>
                </Grid>
                <Grid item xs={subWidth}>
                    <div className="DashContainer">
                        <div className="Dashes" />
                    </div>
                </Grid>
            </Grid>
            <Grid item xs>
                <div className="DashContainer">
                    <div className="Dashes" />
                </div>
            </Grid>

            <Grid item>
                <p className="Info_Number">{value}</p>
            </Grid>
        </Grid>
    );
};
TableOfContents.defaultProps = {
    subLabelWidth: 12,
};
export default TableOfContents;
