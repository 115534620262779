import React, { ReactElement } from 'react';

/* eslint-disable no-param-reassign */
import { Button, Chip } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';

import ErrorCard from 'components/errorCard/ErrorCard';
import TableOfContents from 'components/tableOfContents/TableOfContents';
import { allCategoryNames } from 'components/utils/GlobalConstants';
import ViewContactsTable from 'pageComponents/contact/viewContacts/ViewContactsTable';
import { showDialog } from 'store/actions/Dialog';

import '../reviewUpload/ReviewUpload.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ImportResults = ({ props }): ReactElement => {
    const { contacts, filename, duplicateContacts, successfulContacts, columns } = props;
    const matchedColumns =
        columns.current?.filter((column) => allCategoryNames.includes(column.newColumn.Header as string)) || [];

    const contactList = contacts;
    const dispatch = useDispatch();
    const updatedContacts = successfulContacts.current
        ? successfulContacts.current.filter((contact) => {
              const updated = duplicateContacts.filter(
                  (duplicateContact) => contact.emailAddress === duplicateContact.emailAddress
              );
              if (updated.length > 0) return true;
              return false;
          })
        : [];

    const data = React.useMemo(() => {
        return contactList ?? [];
    }, [contactList]);

    return (
        <div className="ReviewUpload">
            <div className="ReviewFileContainer">
                <div className="ReviewFileSubContainer">
                    <p className="FileUploadedText">File Uploaded:</p>
                    <Chip data-testid="Chip" icon={<InsertDriveFileIcon />} label={filename} />
                    <br />
                    {contacts.length > 0 && (
                        <Button
                            id="openContactsBtn"
                            className="ViewContactsBtn"
                            onClick={() =>
                                dispatch(
                                    showDialog(
                                        true,
                                        'Audience Preview',
                                        <ViewContactsTable contactData={contacts} showValidColumnsOnly={false} />,
                                        <Button
                                            id="cancelButton"
                                            color="primary"
                                            onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                                        >
                                            CLOSE
                                        </Button>,
                                        'lg',
                                        false
                                    )
                                )
                            }
                            disabled={isEmpty(data)}
                            color="primary"
                        >
                            {`VIEW UPLOADED CONTACTS (${data.length})`}
                        </Button>
                    )}
                </div>
            </div>
            <div className="UploadResultsContainer">
                <p className="Title">Upload Results</p>
                {successfulContacts.current !== undefined && successfulContacts.current.length === 0 && (
                    <ErrorCard errorMsg="All contacts that were uploaded have been identified as duplicate contacts in the system. No updates have been made." />
                )}
                {/* For Larger Table of Contents Labels, pass labelWidth:4 && subLabelWidth:11 */}
                <TableOfContents
                    labelWidth={3}
                    label="Columns imported"
                    value={`${matchedColumns.length}/${columns.current?.length || 0}`}
                />
                <TableOfContents labelWidth={3} label="Contacts identified" value={contacts.length} />
                <TableOfContents labelWidth={3} label="Duplicate Contacts" value={duplicateContacts.length} />
                <TableOfContents labelWidth={3} label="Updated Contacts" value={updatedContacts.length} />
            </div>
        </div>
    );
};
export default ImportResults;
