import React from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { showDialog } from 'store/actions/Dialog';
import { Store } from 'store/reducers';

const useStyles = makeStyles({
    dialogPaper: {
        padding: '1rem',
    },
});

export default (): JSX.Element => {
    const { show, title, content, actionButtons, maxWidth, fullWidth } = useSelector((state: Store) => state.Dialog);
    const classes = useStyles();
    const dispatch = useDispatch();

    const closeDialog = () => {
        dispatch(showDialog(false, '', undefined, undefined));
    };

    return (
        <Dialog
            onClose={closeDialog}
            open={show}
            scroll="paper"
            classes={{ paper: classes.dialogPaper }}
            data-testid="customDialog"
            maxWidth={maxWidth}
            fullWidth={fullWidth}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>{actionButtons}</DialogActions>
        </Dialog>
    );
};
