import React, { ReactElement } from 'react';

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import './DuplicateContacts.scss';

const DuplicateContacts = ({
    props,
    showFieldRequired,
    endAnimation,
    validationAnimation,
}: {
    props: {
        overrideDuplicates: boolean | undefined;
        setOverrideDuplicates: React.Dispatch<React.SetStateAction<boolean>>;
    };
    showFieldRequired: boolean;
    endAnimation: () => void;
    validationAnimation: boolean;
}): ReactElement => {
    const { overrideDuplicates, setOverrideDuplicates } = props;
    let radioValue: string | undefined;
    if (overrideDuplicates === true) radioValue = 'Yes';
    else if (overrideDuplicates === false) radioValue = 'No';
    return (
        <div className="DuplicateContainer">
            <p className="QuestionText">
                If contacts in this list already exist in your master list would you like to update those contacts?
            </p>
            <FormControl component="fieldset">
                <RadioGroup
                    aria-label="update duplicates"
                    name="duplicates"
                    value={radioValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setOverrideDuplicates(event.target.value === 'Yes');
                    }}
                >
                    <FormControlLabel
                        value="No"
                        control={<Radio name="duplicateQuestion" required data-testid="No, update" />}
                        label="No, don’t update existing duplicate contacts"
                        className="RadioText"
                    />

                    <FormControlLabel
                        value="Yes"
                        control={<Radio name="duplicateQuestion" required data-testid="Yes, update" />}
                        label="Yes, update existing duplicate contacts with new contact information"
                        className="RadioText"
                    />
                </RadioGroup>
            </FormControl>
            {showFieldRequired && overrideDuplicates === undefined && (
                <p
                    className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                    onAnimationEnd={endAnimation}
                >
                    *Select an option to continue
                </p>
            )}
        </div>
    );
};
export default DuplicateContacts;
