/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ReactElement } from 'react';

import Article from './Article';

const articleTable = {
    margin: '1em 0 3em 0',
    tableLayout: 'fixed',
    width: '100%',
} as React.CSSProperties;

const articleTableHtml = {
    margin: '1em 0',
    tableLayout: 'fixed',
    width: '800px',
} as React.CSSProperties;

const spacer20 = {
    height: '20',
    fontSize: '20px',
    lineHeight: '20px',
} as React.CSSProperties;

const ArticleTable = ({
    isHtml,
    newsletter,
}: {
    isHtml: boolean;
    newsletter: { articles: Array<Record<string, string>>; articleCount: number };
}): ReactElement => {
    const articleIndex = Array.from(Array(newsletter.articleCount), (x, index) => index).filter(
        (element) => !(element % 2)
    );
    return (
        <>
            <table className={isHtml ? 'row' : undefined} style={isHtml ? articleTableHtml : articleTable}>
                <tbody>
                    {articleIndex.map((index, id) => (
                        <Article articles={newsletter.articles} isHtml={isHtml} index={index} key={id} />
                    ))}
                </tbody>
            </table>
            {isHtml && (
                <table className={isHtml ? 'spacer' : undefined}>
                    <tbody>
                        <tr>
                            <td style={spacer20} />
                        </tr>
                    </tbody>
                </table>
            )}
        </>
    );
};
export default ArticleTable;
