import React, { ReactElement, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { UNSUBSCRIBE_CONTACT_PUBLIC } from 'api/mutations';
import showSnackbarError from 'components/utils/ShowSnackbarError';

import './Unsubscribe.scss';

export interface ParamType {
    id: string;
    campaignId: string;
}
const Unsubscribe = (): ReactElement => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id, campaignId } = useParams<ParamType>();
    const [contactEmail, setContactEmail] = useState('');
    const [unsubscribeContact] = useMutation(UNSUBSCRIBE_CONTACT_PUBLIC);

    useEffect(() => {
        const unsubscribe = async () => {
            try {
                const campaignIdNumber = campaignId && Number(campaignId) ? Number(campaignId) : 0;
                const contact = await unsubscribeContact({
                    variables: { id, campaignId: campaignIdNumber },
                });
                setContactEmail(contact.data?.Contact.emailAddress);
            } catch (err) {
                showSnackbarError(dispatch, err);
            }
        };
        if (contactEmail === '') {
            unsubscribe();
        }
    }, [campaignId, contactEmail, dispatch, id, unsubscribeContact]);

    const resubscribe = async () => {
        history.push(`/resubscribe/${id}`);
    };

    return (
        <div className="UnsubscribeContainer">
            <Grid container>
                {(!id || !contactEmail) && <h1 className="ErrorMsg">Error: Contact not found</h1>}
                {contactEmail && (
                    <div className="UnsubscribeBox">
                        <Grid item className="GridContainer">
                            <div>
                                <p className="UnsubscribeText Heading">({contactEmail}) no longer subscribed</p>
                                <p className="UnsubscribeText">
                                    ({contactEmail}) will no longer receive emails from the Air Force Small Business
                                    Innovation Research office.
                                </p>
                                <p className="UnsubscribeText">If this was a mistake, please resubscribe below.</p>
                            </div>
                        </Grid>
                        <Grid className="GridContainer" container item justify="flex-end">
                            <Grid item>
                                <Button onClick={resubscribe} color="primary">
                                    Resubscribe
                                </Button>

                                <a className="OkBtn" href="https://www.afsbirsttr.af.mil/">
                                    <Button color="primary" variant="contained">
                                        OK
                                    </Button>
                                </a>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </Grid>
        </div>
    );
};
export default Unsubscribe;
