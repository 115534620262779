import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Button, MenuItem, Select, TextField } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import { camelCase } from 'lodash';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import trimData from 'components/utils/TrimFormData';

import './FilterSidebar.scss';

export interface FilterSidebarProps {
    onConfirm: (formData: Record<string, unknown>) => void;
}

const filterCategoryNames = ['Email Address', 'Phone Number', 'Job Title', 'Company Name', 'City', 'State'];

// Filters that were removed but are available: 'First Name', 'Last Name', 'Ext', 'NAICS Code', 'DUNS Number',

const categoryAccessors = filterCategoryNames.map((category) => camelCase(category));

// The follow conditions were removed from the front end visibility, but can be added back into conditions if needed:
// 'greater than',
// 'greater than or equal to',
// 'less than',
// 'less than or equal to',
const conditions = ['equals', 'not equals', 'contains', 'not contains'];

export default ({ onConfirm }: FilterSidebarProps): JSX.Element => {
    const defaultValues = categoryAccessors.reduce(
        (ac, a, index) => ({
            ...ac,
            [categoryAccessors[index]]: [{ condition: conditions[0], value: '' }],
        }),
        {}
    );
    const { handleSubmit, control, formState, reset, setValue } = useForm<{ string: [string] }>({
        defaultValues,
    });
    const categoryControls = categoryAccessors.map((category) => {
        return useFieldArray({ control, name: category });
    });

    return (
        <div className="FilterSidebar">
            <form
                id="filterSidebarForm"
                onSubmit={handleSubmit((formData) => {
                    if (onConfirm) onConfirm(trimData(formData));
                })}
            >
                <div className="FilterSidebar__Header">
                    <Button
                        id="resetFiltersButton"
                        color="primary"
                        type="submit"
                        onClick={(): void => {
                            reset({ ...defaultValues });
                        }}
                    >
                        Remove Filters
                    </Button>
                </div>
                <div className="FilterSidebar__Body">
                    <div className="FilterSidebar__Body--scrollable">
                        {filterCategoryNames.map((category, categoryIndex) => {
                            const categoryAccessor = categoryAccessors[categoryIndex];
                            const filterCategory = categoryControls[categoryIndex];
                            const isDirtyField =
                                Object.prototype.hasOwnProperty.call(formState.dirtyFields, categoryAccessor) &&
                                formState.dirtyFields[categoryAccessor].some((filter) => {
                                    return filter?.value || false;
                                });
                            const allConditions = filterCategory.fields.map(({ condition }) => condition);
                            return (
                                <Accordion key={categoryIndex} className={`${isDirtyField ? 'DirtyField' : ''}`}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>{category}</AccordionSummary>
                                    {filterCategory.fields.map((filter, index) => (
                                        <AccordionDetails className="FlexColumn" key={filter.id}>
                                            <p>Condition</p>
                                            <Controller
                                                as={
                                                    <Select className="DirtyField--exclude" variant="outlined">
                                                        {conditions
                                                            .filter((condition) => {
                                                                return !allConditions
                                                                    .slice(0, index)
                                                                    .includes(camelCase(condition));
                                                            })
                                                            .map((condition) => {
                                                                const conditionAccessor = camelCase(condition);
                                                                return (
                                                                    <MenuItem
                                                                        key={conditionAccessor}
                                                                        value={conditionAccessor}
                                                                    >
                                                                        {condition}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                    </Select>
                                                }
                                                control={control}
                                                name={`${categoryAccessor}[${index}].condition`}
                                                onChange={([selected]) => {
                                                    // React Select return object instead of value for selection
                                                    return { value: selected };
                                                }}
                                                defaultValue={filter.condition}
                                            />
                                            <p>Keyword(s) or Value</p>
                                            <Controller
                                                as={
                                                    <TextField
                                                        type="text"
                                                        variant="outlined"
                                                        className="DirtyField--exclude"
                                                        placeholder="Enter keyword(s) or values..."
                                                        InputProps={{
                                                            endAdornment: (
                                                                <ClearIcon
                                                                    onClick={() => {
                                                                        setValue(
                                                                            `${categoryAccessor}[${index}].value`,
                                                                            '',
                                                                            { shouldDirty: true, shouldValidate: true }
                                                                        );
                                                                    }}
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                }
                                                control={control}
                                                id={categoryAccessor}
                                                name={`${categoryAccessor}[${index}].value`}
                                                defaultValue={filter.value}
                                            />
                                            <div className="ConditionButtons">
                                                {(filterCategory.fields.length > 1 || index !== 0) && (
                                                    <Button
                                                        id={`${categoryAccessor}RemoveButton${index}`}
                                                        color="primary"
                                                        onClick={(): void => {
                                                            filterCategory.remove(index);
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>
                                                )}
                                                <div className="ConditionButtons__Right">
                                                    {filterCategory.fields.length === index + 1 &&
                                                        filterCategory.fields.length < conditions.length && (
                                                            <Button
                                                                color="primary"
                                                                id={`${categoryAccessor}AddNewConditionButton${index}`}
                                                                type="button"
                                                                variant="outlined"
                                                                onClick={(): void => {
                                                                    const availableConditions = conditions.filter(
                                                                        (condition) => {
                                                                            return !allConditions
                                                                                .slice(0, index + 1)
                                                                                .includes(camelCase(condition));
                                                                        }
                                                                    );
                                                                    filterCategory.append({
                                                                        condition: camelCase(availableConditions[0]),
                                                                        value: '',
                                                                    });
                                                                }}
                                                            >
                                                                + NEW
                                                            </Button>
                                                        )}
                                                    <Button
                                                        id="ApplyBtn"
                                                        type="submit"
                                                        variant="outlined"
                                                        color="primary"
                                                    >
                                                        Apply
                                                    </Button>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    ))}
                                </Accordion>
                            );
                        })}
                    </div>
                </div>
            </form>
        </div>
    );
};
