import React, { ReactElement, Suspense } from 'react';

import { Grid } from '@material-ui/core';
import {
    mdiAccountGroup,
    mdiAccountMultipleCheck,
    mdiApplicationEdit,
    mdiChartAreaspline,
    mdiChartBox,
    mdiEmailSend,
    mdiFileExcel,
    mdiHome,
    mdiPlaylistEdit,
    mdiTagMultiple,
} from '@mdi/js';
import Icon from '@mdi/react';
import { useSelector } from 'react-redux';

import Landing from 'assets/images/Landing-Page_Graphic-01.png';
import InfoFooter from 'components/layouts/infoFooter/InfoFooter';
import { id } from 'date-fns/locale';
import { Store } from 'store/reducers';

import './InfoPage.scss';

const FeatureTile = ({ label, iconPath }) => {
    return (
        <Grid item xs={12} sm={2} className="FeatureTile">
            <div className="FeatureTile__Background">
                <Icon className="FeatureTile__Icon" path={iconPath} size={1.25} />
            </div>
            <div className="FeatureTile__Label">{label}</div>
        </Grid>
    );
};

interface Feature {
    label: string;
    icon: string;
}

const currentFeatures: Array<Feature> = [
    { label: 'Manage Subscription Status', icon: mdiAccountMultipleCheck },
    { label: 'Add & Import Contacts', icon: mdiFileExcel },
    { label: 'Manage Segment Contacts', icon: mdiPlaylistEdit },
    { label: 'Send Marketing Emails', icon: mdiEmailSend },
    { label: 'Collect & Report Analytics', icon: mdiChartBox },
];

const futureFeatures: Array<Feature> = [
    { label: 'Bulk Upload Data Visualization', icon: mdiChartAreaspline },
    { label: 'Differentiate Audiences', icon: mdiAccountGroup },
    { label: 'Home Page', icon: mdiHome },
    { label: 'Tags', icon: mdiTagMultiple },
    { label: 'Dynamic Template', icon: mdiApplicationEdit },
];

const InfoPage = (): ReactElement => {
    return (
        <div className="InfoPage">
            <Grid container className="TopSection ColoredSection">
                <Grid item xs={12} sm={12} md={6} zeroMinWidth className="TopSection__Description">
                    <h1 className="TopSection__Description--main">
                        The easy way to create email communications that is compatible with the government requirements.
                    </h1>
                    <div className="TopSection__Description--sub">
                        We offer a holistic approach, focusing on secure & effective outreach.
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="TopSection__Graphic">
                    <img
                        src={Landing}
                        alt="Feature Icons in a circle around an open envelop containing a piece of mail"
                    />
                </Grid>
            </Grid>
            <div className="CurrentFeaturesSection">
                <h2 className="CurrentFeaturesSection__Header">Our Features</h2>
                <Grid container className="CurrentFeaturesSection__Body">
                    {currentFeatures.map((feature) => (
                        <FeatureTile iconPath={feature.icon} label={feature.label} />
                    ))}
                </Grid>
            </div>
            <div className="FutureFeaturesSection ColoredSection">
                <h2 className="FutureFeaturesSection__Header">Future Features</h2>
                <Grid container className="FutureFeaturesSection__Body">
                    {futureFeatures.map((feature) => (
                        <FeatureTile iconPath={feature.icon} label={feature.label} />
                    ))}
                </Grid>
            </div>
            <InfoFooter />
        </div>
    );
};

export default InfoPage;
