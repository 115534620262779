import React, { ReactElement } from 'react';

import { Box, Chip, Tooltip } from '@material-ui/core';

import { convertJSONFilterQueryToOptions } from 'components/utils/FilterObject';

import './SegmentFilterChips.scss';

interface SegmentFilterChipsProps {
    filterQuery: any;
    onlyShowFirstX?: number | null;
    additionalContacts?: Array<string> | null;
    onDelete?: (index) => void | null;
    showAndOrs?: boolean;
}

const SegmentFilterChips = ({
    filterQuery,
    onlyShowFirstX,
    additionalContacts,
    onDelete,
    showAndOrs,
}: SegmentFilterChipsProps): ReactElement => {
    let chips;

    if (onlyShowFirstX) {
        const filters = convertJSONFilterQueryToOptions(filterQuery);
        const moreFilters = filters.length - onlyShowFirstX;

        chips = (
            <>
                {additionalContacts && additionalContacts.length > 0 && (
                    <Tooltip
                        id="Tooltip"
                        placement="top"
                        arrow
                        title={additionalContacts.map((emailAddress) => (
                            <div>{emailAddress}</div>
                        ))}
                        aria-label={additionalContacts.join(', ')}
                    >
                        <Chip className="Chip" label={`${additionalContacts.length} manually entered email(s)`} />
                    </Tooltip>
                )}
                {filters.slice(0, onlyShowFirstX).map((filter, index) => {
                    return (
                        <>
                            <Chip
                                className="Chip"
                                key={filter.key}
                                label={filter.label}
                                onDelete={onDelete ? () => onDelete(index) : undefined}
                            />
                        </>
                    );
                })}
                {filters.length > onlyShowFirstX && (
                    <>
                        <Tooltip
                            id="Tooltip"
                            placement="top"
                            arrow
                            title={filters.slice(onlyShowFirstX).map((filter) => (
                                <div>{filter.label}</div>
                            ))}
                            aria-label={filters.slice(onlyShowFirstX).join(', ')}
                        >
                            <Chip className="Chip" id="moreFilters" key="moreFilters" label={`+${moreFilters}`} />
                        </Tooltip>
                    </>
                )}
            </>
        );
    } else {
        chips = (
            <>
                {additionalContacts && additionalContacts.length > 0 && (
                    <Tooltip
                        id="Tooltip"
                        placement="top"
                        arrow
                        title={additionalContacts.map((emailAddress) => (
                            <div>{emailAddress}</div>
                        ))}
                        aria-label={additionalContacts.join(', ')}
                    >
                        <Chip className="Chip" label={`${additionalContacts.length} manually entered email(s)`} />
                    </Tooltip>
                )}
                {convertJSONFilterQueryToOptions(filterQuery).map((filter, index) => {
                    return (
                        <>
                            {showAndOrs && index !== 0 && ` ${filter.andOrCondition.toUpperCase()} `}
                            <Chip
                                className="Chip"
                                key={filter.key}
                                label={filter.label}
                                onDelete={onDelete ? () => onDelete(index) : undefined}
                            />
                        </>
                    );
                })}
            </>
        );
    }
    return (
        <Box display="flex" alignItems="center" className="SegmentFilterChips">
            {chips}
        </Box>
    );
};

SegmentFilterChips.defaultProps = {
    onlyShowFirstX: null,
    additionalContacts: null,
    onDelete: null,
    showAndOrs: false,
};

export default SegmentFilterChips;
