/* eslint-disable import/prefer-default-export */
export const defaultFormValues = {
    id: '',
    emailAddress: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    phoneNumber: '',
    companyName: '',
    dunsNumber: '',
    city: '',
    state: '',
    techFocusAreas: {
        'Select All': false,
        'Advanced Materials': false,
        Aerospace: false,
        'Air Transportation': false,
        'Artificial Intelligence / Machine Learning': false,
        'Augmented Reality / Virtual Reality': false,
        'Bio Technology': false,
        'Cyber Security': false,
        Electronics: false,
        Energy: false,
        Hardware: false,
        Healthcare: false,
        'Information Technology': false,
        'Quantum Computing': false,
        'Robotics/Autonomous Vehicles': false,
        Telecommunications: false,
        Training: false,
        '3D Printing': false,
    },
    companyCertifications: {
        'Small Business': false,
        'Economically Disadvantaged Small Business': false,
        'Service Disabled Veteran Owned Small Business': false,
        'Women Owned Small Business': false,
        'Economically Disadvantaged Women Owned Small Business': false,
        HUBZone: false,
        '8(a)': false,
    },
};
export const companyCerts = [
    'Small Business',
    'Economically Disadvantaged Small Business',
    'Service Disabled Veteran Owned Small Business',
    'Women Owned Small Business',
    'Economically Disadvantaged Women Owned Small Business',
    'HUBZone',
    '8(a)',
];

export const techAreas = [
    'Select All',
    'Advanced Materials',
    'Aerospace',
    'Air Transportation',
    'Artificial Intelligence / Machine Learning',
    'Augmented Reality / Virtual Reality',
    'Bio Technology',
    'Cyber Security',
    'Electronics',
    'Energy',
    'Hardware',
    'Healthcare',
    'Information Technology',
    'Quantum Computing',
    'Robotics/Autonomous Vehicles',
    'Telecommunications',
    'Training',
    '3D Printing',
];
export const stateAbbreviations = {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District of Columbia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    MP: 'Northern Mariana IS',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    VI: 'Virgin Islands',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
};
export enum CAMPAIGN_PAGE {
    ADD_DETAILS = 0,
    SELECT_AUDIENCE,
    SELECT_TEMPLATE,
    ADD_CONTENT,
    REVIEW,
}
export const validateURL = (url: string): boolean => {
    const re = new RegExp('^(http|https)://', 'i');
    return re.test(url);
};
export const allCategoryNames = [
    'Email Address',
    'First Name',
    'Last Name',
    'Phone Number',
    'Ext',
    'Job Title',
    'Company Name',
    'City',
    'State',
    'NAICS Code',
    'DUNS Number',
    'Company Certifications',
];

export const hiddenColumns = ['Status', 'Audience ID', 'Contact Source', 'Is Archived'];

export enum TEMPLATE_TYPES {
    NEWSLETTER = 1,
    EVENT,
    PRESS,
}
