import React, { useEffect, useRef, useState } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
    Button,
    Checkbox,
    ClickAwayListener,
    debounce,
    DialogContentText,
    Divider,
    Drawer as MUIDrawer,
    FormControlLabel,
    FormGroup,
    Grid,
    Grow,
    IconButton,
    makeStyles,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { DELETE_CONTACT, UNSUBSCRIBE_CONTACT, UPDATE_CONTACT } from 'api/mutations/';
import { GET_CONTACT_FROM_EMAIL } from 'api/queries/';
import checkBoxObjToString from 'components/utils/CheckBoxObjToString';
import { companyCerts, defaultFormValues, stateAbbreviations, techAreas } from 'components/utils/GlobalConstants';
import showSnackbarError from 'components/utils/ShowSnackbarError';
import { showDialog } from 'store/actions/Dialog';
import { showDrawer } from 'store/actions/Member';
import { showSnackbar } from 'store/actions/Snackbar';
import { Store } from 'store/reducers/';
import { FormValues } from 'typings/Subscription';

// A few items of note: because we are using Lazy query we reset the value via hook forms
// when switching to the input value
// so that the data will be populated.  This means for the time being if switching back and forth from view
// only to edit mode the typed data will be lost.
// When using react hook forms with MUI:
// When name is on controller level only, the data only is passed out of the form when there is a change.
// When name is located within the TextField as well it will pass the data out every submission as log as you have also registered the textfield

const drawerWidth = 480;

const useStyles = makeStyles({
    root: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flexGrow: 1,
        fontSize: '14px',
        fontFamily: 'Roboto',
    },
    bulletedListItems: {
        listStyleType: 'disc',
    },
    dialogImportantText: {
        color: '#07090c',
    },
    dialogImportantBoldedText: {
        color: '#07090c',
        fontWeight: 600,
    },
    drawer: {
        width: drawerWidth,
        color: 'red',
        overflowX: 'hidden',
    },
    drawerPaper: {
        width: drawerWidth,
        overflowX: 'hidden',
    },
    formControl: {
        minWidth: 120,
    },
    name: {
        fontSize: '14px',
        paddingLeft: '4px',
        marginLeft: '10px',
        color: '#5A7085',
    },
    extName: {
        paddingTop: '14px',
        fontSize: '14px',
        color: '#5A7085',
    },
    nameEntry: {
        fontSize: '14px',
        width: '200px',
        height: '36px',
    },
    phoneEntry: {
        width: '136px',
        height: '36px',
    },
    extEntry: {
        width: '96px',
        height: '36px',
    },
    standardEntry: {
        width: '160px',
    },
    addressEntry: {
        width: '296px',
        height: '36px',
    },
    trlDrop: {
        width: '74px',
        fontSize: '14px',
        padding: '2px',
    },
    previousDrop: {
        width: '296px',
        fontSize: '14px',
    },
    subscribeDrop: {
        width: '96px',
        fontSize: '14px',
    },
    intro: {
        fontSize: '20px',
        paddingLeft: '4px',
        marginLeft: '10px',
        marginTop: '20px',
    },
    sectionName: {
        fontSize: '16px',
        paddingTop: '18px',
        paddingBottom: '6px',
        paddingLeft: '4px',
        marginLeft: '10px',
    },
    data: {
        fontSize: '14px',
        fontFamily: 'Roboto',
    },
    vdata: {
        fontSize: '14px',
        fontFamily: 'Roboto',
        paddingTop: '12px',
        paddingBottom: '12px',
    },
    EmailValidation: {
        color: '#f00',
        margin: '0.1rem 0rem',
        fontSize: '12px',
        fontFamily: 'Roboto',
    },
    actionButton: {
        marginTop: '.7rem',
        marginBottom: '.7rem',
    },
    ellipsisButton: {
        marginTop: '.3rem',
    },
    select: {
        height: '2.5rem',
        marginTop: '0.5rem',
    },
    menuHeight: {
        height: '20vh',
        minHeight: '10rem',
    },
    CheckboxHeading: {
        paddingTop: '1.5rem',
        fontSize: '1rem',
        paddingLeft: '4px',
        marginLeft: '10px',
    },
    CheckboxList: {
        paddingLeft: '4px',
        marginLeft: '10px',
    },
    CheckBoxSubHeading: {
        paddingLeft: '4px',
        marginLeft: '10px',
    },
});

// To change to creatable will need to create database table to store options
export const categoryType = [
    { value: 'VOSB', label: 'VOSB' },
    { value: 'SDVOSB', label: 'SDVOSB' },
    { value: 'WOSB', label: 'WOSB' },
    { value: 'EDWOSB', label: 'EDWOSB' },
    { value: 'HUBZone', label: 'HUBZone' },
    { value: '8(a)', label: '8(a)' },
];

const Drawer = (): JSX.Element => {
    const saveFormId = 'saveContactForm';
    const deleteFormId = 'deleteContactForm';
    const { techFocusAreas, companyCertifications } = defaultFormValues;
    const { show, emailAddress } = useSelector((state: Store) => state.Member);

    const [emailExists, setEmailExists] = useState<boolean>(false);
    const [viewOnly, setViewOnly] = useState(true);
    const [open, setOpen] = useState(false);
    const [, setLastUpdate] = useState(new Date());

    const [unsubscribeContact] = useMutation(UNSUBSCRIBE_CONTACT);
    const [updateContact] = useMutation(UPDATE_CONTACT);
    const [deleteContact] = useMutation(DELETE_CONTACT);
    const [peopleQuery, { called, error, data: queryData }] = useLazyQuery(GET_CONTACT_FROM_EMAIL, {
        variables: { audienceId: 1, emailAddress },
        fetchPolicy: 'network-only',
    });
    const emailCheckQuery = useQuery(GET_CONTACT_FROM_EMAIL, {
        variables: { audienceId: 1, emailAddress },
    });
    const person = {
        id: queryData?.Contact.id || '',
        emailAddress: queryData?.Contact.emailAddress || '',
        firstName: queryData?.Contact.firstName || '',
        lastName: queryData?.Contact.lastName || '',
        jobTitle: queryData?.Contact.jobTitle || '',
        phoneNumber: queryData?.Contact.phoneNumber || '',
        companyName: queryData?.Contact.companyName || '',
        dunsNumber: queryData?.Contact.dunsNumber || '',
        city: queryData?.Contact.city || '',
        state: queryData?.Contact.state || '',
        status: queryData?.Contact.status,
        techFocusAreas,
        companyCertifications,
    };

    if (queryData?.Contact.techFocusAreas !== '') {
        const selectedTechAreas = queryData?.Contact.techFocusAreas?.split(',') || '';

        if (selectedTechAreas)
            selectedTechAreas.forEach((area) => {
                person.techFocusAreas = {
                    ...person.techFocusAreas,
                    [area]: true,
                };
            });
    }
    if (queryData?.Contact.companyCertifications !== '') {
        const selectedCerts = queryData?.Contact.companyCertifications?.split(',') || '';
        if (selectedCerts)
            selectedCerts.forEach((cert) => {
                person.companyCertifications = {
                    ...person.companyCertifications,
                    [cert]: true,
                };
            });
    }

    const { handleSubmit, register, reset, control, setValue, getValues } = useForm<FormValues>({
        defaultValues: person,
    });
    useEffect(() => {
        reset(person);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryData]);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        let mounted = true;
        if (show && mounted) peopleQuery();
        setViewOnly(true);
        return () => {
            mounted = false;
        };
    }, [emailAddress, show, peopleQuery]);

    if (called) {
        if (error && !queryData) {
            return <div className="NoDataError">No DATA to populate drawer</div>;
        }
    }

    const toView = () => {
        setViewOnly(true);
        setOpen(false);
    };

    const toEdit = () => {
        setViewOnly(false);
        setOpen(false);
        reset();
    };

    const onSave = async (formData: FormValues) => {
        const data = {
            emailAddress: formData.emailAddress,
            companyName: formData.companyName,
            city: formData.city,
            state: formData.state === undefined ? person.state : formData.state,
            firstName: formData.firstName,
            lastName: formData.lastName,
            jobTitle: formData.jobTitle,
            dunsNumber: formData.dunsNumber,
            phoneNumber: formData.phoneNumber,
            audienceId: 1,
            techFocusAreas: checkBoxObjToString(formData.techFocusAreas),
            companyCertifications: checkBoxObjToString(formData.companyCertifications),
        };
        let saveSuccessful = false;

        try {
            await updateContact({
                variables: {
                    data,
                    id: person.id,
                },
                refetchQueries: () => ['findContactsFromFilters'],
            });
            saveSuccessful = true;
            dispatch(showDrawer(false, person.emailAddress));
        } finally {
            dispatch(
                showSnackbar(
                    true,
                    `Your changes have ${saveSuccessful ? '' : 'NOT'} been saved.`,
                    saveSuccessful ? 'success' : 'error'
                )
            );
        }
    };

    const onDelete = async () => {
        try {
            await deleteContact({
                variables: { id: person.id },
                refetchQueries: () => ['findContactsFromFilters'],
            });
            dispatch(showDialog(false, '', undefined, undefined));
            dispatch(showDrawer(false, person.emailAddress));
            dispatch(showSnackbar(true, 'Subscriber has been removed.', 'success'));
        } catch (err) {
            showSnackbarError(dispatch, err);
        }
    };

    const toDelete = (): void => {
        dispatch(
            showDialog(
                true,
                `You are about to delete the following contact:`,
                <>
                    <form onSubmit={handleSubmit(onDelete)} id={deleteFormId}>
                        <p>
                            {person.firstName} {person.lastName} - {person.companyName}
                            <br />
                            {person.emailAddress}
                        </p>
                    </form>
                    <DialogContentText className={classes.dialogImportantBoldedText}>
                        Are you sure you want to proceed?
                    </DialogContentText>
                </>,
                <>
                    <Button
                        id="cancelButton"
                        color="primary"
                        onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                    >
                        CANCEL
                    </Button>
                    <Button type="submit" variant="contained" color="primary" form={deleteFormId}>
                        DELETE THIS CONTACT
                    </Button>
                </>
            )
        );
    };

    const onUnsubscribe = async () => {
        try {
            await unsubscribeContact({
                variables: { id: person.id, campaignId: 0 },
                refetchQueries: () => ['findContactsFromFilters'],
            });
            dispatch(showDialog(false, '', undefined, undefined));
            dispatch(showDrawer(false, person.emailAddress));
            dispatch(showSnackbar(true, 'Contact Unsubscribed', 'success'));
        } catch (err) {
            showSnackbarError(dispatch, err);
        }
    };

    const toUnsubscribe = (): void => {
        dispatch(
            showDialog(
                true,
                `You are about to unsubscribe the following contact:`,
                <>
                    <form onSubmit={handleSubmit(onUnsubscribe)} id={deleteFormId}>
                        <p>{person.emailAddress}</p>
                    </form>
                    <DialogContentText className={classes.dialogImportantBoldedText}>
                        Are you sure you want to proceed?
                    </DialogContentText>
                </>,
                <>
                    <Button
                        id="cancelButton"
                        color="primary"
                        onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                    >
                        NO, CANCEL
                    </Button>
                    <Button type="submit" variant="contained" color="primary" form={deleteFormId}>
                        YES, UNSUBSCRIBE
                    </Button>
                </>
            )
        );
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleDrawerClose = () => {
        dispatch(showDrawer(false, person.emailAddress));
    };

    const emailValidation = debounce(async (value) => {
        try {
            await emailCheckQuery.refetch({ emailAddress: value });
            // this shows the validation message stating the entered email is a duplicate
            if (value !== emailAddress) setEmailExists(true);
            return true;
        } catch (err) {
            // this error is a good thing meaning the email is not a duplicate in the DB
            if ((err as Error).message !== 'Contact not found!') {
                showSnackbarError(dispatch, err);
            }
            setEmailExists(false);
            return false;
        }
    }, 500);

    const CancelButton = (): JSX.Element => (
        <Grid item xs="auto">
            <Button className={classes.actionButton} id="btnDrawerCancel" onClick={handleDrawerClose} color="primary">
                Cancel
            </Button>
        </Grid>
    );

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };
    const handleTechAreas = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        if (name === 'techFocusAreas.Select All') {
            techAreas.forEach((area) => setValue(`techFocusAreas.${area}`, checked));
        } else {
            setValue(`techFocusAreas.${name}`, checked);
        }
        setLastUpdate(new Date());
    };
    const handleCerts = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setValue(`companyCertifications.${name}`, checked);
        setLastUpdate(new Date());
    };
    return (
        <div>
            <MUIDrawer className={classes.drawer} variant="persistent" anchor="right" open={show}>
                <Paper className={classes.drawerPaper}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <IconButton
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                data-testid="three-dots"
                                onClick={handleToggle}
                                className={classes.ellipsisButton}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList
                                                    autoFocusItem={open}
                                                    id="menu-list-grow"
                                                    data-testid="test-menu-grow"
                                                    className="test"
                                                >
                                                    {viewOnly === false ? (
                                                        <MenuItem onClick={toView}>View Only</MenuItem>
                                                    ) : (
                                                        <MenuItem id="menu-edit" onClick={toEdit}>
                                                            Edit
                                                        </MenuItem>
                                                    )}

                                                    <MenuItem
                                                        disabled={
                                                            String(person.status).toLowerCase() === 'unsubscribed'
                                                        }
                                                        onClick={toUnsubscribe}
                                                    >
                                                        {`Unsubscribe${
                                                            String(person.status).toLowerCase() === 'unsubscribed'
                                                                ? 'd'
                                                                : ''
                                                        }`}
                                                    </MenuItem>
                                                    <MenuItem disabled onClick={toDelete}>
                                                        Delete
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Grid>

                        <Grid justify="flex-end" direction="row" container item xs={7} spacing={1}>
                            <CancelButton />
                            {viewOnly === false && (
                                <Grid item xs="auto">
                                    <Button
                                        className={classes.actionButton}
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={emailExists}
                                        form={saveFormId}
                                    >
                                        SAVE CHANGES
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Divider />

                    <form onSubmit={handleSubmit((formData) => onSave(formData))} id={saveFormId}>
                        <Typography className={classes.intro}>
                            {person.firstName} {person.lastName} - {person.companyName}
                        </Typography>

                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Typography className={classes.sectionName}>Contact</Typography>
                            </Grid>
                            <Grid item xs={12} container direction="row" spacing={0} wrap="nowrap" />
                            <Grid container alignItems="center">
                                <Grid item xs={4}>
                                    <Typography className={classes.name}>Email Address</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    {viewOnly ? (
                                        <Typography className={classes.vdata}>{person.emailAddress}</Typography>
                                    ) : (
                                        <>
                                            <TextField
                                                type="email"
                                                name="emailAddress"
                                                defaultValue={person.emailAddress}
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                inputProps={{
                                                    style: { fontSize: '14px', padding: '8px' },
                                                    'aria-label': 'emailAddress',
                                                }}
                                                inputRef={register}
                                                className={classes.addressEntry}
                                                onChange={(event) => {
                                                    emailValidation(event.target.value);
                                                }}
                                            />
                                            {emailExists && (
                                                <p data-testid="emailExistsTag" className={classes.EmailValidation}>
                                                    * Email Address Already Used
                                                </p>
                                            )}
                                        </>
                                    )}
                                </Grid>
                                <Grid container alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography className={classes.name}>First Name</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Controller
                                            name="firstName"
                                            control={control}
                                            render={({ onChange }) =>
                                                viewOnly ? (
                                                    <Typography className={classes.vdata}>
                                                        {person.firstName}
                                                    </Typography>
                                                ) : (
                                                    <TextField
                                                        id="firstName"
                                                        defaultValue={person.firstName}
                                                        variant="outlined"
                                                        size="small"
                                                        margin="dense"
                                                        inputProps={{ style: { fontSize: '14px', padding: '8px' } }}
                                                        className={classes.nameEntry}
                                                        name="firstName"
                                                        inputRef={register}
                                                        onChange={onChange}
                                                    />
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography className={classes.name}>Last Name</Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                        <Controller
                                            name="lastName"
                                            control={control}
                                            render={({ onChange }) =>
                                                viewOnly ? (
                                                    <Typography className={classes.vdata}>{person.lastName}</Typography>
                                                ) : (
                                                    <TextField
                                                        id="lastName"
                                                        defaultValue={person.lastName}
                                                        variant="outlined"
                                                        size="small"
                                                        margin="dense"
                                                        className={classes.nameEntry}
                                                        fullWidth
                                                        inputProps={{ style: { fontSize: '14px' } }}
                                                        name="lastName"
                                                        onChange={onChange}
                                                        inputRef={register}
                                                    />
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography className={classes.name}>Job Title</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Controller
                                            name="jobTitle"
                                            control={control}
                                            render={({ onChange }) =>
                                                viewOnly ? (
                                                    <Typography className={classes.vdata}>{person.jobTitle}</Typography>
                                                ) : (
                                                    <TextField
                                                        id="outlined-basic"
                                                        name="jobTitle"
                                                        inputRef={register}
                                                        defaultValue={person.jobTitle}
                                                        variant="outlined"
                                                        margin="dense"
                                                        inputProps={{ style: { fontSize: '14px' } }}
                                                        className={classes.standardEntry}
                                                        onChange={onChange}
                                                    />
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography className={classes.name}>Office Phone</Typography>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Grid container wrap="nowrap" spacing={1}>
                                            <Grid item xs={6} className={classes.data}>
                                                <Controller
                                                    name="phoneNumber"
                                                    control={control}
                                                    render={({ onChange }) =>
                                                        viewOnly ? (
                                                            <Typography className={classes.vdata}>
                                                                {person.phoneNumber}
                                                            </Typography>
                                                        ) : (
                                                            <TextField
                                                                id="outlined-basic"
                                                                name="phoneNumber"
                                                                inputRef={register}
                                                                defaultValue={person.phoneNumber}
                                                                variant="outlined"
                                                                size="small"
                                                                margin="dense"
                                                                inputProps={{ style: { fontSize: '14px' } }}
                                                                className={classes.phoneEntry}
                                                                onChange={onChange}
                                                            />
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography className={classes.sectionName}>Business</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography className={classes.name}>Company Name</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Controller
                                            name="companyName"
                                            control={control}
                                            render={({ onChange }) =>
                                                viewOnly ? (
                                                    <Typography className={classes.vdata}>
                                                        {person.companyName}
                                                    </Typography>
                                                ) : (
                                                    <TextField
                                                        id="outlined-basic"
                                                        name="companyName"
                                                        inputRef={register}
                                                        defaultValue={person.companyName}
                                                        variant="outlined"
                                                        size="small"
                                                        margin="dense"
                                                        inputProps={{ style: { fontSize: '14px' } }}
                                                        className={classes.nameEntry}
                                                        onChange={onChange}
                                                    />
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography className={classes.name}>DUNS #</Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Controller
                                            name="dunsNumber"
                                            control={control}
                                            render={({ onChange }) =>
                                                viewOnly ? (
                                                    <Typography className={classes.vdata}>
                                                        {person.dunsNumber}
                                                    </Typography>
                                                ) : (
                                                    <TextField
                                                        id="outlined-basic"
                                                        name="dunsNumber"
                                                        inputRef={register}
                                                        defaultValue={person.dunsNumber}
                                                        variant="outlined"
                                                        size="small"
                                                        margin="dense"
                                                        inputProps={{ style: { fontSize: '14px' } }}
                                                        className={classes.standardEntry}
                                                        onChange={onChange}
                                                    />
                                                )
                                            }
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container alignItems="center">
                                    <Grid item xs={4}>
                                        <Typography className={classes.name}>City</Typography>
                                    </Grid>

                                    <Grid item xs={8}>
                                        <Grid container wrap="nowrap" spacing={1}>
                                            <Grid item xs={6} className={classes.data}>
                                                <Controller
                                                    name="city"
                                                    control={control}
                                                    render={({ onChange }) =>
                                                        viewOnly ? (
                                                            <Typography className={classes.vdata}>
                                                                {person.city}
                                                            </Typography>
                                                        ) : (
                                                            <TextField
                                                                id="outlined-basic"
                                                                name="city"
                                                                inputRef={register}
                                                                defaultValue={person.city}
                                                                variant="outlined"
                                                                size="small"
                                                                margin="dense"
                                                                inputProps={{ style: { fontSize: '14px' } }}
                                                                onChange={onChange}
                                                            />
                                                        )
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography className={classes.extName}>State</Typography>
                                            </Grid>
                                            <Grid item xs={4} zeroMinWidth>
                                                <Controller
                                                    name="state"
                                                    control={control}
                                                    render={({ onChange }) =>
                                                        viewOnly ? (
                                                            <Typography className={classes.vdata}>
                                                                {person.state}
                                                            </Typography>
                                                        ) : (
                                                            <Select
                                                                id="state"
                                                                name="state"
                                                                placeholder="Enter category"
                                                                variant="outlined"
                                                                onChange={(item) => onChange(item)}
                                                                className={classes.select}
                                                                defaultValue={person.state}
                                                                margin="dense"
                                                                MenuProps={{
                                                                    classes: {
                                                                        paper: classes.menuHeight,
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem value="">Select a state:</MenuItem>
                                                                {Object.keys(stateAbbreviations).map((key) => (
                                                                    <MenuItem value={key} key={key}>
                                                                        {key}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography className={classes.CheckboxHeading}>
                                            Company Certification (If applicable)
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10} className={classes.CheckboxList}>
                                        <Controller
                                            defaultValue={person.companyCertifications}
                                            name="certifications"
                                            control={control}
                                            render={({ onChange }) => (
                                                <FormGroup row={false}>
                                                    {companyCerts.map((cert, index) => {
                                                        return (
                                                            <FormControlLabel
                                                                inputRef={register}
                                                                key={index}
                                                                control={
                                                                    <Checkbox
                                                                        disabled={viewOnly}
                                                                        checked={
                                                                            !!getValues().companyCertifications?.[cert]
                                                                        }
                                                                        onChange={(event) => {
                                                                            onChange(event);
                                                                            handleCerts(event);
                                                                        }}
                                                                        name={`companyCertifications.${cert}`}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={cert}
                                                            />
                                                        );
                                                    })}
                                                </FormGroup>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography className={classes.CheckboxHeading}>
                                            Technology Focus Areas
                                        </Typography>
                                        <DialogContentText className={classes.CheckBoxSubHeading}>
                                            Select focus areas relevant to this contact.
                                        </DialogContentText>
                                    </Grid>
                                    <Grid item xs={10} className={classes.CheckboxList}>
                                        <Controller
                                            defaultValue={person.techFocusAreas}
                                            name="techInterest"
                                            control={control}
                                            render={({ onChange }) => (
                                                <FormGroup row={false}>
                                                    {techAreas.map((techArea, index) => {
                                                        return (
                                                            <FormControlLabel
                                                                inputRef={register}
                                                                key={index}
                                                                control={
                                                                    <Checkbox
                                                                        disabled={viewOnly}
                                                                        checked={
                                                                            !!getValues().techFocusAreas?.[techArea]
                                                                        }
                                                                        onChange={(event) => {
                                                                            onChange(event);
                                                                            handleTechAreas(event);
                                                                        }}
                                                                        name={`techFocusAreas.${techArea}`}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={techArea}
                                                            />
                                                        );
                                                    })}
                                                </FormGroup>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </MUIDrawer>
        </div>
    );
};
export default Drawer;
