import React, { ReactElement } from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';

import theme from 'assets/MuiTheme';
import AlertSnackbar from 'components/alertSnackbar/AlertSnackbar';
import Dialog from 'components/dialog/Dialog';
import Apollo from 'components/providers/Apollo';
import KeyCloak from 'components/providers/KeycloakWrapper';
import Routes from 'components/providers/ReactRouter';
import Redux from 'components/providers/Redux';

const Providers = (): ReactElement => {
    return (
        <KeyCloak>
            <Apollo>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Redux>
                            <Routes />
                            <Dialog />
                            <AlertSnackbar />
                        </Redux>
                    </Router>
                </ThemeProvider>
            </Apollo>
        </KeyCloak>
    );
};

export default Providers;
