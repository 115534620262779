import React, { ReactElement } from 'react';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';

interface Props {
    children: ReactElement;
}

let auth = Keycloak('/keycloak.json');

if (window.location.hostname.includes('edge-afwerx')) {
    auth = Keycloak('/keycloak-afwerx-edge.json');
} else if (window.location.hostname.includes('qa-afwerx')) {
    auth = Keycloak('/keycloak-afwerx-qa.json');
} else if (window.location.hostname.includes('uat-afwerx')) {
    auth = Keycloak('/keycloak-afwerx-uat.json');
}

export default function KeycloakWrapper({ children }: Props): ReactElement {
    return <ReactKeycloakProvider authClient={auth}>{children}</ReactKeycloakProvider>;
}
