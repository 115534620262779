import initialState from 'store/initialState';
import { FindCampaignCampaign } from 'typings/_graphql';
import { Campaign } from 'typings/Campaign';
import { TEMPLATE_TYPES } from './GlobalConstants';

const getTemplateTypeNameFromId = (templateTypeId: number | null): string => {
    const { EVENT, NEWSLETTER, PRESS } = TEMPLATE_TYPES;
    let templateName = 'Type Not Selected';
    if (templateTypeId === EVENT) {
        templateName = 'Event';
    } else if (templateTypeId === NEWSLETTER) {
        templateName = 'Newsletter';
    } else if (templateTypeId === PRESS) {
        templateName = 'Press';
    }
    return templateName;
};

const getCampaignState = (campaign: FindCampaignCampaign): Campaign['template'] => {
    const { EVENT, NEWSLETTER, PRESS } = TEMPLATE_TYPES;

    if (campaign.templateTypeId === EVENT) {
        const eventData = { ...initialState.template.event };
        campaign.campaignTemplateDatas?.forEach((event) => {
            Object.assign(eventData, { [event.inputDescription]: event.inputValue });
        });

        return {
            ...initialState.template,
            type: campaign.templateTypeId ?? 0,
            event: eventData,
        };
    }
    if (campaign.templateTypeId === PRESS) {
        const pressData = { ...initialState.template.press };
        campaign.campaignTemplateDatas?.forEach((press) => {
            Object.assign(pressData, { [press.inputDescription]: press.inputValue });
        });

        return {
            ...initialState.template,
            type: campaign.templateTypeId ?? 0,
            press: pressData,
        };
    }
    if (campaign.templateTypeId === NEWSLETTER) {
        const newsletterData = { ...initialState.template.newsletter };

        campaign.campaignTemplateDatas?.forEach((newsletter) => {
            if (newsletter.groupNumber) {
                newsletterData.articles[newsletter.groupNumber - 1] = {
                    ...newsletterData.articles[newsletter.groupNumber - 1],
                    [newsletter.inputDescription]: newsletter.inputValue,
                };
            } else if (newsletter.inputDescription === 'articleCount') {
                Object.assign(newsletterData, { [newsletter.inputDescription]: parseInt(newsletter.inputValue, 10) });
            } else {
                Object.assign(newsletterData, { [newsletter.inputDescription]: newsletter.inputValue });
            }
        });

        return {
            ...initialState.template,
            type: campaign.templateTypeId ?? 0,
            newsletter: newsletterData,
        };
    }
    return initialState.template;
};

const parseCampaignSenderEmail = (senderEmail: string | null): { senderName: string; senderEmail: string } => {
    const splitSenderEmail = senderEmail?.split(' ');
    let parsedSenderName = '';
    let parsedSenderEmail = '';
    if (splitSenderEmail) {
        parsedSenderName = splitSenderEmail.slice(0, -1).join(' ');
        const [parsedEmailWithBrackets] = splitSenderEmail.slice(-1);
        parsedSenderEmail = parsedEmailWithBrackets.slice(1, -1);
    }
    return { senderName: parsedSenderName, senderEmail: parsedSenderEmail };
};

export { getTemplateTypeNameFromId, getCampaignState, parseCampaignSenderEmail };
