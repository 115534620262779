import React from 'react';

import { DialogContentText, makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Store } from 'store/reducers';

const useStyles = makeStyles({
    dialogImportantText: {
        color: '#07090c',
        fontWeight: 600,
    },
});

const SendEmailConfirmationForm = ({
    formId,
    segmentsWithoutContacts,
    segmentsWithContacts,
    onSubmit,
}: {
    formId: string;
    segmentsWithoutContacts: string[];
    segmentsWithContacts: string[];
    onSubmit: () => void;
}): JSX.Element => {
    const campaign = useSelector((storeState: Store) => storeState.Campaign);
    const { handleSubmit } = useForm();
    const classes = useStyles();

    return (
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            {segmentsWithContacts.length > 0 && (
                <>
                    <DialogContentText data-testid="withContactsFoundText">
                        {`You are about to send `}
                        <span
                            className={classes.dialogImportantText}
                        >{`${campaign.campaignDetails.campaignName}`}</span>
                        {` to the following segment(s):`}
                    </DialogContentText>
                    <DialogContentText className={classes.dialogImportantText}>
                        {segmentsWithContacts.map((name, index) => {
                            return (
                                <>
                                    <strong key={`${name}${index}`}>{name}</strong>
                                    <br />
                                </>
                            );
                        })}
                    </DialogContentText>
                </>
            )}
            {segmentsWithContacts.length > 0 && segmentsWithoutContacts.length > 0 && (
                <>
                    <DialogContentText data-testid="withoutContactsFoundText">
                        {`However, `}
                        <span
                            className={classes.dialogImportantText}
                        >{`${campaign.campaignDetails.campaignName}`}</span>
                        {` will not be sent to the following segment(s) because they contain no subscribers:`}
                    </DialogContentText>
                    <DialogContentText className={classes.dialogImportantText}>
                        {segmentsWithoutContacts.map((name, index) => {
                            return (
                                <>
                                    <strong key={`${name}${index}`}>{name}</strong>
                                    <br />
                                </>
                            );
                        })}
                    </DialogContentText>
                </>
            )}
            {segmentsWithContacts.length === 0 && segmentsWithoutContacts.length > 0 && (
                <>
                    <DialogContentText data-testid="noContactsFoundText">
                        <span
                            className={classes.dialogImportantText}
                        >{`${campaign.campaignDetails.campaignName}`}</span>
                        {` cannot be sent because the segments you have selected contain no subscribers:`}
                    </DialogContentText>
                    <DialogContentText className={classes.dialogImportantText}>
                        {segmentsWithoutContacts.map((name, index) => {
                            return (
                                <>
                                    <strong key={`${name}${index}`}>{name}</strong>
                                    <br />
                                </>
                            );
                        })}
                    </DialogContentText>
                </>
            )}
            <br />
            {segmentsWithContacts.length > 0 ? (
                <DialogContentText className={classes.dialogImportantText}>
                    Are you sure you want to send your campaign?
                </DialogContentText>
            ) : (
                <DialogContentText className={classes.dialogImportantText}>
                    To send campaigns, segments must contain subscribers.
                </DialogContentText>
            )}
        </form>
    );
};

export default SendEmailConfirmationForm;
