import { isArray, startCase } from 'lodash';

interface FilterOption {
    key: string;
    label: string;
    value: string;
    andOrCondition: string;
}

export class FilterObject {
    category: string;

    condition: string;

    value: string | string[];

    andOrCondition: string;

    constructor(category = '', condition = '', value: string | string[] = '', andOrCondition = 'and') {
        this.category = String(category);
        this.condition = String(condition);
        this.value = isArray(value) ? value : String(value);
        this.andOrCondition = andOrCondition;
    }

    getReadableValue(): string {
        const FIRST_CHAR_INDEX = 0;
        if (isArray(this.value)) {
            return this.value.join(', ');
        }
        // syntax is (a|b|\.com), need "a, b, .com" sans quotes
        if (this.value.charAt(FIRST_CHAR_INDEX) === '(') {
            return this.value
                .substring(1, this.value.length - 1)
                .replace(/\|/g, ', ')
                .replace(/\\/g, ''); // remove all escape backslashes
        }
        return this.value;
    }

    getOption(): FilterOption {
        const readableValue = this.getReadableValue();
        return {
            label: [startCase(this.category), this.condition, readableValue].join(' '),
            value: [this.category, this.condition, readableValue].join('|'),
            key: [this.category, this.condition, readableValue].join(' '),
            andOrCondition: this.andOrCondition,
        };
    }
}

export function convertJSONFilterQueryToOptions(filters: any): Array<FilterOption> {
    const usesNewSchema = Object.keys(filters)[0] === 'and' || Object.keys(filters)[0] === 'or';
    let filterOptions: Array<FilterOption>;
    let andOrCondition = 'and';
    if (usesNewSchema) {
        [andOrCondition] = Object.keys(filters);
        const arrayOfFilters = filters[andOrCondition];
        filterOptions = arrayOfFilters
            .map((filter) => {
                const category = Object.keys(filter)[0];
                const values = Object.keys(filter[category])
                    .map((condition) => {
                        const value = filter[category][condition];
                        const filterOption = new FilterObject(category, condition, value, andOrCondition);
                        return filterOption.getOption();
                    })
                    .flat();
                return values;
            })
            .flat();
    } else {
        filterOptions = Object.keys(filters)
            .map((filterCategory) => {
                const filter = filters[filterCategory];
                const values = Object.keys(filter)
                    .map((filterCondition) => {
                        const filterValue = filters[filterCategory][filterCondition];
                        const filterOption = new FilterObject(
                            filterCategory,
                            filterCondition,
                            filterValue,
                            andOrCondition
                        );
                        return filterOption.getOption();
                    })
                    .flat();
                return values;
            })
            .flat();
    }
    return filterOptions;
}

export function convertFilterKeyToFilterObject(
    filterKey: string
): {
    category: string;
    condition: string;
    value: string;
} {
    const category = filterKey.split(' ')[0];
    const condition = filterKey.split(' ')[1];
    const value = filterKey.split(' ')[2];
    const filterObject = { category, condition, value };
    return filterObject;
}

export default { FilterObject, convertJSONFilterQueryToOptions };
