import React, { ReactElement } from 'react';

import { Campaign } from 'typings/Campaign';
import ArticleTable from './ArticleTable';
import NewsletterBannerImg from './NewsletterBannerImg';
import WelcomeMessage from './WelcomeMessage';

const NewsletterContent = ({
    isHtml,
    newsletter,
}: {
    isHtml: boolean;
    newsletter: Campaign['template']['newsletter'];
}): ReactElement => {
    return (
        <div className="NewsletterContent">
            <NewsletterBannerImg isHtml={isHtml} newsletter={newsletter} />
            <WelcomeMessage isHtml={isHtml} newsletter={newsletter} />
            <ArticleTable isHtml={isHtml} newsletter={newsletter} />
        </div>
    );
};
export default NewsletterContent;
