import { ApolloError } from 'apollo-server-errors';
import { Dispatch } from 'redux';

import { showSnackbar } from 'store/actions/Snackbar';

// error can be represented by multiple different types of error, hiding eslint warning
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ShowSnackbarError(dispatch: Dispatch<any>, err: ApolloError | string | unknown): void {
    if (typeof err === 'string') {
        dispatch(showSnackbar(true, err, 'error'));
    } else if ((err as ApolloError).graphQLErrors?.length > 0) {
        const apolloError = err as ApolloError;
        apolloError.graphQLErrors.forEach((graphQLError: ApolloError): void => {
            dispatch(showSnackbar(true, graphQLError.extensions?.exception?.detail ?? graphQLError.message, 'error'));
        });
    } else {
        dispatch(showSnackbar(true, (err as Error).message, 'error'));
    }
}
