import React, { ReactElement } from 'react';

import { TextField } from '@material-ui/core';

import { PhoneProps } from 'typings/InputProps';

const PhoneInput = ({ onChange, className, id, label, value, name }: PhoneProps): ReactElement => {
    return (
        <div className="InputGroup inputDiv">
            <TextField
                variant="outlined"
                color="primary"
                title={name}
                name={name}
                onChange={onChange}
                type="string"
                className={`form-control ${className}`}
                id={`${id}`}
                onFocus={(e): void => e.target.select()}
                defaultValue={label}
                aria-describedby="basic-addon4"
                size="small"
                value={value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}
            />
        </div>
    );
};
export default PhoneInput;
