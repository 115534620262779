import React, { ReactElement } from 'react';

import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';

import { GET_SEGMENT } from 'api/queries/Segments';
import { FindSegmentSegment } from 'typings/_graphql';
import SegmentFilterChips from '../segmentFilterChips/SegmentFilterChips';

import './SegmentDetails.scss';

interface SegmentDetailsProps {
    id: number;
    showSegmentName?: boolean;
}

const SegmentDetails = ({ id, showSegmentName }: SegmentDetailsProps): ReactElement => {
    const segmentData = useQuery(GET_SEGMENT, { variables: { id: id || null } });
    const segment = segmentData.data?.Segment as FindSegmentSegment;

    return (
        <div className="SegmentDetails">
            {segment && (
                <div className="DetailsContainer">
                    {showSegmentName && <p className="name">{segment.name}</p>}
                    {!isEmpty(segment.filterQuery) ? (
                        <SegmentFilterChips
                            filterQuery={segment.filterQuery}
                            additionalContacts={
                                segment.contacts
                                    ? [...segment.contacts.map((contact) => contact.emailAddress)]
                                    : undefined
                            }
                        />
                    ) : (
                        []
                    )}
                </div>
            )}
        </div>
    );
};

SegmentDetails.defaultProps = {
    showSegmentName: true,
};

export default SegmentDetails;
