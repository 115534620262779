import React, { ReactElement, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Button, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RESUBSCRIBE_CONTACT_PUBLIC } from 'api/mutations';
import showSnackbarError from 'components/utils/ShowSnackbarError';

import './Resubscribe.scss';

interface ParamType {
    id: string;
}
const Resubscribe = (): ReactElement => {
    const dispatch = useDispatch();
    const { id } = useParams<ParamType>();
    const [contactEmail, setContactEmail] = useState('');
    const [resubscribeContact] = useMutation(RESUBSCRIBE_CONTACT_PUBLIC);

    useEffect(() => {
        const resubscribe = async () => {
            try {
                const contact = await resubscribeContact({
                    variables: { id },
                });
                setContactEmail(contact.data?.Contact.emailAddress);
            } catch (err) {
                showSnackbarError(dispatch, err); // show snack bar
            }
        };
        if (contactEmail === '') {
            resubscribe();
        }
    }, [contactEmail, dispatch, id, resubscribeContact]);

    return (
        <div className="ResubscribeContainer">
            <Grid container>
                {(!id || !contactEmail) && <h1 className="ErrorMsg">Error: Contact not found</h1>}
                {contactEmail && (
                    <div className="ResubscribeBox">
                        <Grid item className="GridContainerResub">
                            <div>
                                <p className="ResubscribeText Heading">Subscription Reactivated</p>
                                <p className="ResubscribeText">
                                    ({contactEmail}) will continue to receive emails from the Air Force Small Business
                                    Innovation Research office.
                                </p>
                            </div>
                        </Grid>
                        <Grid className="GridContainerResub" container item justify="flex-end">
                            <Grid item>
                                <a className="OkBtn" href="https://www.afsbirsttr.af.mil/">
                                    <Button color="primary" variant="contained">
                                        OK
                                    </Button>
                                </a>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </Grid>
        </div>
    );
};
export default Resubscribe;
