import React, { ReactElement, useEffect } from 'react';

/* eslint-disable no-param-reassign */
import { Button, Chip, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import TableOfContents from 'components/tableOfContents/TableOfContents';
import { allCategoryNames } from 'components/utils/GlobalConstants';
import ViewContactsTable from 'pageComponents/contact/viewContacts/ViewContactsTable';
import { showDialog } from 'store/actions/Dialog';
import { Store } from 'store/reducers';
import { ContactStatus } from 'typings/_graphql';

import './ReviewUpload.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ReviewUpload = ({ props, handleStep, showFieldRequired, validationAnimation, endAnimation }): ReactElement => {
    const { contacts, filename, setContacts, columns } = props;
    const matchedColumns =
        columns.current?.filter((column) => allCategoryNames.includes(column.newColumn.Header as string)) || [];

    const user = useSelector((storeState: Store) => storeState.User);
    const contactList = contacts;

    const dispatch = useDispatch();
    const [subscriptionStatus, setSubscriptionStatus] = React.useState('');
    const date = `${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}`;
    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        contactList.forEach((contact: { contactSource: string; status: any; audienceId: number }) => {
            contact.contactSource = `${filename}_${date}_${user.id}`;
            contact.status = subscriptionStatus;
            contact.audienceId = 1;
        });
        setContacts(contactList);
    }, [contactList, date, filename, setContacts, subscriptionStatus, user.id]);

    const data = React.useMemo(() => {
        return contactList ?? [];
    }, [contactList]);

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if ((event.target as HTMLInputElement).value === ContactStatus.SUBSCRIBED) {
            setSubscriptionStatus(ContactStatus.SUBSCRIBED);
        } else {
            setSubscriptionStatus(ContactStatus.PENDING);
        }
    };

    return (
        <div className="ReviewUpload">
            <div className="ReviewFileContainer">
                <div className="ReviewFileSubContainer">
                    <p className="FileUploadedText">File Uploaded:</p>
                    <Chip
                        data-testid="Chip"
                        icon={<InsertDriveFileIcon />}
                        label={filename}
                        onDelete={() => {
                            dispatch(
                                showDialog(
                                    true,
                                    'Are you sure you want to cancel this import?',
                                    <p className="UploadExitModalText">
                                        If you cancel now, these contacts will not be imported and progress will not be
                                        saved.
                                    </p>,
                                    <>
                                        <Button
                                            id="cancelButton"
                                            color="primary"
                                            onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                                        >
                                            NO, KEEP FILE
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                handleStep(0, true);
                                                dispatch(showDialog(false, '', undefined, undefined));
                                                setContacts([]);
                                            }}
                                        >
                                            YES, CANCEL
                                        </Button>
                                    </>
                                )
                            );
                        }}
                    />

                    <br />
                    {contacts.length > 0 && (
                        <Button
                            id="openContactsBtn"
                            className="ViewContactsBtn"
                            onClick={() =>
                                dispatch(
                                    showDialog(
                                        true,
                                        'Contacts Preview',
                                        <ViewContactsTable contactData={contacts} showValidColumnsOnly={false} />,
                                        <Button
                                            id="cancelButton"
                                            color="primary"
                                            onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                                        >
                                            CLOSE
                                        </Button>,
                                        'lg',
                                        false
                                    )
                                )
                            }
                            disabled={isEmpty(data)}
                            color="primary"
                        >
                            {`VIEW UPLOADED CONTACTS (${data.length})`}
                        </Button>
                    )}
                </div>
            </div>
            <div className="UploadResultsContainer">
                <p className="Title">Upload Results</p>
                {/* For Larger Table of Contents Labels, pass labelWidth:4 && subLabelWidth:11 */}
                <TableOfContents
                    labelWidth={3}
                    label="Columns imported"
                    value={`${matchedColumns.length}/${columns.current?.length || 0}`}
                />
                <TableOfContents labelWidth={3} label="Contacts identified" value={contacts.length} />
                {contacts.length > 0 && (
                    <div className="SubscriptionQuestion">
                        <FormControl component="fieldset">
                            <FormLabel component="legend">
                                *Have these contacts subscribed to the SBIR/STTR mailing list?
                            </FormLabel>
                            <RadioGroup
                                aria-label="subscription status"
                                name="status"
                                value={subscriptionStatus}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel
                                    value={ContactStatus.SUBSCRIBED}
                                    control={<Radio name="subscriptionStatusRadio" data-testid="Permission" required />}
                                    label="Yes, I have permission to email them"
                                />
                                <FormControlLabel
                                    value={ContactStatus.PENDING}
                                    control={<Radio name="subscriptionStatusRadio" data-testid="Pend" required />}
                                    label="Not yet, invite them to subscribe"
                                />
                            </RadioGroup>
                        </FormControl>
                        {showFieldRequired && (
                            <p
                                className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                                onAnimationEnd={endAnimation}
                            >
                                *Please set the subscription status of the contacts
                            </p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
export default ReviewUpload;
