import { CHANGE_STEP } from '../actions/Stepper';

const initialState: { step: number } = {
    step: 0,
};

interface Request {
    type: string;
    payload: { step: number };
}

export default (state = initialState, { type, payload }: Request): { step: number } => {
    const { step } = { ...payload };
    switch (type) {
        case CHANGE_STEP: {
            return { ...state, step };
        }
        default:
            return state;
    }
};
