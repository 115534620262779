import React, { ReactElement } from 'react';

import { TEMPLATE_TYPES } from 'components/utils/GlobalConstants';
import EmailFooter from 'pageComponents/emailTemplates/emailFooter/EmailFooter';
import SocialBar from 'pageComponents/emailTemplates/socialBar/SocialBar';
import SocialFooter from 'pageComponents/emailTemplates/socialBar/SocialFooter';
import { Campaign as ITemplate } from 'typings/Campaign';
import EventContent from './eventComponents/EventContent';
import NewsletterContent from './newsletterComponents/NewsletterContent';
import PressContent from './pressComponents/PressContent';

const templateContainer = {
    cellspacing: '0',
    cellpadding: '0',
    border: '0',
    width: '100%',
    height: '100%',
    borderSpacing: 0,
    margin: '0px auto',
} as React.CSSProperties;
const templateContainerHtml = {
    cellspacing: '0',
    cellpadding: '0',
    border: '0',
    width: '55vw',
    borderSpacing: 0,
    margin: '0px auto',
} as React.CSSProperties;

const templateBody = {
    margin: '0 auto',
    pointerEvents: 'auto',
    textAlign: 'center',
    zIndex: 0,
    borderSpacing: 0,
    backgroundColor: '#0B1539',
    position: 'relative',
    role: 'presentation',
    cellspacing: '0',
    cellpadding: '0',
    border: '0',
    width: '100%',
    height: '100%',
} as React.CSSProperties;
const templateBodyHtml = {
    margin: '0 auto',
    pointerEvents: 'auto',
    textAlign: 'center',
    zIndex: 0,
    borderSpacing: 0,
    backgroundColor: '#0B1539',
    position: 'relative',
    role: 'presentation',
    cellspacing: '0',
    cellpadding: '0',
    border: '0',
    width: '800px',
} as React.CSSProperties;

interface Props {
    isHtml: boolean;
    newsletter: ITemplate['template']['newsletter'];
    event: ITemplate['template']['event'];
    press: ITemplate['template']['press'];
    type: ITemplate['template']['type'];
}

const CampaignTemplate = ({ isHtml, newsletter, press, event, type }: Props): ReactElement => {
    const { NEWSLETTER, PRESS, EVENT } = TEMPLATE_TYPES;
    return (
        <table
            cellPadding="0"
            cellSpacing="0"
            role="presentation"
            className={isHtml ? 'body' : undefined}
            style={isHtml ? templateContainerHtml : templateContainer}
        >
            <tbody>
                <tr>
                    <td
                        className={isHtml ? 'float-center' : undefined}
                        align="center"
                        valign="top"
                        style={{ padding: 0 }}
                    >
                        <table
                            cellPadding="0"
                            cellSpacing="0"
                            role="presentation"
                            style={isHtml ? templateBodyHtml : templateBody}
                            className={isHtml ? 'container float-center' : undefined}
                        >
                            <tbody>
                                <tr>
                                    <td align="center" style={{ padding: 0 }}>
                                        {type === NEWSLETTER && (
                                            <NewsletterContent isHtml={isHtml} newsletter={newsletter} />
                                        )}
                                        {type === PRESS && <PressContent isHtml={isHtml} press={press} />}
                                        {type === EVENT && <EventContent isHtml={isHtml} event={event} />}
                                        {isHtml && `<!--[if !mso & !IE]> <!---->`}
                                        {isHtml && <SocialFooter />}
                                        {isHtml && `<!-- <![endif]-->`}
                                        {isHtml && `<!--[if mso | IE]>`}
                                        <SocialBar isHtml={isHtml} />
                                        {isHtml && `<![endif]-->`}
                                        <EmailFooter isHtml={isHtml} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
export default CampaignTemplate;
