import React, { ReactElement } from 'react';

import { Box, IconButton } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import './NavIcons.scss';

export const ComplianceContainer = (): ReactElement => {
    return (
        <div className="icon-container">
            <div className="icon">
                <IconButton
                    className="overlay-top"
                    aria-label="compliance"
                    href="https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business"
                    target="_blank"
                >
                    <OpenInNewIcon style={{ color: 'white' }} />
                    <Box component="span" style={{ color: 'white' }} fontSize="14px">
                        Compliance
                    </Box>
                </IconButton>
            </div>
        </div>
    );
};

export default ComplianceContainer;
