const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
export function getTimePassedFromDate(dateString: string): string {
    const modifiedDate = new Date(dateString).getTime();
    const now: number = new Date().getTime();
    const timePassed = Math.abs(now - modifiedDate);
    const msInYear = 31536000000;
    const msInMonth = msInYear / 12;
    const msInDay = 86400000;
    const msInHour = msInDay / 24;
    const msInMin = 60000;

    if (timePassed > msInYear) {
        // display in years
        return `${Math.round(timePassed / msInYear)} years ago`;
    }
    if (timePassed > msInMonth) {
        // display in months
        return `${Math.round(timePassed / msInMonth)} months ago`;
    }
    if (timePassed > msInDay) {
        // display in days
        return `${Math.round(timePassed / msInDay)} days ago`;
    }
    // display in hours
    if (timePassed > msInHour) {
        // display in days
        return `${Math.round(timePassed / msInHour)} hours ago`;
    }
    // Display in Minutes
    return `${Math.round(timePassed / msInMin)} minutes ago`;
}

export function getLocalDateString(dateString: string): string {
    return new Date(dateString).toLocaleDateString(undefined).replace(new RegExp('/', 'g'), '-');
}

export function dateConstructor(value?: Date | undefined): { date: string; day: string; month: number } {
    let date;

    if (value) {
        date = new Date(value);
    } else {
        date = new Date();
    }

    const dayOfWeek = date.getUTCDay();
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const dayOfMonth = date.getUTCDate();
    return { date: `${monthNames[month]} ${dayOfMonth}, ${year}`, day: days[dayOfWeek], month };
}

export default function dateDecomposerMonthNames(date: string): string {
    // this function takes in a date in the format: Month Day, Year and returns the format YYYY-MM-DD
    // example: March 1, 2021 returns 2021-03-01
    const year = date.toString().slice(date.length - 4);
    let day = date.slice(date.length - 8, date.length - 6);
    if (day.includes(' ')) day = `0${day.slice(1)}`;
    const month = date.split(' ')[0];

    let monthNum = `${monthNames.indexOf(month) + 1}`;
    if (monthNum.length === 1) monthNum = `0${monthNum}`;
    const formattedDate = `${year}-${monthNum}-${day}`;
    return formattedDate;
}
