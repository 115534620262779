import { gql } from '@apollo/client';

const CREATE_SEGMENT = gql`
    mutation createSegment($data: CreateSegmentInput!) {
        Segment: createSegment(data: $data) {
            id
        }
    }
`;

const UPDATE_SEGMENT = gql`
    mutation updateSegment($id: Float!, $data: UpdateSegmentInput!) {
        Segment: updateSegment(id: $id, data: $data) {
            id
        }
    }
`;

const DELETE_SEGMENT = gql`
    mutation deleteSegment($id: Float!) {
        Segment: deleteSegment(id: $id) {
            id
        }
    }
`;

export { CREATE_SEGMENT, UPDATE_SEGMENT, DELETE_SEGMENT };
