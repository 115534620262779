import React, { useEffect, useMemo, useState } from 'react';

import { FormLabel, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';

import { allCategoryNames } from 'components/utils/GlobalConstants';
import { MatchingColumns } from 'components/utils/UploadContactsUtils';

import './MatchColumnsForm.scss';

const useStyles = makeStyles({
    select: {
        height: '2.5rem',
        margin: '0.3rem 0 1.5rem',
    },
    menuHeight: {
        height: '20vh',
        minHeight: '10rem',
    },
    inputLabel: {
        fontSize: '0.9rem',
        marginBottom: '0.1rem',
        paddingLeft: '1rem',
    },
    descriptionLabel: {
        paddingBottom: '1.5rem',
    },
});

const MatchColumnsForm = ({
    columns,
    formId,
    onSubmit,
}: {
    columns: MatchingColumns[];
    formId: string;
    onSubmit: (formData: Record<string, unknown>) => void;
}): JSX.Element => {
    const [defaultValues, setDefaultValues] = useState({});

    useEffect(() => {
        const tmpDefaultValues = {};
        columns.forEach((column) => {
            const newColumnName = column.newColumn.Header as string;
            const originalColumnName = column.originalColumn.Header as string;
            const columnValue = allCategoryNames.includes(newColumnName as string) ? newColumnName : '';
            tmpDefaultValues[originalColumnName] = column.ignored ? 'ignored' : columnValue;
        });
        setDefaultValues(tmpDefaultValues);
    }, [columns]);

    const { handleSubmit, control, register } = useForm();
    const matchedValues = useMemo(
        () => Object.values(defaultValues).filter((value) => allCategoryNames.includes(value as string)),
        [defaultValues]
    );
    const classes = useStyles();

    return (
        <>
            <FormLabel className={classes.descriptionLabel} component="legend">
                Unrecognized columns cannot be imported. Please relabel or ignore columns.
            </FormLabel>
            <form onSubmit={handleSubmit(onSubmit)} id={formId}>
                {columns.map((column) => {
                    const originalColumnName = column.originalColumn.Header as string;
                    return (
                        <Controller
                            name={originalColumnName}
                            control={control}
                            defaultValue={defaultValues[originalColumnName]}
                            as={({ onChange }) => (
                                <>
                                    <InputLabel className={classes.inputLabel}>
                                        Rename &quot;{originalColumnName}&quot; As
                                    </InputLabel>
                                    <Select
                                        id={originalColumnName}
                                        name={originalColumnName}
                                        fullWidth
                                        variant="outlined"
                                        onChange={(event) => {
                                            onChange(event);
                                            if (event.target.name && event.target.value) {
                                                setDefaultValues({
                                                    ...defaultValues,
                                                    [event.target.name]: event.target.value,
                                                });
                                            }
                                        }}
                                        inputProps={{
                                            'aria-label': originalColumnName,
                                        }}
                                        className={classes.select}
                                        MenuProps={{
                                            classes: {
                                                paper: classes.menuHeight,
                                            },
                                        }}
                                        inputRef={register}
                                        defaultValue={defaultValues[originalColumnName]}
                                        required
                                    >
                                        <MenuItem value="">Select a category:</MenuItem>
                                        <MenuItem value="ignored">(Ignore Column)</MenuItem>
                                        {allCategoryNames.map((key) => {
                                            return (
                                                <MenuItem
                                                    value={key}
                                                    key={key}
                                                    disabled={
                                                        matchedValues.includes(key) &&
                                                        key !== defaultValues[originalColumnName]
                                                    }
                                                >
                                                    {key}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </>
                            )}
                        />
                    );
                })}
            </form>
        </>
    );
};

export default MatchColumnsForm;
