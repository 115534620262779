import React, { ReactElement } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { GET_SEGMENTS_CONTACTS } from 'api/queries/index';
import { CAMPAIGN_PAGE } from 'components/utils/GlobalConstants';
import ViewContactsTable from 'pageComponents/contact/viewContacts/ViewContactsTable';
import CampaignTemplate from 'pageComponents/emailTemplates/CampaignTemplate';
import SegmentDetails from 'pageComponents/segment/segmentDetails/SegmentDetails';
import { enableReviewMode, selectCampaignTab } from 'store/actions/Campaign';
import { showDialog } from 'store/actions/Dialog';
import { Store } from 'store/reducers/index';

import 'assets/sass/ReactTableStyle.scss';
import './ReviewCampaign.scss';

const ReviewCampaign = (): ReactElement => {
    const { SELECT_AUDIENCE, ADD_DETAILS, ADD_CONTENT } = CAMPAIGN_PAGE;
    const campaign = useSelector((storeState: Store) => storeState.Campaign);
    const { segmentIds } = campaign.selectAudience;
    const segmentsContacts = useQuery(GET_SEGMENTS_CONTACTS, { variables: { ids: segmentIds } });
    const contactData = segmentsContacts.data?.Contacts;
    const dispatch = useDispatch();

    return (
        <div className="ReviewCampaign">
            <div className="ReviewForm">
                <Grid container justify="flex-end" direction="row" spacing={2}>
                    <Grid item xs={7}>
                        <p className="CampaignName">{campaign.campaignDetails.campaignName}</p>
                    </Grid>
                    <Grid item xs={5} className="ViewContactsBtnGrid">
                        <Button
                            id="openContactsBtn"
                            className="ViewContactsBtn"
                            onClick={() =>
                                dispatch(
                                    showDialog(
                                        true,
                                        'Audience Preview',
                                        <ViewContactsTable contactData={contactData} />,
                                        <Button
                                            id="cancelButton"
                                            color="primary"
                                            onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                                        >
                                            CLOSE
                                        </Button>,
                                        'lg'
                                    )
                                )
                            }
                            disabled={isEmpty(contactData)}
                            color="primary"
                        >
                            {`VIEW CONTACTS${contactData ? ` (${contactData?.length})` : ''}`}
                        </Button>
                    </Grid>
                    <Grid container item justify="space-between">
                        <Grid item>
                            <p className="SectionLabel">Audience</p>
                        </Grid>
                        <Grid item>
                            {!campaign.isSent && (
                                <Button
                                    color="primary"
                                    className="EditSegmentsBtn"
                                    onClick={() => {
                                        dispatch(enableReviewMode({ boolean: true }));
                                        dispatch(selectCampaignTab({ step: SELECT_AUDIENCE }));
                                    }}
                                >
                                    <EditIcon className="EditIcon" />
                                    EDIT
                                </Button>
                            )}
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <p className="ItemLabel SegmentLabel">Selected Segment(s)</p>
                    </Grid>

                    {segmentIds.length > 0 &&
                        segmentIds.map((segment) => {
                            return (
                                <Grid item xs={12} key={segment}>
                                    <SegmentDetails id={segment} key={`${segment} Details`} showSegmentName />
                                </Grid>
                            );
                        })}
                    <Grid container item justify="space-between">
                        <Grid item>
                            <p className="SectionLabel CampaignDetailsLabel">Campaign Details</p>
                        </Grid>
                        <Grid item>
                            {!campaign.isSent && (
                                <Button
                                    color="primary"
                                    className="EditDetailsBtn"
                                    onClick={() => {
                                        dispatch(enableReviewMode({ boolean: true }));
                                        dispatch(selectCampaignTab({ step: ADD_DETAILS }));
                                    }}
                                >
                                    <EditIcon className="EditIcon" />
                                    EDIT
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <p className="ItemLabel">Campaign Name</p>
                <p className="name">{campaign.campaignDetails.campaignName}</p>
                <p className="ItemLabel">Subject Line</p>
                <p className="name">{campaign.campaignDetails.subject}</p>
                <p className="ItemLabel">From</p>
                <p className="name">{`${campaign.campaignDetails.senderName} <${campaign.campaignDetails.senderEmail}>`}</p>
            </div>
            {campaign.template.type && (
                <div className="TemplatePreview">
                    <div className="EditTemplate">
                        {!campaign.isSent && (
                            <Button
                                className="EditTemplateBtn"
                                color="primary"
                                onClick={() => {
                                    dispatch(enableReviewMode({ boolean: true }));
                                    dispatch(selectCampaignTab({ step: ADD_CONTENT }));
                                }}
                            >
                                <EditIcon className="EditIcon" />
                                EDIT
                            </Button>
                        )}
                    </div>
                    <CampaignTemplate
                        isHtml={false}
                        event={campaign.template.event}
                        newsletter={campaign.template.newsletter}
                        press={campaign.template.press}
                        type={campaign.template.type}
                    />
                </div>
            )}
        </div>
    );
};
export default ReviewCampaign;
