import { useEffect, useState } from 'react';

import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const usePageTracking = (): void => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        if (location.pathname.includes('engage.mile-two.com')) {
            ReactGA.initialize('G-VVS5N325EQ');
            setInitialized(true);
        }
    }, [location]);

    useEffect(() => {
        if (initialized) {
            ReactGA.send('pageview');
        }
    }, [initialized, location]);
};

export default usePageTracking;
