import React, { useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useKeycloak } from '@react-keycloak/web';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { GET_AUTHORIZED_USER } from 'api/queries/index';
import { setUserState } from 'store/actions/User';
import { UserState } from 'store/reducers/User';

const PrivateRouter = ({ children }): JSX.Element => {
    const { keycloak, initialized } = useKeycloak();
    const [getUser, { data, error }] = useLazyQuery(GET_AUTHORIZED_USER);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (error) {
            history.push('/unauthorized');
        } else if (data) {
            const loggedinUser: UserState = {} as UserState;
            loggedinUser.email = data.user.emailAddress;
            loggedinUser.name = data.user.name;
            loggedinUser.id = data.user.id;
            dispatch(setUserState(loggedinUser));
        }
    }, [data, error, dispatch, history]);

    useEffect(() => {
        const getUserProfile = async (
            keycloakInstance: Keycloak.KeycloakInstance
        ): Promise<Keycloak.KeycloakProfile> => {
            const userProfile = await keycloakInstance.loadUserProfile();
            return userProfile;
        };

        if (initialized && keycloak.token) {
            getUserProfile(keycloak).then((kc) => {
                getUser();
            });
        }
    }, [initialized, keycloak, getUser, dispatch]);

    if (initialized) {
        return <div>{children}</div>;
    }
    return <div>Authenticating user, please wait...</div>;
};

export default PrivateRouter;
