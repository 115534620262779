import React, { useCallback, useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Grid, IconButton, InputLabel, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import {
    ArrowBackIos as ArrowBackIosIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
    Clear as ClearIcon,
    Search as SearchIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { GET_SEGMENTS } from 'api/queries';
import SegmentTileList from 'pageComponents/segment/segmentTileList/SegmentTileList';
import { FindSegmentsWithCountSegmentsWithCountResponse } from 'typings/_graphql';

import './SegmentLibrary.scss';

const useStyles = makeStyles({
    endAdornment: {
        cursor: 'pointer',
        padding: '0.1rem',
    },
    startAdornment: {
        marginRight: '0.3rem',
        padding: '0.1rem',
    },
    inputProps: {
        height: '36px',
        fontSize: '16px',
        backgroundColor: '#FFF',
        borderRadius: 4,
        position: 'relative',
        border: '1px solid #ced4da',
        padding: '10px 26px 10px 12px',
    },
    selectProps: {
        fontSize: '16px',
        backgroundColor: '#FFF',
        borderRadius: 4,
        position: 'relative',
        border: '1px solid #ced4da',
        padding: '10px 26px 10px 12px',
    },
});

type PaginationConfig = {
    offset: number;
    limit: number;
};

type SortConfig = {
    field: string;
    direction: string;
};
const sortTypes = [
    { label: 'Newest - Oldest', value: 'dateD' },
    { label: 'Oldest - Newest', value: 'dateA' },
    { label: 'A - Z', value: 'nameA' },
    { label: 'Z - A', value: 'nameD' },
];

const pageSizeOptions = [10, 20, 50];

const SegmentLibrary = (): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();

    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [showing, setShowing] = useState(0);
    const [total, setTotal] = useState(0);

    const [searchTerm, setSearchTeam] = useState('');
    const [sortType, setSortType] = useState('dateD');

    const onClear = () => {
        setSearchTeam('');
    };

    const getPaginationConfig = useCallback((): PaginationConfig => {
        return {
            offset: (pageNumber - 1) * pageSize,
            limit: pageSize,
        };
    }, [pageNumber, pageSize]);

    const getSortConfig = useCallback((): SortConfig => {
        return {
            field: sortType.includes('date') ? 'modifiedDate' : 'name',
            direction: sortType.endsWith('D') ? 'DESC' : 'ASC',
        };
    }, [sortType]);

    const getFilterConfig = useCallback((): string => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const filters: any = {};
        if (searchTerm.length > 0) {
            filters.name = { contains: searchTerm };
        }
        return JSON.stringify(filters);
    }, [searchTerm]);

    // const segmentQuery = useQuery(GET_SEGMENTS_WITH_COUNT, {

    const segmentQuery = useQuery(GET_SEGMENTS, {
        variables: {
            audienceId: 1,
            // pagination: { ...getPaginationConfig() },
            // filters: getFilterConfig(),
            // sort: { ...getSortConfig() },
        },
        fetchPolicy: 'network-only',
    });
    useEffect(() => {
        if (segmentQuery?.data) {
            const totalQuery = segmentQuery.data?.SegmentsWithCountResponse?.nonPaginatedCount;
            const totalSegments = segmentQuery.data?.SegmentsWithCountResponse?.segments?.length;
            setTotal(totalQuery);
            setShowing(totalSegments);

            if (totalQuery % pageSize !== 0) {
                setPages(Math.floor(totalQuery / pageSize) + 1);
            } else {
                setPages(Math.floor(totalQuery / pageSize));
            }
        }
    }, [segmentQuery, pageSize]);

    let pageStart;
    if (Number.isNaN(pageSize)) {
        pageStart = 1;
    } else {
        pageStart = (pageNumber - 1) * pageSize + 1;
    }
    let pageEnd;
    if (Number.isNaN(pageSize)) {
        pageEnd = total;
    } else {
        pageEnd = (pageNumber - 1) * pageSize + showing;
    }

    return (
        <div className="SegmentLibrary">
            <div className="SegmentLibrary__Body">
                <div className="SegmentLibrary__Header">
                    <Grid container spacing={6}>
                        <Grid item xs={8}>
                            <h1 className="SegmentLibrary__Title">Segment Library</h1>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    history.push('/audience/segments/create');
                                }}
                            >
                                CREATE SEGMENT
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <Grid container spacing={6} alignItems="flex-end" style={{ display: 'none' }}>
                    <Grid item xs={5}>
                        <TextField
                            id="globalFilterInput"
                            variant="outlined"
                            InputProps={{
                                className: classes.inputProps,
                                startAdornment: <SearchIcon className={classes.startAdornment} />,
                                endAdornment: <ClearIcon className={classes.endAdornment} onClick={onClear} />,
                            }}
                            placeholder="Search Segments"
                            fullWidth
                            value={searchTerm}
                            onChange={(event) => {
                                setPageNumber(1);
                                setSearchTeam(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={4}>
                        <InputLabel>Sort</InputLabel>
                        <Select
                            title="senderEmail"
                            name="senderEmail"
                            label="Sort"
                            classes={{ select: classes.selectProps }}
                            style={{ width: '100%' }}
                            value={sortType}
                            onChange={(event) => {
                                if (event.target?.value) setSortType(event.target.value as string);
                            }}
                        >
                            {sortTypes.map((s) => {
                                return <MenuItem value={s.value}>{s.label}</MenuItem>;
                            })}
                        </Select>
                    </Grid>
                </Grid>
                <div className="SegmentTiles">
                    {!segmentQuery.loading && <SegmentTileList segments={segmentQuery?.data?.Segments || []} />}
                    {segmentQuery.loading && <h5>Loading...</h5>}
                </div>
                <div className="TableNavigation__Overflow" style={{ display: 'none' }}>
                    <div className="TableNavigation__Left">
                        Showing {pageStart}-{pageEnd} of {total}
                    </div>
                    <div className="TableNavigation__Right">
                        <span>Segments per page</span>
                        <Select
                            id="pageSizeOptionSelect"
                            name="pageSizeOptionSelect"
                            value={Number.isNaN(pageSize) ? 'All' : pageSize}
                            variant="outlined"
                            onChange={(e): void => {
                                setPageSize(Number(e.target.value));
                                setPageNumber(1);
                            }}
                            margin="dense"
                        >
                            {pageSizeOptions.map((pageSizeOption) => (
                                <MenuItem key={pageSizeOption} value={pageSizeOption}>
                                    {pageSizeOption}
                                </MenuItem>
                            ))}
                            <MenuItem value="All">All</MenuItem>
                        </Select>
                        <IconButton
                            id="previousPageButton"
                            onClick={(): void => {
                                setPageNumber(pageNumber - 1);
                            }}
                            disabled={pageNumber === 1}
                        >
                            <ArrowBackIosIcon fontSize="small" />
                        </IconButton>
                        <span>Page</span>
                        <TextField
                            size="small"
                            variant="outlined"
                            className="RoundedInput"
                            id="goToPageInput"
                            type="number"
                            value={pageNumber <= 0 ? 1 : pageNumber}
                            onChange={(event) => {
                                const targetValue = (event.target as HTMLInputElement).value;
                                let newPage: number;
                                if (targetValue && Number(targetValue) <= pages && Number(targetValue) > 0) {
                                    newPage = Number(targetValue);
                                } else {
                                    newPage = 1;
                                }
                                if (newPage !== pageNumber) setPageNumber(newPage);
                            }}
                            onKeyPress={(event): void => {
                                if (event.key === 'Enter') {
                                    const targetValue = (event.target as HTMLInputElement).value;
                                    let newPage: number;
                                    if (targetValue && Number(targetValue) <= pages && Number(targetValue) > 0) {
                                        newPage = Number(targetValue);
                                    } else {
                                        newPage = 1;
                                    }
                                    if (newPage !== pageNumber) setPageNumber(newPage);
                                }
                            }}
                        />
                        <span>of {Number.isNaN(pages) ? 1 : pages.toString()}</span>
                        <IconButton
                            id="nextPageButton"
                            onClick={(): void => {
                                setPageNumber(pageNumber + 1);
                            }}
                            disabled={pageNumber === pages}
                        >
                            <ArrowForwardIosIcon fontSize="small" />
                        </IconButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SegmentLibrary;
