import React, { ReactElement, useEffect, useState } from 'react';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { Button, Chip, Grid } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { GET_CAMPAIGN, GET_SEGMENTS_CONTACTS } from 'api/queries';
import AnalyticsStats from 'components/analyticStats/AnalyticsStats';
import { getTemplateTypeNameFromId } from 'components/utils/CampaignUtils';
import { getLocalDateString, getTimePassedFromDate } from 'components/utils/DateUtil';
import { resetCampaign, setCampaignState } from 'store/actions/Campaign';
import { FindCampaign, FindCampaignsCampaigns } from 'typings/_graphql';

import './CampaignTile.scss';

interface CampaignTileProps {
    campaign: FindCampaignsCampaigns;
}

const CampaignTile = ({ campaign }: CampaignTileProps): ReactElement => {
    const history = useHistory();
    const [campaignQuery, { data }] = useLazyQuery(GET_CAMPAIGN, {
        variables: { id: campaign.id },
        fetchPolicy: 'network-only',
    });

    const dispatch = useDispatch();
    useEffect(() => {
        if (data) {
            const { Campaign }: FindCampaign = data;
            dispatch(setCampaignState({ campaign: Campaign }));
            if (campaign.isSent) {
                history.push(`/campaign/${Campaign.id}`);
            } else {
                history.push(`/campaign/create`);
            }
        }
    }, [campaign.campaignAnalytics, campaign.isSent, data, dispatch, history]);

    const getSegmentNames = (): string => {
        let segmentNames = '';
        if (campaign.segments?.length > 1) {
            campaign.segments?.forEach((listItem) => {
                segmentNames = `${segmentNames.concat(listItem.name)}, `;
            });
            segmentNames = segmentNames.slice(0, segmentNames.length - 2);
        } else {
            campaign.segments?.forEach((listItem: { name: string }) => {
                segmentNames = listItem.name;
            });
        }
        return segmentNames;
    };

    // Can be changed to ENV variable if needed for Demo - decision made 10/4/21
    const realType = 'no';
    const real = realType === 'no' ? 'no' : 'real';

    const client = useApolloClient();

    const [processedCampaign, setProcessedCampaign] = useState({ ...campaign });


    useEffect(() => {
        async function fauxSent() {
            if (campaign?.isSent === true) {
                const { data: data2, errors } = await client.query({
                    query: GET_SEGMENTS_CONTACTS,
                    variables: { ids: campaign.segments.map((segment) => segment.id) },
                    fetchPolicy: 'network-only',
                    errorPolicy: 'all',
                });
                const sampleSent =
                    campaign?.campaignAnalytics?.sentCount !== null &&
                    campaign?.campaignAnalytics?.sentCount !== undefined
                        ? campaign.campaignAnalytics.sentCount
                        : data2.Contacts.length;
                const tinySent = sampleSent < 20;

                const smallSent = sampleSent >= 20 && sampleSent <= 100;

                const mediumSent = sampleSent > 100 && sampleSent < 500;

                const largeSent = sampleSent >= 500 && sampleSent < 1000;

                let sampleSizeAdjust;
                if (largeSent) {
                    sampleSizeAdjust = 2;
                } else if (mediumSent) {
                    sampleSizeAdjust = 3;
                } else if (smallSent) {
                    sampleSizeAdjust = 5;
                } else if (tinySent) {
                    sampleSizeAdjust = 8;
                } else {
                    sampleSizeAdjust = 1;
                }
                const hardBounce = Math.round(sampleSent * (Math.random() * (0.01 * sampleSizeAdjust)));

                const delivered = sampleSent - hardBounce;

                const opened = Math.min(
                    Math.round(
                        delivered * (Math.random() * (0.35 * sampleSizeAdjust > 0.83 ? 0.83 : 0.35 * sampleSizeAdjust))
                    ),
                    delivered
                );

                const emailSystemOpens = Math.abs(
                    Math.round((delivered - opened) * (Math.random() * (0.12 * sampleSizeAdjust)))
                );

                const clicks = Math.round((opened + emailSystemOpens) * (Math.random() * (0.5 - 0.08) + 0.08));

                const unSubs = Math.round(sampleSent * (Math.random() * 0.12));

                const campaignAnalytics = {
                    sentCount: sampleSent,
                    withClicksCount: clicks,
                    deliveredCount: delivered,
                    openedCount: opened,
                    hardBounceCount: hardBounce,
                    complaintCount: 0,
                    hasUnsubscribesCount: unSubs,
                    unknownStatusCount: 0,
                    modifiedDate: new Date(),
                };
                setProcessedCampaign({ ...campaign, campaignAnalytics });
            }
        }
        fauxSent();
    }, [campaign, client]);

    return (
        <div className="CampaignTile">
            {campaign.isSent ? (
                <Grid container className="SentGridContainer">
                    <Grid item container md={7} className="CampaignCardDetails">
                        <Grid item container justify="space-between" alignItems="baseline">
                            <Grid item>
                                <p id="CampaignTitle">{campaign.title}</p>
                                <Chip label="SENT" id="StatusSent" />
                            </Grid>
                        </Grid>
                        <Grid item container alignItems="flex-end" justify="flex-start">
                            <Grid item container className="DetailsGrid">
                                <Grid item container justify="flex-start" alignItems="baseline">
                                    <Grid item md={6}>
                                        <p className="DetailsLabel">Audience Segment</p>
                                    </Grid>

                                    <Grid item md={3}>
                                        <p className="DetailsLabel">Template</p>
                                    </Grid>
                                    <Grid item md={3}>
                                        <p className="DetailsLabel">Date Sent</p>
                                    </Grid>
                                </Grid>

                                <Grid item container alignItems="baseline">
                                    <Grid item md={6}>
                                        <p className="DetailsInfo">{getSegmentNames()}</p>
                                    </Grid>
                                    <Grid item md={3}>
                                        <p className="DetailsInfo">
                                            {getTemplateTypeNameFromId(campaign.templateTypeId)}
                                        </p>
                                    </Grid>
                                    <Grid item md={3}>
                                        <p className="DetailsInfo">{getLocalDateString(campaign.sendDate)}</p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className="AnalyticDivider" container item md={5}>
                        {/* Reporting Icons go here */}
                        <Grid container item justify="space-between">
                            {/* <Grid item md={1} /> */}
                            <Grid item>
                                {processedCampaign?.campaignAnalytics &&
                                    processedCampaign?.campaignAnalytics?.sentCount !== null && (
                                        <p className="SentContacts">
                                            Sent-{' '}
                                            <strong>
                                                {real === 'real'
                                                    ? `${campaign?.campaignAnalytics?.sentCount} Contacts`
                                                    : `${processedCampaign?.campaignAnalytics?.sentCount} Contacts`}
                                            </strong>
                                        </p>
                                    )}
                            </Grid>
                            {/* <Grid md={1} item /> */}
                            <Grid item>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        campaignQuery();
                                    }}
                                >
                                    <VisibilityIcon id="Eye" />
                                    View Details
                                </Button>
                            </Grid>
                        </Grid>

                        {processedCampaign?.campaignAnalytics &&
                        processedCampaign?.campaignAnalytics?.sentCount !== null ? (
                            <Grid item container spacing={2}>
                                <AnalyticsStats
                                    label="Delivered"
                                    needsGridPadding={false}
                                    size={3}
                                    // make sure numerator is a whole number
                                    // numerator={
                                    //     real === 'real'
                                    //         ? campaign?.campaignAnalytics?.deliveredCount
                                    //         : processedCampaign?.campaignAnalytics?.deliveredCount
                                    // }
                                    numerator={processedCampaign?.campaignAnalytics?.deliveredCount}
                                    denominator={
                                        real === 'real'
                                            ? campaign?.campaignAnalytics?.sentCount
                                            : processedCampaign?.campaignAnalytics?.sentCount
                                    }
                                />
                                <AnalyticsStats
                                    label="Opened"
                                    size={3}
                                    numerator={
                                        real === 'real'
                                            ? campaign?.campaignAnalytics?.openedCount
                                            : processedCampaign?.campaignAnalytics?.openedCount
                                    }
                                    denominator={
                                        real === 'real'
                                            ? campaign?.campaignAnalytics?.deliveredCount
                                            : processedCampaign?.campaignAnalytics?.deliveredCount
                                    }
                                />
                                <AnalyticsStats
                                    label="CTR"
                                    size={3}
                                    includeContactCount={false}
                                    denominator={
                                        real === 'real'
                                            ? campaign?.campaignAnalytics?.deliveredCount
                                            : processedCampaign?.campaignAnalytics?.deliveredCount
                                    }
                                    numerator={
                                        real === 'real'
                                            ? campaign?.campaignAnalytics?.withClicksCount
                                            : processedCampaign?.campaignAnalytics?.withClicksCount
                                    }
                                />
                                <AnalyticsStats
                                    label="Unsubscribed"
                                    size={3}
                                    omitRightBorder
                                    denominator={
                                        real === 'real'
                                            ? campaign?.campaignAnalytics?.deliveredCount
                                            : processedCampaign?.campaignAnalytics?.deliveredCount
                                    }
                                    numerator={
                                        real === 'real'
                                            ? campaign?.campaignAnalytics?.hasUnsubscribesCount
                                            : processedCampaign?.campaignAnalytics?.hasUnsubscribesCount
                                    }
                                />
                            </Grid>
                        ) : (
                            <div className="PendingAnalyticsContainer">
                                <p className="PendingAnalyticsHeader">Analytics are not available yet.</p>
                                <p className="PendingAnalyticsText">There are no analytics to show.</p>
                                <p className="PendingAnalyticsText">Please check back on this campaign later.</p>
                            </div>
                        )}
                    </Grid>
                </Grid>
            ) : (
                <Grid container className="DraftTile">
                    <Grid item container justify="space-between" alignItems="baseline">
                        <Grid item>
                            <p id="CampaignTitle">{campaign.title}</p>
                            <Chip label="DRAFT" id="StatusDraft" />
                        </Grid>

                        <Grid item>
                            <Button
                                color="primary"
                                onClick={() => {
                                    dispatch(resetCampaign({ name: '' }));
                                    campaignQuery();
                                }}
                            >
                                <CreateIcon id="Pencil" />
                                Edit
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems="flex-end" justify="flex-end">
                        <Grid item container className="DetailsGrid" md={10}>
                            <Grid item container justify="flex-start" alignItems="baseline">
                                <Grid item md={4}>
                                    <p className="DetailsLabel">Audience Segment</p>
                                </Grid>

                                <Grid item md={2}>
                                    <p className="DetailsLabel">Template</p>
                                </Grid>
                                <Grid item md={2}>
                                    <p className="DetailsLabel">Date Created</p>
                                </Grid>
                                <Grid item md={2}>
                                    <p className="DetailsLabel">Recent Activity</p>
                                </Grid>
                            </Grid>
                            <Grid item container alignItems="baseline">
                                <Grid item md={4}>
                                    <p className="DetailsInfo">{getSegmentNames()}</p>
                                </Grid>
                                <Grid item md={2}>
                                    <p className="DetailsInfo">{getTemplateTypeNameFromId(campaign.templateTypeId)}</p>
                                </Grid>
                                <Grid item md={2}>
                                    <p className="DetailsInfo">
                                        {campaign.sendDate
                                            ? getLocalDateString(campaign.sendDate)
                                            : getLocalDateString(campaign.createdDate)}
                                    </p>
                                </Grid>
                                <Grid item md={2}>
                                    <p className="DetailsInfo">{getTimePassedFromDate(campaign.modifiedDate)}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={2} />
                    </Grid>
                </Grid>
            )}
        </div>
    );
};
export default CampaignTile;
