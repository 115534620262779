import React from 'react';

import { DialogContentText, Input } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Store } from 'store/reducers';

const SendTestEmailForm = ({
    formId,
    onSubmit,
    useDefaultEmail,
}: {
    formId: string;
    onSubmit: (formData: Record<string, unknown>) => void;
    useDefaultEmail?: boolean;
}): JSX.Element => {
    const user = useSelector((storeState: Store) => storeState.User);
    const { handleSubmit, control } = useForm();

    return (
        <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <DialogContentText>Enter an email address</DialogContentText>
            <Controller
                name="testEmail"
                control={control}
                defaultValue={useDefaultEmail ? user.email : ''}
                as={
                    <Input
                        autoFocus
                        inputProps={{
                            style: {
                                fontSize: '14px',
                                padding: '8px',
                            },
                            'aria-label': 'testEmailAddress',
                        }}
                        data-testid="sendTestEmailInput"
                        color="primary"
                        type="email"
                        required
                        style={{ width: '100%' }}
                    />
                }
            />
        </form>
    );
};

SendTestEmailForm.defaultProps = {
    useDefaultEmail: true,
};

export default SendTestEmailForm;
