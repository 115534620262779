import React from 'react';

import { makeStyles, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles({
    endAdornment: {
        cursor: 'pointer',
        padding: '0.1rem',
    },
    startAdornment: {
        marginRight: '0.3rem',
        padding: '0.1rem',
    },
    inputProps: {
        height: '36px',
        fontSize: '14px',
    },
});

export default ({
    globalFilter,
    setGlobalFilter,
}: {
    globalFilter: '';
    setGlobalFilter: (string) => void;
}): JSX.Element => {
    const classes = useStyles();
    const onClear = () => {
        setGlobalFilter('');
    };
    return (
        <TextField
            id="globalFilterInput"
            variant="outlined"
            onChange={(e): void => {
                setGlobalFilter(e.target.value || undefined);
            }}
            value={globalFilter || ''}
            InputProps={{
                className: classes.inputProps,
                startAdornment: <SearchIcon className={classes.startAdornment} />,
                endAdornment: <ClearIcon className={classes.endAdornment} onClick={onClear} />,
            }}
            placeholder="Search Contacts"
            fullWidth
        />
    );
};
