import React, { ReactElement } from 'react';

import { useQuery } from '@apollo/client';

import { GET_SEGMENTS } from 'api/queries';
import { FindSegmentsWithCountSegmentsWithCountResponseSegments } from 'typings/_graphql';
import SegmentTile from '../segmentTile/SegmentTile';

const SegmentTileList = (props: {
    segments: readonly FindSegmentsWithCountSegmentsWithCountResponseSegments[];
}): ReactElement => {
    const { segments } = props;
    const segmentQuery = useQuery(GET_SEGMENTS, {
        variables: { audienceId: 1 },
        fetchPolicy: 'network-only',
    });

    function List(): JSX.Element {
        return (
            <>
                {segments.length ? (
                    segments.map((segment, index) => (
                        <div key={index}>
                            <SegmentTile {...segment} />
                        </div>
                    ))
                ) : (
                    <div>No segments available!</div>
                )}
            </>
        );
    }

    return <div className="SegmentTileList">{segmentQuery.loading ? <h5>Loading...</h5> : <List />}</div>;
};

export default SegmentTileList;
