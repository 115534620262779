import React, { ReactElement } from 'react';

import CampaignTile from 'pageComponents/campaign/campaignTile/CampaignTile';
import { FindCampaignsWithCountCampaignsWithCountResponseCampaigns } from 'typings/_graphql';

import './CampaignTileList.scss';

const CampaignList = (props: {
    campaigns: Array<FindCampaignsWithCountCampaignsWithCountResponseCampaigns>;
}): ReactElement => {
    const { campaigns } = props;
    if (campaigns) {
        return (
            <>
                {campaigns?.map((campaign, index) => {
                    return (
                        <div key={index}>
                            <CampaignTile campaign={campaign} />
                        </div>
                    );
                })}
            </>
        );
    }
    return <></>;
};

export default CampaignList;
