import React, { ReactElement, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { GET_CAMPAIGN } from 'api/queries';
import AnalyticsStats from 'components/analyticStats/AnalyticsStats';
import { setCampaignState } from 'store/actions/Campaign';
import { Store } from 'store/reducers';
import { FindCampaign } from 'typings/_graphql';

import './CampaignReportStats.scss';

export interface ParamType {
    id: string;
}
const CampaignReportStats = (): ReactElement => {
    const campaign = useSelector((storeState: Store) => storeState.Campaign);
    const { campaignAnalytics } = campaign;
    const dispatch = useDispatch();
    const { id } = useParams<ParamType>();

    const [campaignQuery, { data }] = useLazyQuery(GET_CAMPAIGN, {
        variables: { id: Number(id) },
        fetchPolicy: 'network-only',
    });

    // Can be changed to ENV variable if needed for Demo - decision made 10/4/21
    const realType = 'no';
    const real = realType === 'no' ? 'no' : 'real';

    const sampleSent = 223;
    const hardBounce = 1;
    const delivered = 222;
    const opened = 70;
    const clicks = 36;
    const unSubs = 0;

    const clicked = useEffect(() => {
        if (!campaign.id) {
            campaignQuery();
        }
    }, [id, campaign, dispatch, campaignQuery]);

    useEffect(() => {
        if (data) {
            const { Campaign }: FindCampaign = data;
            dispatch(setCampaignState({ campaign: Campaign }));
        }
    }, [campaign?.campaignAnalytics, data, dispatch]);

    return (
        <div className="CampaignReportStats">
            <p className="HeadingText">Total Campaign Breakdown</p>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                    <p className="SentContactsText">
                        Campaign Sent to{' '}
                        <strong>{real === 'real' ? campaignAnalytics?.sentCount : sampleSent} Contacts</strong>
                    </p>
                </Grid>
                <Grid item xs={1}>
                    <div className="Dash" />
                </Grid>

                <AnalyticsStats
                    label="Delivered"
                    needsGridPadding={false}
                    size={undefined}
                    numerator={real === 'real' ? campaign?.campaignAnalytics?.deliveredCount : delivered}
                    denominator={real === 'real' ? campaign?.campaignAnalytics?.sentCount : sampleSent}
                />
                <AnalyticsStats
                    label="Hard Bounce"
                    rightBorderWithExtraPadding
                    size={undefined}
                    numerator={real === 'real' ? campaign?.campaignAnalytics?.hardBounceCount : hardBounce}
                    denominator={real === 'real' ? campaign?.campaignAnalytics?.sentCount : sampleSent}
                />
            </Grid>
            <p className="HeadingText">Delivery Breakdown</p>

            <Grid container spacing={2}>
                <AnalyticsStats
                    label="Opened"
                    needsGridPadding={false}
                    size={undefined}
                    numerator={real === 'real' ? campaign?.campaignAnalytics?.openedCount : opened}
                    denominator={real === 'real' ? campaign?.campaignAnalytics?.deliveredCount : delivered}
                />
                <AnalyticsStats
                    label="Clicked"
                    needsGridPadding={false}
                    size={undefined}
                    numerator={real === 'real' ? campaign?.campaignAnalytics?.withClicksCount : clicks}
                    denominator={real === 'real' ? campaign?.campaignAnalytics?.deliveredCount : delivered}
                />
                <AnalyticsStats
                    label="Unsubscribed"
                    size={undefined}
                    omitRightBorder
                    numerator={real === 'real' ? campaign?.campaignAnalytics?.hasUnsubscribesCount : unSubs}
                    denominator={real === 'real' ? campaign?.campaignAnalytics?.deliveredCount : delivered}
                />
            </Grid>
        </div>
    );
};

export default CampaignReportStats;
