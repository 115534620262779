import { gql } from 'apollo-boost';

const GET_USER = gql`
    query findUser {
        user: user {
            id
            emailAddress
            name
        }
    }
`;

const GET_AUTHORIZED_USER = gql`
    query findAuthorizedUser {
        user: findOrCreateUser {
            id
            emailAddress
            name
        }
    }
`;

export { GET_USER, GET_AUTHORIZED_USER };
