import { gql } from '@apollo/client';

const SAVE_CAMPAIGN = gql`
    mutation saveCampaign($data: CampaignInput!) {
        Campaign: saveCampaign(data: $data) {
            title
            id
        }
    }
`;

const SEND_CAMPAIGN = gql`
    mutation sendCampaign($campaign: CampaignInput!, $html: String!) {
        Campaign: sendCampaign(data: { campaign: $campaign, html: $html }) {
            title
            id
        }
    }
`;

const SEND_TEST_CAMPAIGN = gql`
    mutation sendTestCampaign($campaign: CampaignInput!, $html: String!, $testEmails: [String!]!) {
        Campaign: sendTestCampaign(data: { campaign: $campaign, html: $html, testEmails: $testEmails }) {
            id
        }
    }
`;

export { SAVE_CAMPAIGN, SEND_CAMPAIGN, SEND_TEST_CAMPAIGN };
