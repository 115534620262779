import React, { ReactElement, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { setNavigation } from 'store/actions/Navigation';
import { Store } from 'store/reducers';

import './TopNav.scss';

export const TopNav = (): ReactElement => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigation = useSelector((store: Store) => store.Navigation);
    const { navLocation } = navigation;

    const getPath = (path: string): string => {
        if (path.startsWith('/campaign')) {
            return 'campaign';
        }
        if (path.startsWith('/reporting')) {
            return 'reporting';
        }
        if (path.startsWith('/audience')) {
            return 'audience';
        }

        return path;
    };

    useEffect(() => {
        const path = getPath(location.pathname);

        dispatch(setNavigation(path));
    }, [location, dispatch]);

    return (
        <div className="top-nav">
            <div className="top-nav-flex">
                <nav className="nav-links">
                    <Link className={navLocation === 'campaign' ? 'nav-button active' : 'nav-button'} to="/campaign">
                        Campaign
                    </Link>
                    <Link className={navLocation === 'audience' ? 'nav-button active' : 'nav-button'} to="/audience">
                        Audience
                    </Link>
                </nav>
            </div>
        </div>
    );
};

export default TopNav;
