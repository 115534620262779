import React, { ReactElement, useEffect, useState } from 'react';

import {
    Checkbox,
    DialogContentText,
    FormControlLabel,
    FormGroup,
    Grid,
    GridSize,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { camelCase } from 'lodash';
import { Controller, useForm } from 'react-hook-form';

import { companyCerts, stateAbbreviations, techAreas } from 'components/utils/GlobalConstants';
import { ContactStatus } from 'typings/_graphql';
import { FormValues } from 'typings/Subscription';

import './SubscriptionForm.scss';

const SubscriptionForm = ({
    formId,
    contactData = null,
    onSave,
    defaultFormValues,
    isPublic,
    classes,
    isSubscribe,
}): ReactElement => {
    const { handleSubmit, control, register, setValue, getValues, reset } = useForm<FormValues>({
        defaultValues: defaultFormValues,
    });

    useEffect(() => {
        reset(defaultFormValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactData]);

    const [, setLastUpdate] = useState(new Date());

    const ContactInput = (
        inputCategory: string,
        inputSize: GridSize,
        placeholder?: string,
        isRequired = false
    ): JSX.Element => {
        const inputName = camelCase(inputCategory);

        return (
            <>
                <Grid item xs={12}>
                    <DialogContentText className={classes.inputLabels}>{inputCategory}</DialogContentText>
                </Grid>
                <Grid item xs={inputSize}>
                    <Controller
                        name={inputName}
                        control={control}
                        render={({ onChange }) => (
                            <TextField
                                required={isRequired}
                                inputRef={register}
                                id={inputName}
                                name={inputName}
                                variant="outlined"
                                margin="dense"
                                placeholder={placeholder}
                                fullWidth
                                onChange={onChange}
                                inputProps={{
                                    'aria-label': inputName,
                                }}
                            />
                        )}
                    />
                </Grid>
            </>
        );
    };

    ContactInput.defaultProps = {
        placeholder: '',
    };

    const handleTechAreas = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        if (name === 'techFocusAreas.Select All') {
            techAreas.forEach((area) => setValue(`techFocusAreas.${area}`, checked));
        } else {
            setValue(`techFocusAreas.${name}`, checked);
        }
        setLastUpdate(new Date());
    };
    const handleCerts = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setValue(`companyCertifications.${name}`, checked);
        setLastUpdate(new Date());
    };

    return (
        <div className="SubscriptionForm">
            <form className={classes.form} data-testid={formId} id={formId} onSubmit={handleSubmit(onSave)}>
                {isPublic &&
                    (isSubscribe ? (
                        <>
                            <Typography className={classes.heading}>Subscribe</Typography>
                            <Typography className={classes.subHeading}>
                                Subscribe to the AFWERX SBIR/STTR mailing list to receive news and information about
                                upcoming opportunities.
                            </Typography>
                            <Typography className={classes.subHeading}>
                                Please complete this contact form to receive the most relevant information.
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography className={classes.heading}>Update Mail Preferences</Typography>
                            <Typography className={classes.subHeading}>
                                Please update any outdated contact information to receive more relevant news and
                                updates.
                            </Typography>
                        </>
                    ))}
                <Grid item xs={12}>
                    <Typography className={classes.firstSectionName}>Contact Information</Typography>
                </Grid>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <DialogContentText className={classes.inputLabels}>*Email Address</DialogContentText>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="emailAddress"
                            control={control}
                            render={({ onChange }) => (
                                <TextField
                                    inputRef={register({ required: 'Please enter a valid email' })}
                                    autoFocus
                                    id="emailAddress"
                                    name="emailAddress"
                                    type="email"
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                    required
                                    inputProps={{
                                        'aria-label': 'emailAddress',
                                    }}
                                    onChange={onChange}
                                    placeholder="Enter an email address"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                {!isPublic && isSubscribe && (
                    <Grid container alignItems="center">
                        <Grid item xs={12}>
                            <DialogContentText className={classes.inputLabels}>
                                *Has this contact subscribed to the SBIR/STTR mailing list?
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="subscriptionStatus"
                                control={control}
                                render={({ onChange }) => (
                                    <RadioGroup onChange={onChange}>
                                        <FormControlLabel
                                            value={ContactStatus.SUBSCRIBED}
                                            control={
                                                <Radio
                                                    name="subscriptionStatusRadio"
                                                    data-testid="Permission"
                                                    required
                                                />
                                            }
                                            label="Yes, I have permission to email them"
                                        />
                                        <FormControlLabel
                                            value={ContactStatus.PENDING}
                                            control={
                                                <Radio name="subscriptionStatusRadio" data-testid="Pend" required />
                                            }
                                            label="Not yet, invite them to subscribe"
                                        />
                                    </RadioGroup>
                                )}
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid container alignItems="center">
                    {ContactInput('*First Name', 6, 'Enter first name', true)}
                </Grid>

                <Grid container alignItems="center">
                    {ContactInput('*Last Name', 6, 'Enter last name', true)}
                </Grid>

                <Grid container alignItems="center">
                    {ContactInput('Phone Number', 8, 'Enter phone #')}
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.companySectionName}>Company / Organization Information</Typography>
                </Grid>
                <Grid container alignItems="center">
                    {ContactInput('Job Title', 8, 'Enter job title')}
                </Grid>

                <Grid container alignItems="center">
                    {ContactInput('Company Name', 12, 'Enter company name')}
                </Grid>
                <Grid container alignItems="center">
                    <Grid item xs={8}>
                        <Grid container wrap="nowrap" justify="space-between">
                            <Grid item xs={8}>
                                <DialogContentText className={classes.inputLabels}>City</DialogContentText>
                            </Grid>
                            <Grid item xs={3}>
                                <DialogContentText className={classes.inputLabels}>State</DialogContentText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container wrap="nowrap" justify="space-between">
                            <Grid item xs={8} className={classes.data}>
                                <Controller
                                    name="city"
                                    control={control}
                                    render={({ onChange }) => (
                                        <TextField
                                            inputRef={register}
                                            id="city"
                                            name="city"
                                            variant="outlined"
                                            margin="dense"
                                            placeholder="Enter a city"
                                            fullWidth
                                            inputProps={{
                                                'aria-label': 'city',
                                            }}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3} zeroMinWidth>
                                <Controller
                                    name="state"
                                    control={control}
                                    render={({ onChange }) => (
                                        <Select
                                            id="state"
                                            name="state"
                                            placeholder="State"
                                            fullWidth
                                            variant="outlined"
                                            onChange={onChange}
                                            inputProps={{
                                                'aria-label': 'state',
                                            }}
                                            className={classes.select}
                                            MenuProps={{
                                                classes: {
                                                    paper: classes.menuHeight,
                                                },
                                            }}
                                            inputRef={register}
                                            defaultValue={defaultFormValues.state}
                                        >
                                            <MenuItem value="">Select a state:</MenuItem>
                                            {Object.keys(stateAbbreviations).map((key) => (
                                                <MenuItem value={key} key={key}>
                                                    {key}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container alignItems="center">
                    {ContactInput('DUNS Number', 5, 'Enter DUNS #')}
                </Grid>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Typography className={classes.companySectionName}>
                            Company Certification (If applicable)
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Controller
                            defaultValue={defaultFormValues.companyCertifications}
                            name="certifications"
                            control={control}
                            render={({ onChange }) => (
                                <FormGroup row={false}>
                                    {companyCerts.map((cert, index) => {
                                        return (
                                            <FormControlLabel
                                                inputRef={register}
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        checked={!!getValues().companyCertifications?.[cert]}
                                                        onChange={(event) => {
                                                            onChange(event);
                                                            handleCerts(event);
                                                        }}
                                                        name={`companyCertifications.${cert}`}
                                                        color="primary"
                                                    />
                                                }
                                                label={cert}
                                            />
                                        );
                                    })}
                                </FormGroup>
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems="center">
                    <Grid item xs={12}>
                        <Typography className={classes.companySectionName}>Technology Focus Areas</Typography>
                        {isPublic ? (
                            <DialogContentText>
                                Select focus areas that interest you to receive more relevant information.
                            </DialogContentText>
                        ) : (
                            <DialogContentText>Select focus areas relevant to this contact.</DialogContentText>
                        )}
                    </Grid>
                    <Grid item xs={10}>
                        <Controller
                            defaultValue={defaultFormValues.techFocusAreas}
                            name="techInterest"
                            control={control}
                            render={({ onChange }) => (
                                <FormGroup row={false}>
                                    {techAreas.map((techArea, index) => {
                                        return (
                                            <FormControlLabel
                                                inputRef={register}
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        checked={!!getValues().techFocusAreas?.[techArea]}
                                                        onChange={(event) => {
                                                            onChange(event);
                                                            handleTechAreas(event);
                                                        }}
                                                        name={`techFocusAreas.${techArea}`}
                                                        color="primary"
                                                    />
                                                }
                                                label={techArea}
                                            />
                                        );
                                    })}
                                </FormGroup>
                            )}
                        />
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};
export default SubscriptionForm;
