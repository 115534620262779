import React, { FunctionComponent } from 'react';

import BaseUrl from 'components/utils/BaseUrl';

const SocialContainer = {
    backgroundColor: 'rgb(44, 57, 91)',
    margin: '0',
    padding: '1em 0 1em 1em',
    width: '100%',
    height: '50px',
    textAlign: 'center',
} as React.CSSProperties;

const SocialContainerHtml = {
    backgroundColor: 'rgb(44, 57, 91)',
    margin: '0',
    padding: '1em 0 1em 1em',
    width: '100%',
    height: '100px',
    textAlign: 'center',
} as React.CSSProperties;

const SocialContainerCell = {
    paddingBottom: '1em',
    textAlign: 'center',
    width: '100%',
    margin: '1em 0',
} as React.CSSProperties;

const SocialText = {
    textAlign: 'center',
    display: 'inline',
    color: '#fff',
    fontSize: '18px',
    margin: '0 auto',
    paddingTop: '.5em',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
} as React.CSSProperties;

const SocialTextHtml = {
    textAlign: 'center',
    display: 'inline',
    color: '#fff',
    fontSize: '18px',
    margin: '0 auto',
    paddingTop: '1em',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
} as React.CSSProperties;

const SocialTextCell = {
    padding: '.5em 0',
    margin: '3em auto ',
    width: '100%',
    textAlign: 'center',
} as React.CSSProperties;

const SocialTextCellOutlook = {
    padding: '1em 0',
    margin: '3em auto ',
    width: '100%',
    textAlign: 'center',
} as React.CSSProperties;

const socialIconCell = {
    display: 'inline',
    margin: '0 .5em 0 .5em',
    textAlign: 'center',
} as React.CSSProperties;

const socialIconCellHtml = {
    display: 'inline',
    margin: '0 .75em 0 .75em',
    textAlign: 'center',
} as React.CSSProperties;

const socialIconTable = { textAlign: 'center', width: '60%', margin: '0 20%' } as React.CSSProperties;
const socialIconTableHtml = { textAlign: 'center', width: '41%', margin: '0 30%' } as React.CSSProperties;
const socialIconContainerCell = { width: '100%', textAlign: 'center' } as React.CSSProperties;
const socialIconInnerTableContainer = { width: '100%', textAlign: 'center' } as React.CSSProperties;

type SocialBarProps = {
    isHtml: boolean;
};

const SocialBar: FunctionComponent<SocialBarProps> = ({ isHtml }) => {
    const baseUrl = BaseUrl();
    return (
        <table className={isHtml ? 'row' : undefined} style={isHtml ? SocialContainerHtml : SocialContainer}>
            <tbody>
                <tr>
                    <td align="center" style={SocialContainerCell}>
                        <table style={socialIconInnerTableContainer}>
                            <tbody>
                                <tr>
                                    <td style={isHtml ? SocialTextCellOutlook : SocialTextCell} align="center">
                                        <p style={isHtml ? SocialTextHtml : SocialText}>STAY CONNECTED</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" style={socialIconContainerCell}>
                                        <table style={isHtml ? socialIconTableHtml : socialIconTable} cellPadding="0">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style={isHtml ? socialIconCellHtml : socialIconCell}
                                                        align="center"
                                                    >
                                                        <a
                                                            href="https://www.linkedin.com/company/10864292"
                                                            target=" "
                                                            title="LinkedIn"
                                                        >
                                                            <img
                                                                height={24}
                                                                width={24}
                                                                src={`${baseUrl}/images/templates/linkedin-gray.png`}
                                                                alt="LinkedIn link"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td
                                                        style={isHtml ? socialIconCellHtml : socialIconCell}
                                                        align="center"
                                                    >
                                                        <a
                                                            href="https://twitter.com/AF_SBIR_STTR"
                                                            target=" "
                                                            title="Twitter"
                                                        >
                                                            <img
                                                                height={24}
                                                                width={24}
                                                                src={`${baseUrl}/images/templates/twitter-gray.png`}
                                                                alt="Twitter link"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td
                                                        style={isHtml ? socialIconCellHtml : socialIconCell}
                                                        align="center"
                                                    >
                                                        <a
                                                            href="https://www.facebook.com/afsbirsttr/"
                                                            target=" "
                                                            title="Facebook"
                                                        >
                                                            <img
                                                                height={24}
                                                                width={24}
                                                                src={`${baseUrl}/images/templates/facebook-gray.png`}
                                                                alt="Facebook link"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td
                                                        style={isHtml ? socialIconCellHtml : socialIconCell}
                                                        align="center"
                                                    >
                                                        <a
                                                            href="https://www.instagram.com/afsbirsttr/"
                                                            target=" "
                                                            title="Instagram"
                                                        >
                                                            <img
                                                                height={24}
                                                                width={24}
                                                                src={`${baseUrl}/images/templates/instagram-gray.png`}
                                                                alt="Instagram link"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td
                                                        style={isHtml ? socialIconCellHtml : socialIconCell}
                                                        align="center"
                                                    >
                                                        <a
                                                            href="https://www.youtube.com/channel/UCb3EQFQNK0pw5EFv0rAkTBg"
                                                            target=" "
                                                            title="Youtube"
                                                        >
                                                            <img
                                                                height={24}
                                                                width={24}
                                                                src={`${baseUrl}/images/templates/youtube-gray.png`}
                                                                alt="Youtube link"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td
                                                        style={isHtml ? socialIconCellHtml : socialIconCell}
                                                        align="center"
                                                    >
                                                        <a
                                                            href="https://www.dvidshub.net/unit/AFSBIRSTTR"
                                                            target=" "
                                                            title="DVIDS"
                                                        >
                                                            <img
                                                                height={24}
                                                                width={24}
                                                                src={`${baseUrl}/images/templates/dvids_icon.png`}
                                                                alt="DVIDS Link"
                                                            />
                                                        </a>
                                                    </td>
                                                    <td
                                                        style={isHtml ? socialIconCellHtml : socialIconCell}
                                                        align="center"
                                                    >
                                                        <a
                                                            href="https://www.afsbirsttr.af.mil/"
                                                            target=" "
                                                            title="SBIR/STTR Website"
                                                        >
                                                            <img
                                                                height={24}
                                                                width={24}
                                                                src={`${baseUrl}/images/templates/web-link-gray.png`}
                                                                alt="link"
                                                            />
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default SocialBar;
