import { SET_NAVIGATION } from '../actions/Navigation';
import { Action } from '../index';

export type NavPayload = string;

export type NavigationState = {
    navLocation: string;
};

const initialState: NavigationState = {
    navLocation: '/',
};

export default (state = initialState, action: Action): NavigationState => {
    const { type } = action;
    switch (type) {
        case SET_NAVIGATION: {
            const navLocation = (action.payload as unknown) as NavPayload;
            return { ...state, navLocation };
        }
        default:
            return state;
    }
};
