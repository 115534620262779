import { FindCampaignsCampaigns } from 'typings/_graphql';

export const UPDATE_NEWSLETTER = 'UPDATE_NEWSLETTER';
export const UPDATE_ARTICLE_COUNT = 'UPDATE_ARTICLECOUNT';
export const SELECT_TEMPLATE = 'SELECT_TEMPLATE';
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_PRESS = 'UPDATE_PRESS';
export const SELECT_CAMPAIGN_TAB = 'SELECT_CAMPAIGN_TAB';
export const UPDATE_CAMPAIGN_DETAILS = 'UPDATE_CAMPAIGN_DETAILS';
export const RESET_CAMPAIGN = 'RESET_CAMPAIGN';
export const SELECT_AUDIENCE = 'SELECT_AUDIENCE';
export const CLEAR_ALL_FIELDS = 'CLEAR_ALL_FIELDS';
export const CLEAR_FIELDS = 'CLEAR_FIELDS';
export const UPDATE_CAMPAIGN_ID = 'UPDATE_CAMPAIGN_ID';
export const ENABLE_REVIEW_MODE = 'ENABLE_REVIEW_MODE';
export const SET_CAMPAIGN_STATE = 'SET_CAMPAIGN_STATE';

export const setCampaignState = (data: { campaign: FindCampaignsCampaigns }): Record<string, unknown> => ({
    type: SET_CAMPAIGN_STATE,
    payload: data,
});

export const enableReviewMode = (data: { boolean: boolean }): Record<string, unknown> => ({
    type: ENABLE_REVIEW_MODE,
    payload: data,
});

export const updateCampaignId = (data: { id: number }): Record<string, unknown> => ({
    type: UPDATE_CAMPAIGN_ID,
    payload: data,
});
export const clearAllFields = (data: { name: string }): Record<string, unknown> => ({
    type: CLEAR_ALL_FIELDS,
    payload: data,
});
export const clearFields = (data: {
    name: string;
    resetToInitial: [{ index: number | null; key: string }];
    resetToSave: [{ key: string; index: number | null; value: string }];
}): Record<string, unknown> => ({
    type: CLEAR_FIELDS,
    payload: data,
});
export const selectAudience = (data: { segmentIds: number[]; segmentNames: string[] }): Record<string, unknown> => ({
    type: SELECT_AUDIENCE,
    payload: data,
});
export const resetCampaign = (data: { name: string }): Record<string, unknown> => ({
    type: RESET_CAMPAIGN,
    payload: data,
});

export const updateCampaignDetails = (data: { value: string; name: string }): Record<string, unknown> => ({
    type: UPDATE_CAMPAIGN_DETAILS,
    payload: data,
});
export const selectCampaignTab = (data: { step: number }): Record<string, unknown> => ({
    type: SELECT_CAMPAIGN_TAB,
    payload: data,
});

export const updatePress = (data: { name: string; value: string }): Record<string, unknown> => ({
    type: UPDATE_PRESS,
    payload: data,
});

export const updateEvent = (data: { name: string; value: string }): Record<string, unknown> => ({
    type: UPDATE_EVENT,
    payload: data,
});

export const selectTemplate = (data: { name: string }): Record<string, unknown> => ({
    type: SELECT_TEMPLATE,
    payload: data,
});

export const updateNewsletter = (data: { name: string; value: string; index: string }): Record<string, unknown> => ({
    type: UPDATE_NEWSLETTER,
    payload: data,
});

export const updateArticleCount = (data: { name: string }): Record<string, unknown> => ({
    type: UPDATE_ARTICLE_COUNT,
    payload: data,
});
