import React, { useEffect } from 'react';

import { Paper, Tab, Tabs } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';

import ContactManager from 'pages/contactManager/ContactManager';
import SegmentLibrary from 'pages/segmentLibrary/SegmentLibrary';

interface TabPanelProps {
    children: React.ReactNode;
    index: string | number;
    value: string | number;
}

function TabPanel(props: TabPanelProps): JSX.Element {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
}

function a11yProps(index: string | number): Record<string, unknown> {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

const useTabStyles = makeStyles({
    root: {
        fontSize: '0.9rem',
        fontWeight: 600,
        textTransform: 'none',
    },
});

export default function ContactsPage(): JSX.Element {
    const classes = useStyles();
    const tabClasses = useTabStyles();
    const [value, setValue] = React.useState(0);
    const location = useLocation();

    const handleChange = (event: React.ChangeEvent<Record<string, unknown>>, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (location.pathname.endsWith('segments')) {
            setValue(1);
        }
    }, [location]);

    return (
        <>
            <Paper className={classes.root}>
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" centered>
                    <Tab
                        className={tabClasses.root}
                        label="Contact Manager"
                        to="/audience/contacts"
                        component={Link}
                        {...a11yProps(0)}
                    />
                    <Tab
                        className={tabClasses.root}
                        label="Segment Library"
                        to="/audience/segments"
                        component={Link}
                        {...a11yProps(1)}
                    />
                </Tabs>
            </Paper>
            <TabPanel value={value} index={0}>
                <ContactManager />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <SegmentLibrary />
            </TabPanel>
        </>
    );
}
