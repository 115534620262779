import React, { ReactElement } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { DateProps } from 'typings/InputProps';

const DateInput = ({ onChange, className, id, label, value }: DateProps): ReactElement => {
    return (
        <div className="InputGroup inputDiv DateInput">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    label={label}
                    color="primary"
                    name="date"
                    onChange={onChange}
                    className={`form-control Date ${className}`}
                    id={`${id}`}
                    aria-describedby="basic-addon3"
                    value={value}
                    format="MM/dd/yyyy"
                    margin="normal"
                    disableToolbar
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        ...({ 'data-testid': 'js-datepicker' } as any),
                    }}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
};
export default DateInput;
