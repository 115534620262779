/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
/* eslint-disable react/no-unknown-property */
import React, { ReactElement } from 'react';

/* eslint-disable react/jsx-filename-extension */
import BaseUrl from 'components/utils/BaseUrl';
import { Campaign } from 'typings/Campaign';

function get12HourTime(timeValue: string): string {
    const [hours24, minutes] = timeValue.split(':');
    if (Number.isNaN(hours24) || Number.isNaN(minutes)) return 'TIME';
    const ampm = Number(hours24) >= 12 ? 'PM' : 'AM';
    const hours12 = Number(hours24) % 12 || 12;
    return `${hours12}:${minutes} ${ampm}`;
}

const EventContent = ({
    isHtml = false,
    event,
}: {
    isHtml: boolean;
    event: Campaign['template']['event'];
}): ReactElement => {
    const templateWidth = '100%';
    const wrapperDiv = { width: templateWidth, backgroundColor: '#0B1539' } as React.CSSProperties;

    const logoTd = {
        padding: '30px 80px 10px 50px',
    } as React.CSSProperties;
    const logoDiv = {
        border: '0',
        display: 'block',
        fontSize: '8pt',
        height: 'auto',
        outline: 'none',
        textDecoration: 'none',
        width: '135pt',
    } as React.CSSProperties;

    const divStyle = {
        margin: '0px auto',
        maxWidth: '100%',
        backgroundColor: '#0B1539',
    } as React.CSSProperties;
    const innerDivStyle = { lineHeight: '0', fontSize: '0' } as React.CSSProperties;
    const innerTableStyle = {
        margin: '0px auto',
        maxWidth: '100%',
        border: '0',
        align: 'center',
        width: '100%',
    } as React.CSSProperties;
    const innerTableStyleTd = { fontSize: '0px', padding: '20px 0', textAlign: 'center' } as React.CSSProperties;

    const contentDivHeader = {
        fontSize: '0px',
        textAlign: 'left',
        display: 'inline-block',
        verticalAlign: 'top',
        width: '100%',
    } as React.CSSProperties;
    const contentTable = {
        verticalAlign: 'top',
    } as React.CSSProperties;

    const eventNameTd = {
        fontSize: '0px',
        padding: '10px 80px 10px 50px',
        wordBreak: 'break-word',
    } as React.CSSProperties;
    const eventNameDiv = {
        fontFamily: 'Helvetica Neue, Arial, sans-serif',
        fontSize: '26px',
        fontWeight: 'normal',
        lineHeight: '1',
        textAlign: 'left',
        color: '#ffffff',
    } as React.CSSProperties;
    const locationTd = {
        fontSize: '0px',
        padding: '10px 25px 10px 50px',
        wordBreak: 'break-word',
    } as React.CSSProperties;
    const locationDiv = {
        fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
        fontSize: '20px',
        fontWeight: 'normal',
        lineHeight: '1',
        textAlign: 'left',
        color: '#B88E21',
    } as React.CSSProperties;

    const imageTd = {
        fontSize: '0px',
        padding: '20px 0px 10px 0px',
        wordBreak: 'break-word',
    } as React.CSSProperties;
    const imageA = {
        width: '100%',
    } as React.CSSProperties;
    const imageDiv = {
        border: '0',
        display: 'block',
        fontSize: '8pt',
        height: 'auto',
        outline: 'none',
        textDecoration: 'none',
        width: '100%',
    } as React.CSSProperties;

    const divStyleDescription = {
        margin: '0px auto',
        maxWidth: '100%',
    } as React.CSSProperties;

    const innerTableStyleDescription = {
        margin: '0px auto',
        maxWidth: '100%',
        border: '0',
        align: 'center',
        width: '100%',
        minHeight: '200px',
    } as React.CSSProperties;
    const innerTableStyleDescriptionTdHtml = {
        fontSize: '0px',
        padding: '20px 0 50px 0',
        textAlign: 'center',
    } as React.CSSProperties;
    const innerTableStyleDescriptionTd = {
        fontSize: '0px',
        paddingBottom: '50px',
        textAlign: 'center',
    } as React.CSSProperties;

    const contentDivDescription = {
        fontSize: '0px',
        textAlign: 'left',
        display: 'inline-block',
        verticalAlign: 'top',
        width: '55%',
    } as React.CSSProperties;
    const contentDivRegister = {
        fontSize: '0px',
        textAlign: 'left',
        display: 'inline-block',
        verticalAlign: 'top',
        width: '45%',
    } as React.CSSProperties;
    const descriptionTdHtml = {
        fontSize: '0px',
        padding: '10px 0px 10px 50px',
        wordBreak: 'break-word',
        marginRight: '50px',
    } as React.CSSProperties;
    const descriptionTd = {
        fontSize: '0px',
        padding: '10px 20px 0px 50px',
        wordBreak: 'break-word',
    } as React.CSSProperties;
    const descriptionDivHtml = {
        fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
        fontSize: '18px',
        msoLineHeightRule: 'exactly',
        lineHeight: '150%',
        textAlign: 'left',
        color: '#ffffff',
    } as React.CSSProperties;
    const descriptionDiv = {
        ...descriptionDivHtml,
        marginBottom: '0',
    } as React.CSSProperties;

    const registerDayTd = {
        fontSize: '0px',
        padding: '10px 0 8px 40px',
        wordBreak: 'break-word',
    } as React.CSSProperties;
    const registerDayDiv = {
        fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
        fontSize: '20px',
        lineHeight: '1',
        textAlign: 'left',
        color: '#B88E21',
    } as React.CSSProperties;

    const registerDateTd = {
        fontSize: '0px',
        padding: '0px 0 20px 40px',
        wordBreak: 'break-word',
    } as React.CSSProperties;
    const registerDateDiv = {
        fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: '1',
        textAlign: 'left',
        color: '#ffffff',
        width: '220px',
    } as React.CSSProperties;

    const registerTextTd = {
        fontSize: '0px',
        padding: '0px 0 20px 40px',
        wordBreak: 'break-word',
    } as React.CSSProperties;
    const registerTextDiv = {
        fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
        fontSize: '14px',
        lineHeight: '1.5',
        textAlign: 'left',
        color: '#C4C8CC',
        width: '160px',
    } as React.CSSProperties;

    const registerBtnOuterTd = {
        fontSize: '0px',
        margin: '10px 0px 10px 40px',
        padding: '10px 0px 10px 40px',
        wordBreak: 'break-word',
    } as React.CSSProperties;
    const registerBtnTable = {
        borderCollapse: 'separate',
        width: '160px',
        lineHeight: '100%',
    } as React.CSSProperties;
    const registerBtnTd = {
        border: 'none',
        cursor: 'auto',
        msoPaddingAlt: '10px 15px',
        background: '#F89518',
    } as React.CSSProperties;
    const registerBtnAnchorTag = {
        display: 'inline-block',
        width: '100%',
        textAlign: 'center',
        background: '#F89518',
        color: '#ffffff',
        fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
        fontSize: '20px',
        fontWeight: 'normal',
        lineHeight: '120%',
        margin: '0',
        textDecoration: 'none',
        textTransform: 'none',
        padding: '10px 15px',
        msoPaddingAlt: '0px',
    } as React.CSSProperties;

    const anchorTag = { width: '100%', cursor: 'pointer' } as React.CSSProperties;

    const baseUrl = BaseUrl();

    return (
        <div className="EventContent" style={wrapperDiv}>
            {isHtml &&
                `<!--[if mso | IE]>
                <table
                align="center" border="0" cellpadding="0" cellspacing="0" class="" width="100%"
                >
                <tr>
                    <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
                <![endif]-->`}
            <div style={divStyle}>
                <a href="https://www.afsbirsttr.af.mil/" target=" " style={anchorTag}>
                    {isHtml &&
                        `<!--[if mso | IE]>
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                        <tr>
                        <td
                            class="" style="vertical-align:top;" width="100%"
                        >
                        <![endif]-->`}
                    <div style={innerDivStyle}>
                        <table cellPadding="0" cellSpacing="0" role="presentation" style={innerTableStyle}>
                            <tbody>
                                <tr>
                                    <td align="center" style={innerTableStyleTd}>
                                        {isHtml &&
                                            `<!--[if mso | IE]>
                                            <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                            <tr>
                                            <td
                                                class="" style="vertical-align:top;" width="100%"
                                            >
                                            <![endif]-->`}
                                        <div
                                            className="mj-column-per-100 mj-outlook-group-fix"
                                            style={contentDivHeader}
                                        >
                                            <table
                                                cellPadding="0"
                                                cellSpacing="0"
                                                role="presentation"
                                                style={contentTable}
                                                width="100%"
                                            >
                                                <tr>
                                                    <td align="left" style={logoTd}>
                                                        <a href="https://www.afsbirsttr.af.mil/" target=" ">
                                                            <img
                                                                height="auto"
                                                                src={`${baseUrl}/images/templates/SBIR-STTR-New.png`}
                                                                alt="logo"
                                                                style={logoDiv}
                                                                width="170"
                                                            />
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="left" style={eventNameTd}>
                                                        <div style={eventNameDiv}>
                                                            {event?.name.toUpperCase() || 'EVENT NAME'}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="left" style={locationTd}>
                                                        <div style={locationDiv}>
                                                            {event?.location.toUpperCase() || 'Location'}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td align="center" style={imageTd}>
                                                        <a href={event?.url} target=" " style={imageA}>
                                                            <img
                                                                height="auto"
                                                                src={
                                                                    event?.imageURL ||
                                                                    `${baseUrl}/images/templates/event2.jpg`
                                                                }
                                                                alt="Event Banner"
                                                                style={imageDiv}
                                                                width="100%"
                                                            />
                                                        </a>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                        {isHtml &&
                                            `<!--[if mso | IE]>
                                            </td>
                                            </tr>
                                            </table>
                                            <![endif]-->`}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {isHtml &&
                        `<!--[if mso | IE]>
                        </td>
                        </tr>
                        </table>
                        <![endif]-->`}
                </a>
            </div>
            {isHtml &&
                `<!--[if mso | IE]>                    
                </td>
                </tr>
                </table>
                
                <table
                align="center" border="0" cellpadding="0" cellspacing="0" class="" width="100%"
                >
                <tr>
                <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;">
                <![endif]-->`}
            <div style={divStyleDescription}>
                <div style={innerDivStyle}>
                    <table cellPadding="0" cellSpacing="0" role="presentation" style={innerTableStyleDescription}>
                        <tbody>
                            <tr>
                                <td style={isHtml ? innerTableStyleDescriptionTdHtml : innerTableStyleDescriptionTd}>
                                    {isHtml &&
                                        `<!--[if mso | IE]>
                                            <table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">

                                            <tr>

                                            <td
                                             valign="top" width="60%";
                                            >
                                        <![endif]-->`}
                                    <div className=" mj-outlook-group-fix" style={contentDivDescription}>
                                        <table
                                            cellPadding="0"
                                            cellSpacing="0"
                                            role="presentation"
                                            style={contentTable}
                                            width="100%"
                                        >
                                            <tr>
                                                <td align="left" style={isHtml ? descriptionTdHtml : descriptionTd}>
                                                    {isHtml &&
                                                        `<!--[if mso | IE]>
                                                        <div style="font-family: Ubuntu, Helvetica, Arial, sans-serif;
                                                        font-size:18px;
                                                        mso-line-height-rule:exactly;
                                                        line-height:120%;
                                                        text-align:left;
                                                        color:#ffffff;">
                                                            ${event?.description || 'Event Description'}
                                                        </div>
                                                        <![endif]-->
                                                        <!--[if !mso]> <!---->`}
                                                    <div style={isHtml ? descriptionDivHtml : descriptionDiv}>
                                                        {event?.description || 'Event Description'}
                                                    </div>
                                                    {isHtml && `<!-- <![endif]-->`}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    {isHtml &&
                                        `<!--[if mso | IE]>
                                            </td>
                                            <td
                                            valign="top" width="40%"
                                            >
                                        <![endif]-->`}
                                    <div className=" mj-outlook-group-fix" style={contentDivRegister}>
                                        <table
                                            cellPadding="0"
                                            cellSpacing="0"
                                            role="presentation"
                                            style={contentTable}
                                            width="100%"
                                        >
                                            <tr>
                                                <td align="left" style={registerDayTd}>
                                                    <div style={registerDayDiv}>
                                                        {`${
                                                            event?.day.toUpperCase() || 'Day of Week'
                                                        } - ${get12HourTime(event?.time.toUpperCase())}`}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="left" style={registerDateTd}>
                                                    <div style={registerDateDiv}>
                                                        {event?.date.toUpperCase() || 'Date'}
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td align="left" style={registerTextTd}>
                                                    <div style={registerTextDiv}>
                                                        Register today while tickets are still available!
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                {isHtml &&
                                                    `<!--[if mso | IE]>
                                                    <td
                                                        align="left"
                                                        vertical-align="middle"
                                                        style="font-size:0px;margin:10px 0px 10px 40px;word-break:break-word;"
                                                    >
                                                    <table
                                                        cellPadding="0"
                                                        cellSpacing="0"
                                                        role="presentation"
                                                        style="border-collapse:separate;width:160px;line-height:100%;"
                                                    >
                                                    <tr>
                                                    <td
                                                        color="#F89518"
                                                        role="presentation"
                                                        style="border:none;cursor:auto;mso-padding-alt:10px 25px;background:#F89518;"
                                                        valign="middle"
                                                    >
                                                        <a
                                                            href=${event?.url}
                                                            target=" "
                                                            style="display:inline-block;
                                                            width:100%;
                                                            text-align:center;
                                                            background:#F89518;
                                                            color:#ffffff;
                                                            font-family:Ubuntu, Helvetica, Arial, sans-serif;
                                                            font-size:20px;
                                                            font-weight:normal;
                                                            line-height:120%;
                                                            margin:0;
                                                            text-decoration:none;
                                                            text-transform:none;
                                                            padding:10px 25px;
                                                            mso-padding-alt:0px;"
                                                        >
                                                            REGISTER
                                                        </a>
                                                    </td>
                                                    </tr>
                                                    </table>
                                                    </td>
                                                    <![endif]-->`}
                                                {isHtml && `<!--[if !mso]> <!---->`}
                                                <td align="left" vertical-align="middle" style={registerBtnOuterTd}>
                                                    <table
                                                        cellPadding="0"
                                                        cellSpacing="0"
                                                        role="presentation"
                                                        style={registerBtnTable}
                                                    >
                                                        <tr>
                                                            <td
                                                                color="#F89518"
                                                                role="presentation"
                                                                style={registerBtnTd}
                                                                valign="middle"
                                                            >
                                                                <a
                                                                    href={event?.url}
                                                                    target=" "
                                                                    style={registerBtnAnchorTag}
                                                                >
                                                                    REGISTER
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                {isHtml && `<!-- <![endif]-->`}
                                            </tr>
                                        </table>
                                    </div>
                                    {isHtml &&
                                        `<!--[if mso | IE]>
                                        </td>
                                        </tr>
                                        </table>
                                        <![endif]-->`}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {isHtml &&
                `<!--[if mso | IE]>
                </td>
                </tr>
                </table>
                <![endif]-->`}
        </div>
    );
};
export default EventContent;
