/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-interactive-element-to-noninteractive-role */
import React, { ReactElement } from 'react';

import BaseUrl from 'components/utils/BaseUrl';
import { Campaign } from 'typings/Campaign';

const outsideDiv = {
    margin: '0px auto',
    maxWidth: '600pt',
    backgroundColor: '#0B1539',
    bgColor: '#0B1539',
    background: '#0B1539',
    textAlign: 'center',
} as React.CSSProperties;

const firstTable = {
    align: 'center',
    border: '0',
    backgroundColor: '#0B1539',
    bgColor: '#0B1539',
    background: '#0B1539',
    width: '100%',
} as React.CSSProperties;

const innerWidth = {
    direction: 'ltr',
    display: 'inline-block',
    fontSize: '0px',
    verticalAlign: 'top',
    width: '100%',
    border: '0',
    padding: '0, 25pt',
    textAlign: 'center',
} as React.CSSProperties;

const innerWidthHtml = {
    direction: 'ltr',
    display: 'inline-block',
    fontSize: '0px',
    verticalAlign: 'top',
    width: '100%',
    border: '0',
    padding: '15pt 0',
    textAlign: 'center',
} as React.CSSProperties;

const innerWidthTable = {
    border: '0',
    verticalAlign: 'top',
    padding: ' 0',
    textAlign: 'center',
} as React.CSSProperties;

const innerWidthTableHtml = {
    border: '0',
    verticalAlign: 'top',
    padding: '15pt 0',
    textAlign: 'center',
} as React.CSSProperties;

const centerImage = {
    align: 'center',
    fontSize: '0px',
    padding: '8pt 40pt',
    wordBreak: 'break-word',
} as React.CSSProperties;

const borderCollapseImage = {
    border: '0',
    borderCollapse: 'collapse',
    borderSpacing: '0px',
    align: 'center',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
} as React.CSSProperties;

const borderCollapseImage1 = {
    border: '0',
    borderCollapse: 'collapse',
    borderSpacing: '0px',
    padding: '8pt 8pt',
} as React.CSSProperties;

const tdLogo = {
    width: '200pt',
} as React.CSSProperties;

const logo = {
    border: '0',
    display: 'block',
    fontSize: '8pt',
    height: '72pt',
    outline: 'none',
    textDecoration: 'none',
    width: '220pt',
} as React.CSSProperties;

const tdText = {
    align: 'left',
    fontSize: '0px',
    padding: '5pt 40pt',
    wordBreak: 'break-word',
} as React.CSSProperties;

const tdTextHtml = {
    align: 'left',
    fontSize: '0px',
    padding: '8pt 40pt',
    wordBreak: 'break-word',
} as React.CSSProperties;

const pressRelease = {
    color: 'white',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    fontSize: '12pt',
    fontWeight: 350,
    lineHeight: '1',
    textAlign: 'left',
} as React.CSSProperties;

const pressReleaseHtml = {
    color: 'white',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    fontSize: '15pt',
    fontWeight: 350,
    lineHeight: '1',
    textAlign: 'left',
} as React.CSSProperties;

const date = {
    color: '#969DA3',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    fontSize: '9pt',
    fontWeight: 350,
    lineHeight: '1',
    textAlign: 'left',
} as React.CSSProperties;

const dateHtml = {
    color: '#969DA3',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    fontSize: '12pt',
    fontWeight: 350,
    lineHeight: '1',
    textAlign: 'left',
} as React.CSSProperties;

const tdLine = {
    fontSize: '0px',
    padding: '8pt 40pt',
    wordBreak: 'break-word',
} as React.CSSProperties;

const line = {
    borderTop: 'solid 2pt #B48D35',
    fontSize: '1pt',
    margin: '0px auto',
    width: '100%',
} as React.CSSProperties;

const headline = {
    color: 'white',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    fontSize: '14pt',
    lineHeight: '1',
    textAlign: 'left',
} as React.CSSProperties;

const headlineHtml = {
    color: 'white',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    fontSize: '18pt',
    lineHeight: '1',
    textAlign: 'left',
} as React.CSSProperties;

const subheader = {
    color: '#969DA3',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    fontSize: '12pt',
    fontStyle: 'italic',
    lineHeight: '1',
    textAlign: 'left',
} as React.CSSProperties;

const subheaderHtml = {
    color: '#969DA3',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    fontSize: '15pt',
    fontStyle: 'italic',
    lineHeight: '1',
    textAlign: 'left',
} as React.CSSProperties;

const paragraphs = {
    color: 'white',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    fontSize: '11pt',
    fontWeight: 360,
    textAlign: 'left',
    msoLineHeightRule: 'exactly',
    lineHeight: '150%',
    margin: '0',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
} as React.CSSProperties;

const paragraphsHtml = {
    color: 'white',
    fontFamily: 'Helvetica Neue, Arial, sans-serif',
    fontSize: '11pt',
    fontWeight: 360,
    textAlign: 'left',
    msoLineHeightRule: 'exactly',
    lineHeight: '150%',
    margin: '0',
    wordBreak: 'break-word',
    whiteSpace: 'pre',
} as React.CSSProperties;

const tdImageHtmlImage = {
    marginRight: '18px',
    marginBottom: '22px',
    marginTop: '5px',
    width: '320px',
    background: '#E79851',
    align: 'left',
    display: 'inline-block',
    float: 'left',
} as React.CSSProperties;

const tdImageImage = {
    marginRight: '18px',
    marginBottom: '22px',
    marginTop: '5px',
    width: '320px',
    textAlign: 'left',
    background: '#E79851',
    align: 'left',
    display: 'inline-block',
    float: 'left',
} as React.CSSProperties;

const tdImageHtml = {
    width: '320px',
} as React.CSSProperties;

const tdImage = {
    width: '320px',
    textAlign: 'left',
} as React.CSSProperties;

const outerDivTextWrap = {
    maxWidth: '800px',
    margin: '0 auto',
    marginLeft: '10px',
    overflow: 'hidden',
} as React.CSSProperties;

const innerDivTextWrap = {
    padding: '8px 36px 8px 44px',
} as React.CSSProperties;

const textWrap = {
    maxWidth: '780px',
    margin: '0 auto',
} as React.CSSProperties;

const lightBlue = {
    color: '#73A7D5',
    textDecoration: 'none',
} as React.CSSProperties;

const center = {
    lineHeight: '100%',
    align: 'center',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '98pt',
} as React.CSSProperties;

const centerHtml = {
    lineHeight: '100%',
    align: 'center',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '98pt',
} as React.CSSProperties;

const subTable = {
    border: '0',
    borderCollapse: 'separate',
    lineHeight: '100%',
    align: 'center',
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
} as React.CSSProperties;

const subTD = {
    align: 'center',
    background: '#F89518',
    bgcolor: '#F89518',
    border: 'none',
    cursor: 'auto',
    msoPaddingAlt: '8pt 12pt',
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
} as React.CSSProperties;

const subButtonHtml = {
    align: 'center',
    background: '#F89518',
    color: '#fefefe',
    display: 'inline-block',
    fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
    fontSize: '12pt',
    fontWeight: 'normal',
    lineHeight: '120%',
    margin: '0',
    msoPaddingAlt: '0px',
    padding: '8pt 12pt',
    textDecoration: 'none',
    textTransform: 'uppercase',
    width: '84pt',
    textAlign: 'center',
} as React.CSSProperties;

const subButton = {
    align: 'center',
    background: '#F89518',
    color: '#fefefe',
    display: 'inline-block',
    fontFamily: 'Ubuntu, Helvetica, Arial, sans-serif',
    fontSize: '11pt',
    fontWeight: 'normal',
    lineHeight: '120%',
    margin: '0',
    msoPaddingAlt: '0px',
    padding: '8pt 12pt',
    textDecoration: 'none',
    textTransform: 'uppercase',
    width: '90pt',
    textAlign: 'center',
} as React.CSSProperties;

const PressContent = ({ press, isHtml }: { press: Campaign['template']['press']; isHtml: boolean }): ReactElement => {
    const baseUrl = BaseUrl();
    return (
        <div className="PressContent">
            {isHtml &&
                `<!--[if mso | IE]>
                        <table
                        align="center" border="0" cellpadding="0" cellspacing="0" class="" style="width:600pt;background:#0B1539;background-color:#0B1539;" bgcolor="#0B1539" width="600pt"
                        >
                        <tr>
                            <td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;background:#0B1539;background-color:#0B1539;" bgcolor="#0B1539">
                    <![endif]-->`}
            <div style={outsideDiv}>
                <table cellPadding="0" cellSpacing="0" role="presentation" style={firstTable}>
                    <tbody>
                        <tr>
                            <td
                                className="mj-column-px-600 mj-outlook-group-fix"
                                style={isHtml ? innerWidthHtml : innerWidth}
                            >
                                {isHtml &&
                                    `<!--[if mso | IE]>
                                            <table role="presentation" border="0" cellpadding="0" cellspacing="0">

                                            <tr>

                                            <td
                                            class="" style="vertical-align:top;width:450pt;"
                                            >
                                        <![endif]-->`}

                                <table
                                    cellPadding="0"
                                    cellSpacing="0"
                                    role="presentation"
                                    style={isHtml ? innerWidthTableHtml : innerWidthTable}
                                    width="100%"
                                >
                                    <tr>
                                        <td style={centerImage}>
                                            {isHtml &&
                                                `<!--[if mso | IE]>
                                                        <table
                                                            align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                        >
                                                            <tr>
                                                        
                                                                <td>
                                                    <![endif]-->`}
                                            <table
                                                cellPadding="0"
                                                cellSpacing="0"
                                                role="presentation"
                                                style={borderCollapseImage}
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td align="center" style={tdLogo}>
                                                            {isHtml &&
                                                                `<!--[if mso | IE]>
                                                        <table
                                                            align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                        >
                                                            <tr>
                                                        
                                                                <td>
                                                    <![endif]-->`}
                                                            <a href="https://www.afsbirsttr.af.mil/" target=" ">
                                                                <img
                                                                    src={`${baseUrl}/images/templates/SBIR-STTR-New.png`}
                                                                    alt="SBIR STTR logo"
                                                                    style={logo}
                                                                    width={220}
                                                                    height={72}
                                                                />
                                                            </a>
                                                            {isHtml &&
                                                                `<!--[if mso | IE]>
                                                            </td>
                                                            
                                                        </tr>
                                                        </table>
                                                    <![endif]-->`}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {isHtml &&
                                                `<!--[if mso | IE]>
                                                            </td>
                                                            
                                                        </tr>
                                                        </table>
                                                    <![endif]-->`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={isHtml ? tdTextHtml : tdText}>
                                            <div style={isHtml ? pressReleaseHtml : pressRelease}>Press Release</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={isHtml ? tdTextHtml : tdText}>
                                            <div style={isHtml ? dateHtml : date}>{press.date}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={tdLine}>
                                            <p style={line}> </p>
                                            {isHtml &&
                                                `<!--[if mso | IE]>
                                                        <table
                                                        align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 2pt #B48D35;font-size:1pt;margin:0px auto;width:600pt;" role="presentation" width="600pt"
                                                        >
                                                        <tr>
                                                            <td style="height:0;line-height:0;">
                                                            &nbsp;
                                                            </td>
                                                        </tr>
                                                        </table>
                                                    <![endif]-->`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={isHtml ? tdTextHtml : tdText}>
                                            <div style={isHtml ? headlineHtml : headline}>{press.headline}</div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={isHtml ? tdTextHtml : tdText}>
                                            <div style={isHtml ? subheaderHtml : subheader}>{press.subheadline}</div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={isHtml ? tdImageHtml : tdImage}>
                                            {isHtml &&
                                                `<!--[if mso | IE]>
                                                    <table
                                                        align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                    >
                                                        <tr>
                                                    
                                                            <td style="padding:25px 0 0 0;">
                                                    <![endif]-->`}
                                            <table
                                                cellPadding="0"
                                                cellSpacing="0"
                                                role="presentation"
                                                style={borderCollapseImage1}
                                            >
                                                <tbody>
                                                    <tr>
                                                        {isHtml &&
                                                            `<!--[if mso]>
    <table border="0" cellpadding="0" cellspacing="0" width="" align="left" style="width:810px;"0><tr><td>
    <![endif]-->`}
                                                        <td style={textWrap}>
                                                            <div style={outerDivTextWrap}>
                                                                <div style={innerDivTextWrap}>
                                                                    {isHtml &&
                                                                        `<!--[if mso]>
            <table border="0" cellpadding="0" cellspacing="0" width="320" align="left" style="width:320px;"><tr><td style="padding:0 20px 20px 5px;">
            <![endif]-->`}

                                                                    <img
                                                                        height="auto"
                                                                        src={press.imageURL}
                                                                        alt="From SBIR website"
                                                                        style={isHtml ? tdImageHtmlImage : tdImageImage}
                                                                        width="320"
                                                                    />
                                                                    {isHtml &&
                                                                        ` <!--[if mso]>
                                                                        </td></tr></table>

                                                                    <![endif]-->`}
                                                                    <p style={isHtml ? paragraphsHtml : paragraphs}>
                                                                        {press.description}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {isHtml &&
                                                            `<!--[if mso]>
    </td></tr></table>
    <![endif]-->`}
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {isHtml &&
                                                `<!--[if mso | IE]>
                                                            </td>
                                                            
                                                        </tr>
                                                        </table>
                                                    <![endif]-->`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={isHtml ? tdTextHtml : tdText}>
                                            {isHtml &&
                                                `<!--[if mso]>
            <table><tr><td style="padding:0 0 0 5px;">
            <![endif]-->`}
                                            <div style={paragraphs}>
                                                For more information regarding this matter, please call{' '}
                                                {press.contactName || 'Contact Name'} at{' '}
                                                <a
                                                    href={`tel:${press.contactNum}`}
                                                    className="link-nostyle"
                                                    style={lightBlue}
                                                >
                                                    {' '}
                                                    {press.contactNum.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') ||
                                                        'Contact Phone'}
                                                </a>{' '}
                                                or email at{' '}
                                                <a
                                                    href={`mailto:${press.contactEmail}`}
                                                    className="link-nostyle"
                                                    style={lightBlue}
                                                >
                                                    {press.contactEmail || 'Contact Email'}
                                                </a>
                                                .
                                            </div>
                                            {isHtml &&
                                                `<!--[if mso]>
                                                            </td>
                                                            
                                                        </tr>
                                                        </table>
                                                    <![endif]-->`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={isHtml ? tdTextHtml : tdText}>
                                            {isHtml &&
                                                `<!--[if mso]>
            <table><tr><td style="padding:0 0 0 5px;">
            <![endif]-->`}
                                            <div style={paragraphs}>
                                                Stay up to date on the latest news from the SBIR/STTR Program by
                                                subscribing to our monthly newsletter today.
                                            </div>
                                            {isHtml &&
                                                `<!--[if mso]>
                                                            </td>
                                                            
                                                        </tr>
                                                        </table>
                                                    <![endif]-->`}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td vertical-align="middle" style={centerImage}>
                                            {isHtml &&
                                                `<!--[if mso | IE]>
                                                        <table
                                                            align="center" border="0" cellpadding="0" cellspacing="0" role="presentation"
                                                        >
                                                            <tr>
                                                        
                                                                <td>
                                                    <![endif]-->`}
                                            <table cellPadding="0" cellSpacing="0" role="presentation" style={subTable}>
                                                <tbody style={isHtml ? centerHtml : center}>
                                                    <tr>
                                                        <td
                                                            align="center"
                                                            role="presentation"
                                                            style={subTD}
                                                            valign="middle"
                                                        >
                                                            <a
                                                                style={isHtml ? subButtonHtml : subButton}
                                                                // href={'https://www.afsbirsttr.af.mil'}
                                                                // style={subButton}
                                                                href="https://www.afsbirsttr.af.mil"
                                                                target=" "
                                                            >
                                                                subscribe{' '}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {isHtml &&
                                                `<!--[if mso | IE]>
                                                            </td>
                                                            
                                                        </tr>
                                                        </table>
                                                    <![endif]-->`}
                                        </td>
                                    </tr>
                                </table>

                                {isHtml &&
                                    `<!--[if mso | IE]>
                                            </td>

                                            </tr>

                                                </table>
                                        <![endif]-->`}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};
export default PressContent;
