import React, { ReactElement } from 'react';

import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useSelector } from 'react-redux';

import LinkTable, { LinkData } from 'components/linkTable/LinkTable';
import { getPercentage } from 'components/utils/StringUtils';
import { Store } from 'store/reducers';

import './CampaignReportLinkDetails.scss';

// Can be changed to ENV variable if needed for Demo - decision made 10/4/21
const realType = 'no';
const real = realType === 'no' ? 'no' : 'real';

const CampaignReportLinkDetails = (): ReactElement => {
    const realData = useSelector((storeState: Store) => storeState.Campaign);

    const fauxData = {
        linkData: [
            {
                clickAddress: 'http://localhost:3000/preferences/0b837/12',
                clickCount: 1,
                uniqueClickCount: 1,
            },
            { clickAddress: 'https://twitter.com/AF_SBIR_STTR', clickCount: 6, uniqueClickCount: 5 },
            { clickAddress: 'https://www.afsbirsttr.af.mil/', clickCount: 1, uniqueClickCount: 1 },
            { clickAddress: 'https://www.afsbirsttr.af.mil/event_link', clickCount: 22, uniqueClickCount: 21 },
            { clickAddress: 'https://www.facebook.com/afsbirsttr/', clickCount: 3, uniqueClickCount: 2 },
            { clickAddress: 'https://www.linkedin.com/company/10864292', clickCount: 3, uniqueClickCount: 3 },
        ],
    };

    let campaign;
    if (realType === 'no') {
        campaign = fauxData;
    } else {
        campaign = realData;
    }

    const getLinks = ({ linkType }: { linkType: string }): LinkData[] => {
        let links: LinkData[] = [];

        campaign.linkData?.forEach((l) => {
            links.push({
                link: l.clickAddress,
                clicks: l.uniqueClickCount ? l.uniqueClickCount : 0,
                overall: l.clickCount ? l.clickCount : 0,
            });
        });
        const social: string[] = ['facebook', 'twitter', 'linkedin', 'instagram', 'youtube', 'dvidshub'];
        const appLinks: string[] = ['/preferences', '/unsubscribe'];
        if (linkType === 'social') {
            links = links.filter((linkA) => {
                return social.find((domain) => {
                    return linkA.link.includes(domain) || linkA.link === 'https://www.afsbirsttr.af.mil/';
                });
            });
        } else if (linkType === 'subscribers') {
            links = links.filter((linkA) => {
                return appLinks.find((domain) => {
                    return linkA.link.includes(domain);
                });
            });
            const mergedLinks: LinkData[] = [];
            mergedLinks.push({ link: 'Update Preferences', clicks: 0, overall: 0 });
            mergedLinks.push({ link: 'Unsubscribe', clicks: 0, overall: 0 });
            links.forEach((l) => {
                if (l.link.includes(appLinks[0])) {
                    mergedLinks[0].clicks += l.clicks;
                    mergedLinks[0].overall += l.overall;
                } else if (l.link.includes(appLinks[1])) {
                    mergedLinks[1].clicks += l.clicks;
                    mergedLinks[1].overall += l.overall;
                }
            });

            links = mergedLinks;
        } else {
            links = links.filter((linkA) => {
                return !social.find((domain) => {
                    return linkA.link.includes(domain) || linkA.link === 'https://www.afsbirsttr.af.mil/';
                });
            });
            links = links.filter((linkA) => {
                return !appLinks.find((domain) => {
                    return linkA.link.includes(domain);
                });
            });
        }

        return links;
    };

    return (
        <div className="CampaignReportLinkDetails">
            <h5>Clicks</h5>
            <h6>
                General CTR -&nbsp;
                {real === 'real'
                    ? getPercentage(
                          campaign.campaignAnalytics?.withClicksCount || 0,
                          campaign.campaignAnalytics?.deliveredCount || 1
                      )
                    : getPercentage(36, 222)}
                <Tooltip title="CTR is the number of clicks divided by the number of deliveries">
                    <InfoOutlinedIcon />
                </Tooltip>
            </h6>
            <LinkTable type="Campaign Links" data={getLinks({ linkType: 'campaign' })} />
            <LinkTable type="Social Links" data={getLinks({ linkType: 'social' })} />
            <LinkTable type="Contact Status" data={getLinks({ linkType: 'subscribers' })} />
        </div>
    );
};

export default CampaignReportLinkDetails;
