import React, { ReactElement, useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { NotificationNavIcon, ProfileNavIcon } from './index';

import './NavIcons.scss';

interface Dictionary<T> {
    [details: string]: T;
}

const useOutsideAlerter = (
    ref: React.RefObject<HTMLDivElement>,
    setNavState: React.Dispatch<React.SetStateAction<Dictionary<boolean>>>
): void => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = (event: MouseEvent): void => {
            if (ref.current && !ref.current.contains(event.target as HTMLButtonElement)) {
                setNavState({
                    search: false,
                    profile: false,
                    company: false,
                    notifications: false,
                    settings: false,
                });
            }
        };

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return (): void => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, setNavState]);
};

export const IconContainer = (): ReactElement => {
    const wrapperRef = useRef(null);
    const location = useLocation();

    // const user = useSelector((storeState: Store) => storeState.User);
    const [navOpenState, setNavState] = useState({
        search: false,
        profile: false,
        company: false,
        notifications: false,
        settings: false,
    } as Dictionary<boolean>);

    useEffect(() => {
        setNavState({
            search: false,
            profile: false,
            company: false,
            notifications: false,
            settings: false,
        });
    }, [location]);

    const handleProfileNavIconOpened = (): void => {
        setNavState({
            search: false,
            profile: true,
            company: false,
            notifications: false,
            settings: false,
        });
    };
    const handleNotificationsNavIconOpened = (): void => {
        setNavState({
            search: false,
            profile: false,
            company: false,
            notifications: false, // to re-enable notifications set this to true
            settings: false,
        });
    };

    useOutsideAlerter(wrapperRef, setNavState);

    return (
        <div ref={wrapperRef} className="icon-container">
            <NotificationNavIcon opened={handleNotificationsNavIconOpened} overlayOn={navOpenState.notifications} />
            <ProfileNavIcon opened={handleProfileNavIconOpened} overlayOn={navOpenState.profile} />
        </div>
    );
};

export default IconContainer;
