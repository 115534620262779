import React, { ReactElement } from 'react';

import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { GET_CAMPAIGN_TIME_GRAPH } from 'api/queries';
import { Store } from 'store/reducers';

import './TimeHistoryGraph.scss';

const timeGraphSim = [
    {
        chunksize: '0 Hours',
        senddate: '2021-09-29T17:15:11.792Z',
        datecalculated: 'SEP 29, 2021 - 17:15',
        clicked: '0',
        opened: '0',
    },
    {
        chunksize: '12 Hours',
        senddate: '2021-09-29T17:15:11.792Z',
        datecalculated: 'SEP 30, 2021 - 05:15',
        clicked: '6',
        opened: '39',
    },
    {
        chunksize: '24 Hours',
        senddate: '2021-09-29T17:15:11.792Z',
        datecalculated: 'SEP 30, 2021 - 17:15',
        clicked: '15',
        opened: '49',
    },
    {
        chunksize: '36 Hours',
        senddate: '2021-09-29T17:15:11.792Z',
        datecalculated: 'OCT 01, 2021 - 05:15',
        clicked: '16',
        opened: '51',
    },
    {
        chunksize: '48 Hours',
        senddate: '2021-09-29T17:15:11.792Z',
        datecalculated: 'OCT 01, 2021 - 17:15',
        clicked: '25',
        opened: '56',
    },
    {
        chunksize: '60 Hours',
        senddate: '2021-09-29T17:15:11.792Z',
        datecalculated: 'OCT 02, 2021 - 05:15',
        clicked: '25',
        opened: '59',
    },
    {
        chunksize: '72 Hours',
        senddate: '2021-09-29T17:15:11.792Z',
        datecalculated: 'OCT 02, 2021 - 17:15',
        clicked: '30',
        opened: '68',
    },
    {
        chunksize: '84 Hours',
        senddate: '2021-09-29T17:15:11.792Z',
        datecalculated: 'OCT 03, 2021 - 05:15',
        clicked: '36',
        opened: '70',
    },
    {
        chunksize: '96 Hours',
        senddate: '2021-09-29T17:15:11.792Z',
        datecalculated: 'OCT 03, 2021 - 17:15',
        clicked: 'null',
        opened: 'null',
    },
];

const TimeHistoryGraphSim = (): ReactElement => {
    const campaign = useSelector((storeState: Store) => storeState.Campaign);
    const { id } = campaign;
    const getTimeGraphData = useQuery(GET_CAMPAIGN_TIME_GRAPH, {
        variables: { id },
        fetchPolicy: 'cache-and-network',
    }).data;
    const timeGraphData = getTimeGraphData?.TimeGraph;

    const hourGroup = getTimeGraphData?.TimeGraph[1].chunksize;

    // Can be changed to ENV variable if needed for Demo - decision made 10/4/21
    const realType = 'no';
    const real = realType === 'no' ? 'no' : 'real';

    let graph;
    if (realType !== 'no') {
        if (hourGroup === '6 Hours') {
            graph = 'First 48 Hours';
        } else if (hourGroup === '12 Hours') {
            graph = 'First 4 Days';
        } else {
            graph = 'First Week';
        }
    } else graph = 'First 4 Days';

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${label}`}</p>
                    <p className="opens">{`Opens: ${payload[0].value}`}</p>
                    <p className="clicks">{`Clicks: ${payload[1].value}`}</p>
                    <p className="date">{payload[0].payload.datecalculated}</p>
                </div>
            );
        }

        return null;
    };

    return (
        <div className="TimeHistoryGraph">
            <p>Time History</p>
            <h2 className="TimeSpan">Audience Engagement - {graph} </h2>
            <ResponsiveContainer height={230} width="98%">
                <LineChart
                    data={real === 'real' ? timeGraphData : timeGraphSim}
                    margin={{
                        top: 10,
                        right: 10,
                        left: 0,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="chunksize" />
                    <YAxis />
                    <Tooltip
                        content={<CustomTooltip />}
                        wrapperStyle={{
                            backgroundColor: 'white',
                            padding: '10px',
                            borderRadius: '4px',
                            border: '1px solid #C8CFD6',
                        }}
                    />
                    <Legend align="right" verticalAlign="top" iconType="rect" height={36} />
                    <Line
                        name="Opens"
                        type="monotone"
                        dataKey="opened"
                        stroke="#0170B5"
                        activeDot={{ strokeWidth: 2, r: 6 }}
                    />
                    <Line
                        name="Clicks"
                        type="monotone"
                        dataKey="clicked"
                        stroke="#7986CB"
                        activeDot={{ strokeWidth: 2, r: 6 }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};
export default TimeHistoryGraphSim;
