import React, { ReactElement } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { GET_SEGMENT_NAMES, GET_SEGMENTS_CONTACTS } from 'api/queries/index';
import { getCaseInsensitiveSortOrderIndex } from 'components/utils/ReactTableUtils';
import ViewContactsTable from 'pageComponents/contact/viewContacts/ViewContactsTable';
import SegmentDetails from 'pageComponents/segment/segmentDetails/SegmentDetails';
import { selectAudience } from 'store/actions/Campaign';
import { showDialog } from 'store/actions/Dialog';
import { Store } from 'store/reducers/index';

import 'assets/sass/ReactTableStyle.scss';
import './SelectAudience.scss';

const SelectAudience = ({
    showFieldRequired,
    validationAnimation,
    endAnimation,
}: {
    showFieldRequired: boolean;
    validationAnimation: boolean;
    endAnimation: () => void;
}): ReactElement => {
    const { segmentIds, segmentNames } = useSelector((storeState: Store) => storeState.Campaign.selectAudience);
    const segmentsContacts = useQuery(GET_SEGMENTS_CONTACTS, { variables: { ids: segmentIds } });
    const contactData = segmentsContacts.data?.Contacts;
    const segmentsOptions = useQuery(GET_SEGMENT_NAMES, { variables: { audienceId: 1 }, fetchPolicy: 'network-only' });
    const dispatch = useDispatch();

    const data = React.useMemo(() => {
        return contactData ?? [];
    }, [contactData]);

    const handleSegmentSelect = (values): void => {
        segmentsContacts.refetch();
        const idArray = values ? values.map((item) => item.value) : [];
        const nameArray = values ? values.map((item) => item.label) : [];
        dispatch(selectAudience({ segmentIds: idArray, segmentNames: nameArray }));
    };

    return (
        <div className="CampaignAudience">
            <Grid container justify="flex-end" spacing={2}>
                <Grid item xs={8}>
                    <h2 className="Title">Select audience segment(s)</h2>
                </Grid>
                <Grid item xs={4} className="ViewContactsBtnGrid">
                    <Button
                        id="openContactsBtn"
                        className="ViewContactsBtn"
                        onClick={() =>
                            dispatch(
                                showDialog(
                                    true,
                                    'Audience Preview',
                                    <ViewContactsTable contactData={data} />,
                                    <Button
                                        id="cancelButton"
                                        color="primary"
                                        onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                                    >
                                        CLOSE
                                    </Button>,
                                    'lg'
                                )
                            )
                        }
                        disabled={isEmpty(data)}
                        color="primary"
                    >
                        {`VIEW CONTACTS (${data.length})`}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <p className="FieldTitle">Select Audience Segment(s) (required)</p>
                </Grid>
                <Grid item xs={12} className="SelectGrid">
                    {segmentsOptions.data?.Segments ? (
                        <>
                            <Select
                                defaultValue={
                                    segmentNames
                                        ? segmentNames.map((name, index) => {
                                              return { label: name, value: segmentIds[index] };
                                          })
                                        : null
                                }
                                className={
                                    showFieldRequired && segmentIds.length === 0
                                        ? 'SegmentSelect SegmentSelect__Validation'
                                        : 'SegmentSelect'
                                }
                                options={segmentsOptions.data?.Segments.slice()
                                    .sort((obj1, obj2) => getCaseInsensitiveSortOrderIndex(obj1.label, obj2.label))
                                    .reverse()}
                                placeholder="Select or Search Audience Segment(s)"
                                closeMenuOnSelect={false}
                                isMulti
                                onChange={handleSegmentSelect}
                            />
                            {showFieldRequired && segmentIds.length === 0 && (
                                <p
                                    className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                                    onAnimationEnd={endAnimation}
                                >
                                    *Fill in Missing Field
                                </p>
                            )}
                        </>
                    ) : (
                        <p id="loadingSegmentsError">Loading Segments</p>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <p className="SelectedSegmentsPlaceholder">Selected Segment(s)</p>
                </Grid>
                {segmentIds?.length > 0 &&
                    segmentIds.map((id) => {
                        return (
                            <Grid key={id} item xs={12}>
                                <SegmentDetails id={id} />
                            </Grid>
                        );
                    })}
            </Grid>
        </div>
    );
};
export default SelectAudience;
