import React from 'react';

import { Button, FormLabel, Grid, Tooltip } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { partition } from 'lodash';
import { useDispatch } from 'react-redux';
import { Column, Row, useFlexLayout, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { allCategoryNames } from 'components/utils/GlobalConstants';
import { getBasicColumns } from 'components/utils/ReactTableUtils';
import { MatchingColumns, matchNewColumns } from 'components/utils/UploadContactsUtils';
import { showDialog } from 'store/actions/Dialog';
import MatchColumnForm from './MatchColumnForm';

import 'assets/sass/ReactTableStyle.scss';
import './ImportManagement.scss';

const MAX_CONTACTS_SHOWN = 5;

const ImportManagement = ({
    props,
}: {
    props: {
        contacts: any[];
        columns: React.MutableRefObject<MatchingColumns[] | undefined>;
        openMatchingColumnsForm: () => void;
        forceUpdate: () => void;
    };
    showFieldRequired: boolean;
    endAnimation: () => void;
    validationAnimation: boolean;
}): JSX.Element => {
    const matchColumnFormId = 'matchColumnForm';
    const { contacts, columns, openMatchingColumnsForm, forceUpdate } = props;
    const dispatch = useDispatch();

    const openMatchingColumnForm = (columnName): void => {
        dispatch(
            showDialog(
                true,
                `Edit Column Label: "${columnName}"`,
                <MatchColumnForm
                    columnName={columnName}
                    columns={columns.current ?? []}
                    formId={matchColumnFormId}
                    onSubmit={(formData) => {
                        matchNewColumns(columns, formData);
                        forceUpdate();
                        dispatch(showDialog(false, '', undefined, undefined));
                    }}
                />,
                <>
                    <Button
                        id="cancelButton"
                        color="primary"
                        onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                    >
                        CANCEL
                    </Button>
                    <Button type="submit" variant="contained" color="primary" form={matchColumnFormId}>
                        SAVE CHANGES
                    </Button>
                </>,
                'xs',
                true
            )
        );
    };

    const newContacts = React.useMemo(() => {
        if (columns !== undefined) {
            return contacts.map((contact) => {
                const newContact = {};
                Object.keys(contact).forEach((key) => {
                    const matchedColumn = columns.current?.find((column) => {
                        return column.originalColumn.accessor === key;
                    });
                    if (matchedColumn) {
                        newContact[matchedColumn.newColumn.accessor as string] = contact[key];
                    } else {
                        newContact[key] = contact[key];
                    }
                });
                return newContact;
            });
        }
        return contacts;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contacts, columns.current]);

    const data = React.useMemo(() => {
        return newContacts.slice(0, MAX_CONTACTS_SHOWN) ?? [];
    }, [newContacts]);

    const displayedContacts = React.useMemo((): Row[] => {
        // get all of the columns in every row
        const tmpColumns = getBasicColumns(newContacts, false);
        // create a list of the first X contacts with all columns
        // in case any are missing
        const tmpContacts = data.map((contact) => {
            const fillerColumnValues = {};
            tmpColumns.forEach((column) => {
                fillerColumnValues[column.accessor as string] = '';
            });
            return {
                ...fillerColumnValues,
                ...contact,
            };
        });
        // get the proper column widths
        return tmpContacts;
    }, [newContacts, data]);

    const tableColumns = React.useMemo((): Column[] => {
        return getBasicColumns(displayedContacts, false);
    }, [displayedContacts]);

    const contactTableInstance = useTable({ columns: tableColumns, data }, useFlexLayout, useSticky);

    const matchedOrIgnoredColumns =
        columns.current?.filter(
            (column) =>
                column &&
                ((column.newColumn.Header && allCategoryNames.includes(column.newColumn.Header as string)) ||
                    column.ignored)
        ) || [];

    const [matchedColumns, notMatchedColumns] = partition(
        columns.current,
        (column) => column && column.newColumn.Header && allCategoryNames.includes(column.newColumn.Header as string)
    );

    return (
        <div className="ImportManagement">
            <div className="ImportManagement__TextBody">
                <FormLabel component="legend">
                    Unrecognized columns will not be imported. Please rename designated columns to match our suggested
                    column headers.
                </FormLabel>
                <Grid container alignItems="flex-end" justify="space-between">
                    <Grid item>
                        <p data-testid="contacts-info">
                            <Tooltip
                                title={matchedColumns
                                    .map((column) => column.originalColumn.Header as string)
                                    .join(', ')}
                                className="ColumnsTooltip"
                            >
                                <div>{matchedColumns.length}</div>
                            </Tooltip>{' '}
                            columns will be imported.{' '}
                            <Tooltip
                                title={notMatchedColumns
                                    .map((column) => column.originalColumn.Header as string)
                                    .join(', ')}
                                className="ColumnsTooltip"
                            >
                                <div>{notMatchedColumns.length}</div>
                            </Tooltip>{' '}
                            columns will not be imported. <br />
                            {`Showing ${Math.min(MAX_CONTACTS_SHOWN, contacts.length)} of ${
                                contacts.length
                            } recognized contacts.`}
                        </p>
                    </Grid>
                    <Grid item className="OpenMatchingColumnsFormButton">
                        <Button color="primary" onClick={openMatchingColumnsForm}>
                            EDIT COLUMN LABELS
                        </Button>
                    </Grid>
                </Grid>

                <div {...contactTableInstance.getTableProps()} id="matchingColumnsTable" className="Table Sticky">
                    {contactTableInstance.headerGroups.map((headerGroup) => (
                        <div
                            {...headerGroup.getHeaderGroupProps({ style: { display: 'inline-flex' } })}
                            className="Tr Header"
                        >
                            {headerGroup.headers.map((tableColumn, index) => {
                                const customHeaderStyle: Record<string, unknown> = {};
                                let columnState = '';

                                const matchingColumn =
                                    columns.current?.find((column) => {
                                        return column.originalColumn.Header === tableColumn.Header;
                                    }) || undefined;

                                // change background color based on state
                                if (matchingColumn && matchingColumn.ignored) {
                                    columnState = 'ignored';
                                } else if (
                                    !matchedOrIgnoredColumns
                                        .map((column) => column.newColumn.Header as string)
                                        .includes(tableColumn.Header as string)
                                ) {
                                    columnState = 'unmatched';
                                }

                                if (columnState === 'ignored') {
                                    customHeaderStyle.backgroundColor = '#0000001F';
                                    customHeaderStyle.color = '#9FA4A9';
                                } else if (columnState === 'unmatched') {
                                    customHeaderStyle.backgroundColor = '#FDECEB';
                                    customHeaderStyle.border = '1px solid #C62828';
                                    customHeaderStyle.color = '#C62828';
                                }

                                return (
                                    <div
                                        {...tableColumn.getHeaderProps({
                                            style: {
                                                ...customHeaderStyle,
                                            },
                                        })}
                                        className="Th Header__IsNotSorted"
                                        onClick={() => {
                                            openMatchingColumnForm(tableColumn.Header);
                                        }}
                                        onKeyDown={(event) => {
                                            if (event?.key === 'Enter') {
                                                openMatchingColumnForm(tableColumn.Header);
                                            }
                                        }}
                                        role="button"
                                        tabIndex={0}
                                        data-testid={`header-${index}`}
                                    >
                                        <div className="CustomTableHeader">
                                            {columnState === 'unmatched' && <ErrorOutlineIcon htmlColor="#C62828" />}
                                            <div className="CustomTableHeader__Name">
                                                {tableColumn.render('Header')}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                    <div {...contactTableInstance.getTableBodyProps()}>
                        {contactTableInstance.rows.map((row) => {
                            contactTableInstance.prepareRow(row);
                            return (
                                <div {...row.getRowProps()} className="Tr">
                                    {row.cells.map((cell) => {
                                        const customCellStyle: Record<string, unknown> = {};
                                        let columnState = '';

                                        // change background color based on state
                                        if (
                                            columns.current?.find((column) => {
                                                return column.newColumn.Header === cell.column.Header && column.ignored;
                                            })
                                        ) {
                                            columnState = 'ignored';
                                        } else if (
                                            !matchedOrIgnoredColumns
                                                .map((column) => column.newColumn.Header as string)
                                                .includes(cell.column.Header as string)
                                        ) {
                                            columnState = 'unmatched';
                                        }

                                        if (columnState === 'ignored') {
                                            customCellStyle.backgroundColor = '#0000001F';
                                            customCellStyle.color = '#9FA4A9';
                                        }

                                        return (
                                            <div
                                                {...cell.getCellProps({
                                                    style: {
                                                        ...customCellStyle,
                                                    },
                                                })}
                                                className="Td"
                                            >
                                                {cell.render('Cell')}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImportManagement;
