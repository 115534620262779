import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { Button, Chip, LinearProgress } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { useKeycloak } from '@react-keycloak/web';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import { GET_CONTACTS_FROM_EMAIL } from 'api/queries';
import { createApolloClient } from 'components/providers/Apollo';
import { getBasicColumns } from 'components/utils/ReactTableUtils';
import showSnackbarError from 'components/utils/ShowSnackbarError';
import { fileHandler, MatchingColumns } from 'components/utils/UploadContactsUtils';
import { showDialog } from 'store/actions/Dialog';

import './Upload.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Upload({
    props,
    showFieldRequired,
    endAnimation,
    validationAnimation,
    handleStep,
}: {
    props: {
        contacts: any[];
        setContacts: React.Dispatch<React.SetStateAction<any[]>>;
        setFileName: React.Dispatch<React.SetStateAction<string>>;
        filename: string;
        setDuplicateContacts: React.Dispatch<React.SetStateAction<any[]>>;
        columns: React.MutableRefObject<MatchingColumns[] | undefined>;
        successfulContacts: React.MutableRefObject<any[] | undefined>;
    };
    showFieldRequired: boolean;
    endAnimation: () => void;
    validationAnimation: boolean;
    handleStep: (step: number, reset?: boolean) => void;
}): ReactElement {
    const { contacts, setContacts, setFileName, filename, setDuplicateContacts, columns, successfulContacts } = props;
    const { keycloak } = useKeycloak();
    const token = keycloak?.token;
    const apolloClient = createApolloClient(token);
    const dispatch = useDispatch();
    const [stepNumber, setStepNumber] = useState<number>(0);
    const onDrop = useCallback(
        (files) => {
            setFileName(files[0].name);
            return fileHandler(dispatch, files[0], setContacts, setStepNumber); // needs to return parse data
        },
        [dispatch, setContacts, setFileName]
    );
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.csv, .xlsx',
    });

    const baseUrl = window.location.origin;

    useEffect(() => {
        async function advanceStepper() {
            if (contacts.length > 0 && stepNumber > 0 && columns.current === undefined) {
                const contactColumns = getBasicColumns(contacts, false);

                columns.current = contactColumns.map((column) => {
                    return {
                        newColumn: { Header: column.Header as string, accessor: column.accessor as string },
                        originalColumn: { Header: column.Header as string, accessor: column.accessor as string },
                        ignored: false,
                    };
                });

                const emailAddresses = contacts
                    // eslint-disable-next-line dot-notation
                    .filter((contactObject) => contactObject['emailAddress'])
                    // eslint-disable-next-line dot-notation
                    .map((contactObject) => contactObject['emailAddress']);

                // check for duplicate contacts in database
                try {
                    const result = await apolloClient.query({
                        query: GET_CONTACTS_FROM_EMAIL,
                        errorPolicy: 'ignore',
                        variables: { audienceId: 1, emailAddresses },
                    });
                    const duplicateContacts = result.data.Contacts.map((contact) => ({ ...contact, audienceId: 1 }));
                    setDuplicateContacts(duplicateContacts);
                } catch (err) {
                    showSnackbarError(dispatch, err);
                }

                handleStep(stepNumber);
                setStepNumber(0);
            }
        }
        advanceStepper();
    }, [apolloClient, columns, contacts, dispatch, handleStep, setDuplicateContacts, stepNumber]);

    return (
        <div className="container">
            {showFieldRequired && contacts.length === 0 && (
                <p
                    className={validationAnimation ? 'MissingField Shake' : 'MissingField'}
                    onAnimationEnd={endAnimation}
                >
                    *Upload a file to continue
                </p>
            )}
            {contacts.length > 0 && stepNumber > 0 && (
                <div>
                    <p className="UploadedText">File Uploading:</p>
                    <LinearProgress />
                </div>
            )}
            {contacts.length > 0 && stepNumber === 0 && (
                <div className="ChipContainer">
                    <p className="UploadedText">File Uploaded:</p>
                    <Chip
                        data-testid="Chip"
                        icon={<InsertDriveFileIcon />}
                        label={filename}
                        onDelete={() => {
                            dispatch(
                                showDialog(
                                    true,
                                    'Are you sure you want to cancel this import?',
                                    <p className="UploadExitModalText">
                                        If you cancel now, these contacts will not be imported and progress will not be
                                        saved.
                                    </p>,
                                    <>
                                        <Button
                                            id="cancelButton"
                                            color="primary"
                                            onClick={() => dispatch(showDialog(false, '', undefined, undefined))}
                                        >
                                            NO, KEEP FILE
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                dispatch(showDialog(false, '', undefined, undefined));
                                                setContacts([]);
                                                handleStep(0, true);
                                                columns.current = undefined;
                                                successfulContacts.current = undefined;
                                            }}
                                        >
                                            YES, CANCEL
                                        </Button>
                                    </>
                                )
                            );
                        }}
                    />
                </div>
            )}
            {contacts.length === 0 && (
                <div className="UploadContainer">
                    <div className="TextContainer">
                        <p className="QuestionText">Please upload your contact list.</p>
                    </div>
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <div className="DropzoneWrapper">
                            <input {...getInputProps()} />
                            <CloudUploadIcon className="UploadIcon" />
                            <p className="DirectionText">Drag file here or click to browse for a file to upload.</p>
                            <div className="FormatText">The following format are supported: .xlsx</div>
                        </div>
                    </div>

                    <div>
                        <p className="UploadSample">
                            For best upload results:{' '}
                            <a href={`${baseUrl}/templates/Upload_Template.xlsx`} className="Download" download>
                                Download Sample Template
                            </a>
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}
export default Upload;
