import React, { ReactElement } from 'react';

import Notifications from 'components/notifications/Notifications';
import NavIcon from './NavIcon';

interface NotificationNavIconProps {
    overlayOn: boolean;
    opened: () => void;
}

export const NotificationNavIcon = ({ opened, overlayOn }: NotificationNavIconProps): ReactElement => {
    const notifications = [];
    const disabled = true;

    return !disabled ? (
        <NavIcon
            iconTitle="Notifications"
            icon="notification"
            onOpen={opened}
            overlayOn={overlayOn}
            badgeCount={notifications.length}
            disableOverlay
        >
            {{
                title: (
                    <>
                        <div className="heading">Notifications ({notifications.length})</div>
                    </>
                ),
                content: <Notifications notifications={notifications} />,
            }}
        </NavIcon>
    ) : (
        <div />
    );
};

export default NotificationNavIcon;
