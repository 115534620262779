import { gql } from '@apollo/client';

const GET_SEGMENT = gql`
    query findSegment($id: Float!) {
        Segment: findSegment(id: $id) {
            id
            name
            description
            filterQuery
            contacts {
                id
                emailAddress
            }
        }
    }
`;

const GET_SEGMENTS = gql`
    query findSegments($audienceId: Float!) {
        Segments: findSegments(audienceId: $audienceId, active: true) {
            id
            name
            description
            filterQuery
            createdDate
            createdBy
        }
    }
`;

const GET_SEGMENTS_WITH_COUNT = gql`
    query findSegmentsWithCount($audienceId: Float!, $filters: String, $sort: SortInput, $pagination: PaginationInput) {
        SegmentsWithCountResponse: findSegmentsWithCount(
            audienceId: $audienceId
            filters: $filters
            sort: $sort
            pagination: $pagination
            isArchived: false
        ) {
            segments {
                id
                name
                description
                filterQuery
                createdDate
                createdBy {
                    firstName
                    lastName
                }
                lastUsedDate
                contacts {
                    emailAddress
                }
            }
            nonPaginatedCount
        }
    }
`;

const GET_SEGMENT_NAMES = gql`
    query findSegmentNames($audienceId: Float!) {
        Segments: findSegments(audienceId: $audienceId, active: true) {
            value: id
            label: name
        }
    }
`;

const GET_SEGMENT_CONTACTS = gql`
    query findSegmentContacts($id: Float) {
        Contacts: findSegmentContacts(id: $id) {
            emailAddress
            firstName
            lastName
            phoneNumber
            jobTitle
            companyName
            city
            state
            naicsCode
            dunsNumber
        }
    }
`;

const GET_SEGMENTS_CONTACTS = gql`
    query findSegmentsContacts($ids: [Float!]!) {
        Contacts: findSegmentsContacts(segmentIds: $ids) {
            emailAddress
            firstName
            lastName
            phoneNumber
            jobTitle
            companyName
            city
            state
            naicsCode
            dunsNumber
        }
    }
`;

const GET_SEGMENTS_CONTACTS_COUNT = gql`
    query findSegmentsContactsCount($ids: [Float!]!) {
        SegmentContactCount: findSegmentsContactsCount(segmentIds: $ids)
    }
`;

export {
    GET_SEGMENT,
    GET_SEGMENTS,
    GET_SEGMENT_NAMES,
    GET_SEGMENT_CONTACTS,
    GET_SEGMENTS_CONTACTS,
    GET_SEGMENTS_CONTACTS_COUNT,
    GET_SEGMENTS_WITH_COUNT,
};
