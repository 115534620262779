import React, { ReactElement } from 'react';

import { TextField } from '@material-ui/core';

import { InputProps } from 'typings/InputProps';

const Input = ({
    name,
    onChange,
    maxChar = 1024,
    label,
    className,
    id,
    value,
    showCharRemaining,
    showUrlMessage,
    imageRatioWidth,
}: InputProps): ReactElement => {
    return (
        <div className="InputGroup inputDiv">
            <TextField
                title={name}
                label={label}
                variant="outlined"
                color="primary"
                name={name}
                onChange={onChange}
                className={`form-control ${className}`}
                id={`${id}`}
                aria-describedby="basic-addon3"
                inputProps={{
                    maxLength: maxChar,
                }}
                size="small"
                onFocus={(e): void => e.target.select()}
                value={value}
            />

            {showCharRemaining && (
                <span className="CharRemain">
                    Characters Remaining: <b className="boldCount">{maxChar - value.length}</b>/{maxChar}
                </span>
            )}
            {imageRatioWidth && <span className="CharRemain">{`Recommended Image Ratio ${imageRatioWidth}:1 `}</span>}
            {showUrlMessage && (
                <span className="CharRemain">URL&apos;s must contain http:// or https:// at the beginning</span>
            )}
        </div>
    );
};
export default Input;
