import { ReactElement } from 'react';

import { DialogProps } from '@material-ui/core';

import { Action } from 'store/';
import { SHOW_DIALOG } from 'store/actions/Dialog';

export interface State {
    show: boolean;
    title: string;
    content: ReactElement | undefined;
    actionButtons: ReactElement | undefined;
    maxWidth?: DialogProps['maxWidth'];
    fullWidth?: boolean;
}

const initialState: State = {
    show: false,
    title: '',
    content: undefined,
    actionButtons: undefined,
    maxWidth: 'sm',
    fullWidth: true,
};

export default (state = initialState, action: Action): State => {
    const { type, payload } = action;
    switch (type) {
        case SHOW_DIALOG: {
            return { ...state, ...payload };
        }
        default:
            return { ...state };
    }
};
