import BaseUrl from 'components/utils/BaseUrl';
import { dateConstructor } from 'components/utils/DateUtil';
import { Campaign } from 'typings/Campaign';

const baseUrl = BaseUrl();
const article = {
    title: 'Article Title',
    preview: 'Article Preview',
    articleURL: 'https://www.afsbirsttr.af.mil/',
    imageURL:
        'https://images.unsplash.com/photo-1517429128955-67ff5c1e29da?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
};
const articlesArray = Array(8).fill(article);

const { date: dateString, day } = dateConstructor();
const initialState: Campaign = {
    id: undefined,
    isSent: false,
    campaignAnalytics: null,
    dateModified: undefined,
    createdDate: undefined,
    sendDate: undefined,
    campaignTab: 0,
    reviewMode: false,
    selectAudience: {
        segmentIds: [],
        segmentNames: [],
    },
    campaignDetails: {
        campaignName: '',
        subject: '',
        senderEmail: '',
        senderName: '',
    },
    template: {
        type: 0,
        newsletter: {
            tagline: 'Tagline',
            articleCount: 2,
            date: dateString,
            welcomeMessage: 'Welcome Message',
            articles: articlesArray,
        },
        event: {
            date: dateString,
            day,
            time: '12:00',
            location: 'Location',
            description: 'Event Description',
            name: 'EVENT NAME',
            url: 'https://www.afsbirsttr.af.mil/',
            imageURL: `${baseUrl}/images/templates/event2.jpg`,
        },
        press: {
            date: dateString,
            headline: 'Headline',
            subheadline: 'Sub-Headline',
            description: 'Description',
            contactName: 'Contact Name',
            contactNum: 'Contact Phone',
            contactEmail: 'Contact Email',
            imageURL:
                'https://images.unsplash.com/photo-1515700845405-9ffd33692a68?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1955&q=80',
        },
    },
    linkData: [],
};
export default initialState;
