import { ActiveSegment } from '../../typings/Segment';
import { CHANGE_SEGMENT } from '../actions/Segment';

const initialState: ActiveSegment = {
    name: 'All Contacts',
    id: 1,
};

interface Request {
    type: string;
    payload: ActiveSegment;
}

export default (state = initialState, { type, payload }: Request): ActiveSegment => {
    const { name, id } = { ...payload };
    switch (type) {
        case CHANGE_SEGMENT: {
            return { ...state, name, id };
        }
        default:
            return state;
    }
};
