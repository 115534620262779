import React, { ReactElement } from 'react';

import SortIcon from '@material-ui/icons/Sort';
import { camelCase, isEmpty, startCase } from 'lodash';
import { Column, ColumnInstance, Row } from 'react-table';
import XLSX from 'xlsx';

import { allCategoryNames, hiddenColumns } from 'components/utils/GlobalConstants';

import './ReactTableUtils.scss';

export const pageSizeOptions = [25, 50, 100];

export const generateSortingIndicator = (column: ColumnInstance<Record<string, unknown>>): ReactElement | string => {
    if (typeof column.render('Header') === 'string') {
        if (column.isSorted && column.isSortedDesc) {
            return <SortIcon className="SortIcon Flip" />;
        }
        return <SortIcon className="SortIcon" />;
    }
    return '';
};

export const getCaseInsensitiveSortOrderIndex = (valueA: string | number, valueB: string | number): number => {
    const stringA = valueA ? String(valueA) : '';
    const stringB = valueB ? String(valueB) : '';
    const sameInsensitiveValue = stringA.toLowerCase() === stringB.toLowerCase();
    const sameStartLetter = stringA.charAt(0) === stringB.charAt(0);
    if (isEmpty(stringA) && isEmpty(stringB)) return 0;
    if (isEmpty(stringA)) return 1;
    if (isEmpty(stringB)) return -1;

    if (sameInsensitiveValue && sameStartLetter) {
        return getCaseInsensitiveSortOrderIndex(stringA.substring(1), stringB.substring(1));
    }
    if (sameInsensitiveValue) {
        if (stringA.charAt(0) < stringB.charAt(0)) return -1;
    }
    if (stringA.toLowerCase() < stringB.toLowerCase()) return -1;
    return 1;
};

export const getColumnWidth = (
    rows: Array<Row>,
    accessor: string,
    headerText: string,
    withSorting: boolean
): number => {
    const maxWidth = 400;
    const magicSpacing = 12;
    const magicNumberTypeSpacing = 1.5;
    const magicSortingSpacing = 3;
    const maxWidthValue = rows.reduce((maxRow, currentRow) => {
        return currentRow[accessor] && currentRow[accessor]?.length > (maxRow[accessor]?.length || 0)
            ? currentRow
            : maxRow;
    }, rows[0])[accessor];
    const maxWidthBasedOnCellValues = maxWidthValue?.length || 0;
    // calc special width if it's a number or phone number
    const maxWidthBasedOnCellValuesIfNumber = /^[\d-\s\\(\\)]+$/.test(maxWidthValue) ? maxWidthValue.length : 0;
    const cellLength = Math.max(
        headerText.length + (withSorting ? magicSortingSpacing : 0),
        maxWidthBasedOnCellValues,
        maxWidthBasedOnCellValuesIfNumber * magicNumberTypeSpacing
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
};

const getHeaderRow = (rows: Array<Row>): Array<string> => {
    const headers: string[] = [];
    Object.keys(rows[0].values).forEach(function getHeaders(property) {
        if (property !== 'id') {
            headers.push(property);
        }
    });
    return headers;
};

export const downloadRowData = (rows: Array<Row>): void => {
    // if contains only one row (probably header row) or less, don't do anything
    if (rows.length === 0) return;

    // convert table objects into array of arrays for XLSX
    let rowAOA: string[][] = [];
    const headers: string[] = getHeaderRow(rows);
    rows.forEach((rowObj) => {
        const rowData: string[] = [];
        headers.forEach(function getRowValues(property): void {
            if (property !== 'id') {
                const valueString = String(rowObj.values[property]);
                rowData.push(valueString);
            }
        });

        rowAOA.push(rowData);
    });
    rowAOA = [headers, ...rowAOA];

    // convert row array of arrays to a single worksheet in a workbook & export the workbook
    const worksheet = XLSX.utils.aoa_to_sheet(rowAOA);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet);
    XLSX.writeFile(workbook, 'SheetJSTableExport.xlsx');
};

export const getBasicColumns = (contactData: any[], validOnly = true, withSorting = true): Column[] => {
    if (contactData?.length > 0) {
        const camelCaseColumnNames: Array<string> = [];
        const allCamelCaseColumns = allCategoryNames.map((x) => camelCase(x));
        const allCamelCaseHiddenColumns = hiddenColumns.map((x) => camelCase(x));
        contactData.forEach((row) => {
            Object.keys(row).forEach((columnName: string) => {
                const camelCaseColumnName = camelCase(columnName);
                if (!allCamelCaseHiddenColumns.includes(camelCaseColumnName)) {
                    const columnIndex = allCamelCaseColumns.indexOf(camelCaseColumnName);
                    if ((columnIndex !== -1 || !validOnly) && !camelCaseColumnNames.includes(camelCaseColumnName)) {
                        camelCaseColumnNames.push(camelCaseColumnName);
                    }
                }
            });
        });

        let indexOfEmailAddress = -1;
        const tmpColumns = camelCaseColumnNames.map((camelCaseColumnName, index) => {
            const capitalizedColumnName = allCategoryNames[camelCaseColumnName] || startCase(camelCaseColumnName);
            if (camelCaseColumnName === 'emailAddress') {
                indexOfEmailAddress = index;
            }
            return {
                Header: capitalizedColumnName,
                accessor: camelCaseColumnName,
                width: getColumnWidth(contactData, camelCaseColumnName, capitalizedColumnName, withSorting),
            };
        });

        // if email address is in this list, make sure it's the first one so we can make it sticky if necessary

        if (indexOfEmailAddress >= 0) {
            const emailAddressColumn = { ...tmpColumns.splice(indexOfEmailAddress, 1)[0], sticky: 'left' };
            tmpColumns.splice(0, 0, emailAddressColumn);
            return tmpColumns;
        }
        return tmpColumns;
    }
    return [];
};
