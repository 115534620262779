import React, { ReactElement, useState } from 'react';

import { Button } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';

import logo from 'assets/images/SBIR-STTR Solid White Horizontal.png';

import './ExternalHeader.scss';

const ExternalHeader = (): ReactElement => {
    const [versionNumber] = useState(process.env.REACT_APP_VERSION || 'unknown');
    const { keycloak } = useKeycloak();

    return (
        <header className="external-main-head">
            <img className="SBIRlogo" src={logo} alt="AFWERX SBIR/STTR logo" title={`Version ${versionNumber}`} />
            {keycloak.authenticated && (
                <Button
                    className="LogoutButton"
                    onClick={() => keycloak.logout({ redirectUri: window.location.origin })}
                >
                    Logout
                </Button>
            )}
        </header>
    );
};

export default ExternalHeader;
