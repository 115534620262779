import React from 'react';

import ReactDOM from 'react-dom';

import App from './App';
import Providers from './components/providers/Providers';

ReactDOM.render(
    <App>
        <Providers />
    </App>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// register();   <--- disabling this for now as we are getting unwanted 'over caching' issues
